import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
export default {
    name: 'ToolAutocomplete',
    components: { JobInfoChip },
    props: [
        'manufacturerId', 'value'
    ],
    data() {
        return {
            products: [],
            search: '',
            userInteracted: false,
            debounceFetchProducts: () => {
            }
        };
    },
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({}, this.$listeners, {
                input: function (val) {
                    vm.$emit('input', val);
                }
            });
        }
    },
    watch: {
        search(val) {
            if (this.userInteracted) {
                this.debounceFetchProducts();
            }
        },
        manufacturerId(val) {
            if (this.userInteracted) {
                this.debounceFetchProducts();
            }
        }
    },
    methods: {
        get,
        async fetchProducts() {
            let params = {
                search: this.search,
                manufacturer_id: this.manufacturerId ?? null,
            };
            params.manufacturer_id = this.manufacturerId;
            const tools = await this.$http.get('https://legac-fa-tool-data-api-staging.azurewebsites.net/api/v1/products', { params: params });
            this.products = tools.data.data;
        },
        async onFocus() {
            this.userInteracted = true;
            await this.fetchProducts();
        }
    },
    created() {
        this.debounceFetchProducts = debounce(this.fetchProducts, 500);
    },
    mounted() {
    }
};
