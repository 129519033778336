<script>

import {LOG_STYLES} from "@/common/log";
import PhotoManagerListItem from "@/components/Photos/PhotoManagerListItem.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import {get} from "@/client/utilities/Object";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import DeletePhotoButton from "@/components/Photos/DeletePhotoButton.vue";

export default {
    name: "PhotoManager",
    logContext: 'PhotoManager',
    logStyle: LOG_STYLES.PURPLE,
    components: {DeletePhotoButton, PhotoThumbnail, ConfirmDialog, PhotoManagerListItem},
    inheritAttrs: false,
    props: {
        photos: {
            type: Array,
            default: () => []
        },
        appNotificationTitle: {
            type: String,
            default: ''
        },
        appNotificationContent: {
            type: String,
            default: ''
        },
        appNotificationUrl: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: () => {
            }
        },
        idx: {
            type: Number,
            default: 0
        },
        category: {
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        context: {
            type: Object,
            default: () => {
            }
        },
        uploaded: {
            type: Boolean,
            default: false
        },
        failed: {
            type: Boolean,
            default: false
        },
        validate: {
            type: Boolean,
            default: false
        },
        formRequestId: {
            type: [String, Number],
            default: ''
        },
        purchaseOrderId: {
            type: [String, Number],
            default: ''
        },
        purchaseOrderLineItemId: {
            type: [String, Number],
            default: ''
        },
        purchaseOrderJobId: {
            type: [String, Number],
            default: ''
        },
        warehouseInventoryItemId: {
            default: ''
        },
        toolId: {
            type: [String, Number],
            default: ''
        },
        toolReservationId: {
            type: [String, Number],
            default: ''
        },
        rack: {
            type: [String, Number],
            default: ''
        },
        section: {
            type: [String, Number],
            default: ''
        },
        subSection: {
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        allowMobileDevice() {
            return false;
            // return (this.appNotificationTitle && this.appNotificationContent && this.appNotificationUrl);
        }
    },
    data: function () {
        return {
            headers: [
                {text: 'Photo', value: 'photo'},
                {text: 'Filename', value: 'isDuplicate'},
                {text: 'User', value: 'user.name'},
                {text: 'Date', value: 'created_at'},
                {text: 'Delete', value: 'delete'},
            ],
            loading: false,
            photo: null,
            progress: {
                uploading: false,
                uploaded: false,
                processing: false,
                value: 0,
                color: 'primary'
            },
            uploading: false,
            uploadProgress: 0,
            uploadProcessing: false,
            uploadMessage: '',
            uploadColor: 'accent',
            deleting: false
        }
    },
    methods: {
        onDelete(index) {
            this.photos.splice(index, 1);
        },
        useMobileDevice() {
            let data = {
                name: 'use-mobile-device-for-photo',
                message: {},
                item: this.item,
            };

            let message = {};
            message.title = this.appNotificationTitle;
            message.content = this.appNotificationContent;

            if (this.appNotificationUrl) {
                message.action_type = 'url';
                message.action_value = this.appNotificationUrl;
            }

            data.message = message;

            this.$http.post('/v2/app-notifications', data).then((response) => {
                this.$toast.success('Request sent!');
            });
        },
        isValid() {
            return true;
        },
        isUploading(isUploading = true) {
            this.uploading = isUploading;
            this.$emit('uploading', this.uploading);
        },
        savePhoto() {

            if (this.photo && this.isValid()) {

                try {
                    const selectedData = {
                        photo: this.photo,
                        url: URL.createObjectURL(this.photo)
                    };

                    this.$emit('selected', selectedData);
                } catch (e) {

                }

                this.loading = 'orange';

                let data = new FormData();

                data.append('photo', this.photo);
                data.append('category_id', this.category);

                if (this.purchaseOrderId) {
                    data.append('purchase_order_id', this.purchaseOrderId);
                }

                if (this.purchaseOrderLineItemId) {
                    data.append('purchase_order_line_item_id', this.purchaseOrderLineItemId);
                }

                if (this.purchaseOrderJobId) {
                    data.append('purchase_order_job_id', this.purchaseOrderJobId);
                }

                if (this.warehouseInventoryItemId) {
                    data.append('warehouse_inventory_item_id', this.warehouseInventoryItemId);
                }

                if (this.rack) {
                    data.append('rack', this.rack);
                }

                if (this.section) {
                    data.append('section', this.section);
                }

                if (this.subSection) {
                    data.append('sub_section', this.subSection);
                }

                if (this.formRequestId) {
                    data.append('form_request_id', this.formRequestId);
                }

                if (this.toolId) {
                    data.append('tool_id', this.toolId);
                }

                if (this.toolReservationId) {
                    data.append('tool_reservation_id', this.toolReservationId);
                }

                if (typeof this.context === 'object') {
                    for (const ctx of this.context) {
                        data.append(ctx.key, this.context[ctx]);
                        this.$log.info(`Appending Context ${ctx}: ${this.context[ctx]}`);
                    }
                }

                this.isUploading();

                this.$http.post('/v2/photos/upload', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: this.onUploadProgress
                }).then(response => {
                    if (response.data && response.data.photo) {
                        this.onUploaded(response.data.photo);
                    } else {
                        throw new Error('No Photo Returned');
                    }

                }).catch(error => {
                    this.onFailed(error);
                })
                    .finally(() => {
                        this.photo = null;
                        this.loading = false;
                        this.isUploading(false);
                    });
            }
        },
        onFailed(error) {
            this.$toast.error('Error Uploading Photo');
            this.$log.error('Error Uploading Photo', error);
            this.$emit('failed');
            this.uploadProgress = 0;
            this.uploadMessage = 'Failed to upload photo.';
            this.uploadColor = 'error';
        },
        onUploaded(photo) {

            if (this.photos) {
                this.photos.unshift(photo);
            }

            this.$emit('uploaded', photo);
            this.uploadProcessing = false;
        },
        deletePhoto(photo) {
            let id = get(photo, 'id');
            if (id) {
                photo.deleting = true;
                this.$set(this.photos, this.photos, true);
                setTimeout(() => {
                    this.$http.delete('/v2/photos/' + id).then(response => {
                        this.$emit('deleted');
                        this.onDelete(photo);
                    }).catch((error) => {
                        this.$emit('failed');
                    }).finally(() => {
                        photo.deleting = false;
                    });
                }, 500);
            }
        }
    },
    created() {
        if (!this.photos) {
            this.photos = [];
        }
    }
}
</script>

<template>
    <div class="my-2 mb-4">
        <VRow>
            <VCol>
                <VFileInput
                    v-model="photo"
                    v-bind:rules="rules"
                    accept="image/*"
                    outlined
                    clearable
                    label="Add Photo"
                    :loading="uploading"
                    placeholder="Add Photo"
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    :show-size="1000"
                    hide-details
                    @change="savePhoto()"
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                            color="accent"
                            dark
                            label
                        >
                            {{ text }}
                        </v-chip>
                    </template>
                    <!-- <template v-slot:append-outer v-if="allowMobileDevice">
                         <VBtn
                             @click="useMobileDevice(item, idx)"
                             color="accent"
                             small
                         >
                             Use Phone
                         </VBtn>
                     </template> -->
                </VFileInput>
            </VCol>
        </VRow>
        <VRow v-show="photos.length > 0">
            <VCol>
                <v-data-table
                    :items="photos"
                    :headers="headers"
                    :items-per-page="5"
                >
                    <template v-slot:item.photo="{ item }">
                        <PhotoThumbnail
                            :photo="item"
                            width="40"
                        />
                    </template>
                    <template v-slot:item.isDuplicate="{item}">
                        {{ item.original_filename }}

                        <VChip label color="warning" class="ml-2 pa-1" x-small v-show="item.is_duplicate">Duplicate
                        </VChip>
                    </template>
                    <template v-slot:item.delete="{item}">

                        <DeletePhotoButton
                            :photo="item"
                            @failed="onFailed"
                            @deleted="onDelete"
                        />
                    </template>
                </v-data-table>
            </VCol>
        </VRow>
    </div>
</template>