import Axios from "axios";
import Cache from "@/common/cache";
import {get} from "@/client/utilities/Object";
import AuthService from "@/common/auth.service";

const CompanyService = {
    companies: [],
    prepend: [{
        id: -1,
        name: 'All Companies',
        slug: 'ALL'
    }],
    default: null,
    defaultCompany: {
        id: -1,
        name: 'All Companies',
        slug: 'ALL'
    },
    defaultCompanyId: -1,
    async fetchCompanies() {
        let companies = await Axios.get('/v3/companies')
            .then(response => {
                return response.data.companies
                    .filter(company => company.id < 10000);
            }).catch(error => {
                console.error('Error getting companies', error);
            });

        if (!companies) {
            companies = [];
        }

        companies.unshift(...this.prepend);

        return companies;
    },
    async get() {
        this.companies = await Cache.get('companies', async () => {
            return await this.fetchCompanies();
        });

        return this.companies;
    },
    async getDefault() {
        try {
            let user = await AuthService.getUser();

            if (!user || user.default_company_id !== null) {
                return this.defaultCompany;
            }

            this.default = await Cache.get(`${get(user, 'id', 'none')}_default_company`, async () => {
                return this.companies.find(company => company.id === user.default_company_id);
            });

        } catch (e) {
            console.error('Error getting default company', e);
        }

        return this.default;
    },
    async setDefault(user, company) {
        try {
            return await Axios.post('/v3/companies/default-company', {
                default_company_id: company.id
            }).then(response => {
                this.store(`${get(user, 'id', 'none')}_default_company`, company);
                console.log('Successfully set default company', response);
                return true;
            });
        } catch (e) {
            console.log('Unable to set default company', e);
        }

        return false;
    }
}

export default CompanyService;