<template>
    <v-container>
        <PageTitle>
            Purchase Order Lookup and Import Form
        </PageTitle>

        <FormSubmittedMessage
            v-if="!showForm"
            button-text="New PO Lookup and Import Form"
            :on-click="resetForm"
        >
            {{ serverResponseMessage }}
        </FormSubmittedMessage>

        <v-form v-else v-model="formValid" ref="form">
            <v-card class="mb-8">
                <v-card-text v-if="!user">
                    <v-combobox
                        v-model="employeeName"
                        :items="presetEmployees"
                        :rules="[requiredField]"
                        clearable
                        filled
                        label="Your name"
                        placeholder="Start typing to Search or Add a new name"
                        prepend-inner-icon="mdi-account-search"
                        return-object
                    ></v-combobox>
                </v-card-text>
                <v-card-text>
                    <NotesTextField
                        label="PO Import Notes"
                        v-model="poImportNotes"
                        rows="1"
                    />
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field
                                label="PO Number"
                                v-model="poNumber"
                                outlined
                                prepend-inner-icon="mdi-tag-search-outline"
                                :rules="[requiredField]"
                                @keyup="alert.show=false"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn
                                @click="getPO"
                                block
                                style="min-height:50px;"
                                color="primary"
                                :disabled="!poNumber"
                                :loading="poLookupLoading"
                            >Lookup PO
                            </v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text
                    v-show="alert.show"
                    class="py-2 pt-0"
                >
                    <v-alert
                        close-text="Close Alert"
                        prominent
                        dense
                        dismissible
                        :type="alert.type"
                    >
                        {{ alert.message }}
                    </v-alert>
                </v-card-text>
            </v-card>

            <v-row v-show="lineItems.length > 0">
                <v-col cols="12">
                    Items
                    <v-divider></v-divider>
                </v-col>
            </v-row>

            <v-card
                class="my-3"
                v-for="(part, p) in lineItems"
                :key="p"
            >
                <v-card-text class="pa-4">
                    <v-row>
                        <v-col cols="6" sm="6" lg="6">
                            <v-textarea
                                label="Material Number"
                                :value="part[13]"
                                disabled
                                dense
                                outlined
                                rows="1"
                                auto-grow
                                no-resize
                            ></v-textarea>
                        </v-col>
                        <v-col cols="6" sm="6" lg="6">
                            <v-textarea
                                label="Part description"
                                :value="part[14]"
                                disabled
                                outlined
                                dense
                                rows="1"
                                auto-grow
                                no-resize
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" sm="3" lg="4">
                            <v-text-field
                                label="Quantity"
                                v-model="part[15]"
                                :rules="[requiredField]"
                                type="number"
                                dense
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" lg="5">
                            <v-select
                                ref="part"
                                label="Foreman"
                                v-model="selectedForeman"
                                :rules="[requiredField]"
                                :items="foremans"
                                dense
                                outlined
                            ></v-select>
                        </v-col>
                        <!-- Button to remove more part -->
                        <v-col
                            cols="2" md="3" lg="2"
                            class="text-center"
                        >
                            <!-- <div> -->
                            <v-btn @click="lineItems.splice(p, 1)" color="error">
                                <v-icon left>mdi-selection-remove</v-icon>
                                Remove
                            </v-btn>
                            <!-- </div> -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>


            <!-- Submit button -->
            <v-row v-show="this.lineItems.length > 0">
                <v-col>
                    <v-btn
                        @click="submitForm"
                        block
                        color="primary"
                        style="min-height: 50px"
                        :disabled="!formValid"
                        :loading="loading"
                    >Submit
                    </v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import {PurchaseOrderFormService} from "../common/api/api.service";
import FormSubmittedMessage from "../components/FormSubmittedMessage.vue";
import NotesTextField from "../components/NotesTextField.vue";
import PageTitle from "../components/PageTitle.vue";
import PurchaseOrdersAutocomplete from "../components/PurchaseOrdersAutocomplete.vue";

export default {
    components: {PageTitle, NotesTextField, PurchaseOrdersAutocomplete, FormSubmittedMessage},
    title: 'Purchase Order Lookup and Import Form',
    data() {
        return {
            showForm: true,
            serverResponseStatus: "",
            serverResponseMessage: "",
            loading: false,
            poLookupLoading: false,
            formValid: false,
            requiredField: (v) => !!v || "This is a required field",

            // Alert:
            alert: {
                show: false,
                type: undefined,
                message: "",
            },

            employeeName: "",
            presetEmployees: [],
            poImportNotes: "",
            poNumber: "",

            headers: [], //headers of the 'Import' sheet
            lineItems: [], // line items of the current PO
            foremans: [], // possible foremans
            selectedForeman: "", // selected foreman
        };
    },
    computed: {
        form() {
            return {
                employeeName: this.employeeName,
                poImportNotes: this.poImportNotes,
                lineItems: this.lineItems,
            };
        }
    },
    watch: {
        selectedForeman: function (newVal, oldVal) {
            // Set the same foreman to all line items
            this.lineItems.forEach(function (item, index) {
                item[17] = newVal;
            });
        },
        poImportNotes: function (newVal, oldVal) {
            console.log(` PO # ${newVal}`);
        }
    },
    methods: {
        getPO() {

            this.poLookupLoading = true;
            this.serverResponseStatus = "";
            this.serverResponseMessage = "";

            this.alert  = {
                show: false,
                type: "error",
                message: ""
            };

            PurchaseOrderFormService.getFormData({poNumber: this.poNumber})
                .then((res) => {
                    const results = res.data;

                    this.serverResponseMessage = results.message;
                    this.serverResponseStatus = results.status

                    this.presetEmployees = results['employeeNames'];
                    this.poLookupLoading = false;
                    if (results.status !== "success") {
                        this.alert = {
                            type: "error",
                            message: results.message,
                            show: true,
                        };
                        this.lineItems = [];
                    } else {
                        this.alert = {show: false};
                        this.headers = results.headers;
                        // console.log(`headers: ${this.headers}`);
                        this.lineItems = results.lineItems;
                        // console.log(`lineItems: ${this.lineItems}`);
                        this.foremans = results.foremans.sort();

                        // Set `selectedForeman` to the first foreman
                        this.selectedForeman = results.lineItems[0][17];
                    }
                })
                .catch((err) => {
                    this.$toast.error('Error looking up purchase order.');
                })
                .finally(() => {
                    this.poLookupLoading = false;
                });
        },
        submitForm() {
            this.loading = true;

            PurchaseOrderFormService.submitForm(this.form)
                .then((response) => {
                    const results = response.data;

                    this.serverResponseMessage = results.response;
                    this.loading = false;
                    this.showForm = false;
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.loading = false;
                });
        },
        resetForm() {
            window.location.reload();
        },
    },
};
/*
import PurchaseOrderForm from "@/components/PurchaseOrderForm";

export default {
    // name: "App",
    title: 'Purchase Order Form',

    components: {
        PurchaseOrderForm,
    },

    data: () => ({
        //
    }),
};*/
</script>
