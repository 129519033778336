<script>
import Avatar from "@/common/avatar";

export default {
    title: 'Sidebar',
    logContext: 'Sidebar',
    data() {
        return {
            sidebar: {
                items: [
                    {
                        text: 'Home',
                        to: '/',
                        icon: {
                            name: 'mdi-home'
                        }
                    },
                    {
                        text: 'Requests',
                        to: null,
                        icon: {
                            name:'mdi-home-import-outline',
                            color: 'light-blue'
                        },
                        items: [
                            {
                                text: 'View Approval Requests',
                                to: '/approval/requests'
                            },
                            {
                                text: 'Receiving Request',
                                to: '/requests/receiving'
                            },
                            {
                                text: 'RMA Request',
                                to: '/requests/rma'
                            },
                            {
                                text: 'Transmittal Request',
                                to: '/requests/transmittal'
                            }
                        ]
                    },
                    /*  {
                          text: 'Transmittal',
                          to: null,
                          icon: {
                              name:'mdi-truck-delivery',
                              color: 'green'
                          },
                          items: [
                              {
                                  text: 'New Transmittal Request',
                                  to: '/requests/transmittal'
                              },
                              {
                                  text: 'View Transmittal Requests',
                                  to: '/list/requests/transmittal'
                              }
                          ]
                      },
                     */
                    {
                        text: 'Warehouse Inventory',
                        to: '/warehouse-inventory',
                        icon: {
                            name: 'mdi-clipboard-text',
                            color: 'green'
                        }
                    },
                    {
                        text: 'Receive Form',
                        to: '/receive-form',
                        icon: {
                            name:'mdi-home-import-outline',
                            color: 'light-blue'
                        }
                    },
                    {
                        text: 'Transmittal Form',
                        to: '/transmit-form',
                        icon: {
                            name:'mdi-truck-delivery',
                            color: 'green'
                        }
                    },
                    {
                        text: 'Owner Provided Materials Receiving Form',
                        to: '/receive-owner-materials-form',
                        icon: {
                            name: 'mdi-import',
                            color: 'purple lighten-2'
                        }
                    },
                    {
                        text: 'Return to Warehouse from Job Restock Form',
                        to: '/restock-form',
                        icon: {
                            name: 'mdi-home-import-outline',
                            color: 'indigo lighten-2'
                        }
                    },
                    {
                        text: 'Inventory Form',
                        to: '/inventory-form',
                        icon: {
                            name: 'mdi-order-bool-ascending-variant',
                            color: 'cyan'
                        }
                    },
                    {
                        text: 'Add Purchase Order Form',
                        to: '/po-form',
                        icon: {
                            name: 'mdi-cart-plus',
                            color: 'red lighten-1'
                        }
                    },
                    {
                        text: 'Project Kit Creation Form',
                        to: '/kit-form',
                        icon: {
                            name: 'mdi-tag-multiple',
                            color: 'green lighten-1'
                        }
                    },
                    {
                        text: 'RMA Form',
                        to: '/rma-form',
                        icon: {
                            name: 'mdi-cash-refund',
                            color: 'yellow darken-3'
                        }
                    },
                    {
                        text: 'Reports',
                        to: null,
                        icon: {
                            name: 'mdi-chart-multiple',
                            color: 'light-blue'
                        },
                        items: [
                            {
                                text: 'Photo Reports',
                                to: '/reports/photos'
                            },
                        ]
                    },
                    {
                        text: 'About',
                        to: '/about',
                        icon: {
                            name: 'mdi-help-box',
                        }
                    },
                ]
            }
        };
    },
    computed: {
        isAuthenticated() {
            return this.authenticated();
        }
    },
    methods: {
        toggleSidebar() {
            console.log('toggle');
        },
        generateAvatar(name) {
            const avatar = new Avatar(name);
            return avatar.toDataURL();

            let initials = name.split(' ').map(function(str) { return str ? str[0].toUpperCase() : "";}).join('');
            let canvas = document.createElement('canvas');
            let radius = 30;
            let margin = 5;
            canvas.width = radius*2+margin*2;
            canvas.height = radius*2+margin*2;

            // Get the drawing context
            let ctx = canvas.getContext('2d');
            ctx.beginPath();
            ctx.arc(radius+margin,radius+margin, radius, 0, 2 * Math.PI, false);
            ctx.closePath();
            ctx.fillStyle = 'grey';
            ctx.fill();
            ctx.fillStyle = "white";
            ctx.font = "25px Arial";
            ctx.textAlign = 'center';
            ctx.fillText(initials, radius+5,radius*4/3+margin);

            return canvas.toDataURL();
        }
    },
    created() {

    },
    mounted() {

    },
    props: ['isOpen']
}
</script>

<template>
    <v-navigation-drawer
            v-model="isOpen"
            app
            width="320"
            color="navDrawerBackground"
            fixed
            elevation="3"
            temporary
    >
        <v-list-item
                class="px-4 py-1 navDrawerHeaderBackground"
        >
            <v-img
                    class="float-left"
                    contain
                    src="/img/icons/logo-icon.svg"
            />
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
                two-line
                class="px-2 navDrawerHeaderBackground"
                v-if="user"
        >
            <v-list-item-avatar>
                <img :src="generateAvatar(user.name)" alt="">
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>

        <v-divider></v-divider>

        <VList
                nav
                dense
        >
            <div
                    v-for="(item, idx) in sidebar.items"
                    :key="`nav${idx}`"
            >
                <VListGroup
                        v-if="item.items"
                        key="nav_if"
                        active-class="none"
                        :value="true"
                        no-action
                >
                    <template v-slot:activator>
                        <VListItemIcon class="mr-2" v-if="item.icon">
                            <v-icon left>{{ item.icon.name }}</v-icon>
                        </VListItemIcon>
                        <VListItemTitle>{{ item.text }}</VListItemTitle>
                    </template>

                    <VListItem
                            v-for="(subItem, subIdx) in item.items"
                            :key="`subItem${subIdx}`"
                            :to="subItem.to"
                            link
                            class="pl-12"
                    >
                        <VListItemContent style="overflow-x:auto;">
                            <VListItemTitle>{{ subItem.text }}</VListItemTitle>
                        </VListItemContent>
                        <VListItemIcon v-if="subItem.icon">
                            <VIcon right :color="subItem.icon.color">
                                {{ subItem.icon.name }}
                            </VIcon>
                        </VListItemIcon>
                    </VListItem>
                </VListGroup>

                <VListItem v-else key="nav_else" link :to="item.to" :key="idx">
                    <VListItemIcon v-if="item.icon" class="mr-2">
                        <VIcon :color="item.icon.color">{{item.icon.name}}</VIcon>
                    </VListItemIcon>
                    <VListItemContent style="overflow-x:auto;">
                        <VListItemTitle>{{ item.text }}</VListItemTitle>
                    </VListItemContent>
                </VListItem>
            </div>
        </VList>
    </v-navigation-drawer>
</template>

<style scoped></style>