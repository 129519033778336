export default {
    name: 'ToolManufacturerAutocomplete',
    inheritAttrs: false,
    data() {
        return {
            manufacturers: [],
            loading: false
        };
    },
    methods: {
        async fetchManufacturers() {
            this.$log.log('Fetching manufacturers');
            return this.$http.get('/v3/tools/manufacturers').then(response => {
                const manufacturers = response.data ? response.data.manufacturers : [];
                this.manufacturers = manufacturers;
                return manufacturers;
            });
        },
        onFocus() {
            this.loading = true;
            this.fetchManufacturers().finally(() => {
                this.loading = false;
            });
        }
    }
};
