const LOG_STYLES = {
    BASE: 'background:none; color: inherit;',
    PURPLE: 'background:purple; color:white;',
    GREEN: 'background:lightGreen; color:green;',
    TEAL: 'background:teal; color:white;',
};
class LogService {
    constructor(context = null, logStyle = null) {

        this.setContext(context);

        this.styles = {
            base: 'background:none; color: inherit;',
            green: 'background:lightGreen; color:green; padding:4px;',
            teal: 'background:teal; color:white; padding:3px;',
            purple: 'background:purple; color:white; padding:3px;',
        };

        if(!logStyle) {
            this.setStyle(LOG_STYLES.BASE);
        } else {
            this.setStyle(logStyle);
        }
    }

    setStyle(style) {
        this._style = style;
    }
    log(...msg) {
        this.write('log',...msg);
    };

    error(...msg) {
        this.write('error',...msg);
    };

    info(...msg) {
        this.write('info', ...msg);
    };

    warn(...msg) {
        this.write('warn',...msg);
    };

    write(_type, ...msg) {
        console[_type](this.title(),this._style, this.styles.base,  ...msg);
    }

    setContext(context) {
        this.context = context;
        return this;
    };
    title() {
        return `%c[${this.context}]%c`;
    }
    format(...msg) {
        let formatted = msg.join(' ');
        formatted = `%c[${this.context}]%c ${formatted}`;
        return formatted;
    }
}

const Log = new LogService();
export default Log;
export { LogService, LOG_STYLES };