import DatePicker from "@/components/V2/DatePicker.vue";
import CertificationDocumentManager from "@/components/CertificationDocuments/CertificationDocumentManager.vue";
import NotesTextField from "@/components/NotesTextField.vue";
export default {
    name: 'ToolCertificationForm',
    components: { NotesTextField, CertificationDocumentManager, DatePicker },
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            certification: {
                certified_at: null,
                expires_at: null,
                tool_id: null,
                notes: null,
                documents: [],
            }
        };
    },
    watch: {
        certification: {
            handler(val) {
                this.$emit('input', val);
            }
        }
    }
};
