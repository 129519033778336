<script>
export default {
    name: 'ToolModelNumberInput',
    props: [],
}
</script>

<template functional>
    <v-text-field
            prepend-inner-icon="mdi-music-accidental-sharp"
            v-bind="data.attrs"
            v-on="listeners"
            outlined
            label="Model Number"
    />
</template>

<style scoped>

</style>