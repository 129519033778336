<template>
    <div class="pa-1 pa-md-12">
        <v-container fluid>
            <h2 class="text-center pb-6" style="color: #333; font-weight: bold">
                Warehouse Inventory
            </h2>
        </v-container>

        <v-btn @click="initTests" color="primary">
            Check DYMO printing environment
        </v-btn>

        <v-card>
            <v-card-title> </v-card-title>
            <v-btn @click="testPrint" color="primary"> Test printing </v-btn>
        </v-card>

        <div id="labelImageDiv">
            <img id="labelImage" src="" alt="label preview"/>
        </div>

        <div class="mt-6"><small>last updated Mar 28, 2022</small></div>
    </div>
</template>

<script>
// import dymo from '@/assets/DYMO.Label.Framework.3.0.js';
// import dymo from '@/assets/dymo.connect.framework.js';
// const dymoTemplateFile = require('../assets/legac-template.label');
export default {
    data() {
        return {
            search: "",
            headers: [],
            rows: [],
            loading: true,
            label: '',
        };
    },
    // mounted() {
    //   let dymoScript = document.createElement('script')
    //   dymoScript.setAttribute('src', '@/assets/DYMO-Label-Framework-3-0.js')
    //   document.head.appendChild(dymoScript)
    // },
    created() {
        console.log("created");
        // Attach the DYMO JS scripts
        var scripts = [
            "https://s3.amazonaws.com/download.dymo.com/dymo/Software/JavaScript/dymo.connect.framework.js",
            // "../assets/legac-template.label",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    mounted() {
        setTimeout(() => {
            this.initTests();
        }, 1000);
    },
    computed: {},
    watch: {},
    methods: {
        initTests() {
            if (dymo.label.framework.init) {
                // dymo.label.framework.trace = 1;
                dymo.label.framework.init(this.onload);
            } else {
                this.onload();
            }

            // Check for connected printers:
            setTimeout(this.checkPrinters, 2000);
        },
        onload() {
            try {
                //clearOutput();
                var result = dymo.label.framework.checkEnvironment();
                console.log("DYMO: Validate if the environment meets the requirements");
                console.log("isBrowserSupported: " + result.isBrowserSupported);
                console.log("isFrameworkInstalled: " + result.isFrameworkInstalled);
                if (dymo.label.framework.init) {
                    console.log("isWebServicePresent: " + result.isWebServicePresent);
                }
                console.log("errorDetails: " + result.errorDetails);
            } catch (e) {
                alert(e.message || e);
            }
        },
        checkPrinters() {
            var printers = dymo.label.framework.getPrinters();
            if (printers.length == 0) {
                alert("No DYMO printers are installed. Install DYMO printers.");
                return;
            }

            if (!printers[0].isConnected) {
                alert("DYMO printer is not connected.");
                return;
            }

            // If all tests passed, print to console
            console.log(`DYMO printer "${printers[0]["modelName"]}" is connected.`);
        },
        async testPrint() {
            // const fileName = "@/assets/legac-template.label"
            // const dymoTemplateFile = require('../assets/legac-template.label');
            const dymoTemplateFile = await (
                // await fetch("legac-template.label")
                await fetch("legac-template2.label")
            ).text();

            // console.log(dymoTemplateFile);
            const label = dymo.label.framework.openLabelXml(dymoTemplateFile);
            // console.log(label);
            if(label.isDCDLabel())
                console.log("DYMO Connect label");
            if(label.isDLSLabel())
                console.log("DLS label");
            if(label.isValidLabel())
                console.log("The file is a valid label");
            else
            {
                alert(" The file is not a valid label");
                return;
            }

            const labelInfo = {
                partNumber: "CLAMP-PVC-1",
                partDescription: "1 PVC COND CLAMP",
                jobNumber: "22-131.",
                jobName: "PSG Coinbase Jabil House Install",
                poNumber: "22-131.1002",
                projectManager: "Gabriel Ceniceros",
                foreman: "Randy Gomez",
                receivedDatetime: "2022-02-14 06:54:16 PST",
                receivedBy: "Michael Rezente",
            };
            const printer = dymo.label.framework.getPrinters()[0].modelName;
            this.printLabel(label, labelInfo, printer);
            return;
        },
        // Generates label preview and updates corresponend <img> element
        // Note: this does not work in IE 6 & 7 because they don't support data urls
        // if you want previews in IE 6 & 7 you have to do it on the server side
        updatePreview(){
            if (!this.label) return;
            // console.log(this.label);
            var pngData = this.label.render();
            // console.log(pngData);
            var labelImage = document.getElementById('labelImage');
            labelImage.src = "data:image/png;base64," + pngData;
        },
        printLabel(label, labelInfo, printer) {
            // Replace the label objects with the CSV data
            // 0) Name 1) Description 2) Footprint 3) Manufacturer 4) MPN 5) Storage 6) Total Stock 7) URL 8) Meta-Parts
            // console.log(labelInfo);
            console.log(printer);
            const params = [
                "partNumber",
                "partDescription",
                "jobNumber",
                "jobName",
                "poNumber",
                "projectManager",
                "foreman",
                "receivedDatetime",
                "receivedBy",
            ];
            params.forEach((param) => {
                label.setObjectText(param, labelInfo[param]);
            });
            // label.setObjectText('projectManager', labelInfo['projectManager']);
            // console.log(label);

            // Set the print options
            var labelXml = label.getLabelXml();
//       var labelXml = `<?xml version="1.0" encoding="utf-8"?><DieCutLabel Version="8.0" Units="twips">        <PaperOrientation>Landscape</PaperOrientation>        <Id>Address</Id>        <PaperName>30252 Address</PaperName>        <DrawCommands>            <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>        </DrawCommands>        <ObjectInfo>            <AddressObject>                <Name>Address</Name>                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>                <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>                <LinkedObjectName/>                <Rotation>Rotation0</Rotation>                <IsMirrored>False</IsMirrored>                <IsVariable>True</IsVariable>                <HorizontalAlignment>Left</HorizontalAlignment>                <VerticalAlignment>Middle</VerticalAlignment>                <TextFitMode>ShrinkToFit</TextFitMode>                <UseFullFontHeight>True</UseFullFontHeight>                <Verticalized>False</Verticalized>                <StyledText>                    <Element>                        <String>DYMO
// 3 Glenlake Parkway
// Atlanta, GA 30328</String>                        <Attributes>                            <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>                            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>                        </Attributes>                    </Element>                </StyledText>                <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>                <BarcodePosition>AboveAddress</BarcodePosition>                <LineFonts>                    <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>                    <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>                    <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>                </LineFonts>            </AddressObject>            <Bounds X="332" Y="150" Width="4455" Height="1260"/>        </ObjectInfo>    </DieCutLabel>`;

// console.log(labelXml);
            var printQuality = dymo.label.framework.LabelWriterPrintQuality.Auto;
            dymo.label.framework.createLabelRenderParamsXml()
            var paramsXml = dymo.label.framework.createLabelWriterPrintParamsXml({
                copies: 1,
                printQuality: printQuality,
            });

            this.label = label;
            this.updatePreview();
            // var renderParamsXml = dymo.label.framework.createLabelRenderParamsXml({'labelColor': 1, 'shadowDepth': 1})
            // dymo.label.framework.renderLabel(labelXml, renderParamsXml, printer);
            // Print
            dymo.label.framework.printLabel(printer, paramsXml, labelXml);
            // label.print(printer);
        },
    },
};
</script>
