<template>
    <v-text-field
        v-on="inputListeners"
        v-bind="$attrs"
        outlined
    />
</template>

<script>
export default {
    name: "TextInput",
    inheritAttrs: false,
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        }
    }
}
</script>

<style scoped>

</style>