import vehicleApiMixin from "@/mixins/Api/vehicle-api.mixin";
import { get } from "@/client/utilities/Object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
export default {
    name: 'ListVehicles',
    components: { ConfirmDialog },
    inheritAttrs: false,
    props: [],
    mixins: [vehicleApiMixin],
    data() {
        return {
            vehicles: [],
            loading: false
        };
    },
    methods: {
        get,
        async remove(vehicle) {
            await this.$http.delete('/v3/vehicles/' + vehicle.id);
            this.vehicles = await this.fetchVehicles();
        },
        edit(vehicle) {
            const id = vehicle.id;
            if (id) {
                this.$router.push({ name: 'edit-vehicle', params: { id: id } });
            }
        }
    },
    async created() {
        this.vehicles = await this.fetchVehicles();
    },
};
