import { get } from "@/client/utilities/Object";
import { AxiosResponse } from "axios";
import ApiResponseInterface from "./ApiResponseInterface";
export default class ApiResponse {
    data;
    response;
    status;
    dataKey;
    constructor(response) {
        this.response = response;
    }
    setKey(dataKey) {
        this.dataKey = dataKey;
        return this;
    }
    configure(dataKey, callback) {
        this.dataKey = dataKey;
        this.data = this.map(callback);
        return this;
    }
    static create(response, dataKey, mapCallback) {
        let instance = new ApiResponse(response);
        instance.configure(dataKey, mapCallback);
        return instance;
    }
    ok() {
        return this.response && this.response.status >= 200 && this.response.status < 300;
    }
    getResponseData() {
        return get(this.response.data, this.dataKey, this.response.data);
    }
    map(callback) {
        const data = this.getResponseData();
        let items = [];
        for (let idx in data) {
            items.push(callback(data[idx]));
        }
        return items;
    }
}
