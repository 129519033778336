<script>
import PageTitle from "@/components/PageTitle.vue";
import {get} from "@/client/utilities/Object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";

export default {
    name: 'MaterialRequest',
    components: {ConfirmDialog, PageTitle},
    title: 'Material Requests',
    data() {
        return {
            loading: false,
            page: 1,
            pageCount: 0,
            materialRequests: []
        }
    },
    watch: {
        page() {
            this.fetchData();
        }
    },
    methods: {
        get,
        async fetchData() {
            this.loading = true;
            const params = {
                paginated: true,
                page: this.page,
                per_page: 10
            };
            return await this.$http.get('/v3/materials/requests', {params: params})
                .then(response => {
                    this.pageCount = get(response, 'data.material_requests.last_page', 1);
                    this.materialRequests = get(response, 'data.material_requests.data', []);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        edit(id) {
            this.$router.push({name: 'EditMaterialRequest', params: {id: id}});
        },
        print(id) {
            window.open(`/api/v3/materials/requests/${id}/print`, '_blank');
        },
        create() {
            this.$router.push({name: 'CreateMaterialRequest'});
        },
        async destroy(id) {
            return await this.$http.delete(`/v3/materials/requests/${id}`).then(response => {
                this.materialRequests = response.data.material_requests;
            }).finally(() => {

            });
        }
    },
    async created() {
        await this.fetchData();
    }
}
</script>

<template>
    <v-container>
        <PageTitle>
            <template v-slot:button>
                <v-btn
                    small
                    color="primary"
                    to="/materials/requests/create"
                >
                    New Request
                </v-btn>
            </template>
        </PageTitle>

        <v-card :loading="loading" elevation="3">
            <v-card-text>
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Job</th>
                        <th>Date</th>
                        <th>Need By</th>
                        <th>Foreman</th>
                        <th>Items</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in materialRequests" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ get(item, 'job.job_number') }}</td>
                        <td>{{ item.created_at | date }}</td>
                        <td>{{ item.requested_delivery_date | date }}</td>
                        <td>{{ get(item, 'job.foreman.name') }}</td>
                        <td>{{ item.material_request_items.length }}</td>
                        <td>
                            <v-chip
                                v-show="item.completed_at"
                                label
                                small
                                color="primary"
                                :title="item.completed_at"
                            >
                                <v-icon small>mdi-check</v-icon>
                            </v-chip>

                            <v-chip
                                disabled
                                v-show="!item.completed_at"
                                label
                                small
                                color="secondary"
                            >
                                <v-icon small>mdi-file-document-edit</v-icon>
                            </v-chip>
                        </td>
                        <td>
                            <span v-show="!item.is_completed">
                                <v-btn small color="primary" @click="edit(item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </span>

                            <span v-show="item.is_completed">
                                <v-btn small color="light-blue darken-2" @click="print(item.id)">
                                    <v-icon small>mdi-printer</v-icon>
                                </v-btn>
                            </span>
                        </td>
                        <td>
                            <confirm-dialog
                                color="delete"
                                small
                                :message="`Are you sure you want to delete request ID ${item.id}`"
                                @confirm="destroy(item.id)"
                            >
                                <v-icon small>mdi-trash-can</v-icon>
                            </confirm-dialog>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-card class="mt-2" elevation="3">
            <v-card-text class="py-1">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<style scoped>

</style>