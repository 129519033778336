<template>
    <v-container>

        <PageTitle>
            Receiving Form

            <VBtn
                    color="accent"
                    small
                    class="float-right"
                    href="#/receive-form"
            >
                New Receive Form
            </VBtn>
        </PageTitle>

        <v-form v-model="valid" v-if="showForm" ref="form">
            <v-card>
                <v-card-text>
                    <v-row align-content="center">
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                v-model="poNumber"
                                :items="poNumbers"
                                :loading="loadingImport"
                                :rules="[requiredField]"
                                clearable
                                outlined
                                label="PO Number"
                                placeholder="Start typing to Search"
                                prepend-inner-icon="mdi-tag-search"
                                return-object
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" v-show="poNumber" class="pt-5">
                            <v-btn
                                @click="getJobItemsInWarehouse"
                                color="primary"
                                small
                                :disabled="!poNumber"
                                :loading="jobLookupLoading"
                            >
                                Lookup this job's items in Warehouse
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        class="my-0 py-0"
                        v-show="!!poNumber && deliveryAlert.show"
                    >
                        <v-col>
                            <v-alert
                                close-text="Close Delivery Info"
                                prominent
                                dismissible
                                :type="deliveryAlert.type"
                                :color="deliveryAlert.color"
                            >
                                {{ deliveryAlert.message }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-expansion-panels
                v-show="itemsOfSelectedJob.length > 0"
                v-model="panel"
                multiple
                class="mt-4"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        This job's items in Warehouse
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- Filter by Part name + Description -->
                        <v-row v-show="itemsOfSelectedJob.length > 0">
                            <v-col>
                                <v-autocomplete
                                    v-model="selectedItemsOfSelectedJob"
                                    :items="itemNamesOfSelectedJob"
                                    :loading="jobLookupLoading"
                                    clearable
                                    deletable-chips
                                    multiple
                                    small-chips
                                    label="Filter by Part Number & Description"
                                    placeholder="Start typing to Search"
                                    prepend-inner-icon="mdi-archive-search"
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-simple-table
                            fixed-header
                            height="400"
                        >
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>PO #</th>
                                    <th>Part Number</th>
                                    <th>Part Description</th>
                                    <th>Quantity</th>
                                    <th>Location</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(part, partIndex) in selectedParts"
                                    :key="partIndex"
                                >
                                    <td>
                                        {{ selectedParts[partIndex]["poNo"] }}
                                    </td>
                                    <td>
                                        {{ selectedParts[partIndex]["partNumber"] }}
                                    </td>
                                    <td>
                                        {{ selectedParts[partIndex]["partDescription"] }}
                                    </td>
                                    <td>
                                        {{ selectedParts[partIndex]["partQuantity"] }}
                                    </td>
                                    <td>
                                        {{
                                            [
                                                selectedParts[partIndex]["rack"],
                                                selectedParts[partIndex]["section"],
                                                selectedParts[partIndex]["subSection"]
                                            ].join('-')
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </template>

                        </v-simple-table>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div v-show="poNumber">

                <div
                    v-for="(location, locationIndex) in locations"
                    :key="locationIndex"
                >
                    <!-- Location divider -->
                    <v-col cols="12" class="px-0 mt-3">
                        <v-divider></v-divider>
                    </v-col>

                    <v-card
                        class="mb-2"
                        fluid
                    >
                        <v-card-title>
                            Receiving Location
                        </v-card-title>
                        <v-card-text class="py-0">
                            <v-row class="px-2 py-0 my-0 d-flex justify-space-around">
                                <v-col cols="12" md="3" sm="12">
                                    <v-select
                                        outlined
                                        prepend-inner-icon="mdi-package-variant"
                                        v-model="location.packageType"
                                        :items="packageTypes"
                                        label="Package Type"
                                        @change="
                      location.rack = '';
                      location.section = '';
                      location.subSection = '';
                    "
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="4">
                                    <v-select
                                        v-model="location.rack"
                                        :items="availableRacks(location)"
                                        label="Rack"
                                        outlined
                                        :rules="[requiredField]"
                                        @change="
                      location.section = '';
                      location.subSection = '';
                    "
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="4">
                                    <v-select
                                        v-model="location.section"
                                        :items="availableSections(location)"
                                        label="Section"
                                        outlined
                                        :rules="[requiredField]"
                                        @change="location.subSection = ''"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="4">
                                    <v-select
                                        v-model="location.subSection"
                                        outlined
                                        :items="availableSubSections(location, locationIndex)"
                                        label="Sub-section"
                                        :rules="[requiredField]"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <DividingHeader>
                        Items
                    </DividingHeader>

                    <v-row class="mt-0 pt-0">
                        <v-col cols="12">
                            <v-card
                                justify="center"
                                class="mb-2 px-1"
                                v-for="(part, partIndex) in location.parts"
                                :key="partIndex"
                            >
                                <v-card-text
                                    class="px-5 pt-6"
                                >
                                    <v-row
                                        justify="center"
                                        align="center"
                                    >
                                        <v-col cols="4" md="8">
                                            <v-select
                                                v-model="part.name"
                                                :items="partNumbers"
                                                label="Part number"
                                                :rules="[requiredField]"
                                                required
                                                outlined
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="4" md="2">
                                            <v-text-field
                                                v-model="part.quantity"
                                                :label="quantityLabel(part)"
                                                :rules="[quantityLogic(part)]"
                                                required
                                                outlined
                                            />
                                        </v-col>

                                        <v-col cols="4" md="2">

                                            <v-btn
                                                class="mb-7"
                                                @click="location.parts.splice(partIndex, 1)"
                                                color="red"
                                                outlined
                                            >
                                                <v-icon class="pr-1">mdi-close</v-icon>
                                                Remove
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- Button to add/remove more parts -->
                    <v-row>
                        <v-col
                            cols="12"
                            class="px-2 d-flex justify-space-around align-content-center"
                        >
                            <div class="mt-4" v-show="location.parts.length < partNumbers.length">
                                <v-btn
                                    color="addLocation"
                                    @click="addPackage(locationIndex)"
                                    small
                                >
                                    Add part
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                            <!-- <div v-show="location.parts.length > 1">
                              <v-btn class="addPartBtn" @click="location.parts.pop()" small
                                >Remove part</v-btn
                              >
                            </div> -->
                        </v-col>
                    </v-row>
                </div>

                <!-- Button to add more location -->
                <v-row
                    class="my-6 d-flex justify-space-around"
                    v-show="poNumber"
                >
                    <v-col class="d-flex justify-space-around">
                        <v-btn
                            color="addLocation"
                            @click="addLocation"
                        >
                            <v-icon left>mdi-map-marker-plus</v-icon>
                            Add Location
                        </v-btn
                        >
                    </v-col>
                    <v-col class="d-flex justify-space-around">
                        <v-btn
                            color="removeLocation"
                            @click="locations.pop()"
                        >
                            <v-icon left>mdi-map-marker-minus</v-icon>
                            Remove Location
                        </v-btn
                        >
                    </v-col>
                </v-row>

            </div>

            <FormSubmitButton
                    :valid="valid"
                    :submitting="formSubmitting"
                    @click="submitForm"
                    class="mt-8"
            />

        </v-form>

        <FormSubmittedMessage
            type="success"
            v-if="!showForm"
            :on-click="reloadPage"
            button-text="New Receiving Form"
        >
            Order Submitted.
        </FormSubmittedMessage>

    </v-container>
</template>

<script>
import {ReceiveFormService, WarehouseInventoryService} from "../common/api/api.service";
import DividingHeader from "../components/DividingHeader.vue";
import FormSubmitButton from "../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../components/FormSubmittedMessage.vue";
import PageTitle from "../components/PageTitle.vue";

export default {
    components: {DividingHeader, FormSubmitButton, PageTitle, FormSubmittedMessage},
    title: 'Receiving Form',
    data: () => ({
        showForm: true,
        formSubmitting: false,
        loadingImport: true,
        jobLookupLoading: false,
        valid: false,
        employeeName: "",
        presetEmployees: [],
        projectManager: "",
        requiredField: (v) => !!v || "This is a required field",
        jobNumber: "",
        poNumber: "",
        db: {},
        jobNumbers: [],
        jobsDetails: {},
        poNumbers: [],
        poDetails: {},

        // Delivery alert:
        deliveryAlert: {
            show: false,
            type: undefined,
            color: undefined,
            message: "",
        },
        // Job's items lookup alert:
        jobItemsLookupAlert: {
            show: false,
            type: undefined,
            color: undefined,
            message: "",
        },

        packageTypes: ["Pallet", "Long Pallet", "Loose"],
        rackInfo: {}, // Object of all available locations (aka, location that has no products at that location)
        availableLocationsForLooseItems: {}, // Object of all available locations for loose items (all racks 1-27, 32, 33. For rack 28-31, only section + subSection that has no items on it)
        racks: [],
        sections: [],
        subSections: [],
        // rack: null,
        // section: null,
        // subSection: null,
        partNumbers: [],
        // parts: [
        //   {
        //     name: "",
        //     quantity: "",
        //     packageType: "",
        //     rack: null,
        //     section: null,
        //     subSection: null,
        //   },
        // ],
        partStores: {},
        locations: [
            {
                packageType: "Pallet",
                rack: null,
                section: null,
                subSection: null,
                parts: [
                    // { name: "", quantity: "" }    // template
                ],
            },
        ],

        selectedParts: [], // Array of the selected parts to be returned back to the server
        itemsOfSelectedJob: [], // array of all items of a selected job
        itemNamesOfSelectedJob: [], // array of all item names for all items of a selected job
        selectedItemsOfSelectedJob: [], // array of all selected items of a selected job

        // For accordion expansion:
        panel: [0],
    }),
    methods: {
        test() {
            alert('test');
        },
        reloadPage() {
            window.location.reload();
        },
        quantityMoreThanZero(v) {
            // return part.quantity > 0 || "Quantity must be greater than 0";
            return v > 0 || "Quantity must be greater than 0";
        },
        quantityLogic(part) {
            let partName = part.name;

            // Sum of all the amount for this part in all the locations
            let sum = 0;
            for (let locationInfo of this.locations) {
                for (let partInfo of locationInfo.parts) {
                    if (partInfo.name === partName) {
                        sum += parseFloat(partInfo.quantity);
                    }
                }
            }
            // console.log(`Sum of ${partName} is ${sum}`);

            let quantityAllowed = parseFloat(this.partStores[part.name]);
            // console.log(`${partName} allowed quantity: ${quantityAllowed}`);

            return sum <= quantityAllowed || `Total cannot exceed ${quantityAllowed}`;
        },
        async fetchDatabase() {

            const response = await ReceiveFormService.getFormData();

            if (response) {
                const json = await response.data;
                this.db = json["partsByManagers"];
                this.presetEmployees = json['employeeNames'];
                this.rackInfo = json["availableLocations"];
                this.availableLocationsForLooseItems =
                    json["availableLocationsForLooseItems"];
                // console.log(this.availableLocationsForLooseItems);
                this.racks = Object.keys(this.rackInfo).sort();
                console.log(this.db);
                // console.log(this.rackInfo);
                this.loadingImport = false;

                // Populate jobNumbers:
                for (let [manager, jobs] of Object.entries(this.db)) {
                    // console.log(jobs);
                    // this.jobNumbers.extend([...Object.keys(jobs)]);
                    // this.jobsDetails[]
                    for (let [job, pos] of Object.entries(jobs)) {
                        if (!this.jobsDetails[job]) {
                            this.jobsDetails[job] = {};
                        }
                        this.jobsDetails[job] = pos;
                        this.jobNumbers.push(job);

                        for (let [po, info] of Object.entries(pos)) {
                            if (!this.poDetails[po]) {
                                this.poDetails[po] = {};
                            }
                            this.poDetails[po] = info;
                            this.poNumbers.push(po);
                        }
                    }
                }
                // console.log(this.jobsDetails);
                // console.log(`number of POs: ${this.poNumbers.length}`);
                console.log(`number of Jobs: ${Object.keys(this.jobsDetails).length}`);
                console.log(`number of POs: ${Object.keys(this.poDetails).length}`);
                // console.log(this.poDetails['12-345.6789'])
            }
        },
        async submitForm() {
            // console.log(JSON.stringify(this.form));
            // return;

            if (!this.$refs.form.validate()) return;

            this.formSubmitting = true;

            ReceiveFormService
                .submitForm(this.form)
                .then(response => {
                    this.showForm = false;
                })
                .catch(error => {
                    this.showForm = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.formSubmitting = false;
                });
        },
        async resetForm() {
            this.fetchDatabase();
            this.employeeName = "";
            this.poNumber = "";
            this.jobNumber = "";
            this.projectManager = "";
            this.parts = [{name: "", quantity: ""}];
            this.showForm = true;
        },
        async getJobItemsInWarehouse() {
            if (!this.poNumber) return;

            this.jobLookupLoading = true;
            // Get the jobNumber by the first 7 characters of the poNumber
            let jobNumber = this.poNumber.substring(0, 7);

            const response = await WarehouseInventoryService.byJobNumber(jobNumber);
            const results = await response.data;
            this.jobLookupLoading = false;
            try {
                console.log("Job's Items lookup successful!");
                // console.log(results);
                if (results.status !== "success") {
                    this.jobItemsLookupAlert = {
                        type: "error",
                        message: results.message,
                        show: true,
                    };
                    // this.availableParts = [];
                    this.selectedParts = [];
                } else {
                    this.jobItemsLookupAlert = {show: false};

                    // Reset
                    this.itemNamesOfSelectedJob = [];

                    this.itemsOfSelectedJob = results["itemsByJob"];

                    // Set up item names for filtering:
                    for (const part of this.itemsOfSelectedJob) {
                        let partComboName =
                            part["partNumber"] + " -|- " + part["partDescription"];
                        this.itemNamesOfSelectedJob.push(partComboName);
                    }

                    this.selectedParts = JSON.parse(
                        JSON.stringify(this.itemsOfSelectedJob)
                    );
                }
            } catch (err) {
                console.log(err);
            }
        },
        quantityLabel(part) {
            // Sometimes, there is a total of 0 for the quantity (some positive and some negative).
            if (this.partStores[part.name] >= 0)
                return "Quantity of " + this.partStores[part.name];
            return "Quantity";
        },
        addPackage(locationIndex) {
            this.locations[locationIndex].parts.push({name: "", quantity: ""});
            // part.push({ name: "", quantity: "" });
        },
        addLocation() {
            this.locations.push({
                packageType: "Pallet",
                rack: null,
                section: null,
                subSection: null,
                parts: [
                    // { name: "", quantity: "" }
                ],
            });
        },
        availableRacks(location) {
            let rackOptions = [];
            if (location.packageType.toLowerCase() === "Pallet".toLowerCase()) {
                // console.log(`package type: ${location.packageType}`);
                rackOptions = Object.keys(this.rackInfo).sort((a, b) => a - b);
            } else if (
                location.packageType.toLowerCase() === "Long Pallet".toLowerCase()
            ) {
                // console.log(`package type: ${location.packageType}`);
                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const rack of Object.keys(this.rackInfo)) {
                    for (const section of Object.keys(this.rackInfo[rack])) {
                        // console.log(rack + "-" + section + "-" + JSON.stringify(this.rackInfo[rack][section]));
                        if (
                            this.rackInfo[rack][section].includes("1") &&
                            this.rackInfo[rack][section].includes("2")
                        ) {
                            rackOptions.push(rack);
                            break;
                        }
                    }
                }
                console.log(
                    `Amount of rack options for Long Pallet: ${rackOptions.length}`
                );
            } else {
                // console.log(`package type: ${location.packageType}`);
                rackOptions = Object.keys(this.availableLocationsForLooseItems).sort(
                    (a, b) => a - b
                );
            }

            // return Object.keys(this.rackInfo).sort();
            return rackOptions;
        },
        availableSections(location) {
            // When the form is load, no rack is selected.
            if (!location.rack) return [];

            // if (!rack) return [];
            // return Object.keys(this.rackInfo[rack]).sort((a, b) => a - b);
            let sectionOptions = [];
            // console.log(location.packageType);
            if (location.packageType.toLowerCase() === "pallet") {
                // console.log(location.rack);
                sectionOptions = Object.keys(this.rackInfo[location.rack]).sort(
                    (a, b) => a - b
                );
            } else if (
                location.packageType.toLowerCase() === "long pallet"
            ) {
                // console.log(`package type: ${location.packageType}`);
                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const section of Object.keys(this.rackInfo[location.rack])) {
                    // console.log(location.rack + "-" + section + "-" + JSON.stringify(this.rackInfo[location.rack][section]));
                    if (
                        this.rackInfo[location.rack][section].includes("1") &&
                        this.rackInfo[location.rack][section].includes("2")
                    ) {
                        sectionOptions.push(section);
                    }
                }
            } else {
                sectionOptions = Object.keys(
                    this.availableLocationsForLooseItems[location.rack]
                ).sort((a, b) => a - b);
            }
            return sectionOptions;
        },
        availableSubSections(location, locationIndex) {
            console.log("location", location);
            if (!location.rack || !location.section) return [];

            let subSectionOptions = [];

            // console.log(`package type: ${location.packageType}`);
            if (location.packageType.length > 0 && location.packageType.toLowerCase() != "Loose".toLowerCase()) {
                subSectionOptions = [...this.rackInfo[location.rack][location.section]];
            } else {
                subSectionOptions = [
                    ...this.availableLocationsForLooseItems[location.rack][
                        location.section
                        ],
                ];
            }

            // console.log(subSectionOptions);
            // if the package type is 'Long Pallet', then the subSection is "1,2"
            if (location.packageType.toLowerCase() == "Long Pallet".toLowerCase()) {
                subSectionOptions = ["1,2"];
                this.locations[locationIndex]["subSection"] = "1,2";
            }
            return subSectionOptions;
        },
        parseExpectedDelivery(expectedDelivery) {
            // console.log(expectedDelivery);

            // Reset the delivery alert:
            this.deliveryAlert.color = undefined;
            this.deliveryAlert.type = undefined;
            // this.deliveryAlert.message = '';

            // an application may want to use UTC and make that visible
            // request a weekday along with a long date
            var options = {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "America/Los_Angeles",
            };
            // options.timeZoneName = 'short';

            let expectedDeliveryDate = new Date(expectedDelivery);
            let daysInFuture = this.getDaysHoursAndMinutes(expectedDeliveryDate);
            // console.log(daysInFuture);
            let prefix = "Expected Delivery: ";
            if (daysInFuture >= 0) {
                // console.log('HERE')
                let firstDayNextWeek = this.getNextWeekBegin();
                let isDeliveryDateNextWeek = expectedDeliveryDate >= firstDayNextWeek;
                // console.log(`Delivery date is next week? ${isDeliveryDateNextWeek}`)
                if (daysInFuture < 1) {
                    prefix += `TODAY`;
                    this.deliveryAlert.type = "error";
                } else if (daysInFuture == 1) {
                    prefix += `TOMORROW`;
                    this.deliveryAlert.type = "error";
                } else if (daysInFuture <= 3) {
                    prefix += `In ${daysInFuture} day(s)`;
                    this.deliveryAlert.type = "warning";
                } else {
                    this.deliveryAlert.type = "warning";
                }

                // If delivery date is next week, leave out the day of the week:
                if (isDeliveryDateNextWeek) {
                    delete options["weekday"];
                    this.deliveryAlert.type = "info";
                }
            } else {
                delete options["weekday"];
                // this.deliveryAlert.type = 'disabled'
                this.deliveryAlert.color = "grey";
            }

            let formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                expectedDeliveryDate
            );
            let dateMessage = `${prefix} ${formattedDate}`;
            console.log(dateMessage);

            // Set deliveryAlert message
            this.deliveryAlert.message = dateMessage;
            this.deliveryAlert.show = true;
            // this.deliveryAlert.type = 'info';
        },
        getNextWeekBegin() {
            // Assuming that the week begins on Sunday.
            var now = new Date();
            var next = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + (7 - now.getDay())
            );
            return next;
        },
        getDaysHoursAndMinutes(futureDateText) {
            var now = new Date();
            var today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            ).valueOf();
            var future = new Date(futureDateText).valueOf();
            var sec = 1000;
            var min = 60 * sec;
            var hour = 60 * min;
            var day = 24 * hour;
            var diff = future - today;
            var days = Math.floor(diff / day);
            // var hours=Math.floor(diff%day/hour);
            // var minutes=Math.floor(diff%day%hour/min);
            // Logger.log('%s days %s hours %s minutes',days,hours,minutes);
            return days;
        },
        resetSelectedParts() {
            // Reset `selectedParts` to the all of the parts of a selected job number
            this.selectedParts = JSON.parse(JSON.stringify(this.itemsOfSelectedJob));
        },
    },
    computed: {
        managers() {
            return Object.keys(this.db).sort();
        },
        form() {
            return {
                // Timestamp: new Date(),
                employeeName: this.employeeName,
                projectManager: this.projectManager,
                jobNumber: this.jobNumber,
                poNumber: this.poNumber,
                // rack: this.rack,
                // section: this.section,
                // subSection: this.subSection,
                // parts: this.parts,
                locations: this.locations,
            };
        },
    },
    watch: {
        // projectManager(val) {
        //   if (!val) this.jobNumbers = [];
        //   else this.jobNumbers = Object.keys(this.db[val]).sort();
        //   this.poNumbers = [];
        // },
        jobNumber(val) {
            if (!val) return;
            this.poNumbers = Object.keys(this.db[this.projectManager][val]).sort();
        },
        poNumber(val) {
            if (!val) return;

            this.partStores = {};
            this.locations = [
                {
                    packageType: "Pallet",
                    rack: null,
                    section: null,
                    subSection: null,
                    parts: [
                        // { name: "", quantity: "" }    // template
                    ],
                },
            ];

            let partsConcat = [];
            // let partsData = this.db[this.projectManager][this.jobNumber][val];
            let partsData = this.poDetails[val];

            console.log("PO changed to " + val);
            console.log(partsData);

            let expectedDelivery = partsData[0]["expectedDelivery"];
            // console.log(`Expected Delivery: ${expectedDelivery}`);
            this.parseExpectedDelivery(expectedDelivery);

            //in for loop this.partStores[partConcatName] = partsData[p][2]
            for (let p = 0; p < partsData.length; p++) {
                let concatName =
                    partsData[p]["partName"] + " -|- " + partsData[p]["partDesc"];
                partsConcat.push(concatName);
                // Sometimes, there are multiple lines for the same part, then take sum of the quantity.
                if (!this.partStores[concatName]) this.partStores[concatName] = 0;
                this.partStores[concatName] += partsData[p]["partQuantity"];
            }
            this.partNumbers = partsConcat.sort();
            // console.log(this.partStores);
            // console.log(this.partNumbers);

            // Set up for the first location.
            for (const [partComboName, quantity] of Object.entries(this.partStores)) {
                this.locations[0].parts.push({
                    name: partComboName,
                    quantity: quantity,
                });
            }
        },
        selectedItemsOfSelectedJob(val) {
            // const itemsOfSelectedJob = this.partsGroupedByJobNumber[this.selectedJobNumber];
            if (val.length > 0) {
                // console.log(this.itemsOfSelectedJob);
                // this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    // console.log(item)
                    this.selectedParts.push(
                        ...this.itemsOfSelectedJob.filter(
                            (part) =>
                                `${part.partNumber} -|- ${part.partDescription}` === item
                        )
                    );
                }
                // console.log(this.availableParts);
            } else {
                this.resetSelectedParts();
            }
        },
        // packageType(val) {
        //   if (val === 'Long Pallet') {

        //   }
        //   else if (val === "Loose") {
        //     this.locations[0].rack = "";
        //     this.locations[0].section = "";
        //     this.locations[0].subSection = "";
        //   }
        // },
        // racks(val) {
        //   if (!val) this.sections = [];
        //   else this.sections = Object.keys(this.rackInfo[val]).sort();
        //   this.subSections = [];
        //   // this.section = "";
        //   // this.subSection = "";
        // },
        // sections(val) {
        //   if (!val) return;
        //   this.subSections = this.racks[this.rack][val].sort((a, b) => a - b);
        //   // this.subSection = "";
        // },
    },
    created() {
        console.log("[RECEIVE FORM] Loading Data");
        this.fetchDatabase();
    },
};
</script>

<style scoped>
.addPartBtn,
.submitForm,
.newForm {

}

small {
    color: lightgray;
}
</style>