<template>
    <v-container>
        <WarehouseLocationsAutocomplete v-model="location"></WarehouseLocationsAutocomplete>

        <v-card>
            <v-card-text>
                <JobInfoChip
                        job-number="23-201."
                        company-slug="RTE"
                />
            </v-card-text>
            <v-card-text>
                <JobAutocomplete v-model="job"></JobAutocomplete>
            </v-card-text>
        </v-card>


    </v-container>
</template>
<script>
import JobDetails from "../components/JobDetails.vue";
import OpenJobsAutocomplete from "../components/OpenJobsAutocomplete.vue";
import PurchaseOrdersAutocomplete from "../components/PurchaseOrdersAutocomplete.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import RackAutocomplete from "@/components/V2/RackAutocomplete.vue";
import WarehouseLocationsAutocomplete from "@/components/V2/WarehouseLocationsAutocomplete.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";

export default {
    components: {
        JobInfoChip,
        JobAutocomplete,
        ConfirmDialog,
        WarehouseLocationsAutocomplete,
        RackAutocomplete,
        PhotoManager,
        JobDetails,
        PurchaseOrdersAutocomplete,
        OpenJobsAutocomplete
    },
    title: 'Test Locations',
    watch: {
        job: (newVal, oldVal) => {
            console.log('selected job', newVal, oldVal);
        },
        selectedJob: (newVal, oldVal) => {
            console.log(newVal, oldVal);
        },
        pos(newVal, oldVal) {
            console.log(newVal, oldVal);
        },
        location(newVal, oldVal) {
            console.log(newVal, oldVal);
        }
    },
    methods: {
        showToast(toast) {
            this.$toast[toast]('Test ' + toast + ' toast');
        }
    },
    data() {
        return {
            job: null,
            confirmDialog: {
                show: false,
                data: {
                    title: 'Confirm',
                    message: 'Are you sure you want to delete this item?'
                },
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        icon: 'mdi-close',
                        handler: () => {
                            this.confirmDialog.show = false;
                        }
                    },
                    confirm: {
                        text: 'Confirm',
                        icon: 'mdi-check',
                        handler: () => {
                            this.confirmDialog.show = false;
                        }
                    }
                }
            },
            photos: [],
            racks: [],
            context: {
                purchase_order_id: 1,
                purchase_order_line_item_id: 1,
                warehouse_rack_id: 1
            },
            pos: [],
            selectedJob: {},
            toastTypes: ['success', 'info', 'warning', 'error'],
            buttonColors: [
                'primary', 'secondary', 'accent', 'addLocation', 'removeLocation',
            ],
            alertColors: [
                'info', 'success', 'warning', 'error',
            ],
            location: null
        }
    }
}
</script>