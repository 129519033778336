import {APP_BASE_URL} from './environment';
import {startsWith, trimEnd, trimStart} from "@/client/utilities/String";

const UrlService = {
    getBaseUrl() {
        return trimEnd(APP_BASE_URL, '/') + '/';
    },
    cleanPath(path) {

        if(startsWith(path, '/')) {
            path = trimStart(path, '/');
        }

        if(startsWith(path, 'http')) {
            console.error('Invalid url path', path);
            throw new Error('Invalid url path');
        }

        return path;
    },
    build(url) {
        url = this.cleanPath(url);
        return `${UrlService.getBaseUrl()}${url}`;
    }
}

const Url = (url) => {
    return UrlService.build(url);
};

export default UrlService;
export {Url, UrlService};