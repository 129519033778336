export default {
    name: 'QuantityInput',
    inheritAttrs: false,
    props: [],
    data() {
        return {};
    },
    methods: {},
    async mounted() {
    }
};
