<template>
    <v-app id="inspire">
        <v-navigation-drawer
            v-if="authenticated()"
            v-model="drawer"
            app
            color="navDrawerBackground"
            elevation="3"
            fixed
            temporary
            width="320"
        >
            <v-list-item
                class="px-4 py-1 navDrawerHeaderBackground"
            >
                <v-img
                    class="float-left"
                    contain
                    src="/img/icons/logo-icon.svg"
                />
            </v-list-item>

            <v-divider/>

            <v-list-item
                :class="`blue-grey darken-4 company ${get(defaultCompany, 'slug')} background-color`"
            >
                <v-list-item-content
                    v-show="!isEditingCompany"
                >
                    <v-row>
                        <v-col class="align-content-center" cols="8">
                            {{ get(defaultCompany, 'name') }}
                        </v-col>
                        <v-col cols="4">
                            <v-btn
                                v-show="user.is_admin"
                                color="blue"
                                small
                                text
                                @click="isEditingCompany = true"
                            >
                                Change
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-list-item-content>
                <v-list-item-content v-show="isEditingCompany">
                    <v-autocomplete
                        v-model="defaultCompany"
                        :items="companies"
                        class="elevation-2"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        label="Company"
                        outlined
                        return-object
                        @change="selectCompany"
                    />
                </v-list-item-content>
            </v-list-item>

            <v-divider/>

            <v-list-item
                v-if="user"
                class="px-2 navDrawerHeaderBackground"
                two-line
            >
                <v-list-item-avatar>
                    <img :src="generateAvatar(user.name)" alt="">
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>

            <v-divider></v-divider>

            <VList
                v-once
                dense
                nav
            >

                <div
                    v-for="(item, idx) in sidebar.items"
                    :key="`nav${idx}`"
                >
                    <VListGroup
                        v-if="item.items"
                        v-show="item.visible && item.visible()"
                        key="nav_if"
                        v-model="item.active"
                        active-class="none"
                        no-action
                    >
                        <template v-slot:activator>
                            <VListItemIcon v-if="item.icon" class="mr-2">
                                <v-icon :color="item.icon.color" left>{{ item.icon.name }}</v-icon>
                            </VListItemIcon>
                            <VListItemTitle>{{ item.text }}</VListItemTitle>
                        </template>

                        <div v-for="(subItem, subIdx) in item.items"
                             :key="`subItem${subIdx}${idx}`"
                        >
                            <div v-if="subItem.items" :key="`nav2${subIdx}`">
                                <VListGroup
                                    :key="`sub2-items${subIdx}${idx}`"
                                    :value="`${subIdx}${idx}`"
                                    no-action
                                    sub-group
                                >
                                    <template v-slot:activator>
                                        <VListItemTitle>{{ subItem.text }}</VListItemTitle>
                                    </template>
                                    <VListItem
                                        v-for="(sub2Item, sub2Idx) in subItem.items"
                                        :key="`sub22-${sub2Idx}${subIdx}${idx}`"
                                        :to="sub2Item.to"
                                        link
                                    >
                                        <VListItemTitle>{{ sub2Item.text }}</VListItemTitle>
                                    </VListItem>
                                </VListGroup>
                            </div>
                            <div v-else key="subelse">
                                <v-subheader
                                    v-if="subItem.subHeader"
                                    class="pl-10"
                                    v-html="subItem.subHeader"
                                />
                                <VListItem
                                    v-else
                                    :key="`sub2${subIdx}${idx}`"
                                    :to="subItem.to"
                                    class="pl-12"
                                    link
                                >
                                    <VListItemContent style="overflow-x:auto;">
                                        <VListItemTitle>{{ subItem.text }}</VListItemTitle>
                                    </VListItemContent>
                                    <VListItemIcon v-if="subItem.icon">
                                        <VIcon :color="subItem.icon.color" right>
                                            {{ subItem.icon.name }}
                                        </VIcon>
                                    </VListItemIcon>
                                </VListItem>
                            </div>
                        </div>
                    </VListGroup>

                    <VListItem
                        v-else
                        :key="idx"
                        :to="item.to"
                        link
                    >
                        <VListItemIcon v-if="item.icon" class="mr-2">
                            <VIcon :color="item.icon.color">{{ item.icon.name }}</VIcon>
                        </VListItemIcon>
                        <VListItemContent style="overflow-x:auto;">
                            <VListItemTitle>{{ item.text }}</VListItemTitle>
                        </VListItemContent>
                    </VListItem>
                </div>
            </VList>

        </v-navigation-drawer>

        <v-app-bar
            v-if="authenticated()"
            app
            color="headerBackground"
            elevation="3"
            hide-on-scroll
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"/>

            <v-img
                class="mx-2 mr-4 d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex"
                contain
                max-height="35"
                max-width="200"
                src="/img/icons/logo-icon.svg"
            />

            <v-img
                class="mx-2 d-flex d-xl-none d-lg-none d-md-none d-sm-none"
                contain
                max-height="35"
                max-width="120"
                src="/img/icons/logo-icon.svg"
            />

            <div class="d-none d-lg-flex d-md-flex">
                <div
                    v-show="!isEditingCompany"
                    :class="`px-2 py-1 elevation-2 company header background-color ${get(defaultCompany, 'slug')}`"
                >
                    {{ get(defaultCompany, 'name', '') }}
                    <v-btn
                        v-show="user.is_admin"
                        icon
                        small
                        @click="isEditingCompany = true"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </div>
                <div v-show="isEditingCompany">
                    <v-autocomplete
                        v-model="defaultCompany"
                        :items="companies"
                        class="elevation-2"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        label="Company"
                        outlined
                        return-object
                        @change="selectCompany"
                    />
                </div>
            </div>
            <v-spacer></v-spacer>

            <v-btn
                small
                class="mr-6"
                color="info"
                @click="openHelpDialog"
            >
                <v-icon small left class="d-none d-md-flex d-lg-flex">
                    mdi-information-box
                </v-icon>
                Help
            </v-btn>

            <VMenu
                v-model="notificationMenu"
                min-width="100"
                offset-y
            >
                <template v-slot:activator="{on, attrs}">
                    <VBadge
                        :content="notificationCount"
                        bottom
                        color="accent"
                        left
                        overlap
                    >
                        <VBtn
                            icon
                            plain
                            x-small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <VIcon
                                color="white"
                            >mdi-bell
                            </VIcon>
                        </VBtn>
                    </VBadge>
                </template>

                <VList
                    subheader
                >
                    <VSubheader>Notifications</VSubheader>
                    <VListItem
                        v-for="(notification, nidx) in notifications"
                        :key="nidx"
                        @click="notification.action"
                    >
                        <v-list-item-icon>
                            <v-icon :color="notification.color ?? 'green'">mdi-creation</v-icon>
                        </v-list-item-icon>

                        <VListItemContent>
                            <VListItemTitle>{{ notification.title }}</VListItemTitle>
                            <VListItemSubtitle>{{ notification.subtitle }}</VListItemSubtitle>
                        </VListItemContent>

                    </VListItem>
                </VList>
            </VMenu>

            <v-menu
                v-if="user"
                v-once
                absolute
                content-class="navDrawerHeaderBackground"
                min-width="250"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="user"
                        class="ml-2"
                        elevation="0"
                        small
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ user.name }}
                        <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.display_email || user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                        <v-list-item-action>
                            <v-switch
                                v-model="darkMode"
                                dense
                                inset
                            ></v-switch>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Enable Dark Mode
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item dense to="/notifications/subscriptions">
                        <v-list-item-icon>
                            <v-icon>mdi-bell-cog-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Notification Settings
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <div v-if="user" v-show="user.is_admin">
                    <v-divider></v-divider>

                    <v-list>
                        <v-list-item dense @click="goToAdmin()">
                            <v-list-item-content>
                                <v-list-item-title>Admin Portal</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item dense @click="logout()">
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-app-bar>

        <v-main>
            <v-container class="mb-12 pt-6" fluid>

                <v-alert
                    v-if="testModeEnabled"
                    border="left"
                    dense
                    icon="mdi-alert-box"
                    outlined
                    text
                    type="warning"
                >
                    <div class="text-h6 font-weight-bold">Test Mode</div>

                    Changes will not be reflected for users on the live application.
                    Any data input here may be lost or changed at any time without notice.
                </v-alert>

                <!-- If using vue-router -->
                <router-view :key="$route.fullPath"></router-view>
            </v-container>
        </v-main>

        <v-footer
            absolute
            app
            color=""
            elevation="5"
            padless
        >
            <VCard class="text-center text-subtitle-2 font-weight-thin white--text"
                   color="footerBackgroundColor"
                   flat
                   tile
                   width="100%"
            >
                <VCardText>
                    <span v-show="updatedAt" style="font-size:12px;">Last Updated {{ updatedAt }}</span>
                </VCardText>
            </VCard>
        </v-footer>

        <v-dialog
            v-model="isAuthenticating"
            width="300"
        >
            <v-card
                color="primary"
            >
                <v-card-title class="text-center justify-center">
                    Authenticating
                </v-card-title>
                <v-card-text>
                    <v-progress-linear
                        class="my-2"
                        color="white"
                        height="15"
                        indeterminate
                        striped
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="releaseNotesDialog"
            height="80%"
            scrollable
            width="80%"
        >
            <v-card>
                <v-card-title class="green darken-4">
                    <v-icon left>mdi-creation</v-icon>
                    What's New
                </v-card-title>
                <v-card-text>
                    <v-window
                        v-model="releaseNotesCarousel"
                        height="50%"
                    >
                        <v-window-item
                            v-for="(item, idx) in releaseNotes"
                            :key="idx"
                        >
                            <v-card>
                                <v-card-title>
                                    {{ item.title }}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{ item.subtitle }}
                                </v-card-subtitle>
                                <v-card-text>
                                    {{ item.body }}
                                </v-card-text>
                                <v-card-text v-if="item.items && item.items.length > 0">
                                    <ul>
                                        <li v-for="(i, idx2) in item.items" :key="idx2">
                                            {{ i }}
                                        </li>
                                    </ul>
                                </v-card-text>
                                <v-card-text
                                    class="text-center"
                                >
                                    <v-img

                                        v-if="item.image"
                                        :src="item.image"
                                        class="mx-auto"
                                        contain
                                        max-height="300"
                                    >

                                    </v-img>

                                    <video
                                        v-if="item.video"
                                        :src="item.video"
                                        autoplay
                                        class="mx-auto"
                                        controls
                                        loop
                                        muted
                                        width="80%"
                                    >

                                    </video>
                                </v-card-text>
                            </v-card>
                        </v-window-item>
                    </v-window>
                </v-card-text>

                <v-card-actions class="justify-space-between">
                    <v-btn
                        color="secondary"
                        @click="prev"
                    >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Previous
                    </v-btn>
                    <v-item-group
                        v-model="releaseNotesCarousel"
                        class="text-center d-md-flex d-lg-flex d-xl-flex d-sm-flex d-none"
                        mandatory
                    >
                        <v-item
                            v-for="(item, n) in releaseNotes"
                            :key="`btn-${n}`"
                            v-slot="{ active, toggle }"
                        >
                            <v-btn
                                :input-value="active"
                                icon
                                @click="toggle"
                            >
                                <v-icon>mdi-record</v-icon>
                            </v-btn>
                        </v-item>
                    </v-item-group>

                    <v-btn
                        color="info"
                        @click="next"
                    >
                        Next
                        <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-actions class="justify-space-between pb-4 mt-5">
                    <v-spacer/>
                    <v-btn
                        color="secondary"
                        @click="dismissReleaseNotesDialog"
                    >
                        <v-icon left>
                            mdi-close-circle
                        </v-icon>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar :timeout="-1" :value="hasUpdate" bottom right vertical>
            An update is available, please reload app.
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="refreshApp"
                >
                    Update & Reload
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog
            v-model="helpDialog"
            width="350"
            scrollable
            eager
        >
            <v-card>
                <v-card-title>Help Request</v-card-title>
                <v-card-text class="pa-1">
                    <iframe ref="helpDialogIframe"
                            name="helpDialogIframe"
                            id="helpDialogIframe"
                            src=""
                            style="border:0;width:100%;height:320px;">
                    </iframe>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        small
                        outlined
                        color="error"
                        @click="closeHelpDialog"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="hasUpdate"
            persistent
            width="300"
        >
            <v-card>
                <v-card-title>
                    Updating App...
                </v-card-title>
                <v-card-subtitle>Page will reload</v-card-subtitle>
                <v-card-text>
                    <v-progress-circular
                        :rotate="360"
                        :size="100"
                        :value="5"
                        :width="15"
                        color="primary"
                    >
                        5
                    </v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import AuthService from "./common/auth.service";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import Avatar from "@/common/avatar";
import SidebarItems from "@/common/sidebar-items";
import CompanyService from "@/common/company-service";
import {get} from "@/client/utilities/Object";

export default {
    components: {Sidebar},
    title: 'Home',
    baseTitle: 'LegaC Forms',
    async created() {
        await this.fetchCompanies();
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, {once: true})

        if ("serviceWorker" in navigator) {
            // Prevent multiple refreshes
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return
                this.refreshing = true;
                // Here the actual reload of the page occurs
                window.location.reload();
            });
        }

        this.darkMode = this.getCachedDarkModeState();

        await AuthService.authenticate()
            .then(user => {
                if (user) {
                    if (this.$router.currentRoute.name === 'Login') {
                        this.$router.push({name: 'Home'});
                    }

                    this.defaultCompany = this.getDefaultCompany(user);
                }
            })
            .catch(error => {
                AuthService.clearSession();
                if (this.$router.currentRoute.name !== 'Login') {
                    this.$router.push({name: 'Login'});
                }
            })
            .finally(async (response) => {
                this.isAuthenticating = false;
            });

        this.releaseNotesDialog = false;
    },
    computed: {
        updatedAt() {
            const el = document.querySelector('meta[name="updated_at"]');

            if (el) {
                return el.content;
            }

            return '';
        },
        showReleaseNotes() {
            const show = window.localStorage.getItem('showReleaseNotes') !== 'no';
            const version = window.localStorage.getItem('releaseNotesVersion');

            if (parseInt(version) === parseInt(this.releaseNotesVersion)) {
                // return show;
            }

            return false;
        },
        testModeEnabled() {
            const host = window.location.host;
            return host.includes('localhost') || host.includes('staging');
        },
        notificationCount() {

            if (this.showReleaseNotes) {
                return this.releaseNotes.length;
            }

            return '0';
        }
    },
    methods: {
        get,
        openHelpDialog() {
            this.$refs.helpDialogIframe.src = `/help?url=${encodeURIComponent(window.location.href)}`;
            this.helpDialog = true;
        },
        closeHelpDialog() {
            this.$refs.helpDialogIframe.src = '';
            this.helpDialog = false;
        },
        async selectCompany(company) {
            this.defaultCompany = company;
            this.isEditingCompany = false;
            await this.$http.post('/v3/companies/default-company', {
                default_company_id: company.id
            }).then(response => {
                console.log(`Set default company to ${company.name}`);
                window.location.reload();
            }).catch(error => {
                this.$toast.error('Error updating default company');
            });
        },
        getDefaultCompany(user) {
            const company = this.companies.find(company => company.id === user.default_company_id);

            if (!company) {
                return this.companies.find(company => company.id === -1);
            }

            return company;
        },
        async fetchCompanies() {
            this.companies = await CompanyService.get();
        },
        requestPermissions() {
            if ('Notification' in window && Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
        },
        hasNotifications() {
            if (this.user) {
                this.$http.get('/v2/app-notifications/check/' + this.user.id + '?date=' + this.lastNotificationDate)
                    .then(response => {
                        if (response.status === 200) {
                            this.getNotifications();
                            this.checkNotifications();
                        } else {

                        }
                    });
            }
        },
        getNotifications() {
            this.$http.get('/v2/app-notifications').then(response => {
                if (response) {
                    this.lastNotificationDate = get(response, 'data.last_notification_date', '');
                    this.notifications = get(response, 'data.notifications', []);
                }
            }).catch(error => {
                console.log(error);
            });
        },
        checkNotifications() {

            /*
            this.$http.get('/v2/app-notifications').then(response => {
                const _notifications = get(response, 'data.notifications');
                let displayed = JSON.parse(localStorage.getItem('displayedNotifications'));

                if (!displayed) {
                    displayed = [];
                }

                let notifications = [];

                each(_notifications, notification => {
                    if (displayed.indexOf(notification.id) === -1) {
                        notifications.push(notification);
                    }
                });

                if (notifications) {
                    if ("Notification" in window) {
                        if (Notification.permission === "granted") {

                            each(notifications).forEach(item => {

                                displayed.push(item.id);
                                window.localStorage.setItem('displayedNotifications', JSON.stringify(uniq(displayed)));

                                const notification = new Notification(item.title, {
                                    body: item.content,
                                    icon: '/img/icons/favicon-32x32.png'
                                });
                                notification.onclick = () => {
                                    window.open('http://localhost:8080/' + item.action_value);
                                };
                            })
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });

             */
        },
        updateAvailable(event) {
            this.registration = event.detail;
            this.hasUpdate = true;
            this.refreshApp();
        },
        refreshApp() {
            console.log('[ServiceWorker] Refreshing app');
            this.hasUpdate = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
        },
        getCachedDarkModeState() {
            let cachedMode = localStorage.getItem('darkMode');
            return cachedMode !== 'false';
        },
        goToAdmin() {
            window.open('/admin');
        },
        next() {
            this.releaseNotesCarousel = this.releaseNotesCarousel + 1 === this.releaseNotes.length
                ? 0
                : this.releaseNotesCarousel + 1
        },
        prev() {
            this.releaseNotesCarousel = (this.releaseNotesCarousel - 1 < 0) ? this.releaseNotes.length - 1 : this.releaseNotesCarousel - 1;
        },
        dismissReleaseNotesDialog() {
            this.releaseNotesDialog = false;
            window.localStorage.setItem('releaseNotesVersion', this.releaseNotesVersion);
            window.localStorage.setItem('showReleaseNotes', 'no');
        },
        openReleaseNotesDialog() {
            this.releaseNotesDialog = true;
        },
        generateAvatar(name) {
            const avatar = new Avatar(name);
            return avatar.toDataURL();
        }
    },
    data() {
        return {
            helpDialog: false,
            isEditingCompany: false,
            perInterval: Math.ceil((this.updateDelaySeconds * 1000) / this.maxIntervals),
            updatePercent: 0,
            updateDelaySeconds: 5,
            updateInterval: null,
            intervals: 0,
            maxIntervals: 10,
            lastNotificationDate: '',
            appNotifications: [],
            companies: [],
            defaultCompany: null,
            sidebar: {
                model: null,
                items: SidebarItems(this)
            },
            registration: null,
            hasUpdate: false,
            refreshing: false,
            drawer: null,
            darkMode: null,
            isAuthenticating: true,
            notificationMenu: false,
            notifications: [
                {
                    title: 'New Release',
                    subtitle: 'New features available',
                    action: () => {
                        this.openReleaseNotesDialog()
                    }
                }
            ],
            releaseNotesDialog: false,
            releaseNotesCarousel: 0,
            releaseNotesVersion: 1,
            releaseNotes: [
                {
                    title: 'New Job Dropdown',
                    subtitle: 'Transmittal, Restock, RMA, Kit Creation Forms',
                    body: `New job number selection dropdown with more job details, including company, job description and line item counts.`,
                    items: [
                        'Full-text search on job descriptions in dropdown.',
                        'You can use the dropdown search box to filter by job number and description.',
                        'Selecting a duplicate job number will open a dialog to confirm selection of the two different job numbers.'
                    ],
                    video: '/media/releases/release-1/transmittal-job-dropdown-search.mov',
                    image: null,
                },
                {
                    subtitle: 'Transmittal Form',
                    title: 'Duplicate Job Dialog',
                    body: 'Dialog to select job & company when there are multiple jobs for the same job number',
                    image: null,
                    video: '/media/releases/release-1/transmittal-duplicate-job-codes.mov',
                },
                {
                    subtitle: 'Transmittal Form',
                    title: 'Data Entry Table',
                    body: 'Item selection has been redone to use a table to list items. Click on a cell in the Qty column to modify transmittal quantity on items.',
                    image: null,
                    video: '/media/releases/release-1/transmittal-table.mov',
                },
                {
                    title: 'Duplicate PO Dialog',
                    subtitle: 'Receive Form',
                    body: 'Dialog to select PO & Company when there are multiple POs for the same PO number',
                    image: null,
                    video: '/media/releases/release-1/receiving-duplicate-po-numbers.mov',
                },
                {
                    title: 'Inline PO Import',
                    subtitle: 'Receive Form',
                    body: 'When no PO number is found for the entered text, a button will appear to attempt an import of the PO.',
                    image: null,
                    video: '/media/releases/release-1/receiving-inline-po-import.mov',
                }
            ],
            items: [
                {
                    title: "Home",
                    icon: "mdi-home",
                    to: "/"
                },
                {
                    title: "Warehouse Inventory",
                    color: "green",
                    icon: "mdi-clipboard-text",
                    to: "/warehouse-inventory",
                },
                {
                    title: "Receive Form",
                    color: "light-blue",
                    icon: "mdi-home-import-outline",
                    to: "/receive-form",
                },
                {
                    title: "Transmittal Form",
                    color: "green",
                    icon: "mdi-truck-delivery",
                    to: "/transmit-form",
                },
                {
                    title: "Owner Provided Materials Receiving Form",
                    color: "purple lighten-2",
                    icon: "mdi-import",
                    to: "/receive-owner-materials-form",
                },
                {
                    title: "Return to Warehouse from Job Restock Form",
                    color: "indigo lighten-2",
                    icon: "mdi-home-import-outline",
                    to: "/restock-form",
                },
                {
                    title: "Inventory Form",
                    color: "cyan",
                    icon: "mdi-order-bool-ascending-variant",
                    to: "/inventory-form",
                },
                {
                    title: "Add Purchase Order Form",
                    color: "red lighten-1",
                    icon: "mdi-cart-plus",
                    to: "/po-form",
                },
                {
                    title: "Project Kit Creation Form",
                    color: "green lighten-1",
                    icon: "mdi-tag-multiple",
                    to: "/kit-form",
                },
                {
                    title: "RMA Form",
                    color: "yellow darken-3",
                    icon: "mdi-cash-refund",
                    to: "/rma-form",
                },
                {
                    title: "About",
                    icon: "mdi-help-box",
                    to: "/about"
                }
            ],
        };
    }
};
</script>
<style lang="scss">

.company {
    &.RTE {
        &.background-color {
            background: rgba(114, 35, 35) !important;
            }
        }

    &.P1 {
        &.background-color {
            background: rgba(30, 58, 93) !important;
            }
        }
    }

h1, h2, h3, h4 {
    font-weight: 400;
    letter-spacing: 1px;
    }

.theme--light.v-application {
    background: #f8f8f8;
    }

.theme--light.v-app-bar,
.theme--light.v-app-bar .v-app-bar__nav-icon,
.theme--light.v-app-bar.v-app-bar .v-toolbar__title .v-icon {
    color: #fff !important;
    }

.theme--light .v-footer .text--secondary {
    color: #efefef !important;
    }

.theme--light.v-application .v-navigation-drawer .theme--light.v-list-item {

    }

.theme--light.v-application .v-navigation-drawer .theme--light.v-list-item .v-list-item__subtitle {

    }

.theme--dark.v-application {
    background: #121212;
    }

.Vue-Toastification__toast--error {
    background: var(--v-error-base) !important;
    }

.Vue-Toastification__toast--warning {
    background: var(--v-warning-base) !important;
    }

.Vue-Toastification__toast--info {
    background: var(--v-info-base) !important;
    }

.Vue-Toastification__toast--success {
    background: var(--v-success-base) !important;
    }


.Vue-Toastification__toast {
    border-radius: 4px !important;
    border: 0px solid rgba(0, 0, 0, 0.3);
    border-left: 10px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    }

.staging-logo-text {
    z-index: 1000;
    letter-spacing: 1px;
    position: absolute;
    top: 32px;
    left: 84px;
    font-size: 13px;
    color: #ffcfcf;
    text-shadow: 2px 3px black;
    font-weight: bold;
    }

.v-list-item--link:before {
    background: rgba(255, 255, 255, 0.5);
    }
</style>