export default {
    name: 'BaseForm',
    inheritAttrs: false,
    props: {
        form: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            valid: false
        };
    },
    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        getErrors() {
            return this.$refs.form.inputs.filter(input => !input.valid)
                .map(input => input.errorBucket)
                .flat();
        },
        onSubmit(e) {
            e.preventDefault();
            this.form.valid = this.validate();
            this.form.errors = this.getErrors();
        }
    },
    async mounted() {
    }
};
