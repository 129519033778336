import PageTitle from "@/components/PageTitle.vue";
import { Url } from "@/common/url.service";
export default {
    name: 'ExportToolData',
    title: 'Export Tool Data',
    components: { PageTitle },
    inheritAttrs: false,
    props: [],
    data() {
        return {
            include: [
                'tools',
                'tool_models',
                'tool_kits'
            ],
            includePhotos: true
        };
    },
    methods: {
        exportTools() {
            const url = Url(`/api/v3/tools/export`);
            window.open(url);
        },
        exportToolModels() {
            const url = Url(`/api/v3/tools/models/export`);
            window.open(url);
        },
    },
    async mounted() {
    }
};
