import Config from '@/common/config';
export default {
    name: 'BasicImage',
    inheritAttrs: false,
    props: {},
    computed: {
        placeholderImageUrl() {
            return Config.photos.placeholder.url;
        }
    }
};
