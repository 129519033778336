<script>
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import CertificationDocumentManager from "@/components/CertificationDocuments/CertificationDocumentManager.vue";
import DatePicker from "@/components/V2/DatePicker.vue";

export default {
    name: 'AddToolAccessoryComponent',
    components: {DatePicker, CertificationDocumentManager, PhotoManager},
    props: [
        'name', 'description', 'price', 'photos', 'required',
        'requiresCertification', 'certificationDocuments',
        'serialNumber', 'category', 'categories',
        'certificationStartDate', 'certificationEndDate'
    ],
    data() {
        return {
        };
    }
}
</script>

<template>
<v-row cols="3" dense>
    <v-col cols="12">
        <v-text-field
                outlined
                label="Name"
                v-model="name"
        />
    </v-col>
    <v-col cols="12">
        <v-text-field
                outlined
                label="Description"
                v-model="description"
        />
    </v-col>
    <v-col cols="12">
        <v-combobox
                :items="categories"
                v-model="category"
                outlined
                label="Category"
                item-text="name"
                item-value="id"
                clearable
                :rules="[v => !!v || 'Category is required']"
        >
        </v-combobox>
    </v-col>
    <v-col cols="12">
        <v-row>
            <v-col>
                <v-switch v-model="requiresCertification" label="Requires Certification"/>
            </v-col>
        </v-row>
        <v-row v-show="requiresCertification">
                <v-col cols="12">
                    <v-text-field
                            v-model="serialNumber"
                            label="Serial Number"
                            outlined
                    />
                </v-col>
                <v-col>
                    <DatePicker
                            label="Certification Date"
                            v-model="certificationStartDate"
                            :rules="[v => !!v || 'Start Date is required']"
                    />
                </v-col>
                <v-col>
                    <DatePicker label="Certification Expiration Date"
                                :rules="[v => !!v || 'End Date is required']"
                                v-model="certificationEndDate"
                    />
                </v-col>
            <v-col cols="12">
                <CertificationDocumentManager
                        v-model="certificationDocuments"
                />
            </v-col>
        </v-row>
    </v-col>
    <v-col cols="6">
        <v-text-field
                outlined
                label="Price"
                v-model="price"
        />
    </v-col>
    <v-col cols="6">
        <v-select
                outlined
                v-model="required"
                :items="['Yes', 'No']"
                label="Required"
        />
    </v-col>
    <v-col>
        <PhotoManager :photos="photos" />
    </v-col>
</v-row>
</template>

<style scoped>

</style>