export default {
    name: "JobInfoChip",
    props: {
        jobNumber: {
            type: String,
            default: ""
        },
        jobDescription: {
            type: String,
            default: ""
        },
        companySlug: {
            type: String,
            default: ""
        }
    }
};
