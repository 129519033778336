<script>
import PageTitle from "@/components/PageTitle.vue";
import {get} from "@/client/utilities/Object";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import debounce from "@/client/utilities/Debounce";
import cloneObject from "@/common/clone-object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";

export default {
    name: 'CreateMaterialRequest',
    components: {JobInfoChip, DeliveryWindowSelect, ConfirmDialog, DatePicker, JobAutocomplete, PageTitle},
    title: 'New Material Request',
    data() {
        return {
            saveAbortController: new AbortController(),
            rules: {
                required: (message) => (value) => (typeof value === "string" ? value.trim().length > 0 : !!value) || message,
                phaseCode: (value) => {
                    value = get(value, 'phase_code', value);
                    const regex = /^[0-9]{2}-[0-9]{2}$/gm;
                    return !!regex.exec(value) || 'Invalid phase code format (e.g., 01-01)'
                }
            },
            modified: false,
            numPhaseCodes: 0,
            loading: false,
            isValid: false,
            delivery_type: 1,
            submitting: false,
            snackbar: {
                open: false,
                saving: false,
                text: 'Saving...',
                color: 'blue-grey',
                timeout: 3000,
            },
            creating: false,
            isSaving: false,
            isAddingLine: false,
            job: null,
            is_saving: false,
            data: {
                delivery_windows: [
                    {
                        text: 'Morning',
                        value: 'morning',
                        icon: 'mdi-weather-sunset-up',
                        color: 'yellow lighten-4'
                    },
                    {
                        text: 'Afternoon',
                        value: 'afternoon',
                        icon: 'mdi-weather-sunny',
                        color: 'orange lighten-4'
                    },
                    {
                        text: 'Evening',
                        value: 'evening',
                        icon: 'mdi-moon-waning-crescent',
                        color: 'accent lighten-4'
                    },
                    {
                        text: 'Urgent',
                        value: 'urgent',
                        icon: 'mdi-alert-circle',
                        color: 'error'
                    },
                    {
                        text: 'Will Call',
                        value: 'will call',
                        icon: 'mdi-phone',
                        color: 'green'
                    }
                ],
                destinations: [
                    {text: 'Warehouse', id: 'warehouse'},
                    {text: 'Jobsite', id: 'jobsite'},
                ],
                units_of_measure: [],
                address: {
                    line1: '',
                    line2: ''
                },
                types: [],
                job_parts: [],
                warehouse_parts: [],
                job: null,
                jobs: [],
                phase_codes: [],
                material_request_item_types: [],
                line_item: {
                    type: null,
                    line_number: null,
                    quantity: null,
                    part_description: null,
                    part_number: null,
                    manufacturer: null,
                    phase_code: null
                }
            },
            material_request: {
                id: null,
                completed_at: null,
                ship_to_name: null,
                job: null,
                address1: null,
                address2: null,
                requested_delivery_date: null,
                requested_date: null,
                cell_phone_number: null,
                delivery_window: null,
                material_request_items: [],
                status: 'created'
            },
            runningSave: false,
            defaults: {
                material_request: {
                    id: null,
                    completed_at: null,
                    ship_to_name: null,
                    job: null,
                    address1: null,
                    address2: null,
                    requested_delivery_date: null,
                    requested_date: null,
                    cell_phone_number: null,
                    delivery_window: null,
                    material_request_items: [],
                    user: null,
                    status: 'created'
                },
                address: {
                    jobsite: {
                        job_id: null,
                        line1: null,
                        line2: null,
                    },
                    warehouse: {
                        line1: '6751 Southfront Rd',
                        line2: 'Livermore, CA 94551',
                    }
                }
            }
        }
    },
    computed: {
        isCompleted() {
            return this.material_request.completed_at > '2020';
        },
        jobParts() {
            return Object.values(cloneObject(this.data.job_parts));
        },
        whParts() {
            return Object.values(cloneObject(this.data.warehouse_parts));
        },
        foreman() {
            return get(this.material_request, 'job.foreman.name');
        },
        jobName() {
            return get(this.material_request, 'job.description');
        },
        jobId() {
            return get(this.material_request, 'job.id');
        },
        todaysDate() {
            return this.formatDate(new Date().toISOString().split('T')[0]);
        },
        hasId() {
            return get(this.$route, 'params.id');
        }
    },
    methods: {
        get,
        setAddressToJobsite() {
            this.material_request.address1 = this.getDefaultJobsiteAddress(1);
            this.material_request.address2 = this.getDefaultJobsiteAddress(2);
        },
        async onDeliveryTypeClicked(type) {
            if (type === 'warehouse') {
                this.material_request.delivery_destination_type = 'warehouse';
                this.material_request.address1 = this.defaults.address.warehouse.line1;
                this.material_request.address2 = this.defaults.address.warehouse.line2;
            }

            if (type === 'jobsite') {
                this.material_request.delivery_destination_type = 'jobsite';
                this.setAddressToJobsite();
            }

            await this.debounceSave();
        },
        getDefaultJobsiteAddress(line) {

            if (line === 1) {
                return get(this.material_request, 'job.mail_address', '');
            }

            let address2 = [
                get(this.material_request, 'job.mail_city'),
                get(this.material_request, 'job.mail_state', ''),
                get(this.material_request, 'job.mail_zip')
            ];
            address2 = address2.filter(Boolean).join(' ');

            if (line === 2) {
                return address2;
            }

            return '';
        },
        debounceSave: debounce(function (value, event) {
            return this.save()
        }, 1000),
        async submit() {
            if (!this.isCompleted && this.$refs.createMaterialRequestForm.validate()) {
                this.runningSave = true;
                this.submitting = true;
                this.material_request.status = 'submitted';
                return await this.save()
                    .then(() => {
                        this.$router.push({name: 'CreateMaterialRequest'});
                    })
                    .finally(() => {
                        this.material_request.status = false;
                        this.submitting = false;
                    });
            }
        },
        async getParts(item) {
            return await this.$http.post(`/v3/materials/requests/items/parts`, {item: item}).then((response) => {
                return response.data.parts;
            });
        },
        saving() {
            console.log('saving...');
            this.submitting = true;
            this.snackbar.open = true;
            this.snackbar.saving = true;
            this.snackbar.text = 'Saving...';
            this.snackbar.color = 'blue-grey';
        },
        saved(failed = false) {
            if (failed) {
                this.snackbar.color = 'error';
                this.snackbar.text = 'Save Failed';
            } else {
                this.snackbar.text = 'Saved';
                this.snackbar.color = 'primary';
            }

            this.submitting = false;
            this.snackbar.saving = false;
            this.material_request.submitted = false;
        },
        async save() {
            let controller = new AbortController();

            if (!this.material_request.id) {
                return;
            }

            if (this.isCompleted) {
                this.$toast.warning('Materials requests can not be edited after submission.');
                return;
            }

            if (this.snackbar.saving) {
                console.log('Aborting previous save request');
                if (!controller.signal.aborted) {
                    controller.abort();
                    controller = new AbortController();
                }
            }

            this.saving();

            this.$refs.createMaterialRequestForm.validate();

            return await this.$http.post(`/v3/materials/requests/${this.material_request.id}`,
                this.material_request,
                {
                    signal: controller.signal
                }
            ).then((response) => {
                this.setData(response);
                return response.data.material_request;
            }).finally(() => {
                this.saved();
                setTimeout(() => {
                    this.runningSave = false;
                }, 500);
            });
        },
        async onJobChanged(job) {
            const id = get(this.material_request, 'id');

            if (!id) {
                this.creating = true;
                return await this.fetchNewMaterialRequest(job);
            } else {
                await this.fetchMaterialRequest(id);
            }

            this.material_request = cloneObject(this.defaults.material_request);
            this.material_request.id = id;
            this.material_request.purchase_order_job_id = job.id;
            await this.save();
        },
        async fetchJob(id) {
            this.loading = true;
            return await this.$http.get(`/v3/jobs/${id}`).then((response) => {
                this.data.job = response.data.job;
                return response.data.job;
            }).finally(() => {
                this.loading = false;
            });
        },
        async fetchTypes() {
            return await this.$http.get('/v3/materials/requests/items/types')
                .then((response) => {
                    let types = get(response, 'data.material_request_item_types', []);
                    types = types.map((type) => {

                        if (type.id > 3) {
                            type.disabled = true;
                        }

                        return type;
                    });

                    this.data.material_request_item_types = types;

                }).catch((error) => {
                    console.log(error);
                });
        },
        async fetchJobs() {
            return await this.$http.get('/v3/jobs').then((response) => {
                this.data.jobs = response.data.jobs;
                return response.data.jobs;
            }).catch((error) => {
                console.log(error);
            })
        },
        async fetchMaterialRequest(id) {
            return await this.$http.get(`/v3/materials/requests/${id}`).then((response) => {

                const type = get(response, 'data.material_request.delivery_destination_type');

                this.delivery_type = 1;

                if (type === 'warehouse') {
                    this.delivery_type = 0;
                }

                this.setData(response);
            });
        },
        async fetchUnitsOfMeasure() {
            return await this.$http.get('/v3/unit-of-measures')
                .then((response) => {
                    this.data.units_of_measure = get(response, 'data.unit_of_measures', []);
                    return response.data.units_of_measure;
                }).catch((error) => {
                    console.log(error);
                });
        },
        async fetchNewMaterialRequest(job) {
            this.loading = true;
            return await this.$http.post(`/v3/materials/requests/create`, {job: job}).then(response => {
                const id = get(response, 'data.material_request.id');
                if (id) {
                    this.$router.push(`/materials/requests/${id}`);
                }
            }).catch(error => {
                this.$toast.error('Failed to create material request');
            })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        async addLine() {
            this.saving();
            return await this.$http.post(`/v3/materials/requests/${this.material_request.id}/add-line-item`, this.material_request)
                .then((response) => {
                    this.setData(response);
                })
                .finally(() => {
                    this.saved();
                });
        },
        async deleteLine(item) {
            if (item.id) {
                return this.$http.delete(`/v3/materials/requests/items/${item.id}`)
                    .finally(async (response) => {
                        return await this.save();
                    });
            }
        },
        async updatePartNumber(item, selected) {
            if (item) {
                item.part_number = get(selected, 'material');

                if (!item.part_number) {
                    item.part_number = get(selected, 'part_number');
                }

                item.part_description = get(selected, 'description');

                if (!item.part_description) {
                    item.part_description = get(selected, 'part_description')
                }

                item.phase_code = this.data.phase_codes.filter((code) => {
                    return code.phase_code === get(selected, 'phase_code');
                }).pop();

                item.quantity = get(selected, 'original_units');
            }

            return this.debounceSave();
        },
        changeType(item, type) {
            this.save();
        },
        print() {
            window.open(`/api/v3/materials/requests/${this.material_request.id}/print`, '_blank');
        },
        back() {
            this.$router.push({name: 'MaterialRequest'});
        },
        setData(response) {

            if (response.data.job_parts) {
                this.data.job_parts = response.data.job_parts;
            }

            if (response.data.warehouse_parts) {
                this.data.warehouse_parts = response.data.warehouse_parts;
            }

            this.data.phase_codes = get(response, 'data.material_request.job.phase_codes', []);

            if (this.data.phase_codes) {
                this.numPhaseCodes = get(this.data, 'phase_codes.length', 0);
            }

            let material_request = get(response, 'data.material_request');

            if (material_request && material_request.id > 0) {

                if (!material_request.ship_to_name || material_request.ship_to_name.length < 3) {
                    material_request.ship_to_name = get(material_request, 'job.foreman.name');
                }

                if (!material_request.cell_phone_number || material_request.cell_phone_number.length < 1) {
                    material_request.cell_phone_number = get(material_request, 'job.foreman.cell_phone');
                }

                if (this.data.warehouse_parts) {
                    for (let item of material_request.material_request_items) {
                        const type = get(item, 'material_request_item_type.code');
                        if (type === 'W') {
                            for (let part of this.whParts) {
                                if (part.part_combo_name === item.part_combo_name.toUpperCase()) {
                                    item.part = part;
                                    break;
                                }
                            }
                        }
                    }
                }

                this.material_request = material_request;
            }

            if (this.delivery_type === 1) {
                this.setAddressToJobsite();
            }
        }
    },
    async created() {

        this.loading = true;

        let requestPool = [
            await this.fetchJobs(),
            await this.fetchUnitsOfMeasure(),
            await this.fetchTypes()
        ];

        if (this.$route.params.id) {
            requestPool.push(await this.fetchMaterialRequest(this.$route.params.id));
        }

        await Promise.all(requestPool);

        //this.data.material_request_item_types = await this.fetchTypes();
        /*this.data.material_request_item_types.map((type) => {

            if (type.id > 3) {
                type.disabled = true;
            }

            return type;
        });
         */

        this.delivery_type = 1;
        this.loading = false;
    }
}
</script>

<template>
    <v-container fluid>
        <PageTitle>
            <template v-slot:prepend-button>
                <v-btn small text color="secondary"
                       class="pa-0"
                       @click="back"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </template>
            <template v-slot:button>
                <v-btn :disabled="!material_request.job" small color="blue-grey" @click="print">
                    <v-icon left>mdi-printer</v-icon>
                    Print
                </v-btn>
            </template>
            <span v-show="!material_request.id">Create</span> Material Request <span
            v-show="material_request.id">#{{ material_request.id }}</span>
        </PageTitle>

        <v-form :disabled="isCompleted"
                v-model="isValid"
                ref="createMaterialRequestForm"
        >
            <v-card :loading="loading">
                <v-card-text class="d-flex justify-start">
                    <div class="font-weight-bold align-self-center py-2 mr-2">
                        Job Number:
                    </div>
                    <div>
                        <v-autocomplete
                            dense
                            @change="onJobChanged"
                            v-model="material_request.job"
                            :items="data.jobs"
                            hide-details
                            item-text="job_number"
                            item-value="id"
                            return-object
                            :rules="[rules.required('Job is required.')]"
                        >
                            <template v-slot:selection="data">
                                <JobInfoChip
                                    clearable
                                    :job-number="data.item.job_number"
                                    :job-description="data.item.description"
                                    :company-slug="get(data, 'item.company.slug')"
                                />
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.job_number }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span>
                                            <i v-if="data.item.company">{{ data.item.company.slug }}</i> -
                                            <b>{{ data.item.description }}</b>
                                        </span>
                                        <span v-if="data.item.inventory_item_count !== undefined && !hideItemCount">
                                           - {{ data.item.inventory_item_count }} items
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div style="width:50%;" v-show="creating">
                        <v-progress-circular indeterminate></v-progress-circular>
                        Importing data from vista...
                    </div>
                </v-card-text>
            </v-card>
            <div v-show="material_request.job && hasId">
                <v-card>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="5" class="d-flex justify-start">
                                <div class="font-weight-bold align-self-center py-2 mr-2">
                                    Today's Date:
                                </div>
                                <div class="d-flex align-self-center">
                                    {{ todaysDate }}
                                </div>
                            </v-col>
                            <v-spacer/>
                            <v-col cols="5">
                                <v-row dense>
                                    <v-col class="d-flex justify-start">
                                        <div class="align-self-center py-2 font-weight-bold mr-2">
                                            Delivery Window:
                                        </div>
                                        <div class="d-flex justify-start flex-grow-1">
                                            <VSelect
                                                @change="save"
                                                :items="data.delivery_windows"
                                                v-model="material_request.delivery_window"
                                                :rules="[rules.required('Delivery Window is required.')]"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-icon>
                                                        <VIcon
                                                            :color="item.color"
                                                        >
                                                            {{ item.icon }}
                                                        </VIcon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </VSelect>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="d-flex justify-start">
                                        <div class="align-self-center py-2 font-weight-bold mr-2">
                                            Need by Date:
                                        </div>
                                        <div class="d-flex justify-start flex-grow-1">
                                            <date-picker
                                                dense
                                                prepend-inner-icon=""
                                                :outlined="false"
                                                label=""
                                                hide-details
                                                clearable
                                                :rules="[rules.required('Delivery Date is required.')]"
                                                @change="save"
                                                v-model="material_request.requested_delivery_date"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" class="d-flex justify-start">
                                <div class="font-weight-bold py-2 mr-2 align-self-center">
                                    Job Name:
                                </div>
                                <div class="mr-2 flex-grow-1">
                                    <v-text-field
                                        dense
                                        disabled
                                        v-model="jobName"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="8" class="d-flex justify-start">
                                <div class="py-1 mr-2 font-weight-bold">
                                    Ship To:
                                </div>
                                <div class="flex-grow-1">
                                    <div class="mb-4">
                                        <v-btn-toggle
                                            mandatory
                                            dense
                                            v-model="delivery_type"
                                        >
                                            <v-btn
                                                :disabled="isCompleted"
                                                small
                                                @click="onDeliveryTypeClicked(type.id)"
                                                v-for="type in data.destinations"
                                                :key="type.id"
                                            >
                                                {{ type.text }}
                                            </v-btn>
                                        </v-btn-toggle>
                                    </div>
                                    <div>
                                        <v-text-field
                                            dense
                                            :rules="[rules.required('Address is required.')]"
                                            v-model="material_request.address1"
                                        />
                                    </div>
                                    <div>
                                        <v-text-field
                                            dense
                                            v-model="material_request.address2"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5" class="d-flex justify-start">
                                <div class="mr-2 font-weight-bold py-2">
                                    Foreman / Site Contact
                                </div>
                                <div class="flex-grow-1">
                                    <v-text-field
                                        dense
                                        :hint="get(material_request, 'job.foreman.name', ' ')"
                                        persistent-hint
                                        v-model="material_request.ship_to_name"
                                    />
                                </div>
                            </v-col>

                            <v-spacer/>

                            <v-col cols="5" class="d-flex justify-start">
                                <div class="mr-2 font-weight-bold py-2">
                                    Cell Phone #:
                                </div>
                                <div class="flex-grow-1">
                                    <v-text-field
                                        dense
                                        :loading="loading"
                                        v-model="material_request.cell_phone_number"
                                        :hint="get(material_request, 'job.foreman.cell_phone', ' ')"
                                        persistent-hint
                                    />
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card
                    class="mt-1"
                    v-if="material_request.job"
                >
                    <v-card-text>
                        <v-simple-table
                            dense
                            class="material-request-table"
                        >
                            <thead>
                            <tr>
                                <th style="width:50px;">Type</th>
                                <th style="width:50px;">#</th>
                                <th style="width:120px;">Qty</th>
                                <th style="width:70px;">Unit</th>
                                <th>Part #</th>
                                <th>Description</th>
                                <th>Manuf.</th>
                                <th style="width:110px;">Phase Code</th>
                                <th style="width:60px;"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in material_request.material_request_items">
                                <td class="py-2">
                                    <v-select
                                        @change="changeType(item, $event)"
                                        disable-lookup
                                        dense
                                        hide-details
                                        chips
                                        small-chips
                                        item-disabled="disabled"
                                        solo
                                        outlined
                                        :items="data.material_request_item_types"
                                        v-model="item.material_request_item_type"
                                        item-value="id"
                                        return-object
                                        :rules="[rules.required('Type is required.')]"
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip label x-small class="px-2 mx-1">
                                                {{ get(data, 'item.code') }}
                                            </v-chip>
                                        </template>

                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ get(data, 'item.name') }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ get(data, 'item.description') }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-select>
                                </td>
                                <td style="text-align: center;">{{ item.line_number }}</td>
                                <td>
                                    <v-text-field :loading="loading"
                                                  type="number"
                                                  v-model="item.quantity"
                                                  dense
                                                  outlined
                                                  hide-details
                                                  :rules="[rules.required('Quantity is required.')]"
                                    />
                                </td>
                                <td>
                                    <v-select :items="data.units_of_measure"
                                              item-value="id"
                                              item-text="abbreviation"
                                              v-model="item.unit_of_measure_id"
                                              chips
                                              dense
                                              outlined
                                              hide-details
                                              :rules="[rules.required('Unit is required.')]"
                                              @change="save"
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip label>
                                                {{ get(data, 'item.abbreviation') }}
                                            </v-chip>
                                        </template>
                                    </v-select>
                                </td>
                                <td>
                                    <v-autocomplete
                                        outlined
                                        v-if="item.material_request_item_type_id === 2"
                                        :key="`job-ac-${item.id}`"
                                        :items="jobParts"
                                        @change="updatePartNumber(item, $event)"
                                        v-model="item.part"
                                        :loading="loading"
                                        item-text="part_combo_name"
                                        item-value="part_combo_name"
                                        return-object
                                        clearable
                                        dense
                                        hide-details
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-avatar tile>
                                                <v-img
                                                    :src="get(data, 'item.photo.thumbnail.url', get(data, 'item.photos.0.thumbnail.url', ''))"
                                                />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ get(data, 'item.material') }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ get(data, 'item.description') }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                        v-else-if="item.material_request_item_type_id === 3"
                                        :key="`wh-ac-${item.id}`"
                                        :items="whParts"
                                        @change="updatePartNumber(item, $event)"
                                        v-model="item.part"
                                        item-text="part_combo_name"
                                        item-value="part_combo_name"
                                        return-object
                                        outlined
                                        hide-details
                                        dense
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-avatar tile>
                                                <v-img
                                                    :src="get(data, 'item.photo.thumbnail.url', get(data, 'item.photos.0.thumbnail.url', ''))"
                                                />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ get(data, 'item.part_number') }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ get(data, 'item.part_description') }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                        <span
                                                            style="font-style:italic;font-weight:bold;font-size:11px;">{{
                                                                get(data, 'item.job_number')
                                                            }}</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                    <v-text-field
                                        v-else
                                        :key="`new-item-${item.id}`"
                                        v-model="item.part_number"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.part_description"
                                        :rules="[rules.required('Description is required.')]"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.manufacturer"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </td>
                                <td>
                                    <v-autocomplete
                                        class="mr-select"
                                        @change="save"
                                        v-if="numPhaseCodes > 0"
                                        :key="`phase-mr-item-${item.id}`"
                                        :items="data.phase_codes"
                                        v-model="item.phase_code"
                                        item-text="search_text"
                                        return-object
                                        dense
                                        chips
                                        small-chips
                                        deletable-chips
                                        outlined
                                        hide-details
                                        :rules="[rules.required('Phase Code is required.'), rules.phaseCode]"
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip label>
                                                {{ get(data, 'item.phase_code') }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ get(data, 'item.phase_code') }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ get(data, 'item.description') }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                    <v-text-field
                                        v-else
                                        :key="`new-phase-mr-item-${item.id}`"
                                        v-model="item.phase_code"
                                        :rules="[rules.required('Phase Code is required.'), rules.phaseCode]"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </td>
                                <td>
                                    <confirm-dialog
                                        @confirm="deleteLine(item)"
                                        color="delete"
                                        small
                                        icon
                                        :message="`Really Delete line # ${item.line_number} from this request?`"
                                    >
                                        <v-icon small>mdi-delete</v-icon>
                                    </confirm-dialog>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-show="!isCompleted"
                            color="primary"
                            @click="addLine"
                            :loading="submitting"
                            :disabled="submitting"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Add Line
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <v-card class="my-2">
                    <v-card-text class="py-2">
                        <v-text-field
                            label="Special Comments"
                            v-model="material_request.notes"
                        />
                    </v-card-text>
                </v-card>
            </div>

            <v-row class="my-2" v-if="!isCompleted">
                <v-col>
                    <v-btn block
                           color="info"
                           @click="save"
                           :loading="submitting"
                           :disabled="submitting || isCompleted"
                    >
                        <v-icon small left>mdi-content-save</v-icon>
                        Save as Draft
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        :color="(isValid ? 'primary' : 'orange darken-1')"
                        :disabled="submitting || !isValid || isCompleted"
                        :loading="submitting && isValid"
                        @click="submit"
                        block
                    >
                        Submit Material Request
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="my-2" v-if="isCompleted">
                <v-col>
                    <v-btn :disabled="!material_request.job"
                           small
                           color="blue-grey"
                           @click="print">
                        <v-icon left>mdi-printer</v-icon>
                        Print
                    </v-btn>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-form>


        <v-snackbar
            height="50"
            max-width="100"
            v-model="snackbar.open"
        >
            <v-progress-circular indeterminate v-show="snackbar.saving "/>
            <v-icon color="success" v-show="!snackbar.saving">mdi-check</v-icon>

            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    small
                    v-bind="attrs"
                    @click="snackbar.open = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<style>
.material-request-table .v-select .v-input__slot, .material-request-table .v-select .v-input__slot fieldset {
    padding: 0 !important;
}

.material-request-table tbody tr td, .material-request-table thead tr th {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.material-request-table tbody tr:nth-child(even) {
    background-color: #323232;
}
</style>