<script>
export default {
    name: "DatePicker",
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Date"
        },
        hint: {
            type: String,
            default: "YYYY-MM-DD Format"
        },
        outlined: {
            type: Boolean,
            default: true
        },
        prependInnerIcon: {
            type: String,
            default: "mdi-calendar"
        },
    },
    data() {
        return {
            menu: false,
            date: null,
        }
    },
    watch: {
        date(val) {
            this.$emit('input', val);
        }
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
    }
}
</script>

<template>
    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="selected"
                    v-bind:outlined="outlined"
                    v-bind:prepend-inner-icon="prependInnerIcon"
                    persistent-hint
                    v-on:input="$emit('input', $event)"
                    v-on="on"
                    v-bind:label="label"
                    v-bind="$attrs"
                    clearable
            ></v-text-field>
        </template>
        <v-date-picker
                v-model="selected"
                @input="menu = false"
                no-title
                scrollable
                @change="$emit('change', $event)"
                clearable
        >
        </v-date-picker>
    </v-menu>
</template>

<style scoped>

</style>