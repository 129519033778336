import { get } from "@/client/utilities/Object";
import Config from '@/common/config';
export default {
    name: "PhotoThumbnail",
    inheritAttrs: false,
    data: function () {
        return {
            viewPhotoDialog: false,
            isRotatingPhoto: false,
            buttons: [
                {
                    icon: 'mdi-eye',
                    color: 'blue darken-2',
                    onClick: (photo) => {
                        this.viewPhotoDialog = true;
                    }
                },
                {
                    icon: 'mdi-file-rotate-right',
                    text: 'Rotate',
                    color: 'orange darken-4',
                    onClick: (photo) => this.rotatePhoto(this.photoId)
                },
            ]
        };
    },
    computed: {
        photoModel: function () {
            if (Array.isArray(this.photo) && this.photo.length > 0) {
                return this.photo[0];
            }
            return this.photo;
        },
        placeholderUrl: () => {
            return Config.photos.placeholder.url;
        },
        thumbnailUrl: function () {
            return get(this.photoModel, 'thumbnails.0.url', get(this.photoModel, 'thumbnails.75.url', get(this.photoModel, 'thumbnail.url')));
        },
        photoUrl: function () {
            return get(this.photoModel, 'url');
        },
        photoId: function () {
            return get(this.photoModel, 'id');
        },
        disableHover: function () {
            if (parseInt(this.width) < 75) {
                return true;
            }
            return !this.hover;
        },
        smallButtons: function () {
            return parseInt(this.width) >= 75 && parseInt(this.width) <= 100;
        },
        progressSize: function () {
            if (parseInt(this.width)) {
                return parseInt(this.width) * 0.6;
            }
            return 50;
        },
        progressWidth: function () {
            return (this.progressSize / 10) - 1;
        }
    },
    methods: {
        remove(photo) {
            this.$log.log(`Delete clicked for photo ID ${photo.id}`);
            this.$emit('delete', photo);
        },
        downloadPhoto: function (photoId) {
            let filename = this.title;
            window.open('/api/v2/photos/' + photoId + '/download?prefix=' + filename);
        },
        rotatePhoto(photoId) {
            this.isRotatingPhoto = true;
            return this.$http.post('/v2/photos/' + photoId + '/rotate').then(response => {
                this.photo = response.data.photo;
            }).finally(() => {
                this.isRotatingPhoto = false;
            });
        }
    },
    props: {
        photo: {},
        width: {
            type: String,
            default: "75"
        },
        title: {
            type: String,
            default: null
        },
        hover: {
            type: Boolean,
            default: false
        }
    }
};
