import {clone, get} from "@/client/utilities/Object";
import Vue from "vue";
import Config from '../config.js';

const ApiService = {
    options: {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    },
    client: {},
    init() {
        Vue.axios.defaults.baseURL = this.getApiBaseUrl();
        Vue.axios.defaults.validateStatus = (status) => {
            return status >= 200 && status < 300;
        }
    },
    getApiBaseUrl() {
        if(window.location.hostname === 'localhost' || window.location.port === '8080') {
            return `http://localhost/api`;
        }

        return `${Config.api_url}`;
    },
    getUrl(path) {

        if(window.location.hostname === 'localhost') {
            return `http://localhost/api${path}`;
        }

        return `${Config.api_url}${path}`;
    },
    post(path, body) {
        return this.request('POST', path, body);
    },

    /**
     * Executes request from config array
     * endpoint = [METHOD, URI]
     *
     * @param endpoint
     * @param body
     * @returns {Promise<Response>}
     */
    call(endpoint, body) {
        let method = endpoint[0];
        let path = endpoint[1];
        return this.request(method, path, body);
    },
    request(method, path, body) {

        let config = clone(this.options);

        config.url = path;

        config.method = method;

        if (body && method === 'GET') {
            config.params = body;
        } else {
            config.data = body;
        }

        console.log(`[API Service][${method.toUpperCase()}] (${config.url})`, config);

        return Vue.axios.request(config);
    }
};

export default ApiService;

export const UserService = {
    async getUser() {
        const response = await ApiService.call(Config.endpoints.user.get, {});
        if(response) {
            return await get(response, 'data.user');
        }
    },
    login(email, password) {
        return ApiService.call(Config.endpoints.user.login, {
            email: email,
            password: password
        });
    },
    logout() {
        return ApiService.call(Config.endpoints.user.logout);
    }
};

export const WarehouseInventoryService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.warehouseInventory.data, body);
    },
    byJobNumber(jobNumber) {
        return ApiService.call(Config.endpoints.warehouseInventory.byJobNumber, {jobNumber: jobNumber});
    }
};

export const ReceiveFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.receiveForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.receiveForm.submit, form);
    }
};

export const TransmittalFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.transmittalForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.transmittalForm.submit, form);
    },
    getJobInfo(form) {
        return ApiService.call(Config.endpoints.transmittalForm.search, form);
    },
    getFormDataV2(body) {
        return ApiService.call(Config.endpoints.v2.transmittalForm.data, body);
    },
    getInventory(body) {
        return ApiService.call(Config.endpoints.v2.transmittalForm.inventory, body);
    }
};

export const OwnerMaterialReceivingFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.ownerMaterialsReceivingForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.ownerMaterialsReceivingForm.submit, form);
    }
};

export const RestockFormService = {
    itemsByJobNumber(body) {
        return ApiService.call(Config.endpoints.restockForm.search, body);
    },
    getFormData(body) {
        return ApiService.call(Config.endpoints.restockForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.restockForm.submit, form);
    }
};

export const InventoryFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.inventoryForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.inventoryForm.submit, form);
    }
};

export const PurchaseOrderFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.purchaseOrders.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.purchaseOrders.submit, form);
    }
};

export const KitCreationFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.kitCreationForm.data, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.kitCreationForm.submit, form);
    }
};

export const RmaFormService = {
    getFormData(body) {
        return ApiService.call(Config.endpoints.rmaForm.data, body);
    },
    itemsByJobNumber(body) {
        return ApiService.call(Config.endpoints.rmaForm.search, body);
    },
    submitForm(form) {
        return ApiService.call(Config.endpoints.rmaForm.submit, form);
    }
};