import Client from "../lib/Client";
import Job from "../../models/Job/Job";
import JobInterface from "../../models/Job/JobInterface";
export default class JobService extends Client {
    model = Job;
    dataKey = 'jobs';
    async get() {
        return await this.request.get('/jobs');
    }
    async open() {
        return await this.request.get(`/jobs/open`);
    }
    async inInventory(id) {
        return await this.request.put(`/jobs/in-inventory`);
    }
}
