import Model from "../Model";
import CompanyInterface from "./CompanyInterface";
export default class Company extends Model {
    id;
    company_id;
    jcco;
    name;
    slug;
    domain;
    created_at;
    updated_at;
}
