<template>
    <v-container>
        <PageTitle>
            Owner Provided Material Receiving Form
        </PageTitle>

        <v-form v-model="valid" v-if="showForm" ref="form">

            <v-card class="my-3">
                <v-card-text>
                    <ProjectManagerAutocomplete
                        v-model="projectManager"
                        :items="projectManagers"
                    />
                </v-card-text>
                <v-card-text class="py-0 my-0">
                    <v-row>
                        <v-col cols="6" md="6">
                            <v-text-field
                                v-model="jobNumber"
                                :rules="[requiredField]"
                                label="Job Number"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" md="6">
                            <v-text-field
                                v-model="poNumber"
                                :rules="[requiredField]"
                                label="P.O. Number"
                                required
                                outlined
                            ></v-text-field>

                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="pt-0">
                    <JobDetails
                        v-if="job"
                        :po-number="purchaseOrder ? purchaseOrder.po_number : ''"
                        :job-number="job.job_number"
                        :job-description="job.description"
                        :project-manager="job.project_manager ? job.project_manager.name : ''"
                        :foreman="job.foreman? job.foreman.name : ''"
                    />
                </v-card-text>
            </v-card>

            <v-card
                v-for="(location, locationIndex) in locations"
                :key="locationIndex"
                class="my-4"
            >
                <v-card-text>
                    <v-row class="px-2 pt-2 mb-0 d-flex justify-space-around">
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="location.packageType"
                                :items="packageTypes"
                                label="Package Type"
                                outlined
                                prepend-inner-icon="mdi-package-variant"
                                @change="
                              location.rack = '';
                              location.section = '';
                              location.subSection = '';
                            "
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="3">
                            <v-select
                                v-model="location.rack"
                                :items="availableRacks(location)"
                                label="Rack"
                                :rules="[requiredField]"
                                outlined
                                @change="
                              location.section = '';
                              location.subSection = '';
                            "
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="3">
                            <v-select
                                v-model="location.section"
                                :items="availableSections(location)"
                                label="Section"
                                :rules="[requiredField]"
                                @change="location.subSection = ''"
                                outlined
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="3">
                            <v-select
                                v-model="location.subSection"
                                :items="availableSubSections(location, locationIndex)"
                                label="Sub-section"
                                :rules="[requiredField]"
                                required
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row class="my-0">
                        <v-col>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>

                    <v-row
                        class="px-2 my-0"
                        v-for="(part, partIndex) in location.parts"
                        :key="partIndex"
                    >
                        <v-col cols="12" md="4">
                            <v-textarea
                                v-model="part.number"
                                :rules="[requiredField]"
                                label="Part Number"
                                rows="1"
                                auto-grow
                                outlined
                                required
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-textarea
                                v-model="part.description"
                                :rules="[requiredField]"
                                label="Part Description"
                                rows="1"
                                auto-grow
                                outlined
                                required
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                v-model="part.quantity"
                                label="Quantity"
                                outlined
                                :rules="[quantityMoreThanZero]"
                                required
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- Button to add/remove more parts -->
                        <v-col
                            cols="12"
                            class="px-2 d-flex justify-space-around align-content-center"
                        >
                            <div>
                                <v-btn
                                    class="addPartBtn"
                                    @click="addPackage(locationIndex)"
                                    small
                                    tile
                                    color="addLocation"
                                >
                                    Add part
                                    <v-icon
                                        small
                                        right
                                    >mdi-plus
                                    </v-icon>
                                </v-btn>
                            </div>
                            <div v-show="location.parts.length > 1">
                                <v-btn
                                    class="addPartBtn"
                                    @click="location.parts.pop()"
                                    small
                                    tile
                                    color="removeLocation"
                                >
                                    Remove part
                                    <v-icon small right>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Button to add more location -->
            <v-row class="my-6 my-md-10 d-flex justify-space-around">

                <v-col class="d-flex justify-space-around">

                    <v-btn
                        class="addLocationBtn"
                        @click="addLocation"
                        color="addLocation"
                        tile
                    >
                        <v-icon left>mdi-map-marker-plus</v-icon>
                        Add Location
                    </v-btn
                    >

                </v-col>

                <v-col class="d-flex justify-space-around">

                    <v-btn
                        class="addLocationBtn"
                        @click="locations.pop()"
                        tile
                        color="removeLocation"
                    >
                        <v-icon left>mdi-map-marker-minus</v-icon>
                        Remove Location
                    </v-btn
                    >

                </v-col>

            </v-row>

            <!-- Submit Form button -->

            <FormSubmitButton
                :valid="valid"
                :submitting="formSubmitting"
                @click="submitForm"
            />

        </v-form>

        <FormSubmittedMessage
            v-if="!showForm"
            button-text="Submit a New Order"
            :on-click="reloadPage"
        >
            Owner Provided Materials Receiving Form submitted.
        </FormSubmittedMessage>

    </v-container>
</template>

<script>
import {OwnerMaterialReceivingFormService} from "@/common/api/api.service";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import JobDetails from "@/components/JobDetails.vue";
import PageTitle from "@/components/PageTitle.vue";
import ProjectManagerAutocomplete from "@/components/V2/ProjectManagerAutocomplete.vue";

export default {
    components: {
        ProjectManagerAutocomplete,
        FormSubmitButton,
        FormSubmittedMessage,
        PageTitle,
        JobDetails
    },
    title: 'Owner Material Receiving Form',
    data: () => ({
        purchaseOrder: null,
        purchaseOrders: [],
        openJobs: [],
        job: null,
        showForm: true,
        formSubmitting: false,
        loadingImport: true,
        valid: false,
        employeeName: "",
        presetEmployees: [],
        projectManager: "",
        requiredField: (v) => !!v || "This is a required field",
        jobNumber: "",
        poNumber: "",
        jobName: "",
        foreman: "",
        managers: [],
        projectManagers: [],
        foremans: [],
        jobNumbers: [],
        poNumbers: [],
        // pkgToggle: false,
        packageTypes: ["Pallet", "Long Pallet", "Loose"],
        rackInfo: {}, // Object of all available locations (aka, location that has no products at that location)
        availableLocationsForLooseItems: {}, // Object of all available locations for loose items (all racks 1-27, 32, 33. For rack 28-31, only section + subSection that has no items on it)
        racks: [],
        sections: [],
        subSections: [],
        partNumbers: [],
        partStores: {},
        locations: [
            {
                packageType: "Pallet",
                rack: null,
                section: null,
                subSection: null,
                parts: [{number: "", description: "", quantity: ""}],
            },
        ],
    }),
    methods: {
        reloadPage() {
            window.location.reload();
        },
        quantityMoreThanZero(v) {
            // return part.quantity > 0 || "Quantity must be greater than 0";
            return v > 0 || "Quantity must be greater than 0";
        },
        async fetchDatabase() {

            const response = await OwnerMaterialReceivingFormService.getFormData();

            const json = await response.data;

            this.presetEmployees = json['employeeNames'];
            this.rackInfo = json["availableLocations"];
            this.availableLocationsForLooseItems = json["availableLocationsForLooseItems"];

            this.racks = Object.keys(this.rackInfo).sort();

            this.managers = json.managers;
            this.projectManagers = json.projectManagers;
            this.foremans = json.foremans;
            this.loadingImport = false;
        },
        async submitForm() {

            if (!this.$refs.form.validate()) return;

            this.formSubmitting = true;

            OwnerMaterialReceivingFormService
                .submitForm(this.form)
                .then(response => {
                    this.showForm = false;
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.error('Error submitting owner material receiving form.');
                    this.showForm = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.formSubmitting = false;
                });
        },
        async resetForm() {
            window.location.reload();
        },
        addPackage(locationIndex) {
            this.locations[locationIndex].parts.push({
                number: "",
                description: "",
                quantity: "",
            });
        },
        addLocation() {
            this.locations.push({
                packageType: "Pallet",
                rack: null,
                section: null,
                subSection: null,
                parts: [{number: "", description: "", quantity: ""}],
            });
        },
        availableRacks(location) {
            let rackOptions = [];
            if (location.packageType.toLowerCase() == "Pallet".toLowerCase()) {
                // console.log(`package type: ${location.packageType}`);
                rackOptions = Object.keys(this.rackInfo).sort((a, b) => a - b);
            } else if (
                location.packageType.toLowerCase() == "Long Pallet".toLowerCase()
            ) {
                // console.log(`package type: ${location.packageType}`);
                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const rack of Object.keys(this.rackInfo)) {
                    for (const section of Object.keys(this.rackInfo[rack])) {
                        // console.log(rack + "-" + section + "-" + JSON.stringify(this.rackInfo[rack][section]));
                        if (
                            this.rackInfo[rack][section].includes(1) &&
                            this.rackInfo[rack][section].includes(2)
                        ) {
                            rackOptions.push(rack);
                            break;
                        }
                    }
                }
                console.log(
                    `Amount of rack options for Long Pallet: ${rackOptions.length}`
                );
            } else {
                // console.log(`package type: ${location.packageType}`);
                rackOptions = Object.keys(this.availableLocationsForLooseItems).sort(
                    (a, b) => a - b
                );
            }

            // return Object.keys(this.rackInfo).sort();
            return rackOptions;
        },
        availableSections(location) {
            // When the form is load, no rack is selected.
            if (!location.rack) return [];

            // if (!rack) return [];
            // return Object.keys(this.rackInfo[rack]).sort((a, b) => a - b);
            let sectionOptions = [];
            // console.log(location.packageType);
            if (location.packageType.toLowerCase() == "Pallet".toLowerCase()) {
                // console.log(location.rack);
                sectionOptions = Object.keys(this.rackInfo[location.rack]).sort(
                    (a, b) => a - b
                );
            } else if (
                location.packageType.toLowerCase() == "Long Pallet".toLowerCase()
            ) {
                // console.log(`package type: ${location.packageType}`);
                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const section of Object.keys(this.rackInfo[location.rack])) {
                    // console.log(location.rack + "-" + section + "-" + JSON.stringify(this.rackInfo[location.rack][section]));
                    if (
                        this.rackInfo[location.rack][section].includes(1) &&
                        this.rackInfo[location.rack][section].includes(2)
                    ) {
                        sectionOptions.push(section);
                    }
                }
            } else {
                sectionOptions = Object.keys(
                    this.availableLocationsForLooseItems[location.rack]
                ).sort((a, b) => a - b);
            }
            return sectionOptions;
        },
        availableSubSections(location, locationIndex) {
            if (!location.rack || !location.section) return [];

            let subSectionOptions = [];

            // console.log(`package type: ${location.packageType}`);
            if (location.packageType.toLowerCase() != "Loose".toLowerCase()) {
                subSectionOptions = [...this.rackInfo[location.rack][location.section]];
            } else {
                subSectionOptions = [
                    ...this.availableLocationsForLooseItems[location.rack][
                        location.section
                        ],
                ];
            }

            // console.log(subSectionOptions);
            // if the package type is 'Long Pallet', then the subSection is "1,2"
            if (location.packageType.toLowerCase() == "Long Pallet".toLowerCase()) {
                subSectionOptions = ["1,2"];
                this.locations[locationIndex]["subSection"] = "1,2";
            }
            return subSectionOptions;
        },
        resetLocation(location) {
            location.rack = "";
            location.section = "";
            location.subSection = "";
        },
    },
    computed: {
        form() {
            return {
                employeeName: this.employeeName,
                projectManager: this.projectManager,
                jobNumber: this.jobNumber,
                poNumber: this.poNumber,
                jobName: this.jobName,
                foreman: this.foreman,
                locations: this.locations,
            };
        }
    },
    created() {
        this.fetchDatabase();
    }
};
</script>

<style>
.addPartBtn,
.submitForm,
.newForm {
    display: block;
    width: 100%;
    margin-bottom: 15px;
}

small {
    color: lightgray;
}
</style>