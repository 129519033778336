const get = function (object, path, _default = null) {
    let value = ('' + path).split('.')
        .filter(item => item !== '')
        .reduce((prev, cur) => prev ? prev[cur] : undefined, object);
    if (value === undefined) {
        value = _default;
    }
    return value;
};
const clone = (object) => JSON.parse(JSON.stringify(object));
export { get, clone };
