<script>
import {get} from "@/client/utilities/Object";

export default {
    name: 'RackAutocomplete',
    inheritAttrs: false,
    props: ['items', 'loading', 'value', 'rules', 'packageType', 'disabled'],
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
        racks: function () {
            const racks = Object.values(get(this.packageType, 'racks', []));
            return racks;
        }
    },
}
</script>

<template>
    <VAutocomplete
            outlined
            v-on="inputListeners"
            v-bind:disabled="disabled"
            v-bind:value="value"
            :items="racks"
            v-bind:rules="rules"
            v-bind:loading="loading"
            item-text="rack"
            item-value="id"
            label="Rack"
            return-object
            clearable
    />
</template>

<style scoped>

</style>