<template>
    <VSelect
        :items="items"
        :label="label"
        outlined
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
    >



        <template v-slot:item="{ item }">
                <v-list-item-icon>
                    <VIcon
                            :color="item.color"
                    >
                        {{item.icon}}
                    </VIcon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item-content>
        </template>
    </VSelect>
</template>

<script>
export default {
    name: "DeliveryWindowSelect",
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            default: () =>  [
                {
                    text: 'Morning',
                    value: 'morning',
                    icon: 'mdi-weather-sunset-up',
                    color: 'yellow lighten-4'
                },
                {
                    text: 'Afternoon',
                    value: 'afternoon',
                    icon: 'mdi-weather-sunny',
                    color: 'orange lighten-4'
                },
                {
                    text: 'Evening',
                    value: 'evening',
                    icon: 'mdi-moon-waning-crescent',
                    color: 'accent lighten-4'
                },
                {
                    text: 'Urgent',
                    value: 'urgent',
                    icon: 'mdi-alert-circle',
                    color: 'error'
                },
                {
                    text: 'Will Call',
                    value: 'will call',
                    icon: 'mdi-phone',
                    color: 'green'

                }
            ]
        }
    },
    computed: {
        "inputListeners": function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    "input": function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        }
    },
}
</script>

<style scoped>

</style>