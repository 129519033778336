<template>
    <v-card
        v-bind="$attrs"
        class="mx-auto align-center"
        elevation="6"
        max-width="500"
    >
        <v-card-title>
            {{ title }}
        </v-card-title>

        <v-card-text
            class="font-weight-bold align-content-center text-h6"
        >

            <v-alert
                prominent
                border="left"
                :type="type"
                text
            >
                <slot></slot>
            </v-alert>

        </v-card-text>

        <v-card-actions>

            <v-btn color="primary" block class="newForm" @click="onClick()">
                {{ buttonText }}
            </v-btn>

        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "FormSubmittedMessage",
    inheritAttrs:false,
    props: {
        title: {
            type: String,
            default: 'Form Submitted'
        },
        type: {
            type: String,
            default: 'success'
        },
        buttonText: {
            type: String,
            default: 'Submit New'
        },
        onClick: {
            type: Function,
            default: () => {
                window.location.reload();
            }
        }
    }
}
</script>