<template>
    <v-card>
        <v-card-text class="pb-0">
            <v-textarea
                    :label="label"
                    :placeholder="placeholder"
                    :rows="rows"
                    :prepend-inner-icon="prependInnerIcon"
                    outlined
                    v-bind="$attrs"
                    v-bind:value="value"
                    v-on="inputListeners"
            ></v-textarea>
        </v-card-text>
    </v-card>

</template>

<script>
export default {
    name: "NotesTextFieldV2",
    inheritAttrs: false,
    computed: {
        "inputListeners": function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    "input": function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        }
    },
    props: {
        label: {
            type: String,
            default: 'Notes'
        },
        placeholder: {
            type: String,
            default: 'Enter your notes...'
        },
        prependInnerIcon: {
            type: String,
            default:'mdi-message-text-outline'
        },
        rows: {
            type: String,
            default: "1"
        },
        value: ''
    }
}
</script>