<script>
import {get} from "@/client/utilities/Object";

export default {
    name: 'SubSectionAutocomplete',
    inheritAttrs: false,
    props: ['items', 'loading', 'value', 'rules', 'section', 'disabled'],
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
        subSections: function () {

            if(!this.items) {
                return get(this.section, 'sub_sections', []);
            }

            return this.items;
        }
    },
}
</script>

<template>
    <VAutocomplete
            outlined
            v-on="inputListeners"
            v-bind:value="value"
            :items="subSections"
            v-bind:rules="rules"
            v-bind:disabled="disabled"
            v-bind:loading="loading"
            item-text="sub_section"
            item-value="id"
            label="Sub Section"
            return-object
            clearable
    />
</template>

<style scoped>

</style>