import { get } from "@/client/utilities/Object";
export default {
    name: 'AddLineItemDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            valid: true,
            item: {
                price: null,
                part_number: null,
                part_description: null,
                part_quantity: null,
            },
            search: '',
            selectedItem: null,
            items: [],
            loading: false,
            saving: false,
        };
    },
    methods: {
        itemSelected(item) {
            if (typeof item === 'string') {
                this.item.part_number = item;
                return;
            }
            this.item.price = get(item, 'total_cost');
            this.item.part_number = get(item, 'part_number');
            this.item.part_description = get(item, 'part_description');
            this.$refs.partQty.focus();
        },
        reset() {
            this.item.part_number = null;
            this.item.part_description = null;
            this.item.part_quantity = null;
            this.item.price = null;
            this.selectedItem = null;
        },
        async fetchLineItems() {
            this.loading = true;
            const items = await this.$http.get('/v3/warehouse/inventory/ad-hoc').then(response => get(response, 'data.items', []));
            this.loading = false;
            this.items = items;
            return items;
        },
        close() {
            this.reset();
            this.$emit('input', false);
        },
        async save() {
            this.$refs.addLineItemForm.validate();
            if (this.valid) {
                this.saving = true;
                return await this.$http.post('/v3/warehouse/inventory/ad-hoc', this.item)
                    .then(response => {
                    return get(response, 'data.item');
                })
                    .then(item => {
                    this.$emit('saved', item, this.item);
                    this.close();
                })
                    .catch(error => {
                    this.$log.error(error);
                })
                    .finally(() => {
                    this.saving = false;
                });
            }
        }
    }
};
