import { clone } from "../utilities/Object";
import ModelDataTypeInterface from "./ModelDataTypeInterface";
export default class Model {
    fill(data) {
        for (let key in data) {
            this[key] = data[key];
        }
    }
    clone() {
        return Object.create(this.constructor.prototype);
    }
}
