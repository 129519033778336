import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    customVariables: ['~/scss/variables.scss'],
    theme: {
        options: {
            customProperties: true
        },
        dark: true,
        themes: {
            light: {
                primary: colors.green,
                secondary: colors.grey,
                accent: colors.purple.accent4,
                error: '#CF6679',
                info: colors.cyan.accent4,
                success: colors.lightGreen.darken2,
                warning: colors.yellow.darken4,

                addLocation: colors.green.lighten3,
                removeLocation: colors.red.lighten3,
                delete: '#b95656',

                jobDetailsBackground: colors.teal.lighten5,
                jobDetailsTitleBackground: colors.teal.lighten4,

                tabHeaderBackground: colors.green.lighten5,
                footerBackgroundColor: colors.green.darken2,

                navDrawerBackground: colors.green.lighten4,
                headerBackground: colors.green.lighten2,
                stagingHeaderBackground: colors.orange.darken4,

                microsoftLoginButton: colors.indigo.lighten1,
            },
            dark: {
                primary: colors.green.darken1,
                secondary: colors.grey.darken1,
                accent: colors.indigo.lighten1,
                error: colors.red.lighten2,
                info: colors.cyan.darken2,
                success: colors.lightGreen.darken2,
                warning: colors.amber.darken3,

                addLocation: colors.green,
                removeLocation: colors.red.lighten2,

                delete: '#b95656',

                stagingHeaderBackground: '#572222',
                footerBackgroundColor: colors.grey.darken4,

                navDrawerBackground: "#363636",
                navDrawerHeaderBackground: "#282828",

                jobDetailsBackground: colors.green.darken2,
                jobDetailsTitleBackground: colors.green.darken4,

                tabHeaderBackground: colors.grey.darken3,
                microsoftLoginButton: colors.indigo.darken1,

                rteHeaderBackground: '#722323',
                p1HeaderBackground: '#1E3A5D',
            }
        }
    }
});