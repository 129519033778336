export default {
    name: 'SubmitButtonWithMessages',
    inheritAttrs: false,
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        messages() {
            return this.value.errors ?? [];
        },
        showMessages() {
            return this.value.errors.length > 0;
        }
    },
    methods: {
        onClick() {
            console.log(this.value);
        }
    },
    async mounted() {
    }
};
