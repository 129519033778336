<script>
import PageTitle from "@/components/PageTitle.vue";
import dayjs from "dayjs";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import {LOG_STYLES} from "@/common/log";
import {get} from "@/client/utilities/Object";
import TabBadgeHeader from "@/components/TabBadgeHeader.vue";

export default {
    name: "ApprovalRequests",
    title: 'Approval Requests',
    logContext: 'ApprovalRequests',
    logStyle: LOG_STYLES.TEAL,
    components: {TabBadgeHeader, ConfirmDialog, PhotoManager, PageTitle},
    data() {
        return {
            table: {
                headers: [],
                data: []
            },
            dialogs: {
                rma: {}
            },
            tables: {
                shared: [
                    {text: 'User', value: 'user.name', sortable: false, filterable: false},
                    {text: 'Status', value: 'status'},
                    {text: 'Edit', value: 'edit', sortable: false, filterable: false},
                    {text: 'Delete', value: 'delete', sortable: false, filterable: false},
                ],
                receiving: {
                    headers: [
                        {text: 'PO #', value: 'purchase_order.po_number', sortable: true, filterable: true},
                        {
                            text: 'Job',
                            value: 'purchase_order.purchase_order_job.description',
                            sortable: true,
                            filterable: true
                        },
                        {text: 'Date', value: 'submitted_date', sortable: true, filterable: true},
                        {text: 'Status', value: 'status'},
                        {
                            text: '', value: 'approve', action: (item) => {
                                this.approve(item);
                            }
                        },
                        {
                            text: '', value: 'edit', action: (item) => {
                                this.editItem(item);
                            }, sortable: false, filterable: false
                        },
                        {
                            text: '', value: 'delete', action: (item, index) => {
                                this.destroy(item, this.receiving, index);
                            }, sortable: false, filterable: false
                        },
                        {text: 'User', value: 'user.name', sortable: false, filterable: false},
                        {text: 'ID', value: 'id'}
                    ]
                },
                transmittal: {
                    headers: [
                        {text: 'Job Number', value: 'job.job_number', sortable: true, filterable: true},
                        {text: 'Job', value: 'job.description', sortable: true, filterable: true},
                        {text: 'Mode', value: 'mode', sortable: true, filterable: true},
                        {text: 'Date', value: 'submitted_date', sortable: true, filterable: true},
                        {text: 'Status', value: 'status'},
                        {
                            text: '', value: 'approve', action: (item) => {
                                this.approveTransmittal(item);
                            }
                        },
                        {
                            text: '', value: 'edit', action: (item) => {
                                this.editRma(item);
                            }, disabled: (item) => {
                                return item.status_id > 1;
                            }, sortable: false, filterable: false
                        },
                        {
                            text: '', value: 'delete', action: (item, index) => {
                                this.destroy(item, this.transmittal, index);
                            }, sortable: false, filterable: false,
                            width: 50
                        },
                        {text: 'User', value: 'user.name', sortable: false, filterable: false},
                        {text: 'ID', value: 'id'}
                    ]
                },
                rma: {
                    headers: [
                        {text: 'Job Number', value: 'job.job_number', sortable: true, filterable: true},
                        {text: 'Job', value: 'job.description', sortable: true, filterable: true},
                        {text: 'Date', value: 'submitted_date', sortable: true, filterable: true},
                        {text: 'Status', value: 'status'},
                        {
                            text: '', value: 'approve', action: (item) => {
                                this.approveRma(item);
                            }
                        },
                        {
                            text: '', value: 'edit', action: (item) => {
                                this.editRma(item);
                            }, disabled: (item) => {
                                return item.status_id > 1;
                            }, sortable: false, filterable: false
                        },
                        {
                            text: '', value: 'delete', action: (item, index) => {
                                this.destroy(item, this.rmaRequestItems, index);
                            }, sortable: false, filterable: false
                        },
                        {text: 'User', value: 'user.name', sortable: false, filterable: false},
                        {text: 'ID', value: 'id'}
                    ]
                },
                photos: {
                    headers: [
                        {text: 'Rack', value: 'rack.rack'},
                        {text: 'Section', value: 'section.section'},
                        {text: 'Sub Section', value: 'sub_section.sub_section'},
                        {text: 'Status', value: 'completed'},
                        {
                            text: '', value: 'upload.photo', action: (item) => {
                                this.uploadPhoto(item);
                            }, button: {text: 'Upload', icon: 'mdi-camera'}, disabled: (item) => {
                                return false;
                            }, sortable: false, filterable: false
                        },
                        {
                            text: '', value: 'delete', action: (item, index) => {
                                this.destroyPhotoRequest(item, this.photoRequests, index)
                            }, sortable: false, filterable: false
                        },
                        {text: 'User', value: 'user.name'},
                        {text: 'ID', value: 'id'}
                    ]
                },
                unknown: {
                    headers: [
                        {text: 'PO #', value: 'po_number', sortable: true, filterable: true},
                        {text: 'Part #', value: 'part_number', sortable: true, filterable: true},
                        {text: 'Part Description', value: 'part_description', sortable: true, filterable: true},
                        {text: 'Created', value: 'created_at', sortable: true, filterable: true},
                        {text: 'Completed', value: 'completed'},
                        {text: '', value: 'complete.unknown', sortable: false, filterable: false},
                        {text: '', value: 'reject.unknown', sortable: false, filterable: false},
                        {text: 'User', value: 'user.name'},
                        {text: 'ID', value: 'id'}
                    ]
                }
            },
            photoDialog: false,
            photoDialogItem: {
                warehouse_rack_id: null,
                warehouse_section_id: null,
                warehouse_sub_section_id: null,
            },
            showApproved: false,
            tab: 'receiving',
            receiving: [],
            transmittal: [],
            photoRequests: [],
            unknownItems: [],
            rmaRequestItems: [],
            stats: {
                receiving: 0,
                transmittal: 0,
                photos: 0,
                unknown: 0,
                rma: 0,
            },
            loading: false,
        }
    },
    methods: {
        get,
        uploadPhoto(item) {
            this.photoDialog = true;
            this.photoDialogItem = item;
        },
        onPhotoUploaded(photo) {
            this.$http.post(`/v2/photo-requests/${this.photoDialogItem.id}/complete`).then((response) => {
                this.photoDialog = false;
                this.photoDialogItem = {
                    warehouse_rack_id: null,
                    warehouse_section_id: null,
                    warehouse_sub_section_id: null,
                };
                this.fetchPhotoRequests();
            })
        },
        getModeTitle(mode) {

            if (mode === 'warehouseToJobsite') {
                return 'Warehouse to Jobsite';
            }

            if (mode === 'switchToJob') {
                return 'Switch to Job';
            }

            if (mode === 'switchToStock') {
                return 'Switch to Stock';
            }

            return '';
        },
        approve(item) {
            this.$router.push({path: `/requests/receiving/approve/${item.uuid}`});
        },
        approveTransmittal(item) {
            this.$router.push({path: `/requests/transmittal/approve/${item.uuid}`});
        },
        approveRma(item) {
            this.$router.push({path: `/requests/rma/approve/${item.uuid}`});
        },
        editRma(item) {
            window.open(`#/requests/rma/${item.uuid}`, '_blank');
        },
        reject(item) {
        },
        cancel(item) {
        },
        destroy(item, items, idx) {
            this.$http.delete(`/v2/forms/requests/${item.id}`).then((response) => {
                item.deleted = true;
                items.splice(idx, 1);
            }).catch((response) => {
                this.$toast.error('Error deleting request.');
            });
        },
        destroyPhotoRequest(item, items, idx) {
            this.$http.delete(`/v2/photo-requests/${item.id}`).then((response) => {
                item.deleted = true;
                items.splice(idx, 1);
            }).catch((response) => {
                this.$toast.error('Error deleting photo request.');
            })
        },
        editItem(item) {
            window.open(`#/requests/${this.tabValue}/${item.uuid}`, '_blank');
        },
        editTransmittal(item) {
            let status = get(item, 'status.slug');
            if (status === 'draft') {
                window.open(`#/requests/transmittal/${item.uuid}`, '_blank');
            } else {
                this.$toast.error('Only drafts may be modified.');
            }
        },
        dateFormat(dateString) {
            let date = dayjs(dateString, 'YYYY-MM-DD HH:mma').local();
            return date.format('MM/DD/YY hh:mma');
        },
        completeUnknown(item) {
            this.$http.post(`/v2/forms/requests/unknown-items/${item.id}/complete`, item).then((response) => {
                this.fetchUnknownItems();
            });
        },
        rejectUnknown(item) {
            this.$http.post(`/v2/forms/requests/unknown-items/${item.id}/cancel`, item).then((response) => {
                this.fetchUnknownItems();
            });
        },
        view(item) {
            this.viewRequestDialog = true;
            this.viewDialogItem = item;
        },
        async fetchData() {
            await this.fetchReceiving();
            await this.fetchTransmittal();
            await this.fetchPhotoRequests();
            await this.fetchUnknownItems();
            await this.fetchRmaRequestItems();
        },
        async fetchTransmittal() {
            const response = await this.$http.get(`/v2/forms/requests/transmittal`);
            this.transmittal = response.data;
            this.table.headers = this.tables.transmittal.headers;
            this.table.data = this.transmittal;
        },
        async fetchReceiving() {
            const response = await this.$http.get('/v2/forms/requests/receiving');
            this.receiving = response.data;
            this.table.headers = this.tables.receiving.headers;
            this.table.data = this.receiving;
        },
        async fetchPhotoRequests() {
            const response = await this.$http.get('/v2/photo-requests');
            this.photoRequests = response.data;
            this.table.headers = this.tables.photos.headers;
            this.table.data = this.photoRequests;
        },
        async fetchUnknownItems() {
            const response = await this.$http.get('/v2/forms/requests/unknown-items');
            this.unknownItems = response.data;
            this.table.headers = this.tables.unknown.headers;
            this.table.data = this.unknownItems;
        },
        async fetchRmaRequestItems() {
            const response = await this.$http.get('/v2/forms/requests/rma');
            this.rmaRequestItems = response.data;
            this.table.headers = this.tables.rma.headers;
            this.table.data = this.rmaRequestItems;
        },
        async fetchFormData() {
            return await this.$http.get('/v2/forms/requests/data').then((response) => {
                let stats = get(response, 'data.approvals.counts');
                if (stats) {
                    this.stats = stats;
                }
                return response.data;
            });
        },
        async loadTabData(tab) {
            if (!tab || tab === 'receiving') {
                return await this.fetchReceiving();
            }
            if (tab === 'transmittal') {
                return await this.fetchTransmittal();
            }

            if (tab === 'rma') {
                return await this.fetchRmaRequestItems();
            }

            if (tab === 'photos') {
                return await this.fetchPhotoRequests();
            }
            if (tab === 'unknown') {
                return await this.fetchUnknownItems();
            }
        },
        onEdit(item, index, header) {

        },
        onDelete(item, index, header) {

        }
    },
    computed: {
        receivingHeaders() {
            return this.tables.receiving.headers.concat(this.tables.shared);
        },
        receivingCount() {
            return '' + this.stats.receiving;
        },
        transmittalCount() {
            return '' + this.stats.transmittal;
        },
        photoRequestCount() {
            return '' + this.stats.photos;
        },
        unknownItemsCount() {
            return '' + this.stats.unknown;
        },
        rmaRequestCount() {
            return '' + this.stats.rma;
        },
        tabValue() {
            let parts = this.tab.split('/');
            return parts[parts.length - 1];
        }
    },
    async mounted() {
        const tab = get(this.$route, 'params.tab', 'receiving');
        this.loading = true;
        await this.fetchFormData();
        await this.loadTabData(tab);
        this.loading = false;
    }
}
</script>

<template>
    <VContainer fluid>
        <PageTitle>Approval Requests</PageTitle>

        <VTabs
            v-model="tab"
            background-color="tabHeaderBackground"
        >
            <VTab to="/approval/requests/receiving" key="receiving-tab">
                <TabBadgeHeader :count="receivingCount" icon="mdi-home-import-outline">Receiving</TabBadgeHeader>
            </VTab>
            <VTab to="/approval/requests/transmittal" key="transmittal-tab">
                <TabBadgeHeader :count="transmittalCount" icon="mdi-truck-delivery">Transmittals</TabBadgeHeader>
            </VTab>
            <VTab to="/approval/requests/rma" key="rma-tab">
                <TabBadgeHeader :count="rmaRequestCount" icon="mdi-truck-delivery">RMA</TabBadgeHeader>
            </VTab>
            <VTab to="/approval/requests/photos" key="photos-tab">
                <TabBadgeHeader :count="photoRequestCount">Photo Requests</TabBadgeHeader>
            </VTab>
            <VTab to="/approval/requests/unknown" key="unknown-tab">
                <TabBadgeHeader :count="unknownItemsCount">Unknown Items</TabBadgeHeader>
            </VTab>
        </VTabs>

        <v-card>
            <v-card-text>
                <v-data-table
                    :headers="table.headers"
                    :items="table.data"
                    :loading="loading"
                    item-key="id"
                >

                    <template v-slot:item.submitted_date="{item}">
                        {{ item.submitted_date | date }}
                    </template>

                    <template v-slot:item.delete="{item, index, header}">
                        <ConfirmDialog :message="`Are you sure you want to delete item ID ${item.id}?`"
                                       @confirm="header.action ? header.action(item, index, header): false"
                                       :disabled="header.disabled ? header.disabled(item) : false"
                                       color="error"
                                       small
                        >
                            <VIcon small>mdi-delete</VIcon>
                        </ConfirmDialog>
                    </template>
                    <template v-slot:item.upload.photo="{item}">
                        <VBtn color="primary"
                              small
                              class="mr-2 ml-4"
                              @click="uploadPhoto(item)"
                              :disabled="item.completed"
                        >
                            <VIcon left>mdi-camera</VIcon>
                            Upload
                        </VBtn>
                    </template>
                    <template v-slot:item.approve="{item, index, header}">
                        <VBtn color="primary"
                              small
                              @click="header.action ? header.action(item, index, header): false"
                              :disabled="header.disabled ? header.disabled(item) : false"
                        >
                            <VIcon left>{{ get(header, 'button.icon', 'mdi-check') }}</VIcon>
                            Approve
                        </VBtn>
                    </template>

                    <template v-slot:item.edit="{item, index, header}">
                        <VBtn color="info"
                              small
                              @click="header.action ? header.action(item, index, header): false"
                              :disabled="header.disabled ? header.disabled(item) : false"
                        >
                            <VIcon small>{{ get(header, 'button.icon', 'mdi-pencil') }}</VIcon>
                        </VBtn>
                    </template>

                    <template v-slot:item.status="{item}">
                        <VChip
                            :color="get(item,'status.background_color')"
                            label
                            x-small
                            class="py-3"
                        >
                            <VIcon x-small left>
                                {{ get(item, 'status.icon') }}
                            </VIcon>

                            {{ get(item, 'status.name') }}
                        </VChip>
                    </template>

                    <template v-slot:item.completed="{item}">
                        <VChip
                            :color="(item.completed ? 'success' :'warning')"
                            label
                            small
                        >
                            <VIcon small left>{{ item.completed ? 'mdi-check' : 'mdi-clock' }}</VIcon>
                            {{ item.completed ? 'Completed' : 'Pending' }}
                        </VChip>
                    </template>

                    <template v-slot:item.complete.unknown="{item}">
                        <ConfirmDialog
                            :message="`Remove Unknown Inventory Item ${item.id}?`"
                            @confirm="completeUnknown(item)"
                            color="error"
                            :disabled="item.completed"
                            small
                        >
                            <VIcon small left>mdi-delete</VIcon>
                            Remove
                        </ConfirmDialog>
                    </template>

                    <template v-slot:item.reject.unknown="{item}">
                        <ConfirmDialog
                            :message="`Keep Inventory Item ${item.id}?`"
                            @confirm="rejectUnknown(item)"
                            color="accent"
                            :disabled="item.completed"
                            small
                        >
                            Keep
                        </ConfirmDialog>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <VDialog scrollable v-model="photoDialog">
            <VCard>
                <VCardTitle>
                    Upload Rack Photo for
                    {{ get(photoDialogItem, 'rack.rack') }}.{{
                        get(photoDialogItem, 'section.section')
                    }}.{{ get(photoDialogItem, 'sub_section.sub_section') }}
                </VCardTitle>
                <VCardText>
                    <PhotoManager
                        :photos="[]"
                        :rack="get(photoDialogItem, 'rack.rack')"
                        :section="get(photoDialogItem, 'section.section')"
                        :sub-section="get(photoDialogItem,'sub_section.sub_section')"
                        @uploaded="onPhotoUploaded"
                    >
                    </PhotoManager>
                </VCardText>
            </VCard>
        </VDialog>
    </VContainer>
</template>

<style scoped>

</style>