import Model from "../Model";
import NotificationSubscriptionInterface from "./NotificationSubscriptionInterface";
export default class NotificationSubscription extends Model {
    content;
    created_at;
    email;
    enabled;
    id;
    if_company_id;
    if_job_number;
    if_po_number;
    if_to_email_address;
    notification_type_id;
    phone_number;
    purchase_order_job_id;
    updated_at;
    user_id;
}
