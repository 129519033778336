import {LogService} from "@/common/log";

function getTitle (vm) {
    let {title} = vm.$options;

    if(vm.data && vm.data.title) {
        title = vm.data.title;
    }

    if (title) {
        return typeof title === 'function'
            ? title.call(vm)
            : title
    }
}
export default {
    mounted() {

        const {logContext, logStyle, logCaller, name} = this.$options;

        if(logContext || name) {
            this.$log = new LogService(logContext ?? name);
            if(logStyle) {
                this.$log.setStyle(logStyle);
            }
        }

        const title = getTitle(this);
        if (title) {
            document.title = title + ' - Virtual Warehouse';
        }
    }
}