import PageTitle from "@/components/PageTitle.vue";
import ToolNameInput from "@/components/Tools/Inputs/ToolNameInput.vue";
import { clone, get } from "@/client/utilities/Object";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import BasicImage from "@/components/Photos/BasicImage.vue";
import SubmitButtonWithMessages from "@/components/Form/SubmitButtonWithMessages.vue";
import BaseForm from "@/components/Form/BaseForm.vue";
import debounce from "@/client/utilities/Debounce";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
export default {
    name: 'CreateToolKit',
    components: {
        FormSubmittedMessage,
        ConfirmDialog,
        ToolModelAutocomplete,
        BaseForm, SubmitButtonWithMessages, BasicImage, PhotoThumbnail, ToolNameInput, PageTitle
    },
    inheritAttrs: false,
    props: [],
    data() {
        return {
            editing: false,
            filterModels: [0, 1, 2],
            selectedModels: [],
            data: {
                tool_models: [],
            },
            search: null,
            form: {
                errors: [],
                valid: false,
                name: '',
                description: '',
                notes: '',
                tool_models: [],
                processing: false,
                submitted: false,
            }
        };
    },
    watch: {
        selectedModels(model) {
            this.selectTool(model);
            this.$nextTick(() => {
                this.selectedModels = null;
            });
        },
        selectedTool(tool) {
            this.selectTool(tool);
            this.selectedTool = null;
        }
    },
    methods: {
        get,
        submit: async function () {
            if (this.form.valid) {
                this.form.processing = true;
                let url = `/v3/tools/kits`;
                let req = null;
                if (this.editing && this.form.toolKitId) {
                    url += `/${this.form.toolKitId}`;
                    req = this.$http.put(url, this.form);
                }
                else {
                    req = this.$http.post(url, this.form);
                }
                if (req) {
                    req.then((response) => {
                        this.form.submitted = true;
                        this.$router.push({ path: '/tools/kits/list' });
                    }).catch((response) => {
                        this.form.submitted = false;
                    })
                        .finally((response) => {
                        this.form.processing = false;
                    });
                    return req;
                }
            }
        },
        selectTool: function (tool) {
            if (tool) {
                this.form.tool_models.push(tool);
            }
        },
        unselectTool: function (tool) {
            this.form.tool_models.splice(this.form.tool_models.indexOf(tool), 1);
        },
    },
    async mounted() {
        if (this.$route.params.id > 0) {
            const kit = await this.$http.get('/v3/tools/kits/' + this.$route.params.id)
                .then(response => {
                return get(response, 'data.tool_kit', {});
            });
            if (kit) {
                this.editing = true;
                this.form.toolKitId = this.$route.params.id;
                this.form.name = kit.name;
                this.form.description = kit.description;
                this.form.notes = kit.notes;
                this.form.tool_models = kit.tool_models;
            }
        }
    }
};
