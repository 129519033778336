<template>
    <v-container>

        <PageTitle />

        <VCard class="mb-8">
            <VCardTitle>Search by Job</VCardTitle>
            <VCardText>
                <JobsAutocomplete v-model="job" :jobs="jobs" :items="items"></JobsAutocomplete>
            </VCardText>
        </VCard>

        <div v-show="job.id">

            <DividingHeader>Available Reports</DividingHeader>

            <VRow class="mt-3">

                <VCol
                        cols="12"
                        lg="4"
                        md="6"
                >
                    <VCard
                            elevation="4"
                            class="mr-2"
                    >
                        <VCardTitle>
                            <VIcon left color="red lighten-3">mdi-file-pdf-box</VIcon>
                            Photo Report
                        </VCardTitle>
                        <VCardSubtitle>
                            A PDF report detailing items currently stored in the warehouse.
                        </VCardSubtitle>
                        <VCardText class="pb-15">
                            <b>Report contents</b>
                            <ul>
                                <li>Photos of item in warehouse</li>
                                <li>Photos of warehouse location</li>
                                <li>Photos of Packing Slips</li>
                            </ul>
                        </VCardText>
                        <VCardText class="pb-7"></VCardText>
                        <VCardActions class="pa-5">
                            <VBtn block class="mx-auto" color="accent" @click="openPhotoReport">
                                Download
                                <VIcon left>mdi-file-download</VIcon>
                            </VBtn>
                        </VCardActions>
                    </VCard>
                </VCol>
                <VCol
                        v-if="showCostReport"
                        cols="12"
                        lg="4"
                        md="6"
                >
                    <VCard
                            elevation="4"
                            class="mr-2"
                    >
                        <VCardTitle>
                            <VIcon color="green lighten-2" left>mdi-microsoft-excel</VIcon>
                            Cost & Photo Report
                        </VCardTitle>
                        <VCardSubtitle>
                            An Excel report with photos and costing for items in warehouse.
                        </VCardSubtitle>
                        <VCardText>
                            <b>Report contents</b>
                            <ul>
                                <li>Item Photos</li>
                                <li>Unit Cost</li>
                                <li>Total Cost</li>
                                <li>Tax</li>
                            </ul>
                        </VCardText>
                        <VCardText class="pb-13"></VCardText>
                        <VCardActions class="pa-5">
                            <VBtn block color="accent" @click="openExcelReport">
                                Download
                                <VIcon right>mdi-file-download</VIcon>
                            </VBtn>
                        </VCardActions>
                    </VCard>
                </VCol>
                <VCol
                        v-if="showCustomerCostReport"
                        cols="12"
                        lg="4"
                >
                    <VCard
                            elevation="4"
                    >
                        <VCardTitle>
                            <VIcon color="green lighten-2" left>mdi-microsoft-excel</VIcon>
                            Customer Cost & Photo Report
                        </VCardTitle>
                        <VCardSubtitle>
                            An Excel report with photos and costing for items in warehouse, with configurable markup on dollar amounts.
                        </VCardSubtitle>
                        <VCardText>
                            <b>Report contents</b>
                            <ul class="mb-2">
                                <li>Item Photos</li>
                                <li>Unit Cost</li>
                                <li>Total Cost</li>
                                <li>Tax</li>
                            </ul>
                            Markup percentage will <b>increase</b> unit cost, total cost, and tax amounts by the specified percentage.
                        </VCardText>
                        <VCardActions class="px-4 py-4">
                            <VRow>
                                <VCol cols="12" lg="6" md="6" sm="6">
                                    <TextInput
                                            dense
                                            hide-details
                                            v-model="job.markup_percentage"
                                            type="number"
                                            suffix="%"
                                            label="Markup Percentage"
                                    ></TextInput>
                                </VCol>
                                <VCol cols="12" lg="6" md="6" sm="6">
                                    <VBtn block color="accent" @click="openCustomerPhotoReport">
                                        Download
                                        <VIcon right>mdi-file-download</VIcon>
                                    </VBtn>
                                </VCol>
                            </VRow>
                        </VCardActions>
                    </VCard>
                </VCol>
            </VRow>
            <v-row>
                <VCol
                    cols="12"
                    lg="4"
                >
                    <VCard
                            elevation="4"
                    >
                        <VCardTitle>
                            <VIcon color="green lighten-2" left>mdi-microsoft-excel</VIcon>
                            Tool Report
                        </VCardTitle>
                        <VCardSubtitle>
                            An Excel report with tools assigned to {{job.job_number}}
                        </VCardSubtitle>
                        <VCardText>
                            <b>Report contents</b>
                            <ul class="mb-2">
                                <li>Photos</li>
                                <li>Details</li>
                                <li>Pricing</li>
                            </ul>
                        </VCardText>
                        <VCardActions class="pa-5">
                            <VBtn block color="accent" @click="openToolExcelReport">
                                Download
                                <VIcon right>mdi-file-download</VIcon>
                            </VBtn>
                        </VCardActions>
                    </VCard>
                </VCol>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import TextInput from "@/components/TextInput.vue";
import {Url}  from "@/common/url.service";
export default {
    components: {TextInput, DividingHeader, JobsAutocomplete, PageTitle},
    title: 'Photo Reports',
    data() {
        return {
            markupPercentage: 0,
            jobs: [],
            items: [],
            job: {
                id: null,
                job_number:null,
                description: null,
                company_id: null
            },
            purchaseOrder: null,
        }
    },
    computed: {
        showCostReport() {
            return this.user.ryvit_project_manager_id > 0 || this.user.is_admin;
        },
        showCustomerCostReport() {
            return this.user.ryvit_project_manager_id > 0 || this.user.is_admin;
        }
    },
    watch: {
        job(job) {
            if(job.job_number && job.jcco) {
                this.markupPercentage = job.markup_percentage;
            }
        }
    },
    methods: {
        openToolExcelReport() {
            if(this.job) {
                let url = Url(`/reports/tools/job/${this.job.id}/excel`);
                window.open(url);
            }
        },
        openCustomerPhotoReport() {
            if(this.job) {
                let url = Url(`/reports/photos/job/${this.job.id}/excel?markupPercentage=${this.job.markup_percentage}`);
                window.open(url);
            }
        },
        openPhotoReport() {
            if(this.job) {
                let url = Url(`/reports/photos/job/${this.job.id}/pdf`);
                window.open(url);
            }
        },
        openExcelReport() {
            if(this.job) {
                let url = Url(`/reports/photos/job/${this.job.id}/excel`);
                window.open(url);
                setTimeout(() => {
                    this.getJobs();
                }, 1000);
            }
        },
        getJobs() {
            this.$http.get('/jobs')
              .then(response => {
                    this.jobs = response.data.jobs;
                    this.items = response.data.jobs;
                })
              .catch(error => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.getJobs();
    }
}
</script>