<script>
import {VMoney} from 'v-money'
export default {
    directives: { money: VMoney },
    name: 'CurrencyInput',
    props: ['value'],
    data() {
        return {
            format: {
                decimal: ".",
                thousands: ",",
                precision: 2,
                masked: true
            }
        }
    }
}
</script>
<template>
    <v-text-field
            outlined
            prefix="$"
            v-money="format"
            v-on="$listeners"
            v-bind="$attrs"
    />
</template>

<style scoped>

</style>