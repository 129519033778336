<script>
import FormSubmittedMessage from "../../../components/FormSubmittedMessage.vue";
import PageTitle from "../../../components/PageTitle.vue";
import PhotoThumbnail from "../../../components/Photos/PhotoThumbnail.vue";
import {get} from "@/client/utilities/Object";
import {each} from "@/client/utilities/Each";
import PartName from "@/components/V2/PartName.vue";
import TextInput from "@/components/TextInput.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import cloneObject from "@/common/clone-object";

export default {
    name: "ApproveRmaRequest",
    components: {
        NotesTextField,
        DatePicker,
        DeliveryWindowSelect,
        PhotoManager, TextInput, PartName, PhotoThumbnail, PageTitle, FormSubmittedMessage},
    title: 'Approve RMA Request',
    data() {
        return {
            rules: {
                required: v => !!v || "This is a required field",
            },
            step: 1,
            formSubmitted: false,
            form: {
                job: null,
                newJob: null,
                project_manager: null,
                items: [],
                loading: false,
                form: {
                    job: null,
                    loading: false,
                    source: null,
                    items: [],
                    notes: ''
                },
                delivery_items_photos: [],
                delivery_photos: [],
                notes: null,
                delivery: {
                    site_contact_name: '',
                    delivery_date: '',
                    delivery_window: '',
                    delivery_notes: '',
                }
            },
            steps: {
                1: {
                    name: 'Select Items',
                    isValid: () => {
                        return true;
                    }
                }
            },
            request: null,
            inventoryItems: [],
            inventoryItemCount: 0,
        }
    },
    watch: {
        step() {
            this.updateFormRequest();
        }
    },
    methods: {
        get,
        resetForm() {
            this.$router.push({path: '/approval/requests'})
        },
        async updateFormRequest(status = null) {
            let uuid = this.$route.params.uuid;

            let data = this.form;
            let url = `/v2/forms/requests/rma/${uuid}`;

            if(status) {
                url += `/${status}`;
            }

            console.info('[FormRequest] Updated Form Request ' + uuid, data);

            return this.$http.post(url, data)
                .then(response => {
                    return response.data.request;
                })
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/rma/' + uuid).then(response => {
                    this.request = response.data;
                    this.form = cloneObject(get(this.request, 'data'));
                    this.form.items = cloneObject(this.form.items).filter(item => item.rma_quantity > 0);
                });
            } else {
                this.$toast.error('No Form Request Found');
            }
        },
        changeStep(step) {
            if (this.stepValid(step - 1)) {
                this.step = step;
            }
        },
        validate(step) {
            if (this.stepValid(step)) {
                this.step = step + 1;
            }
        },
        stepValid(stepNumber) {

            let step = get(this.steps, stepNumber);

            if (step) {
                return step.isValid();
            }
        },
        stepEditable(stepNumber) {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            });

            return valid;
        },
        getInventory(part) {
            return this.inventoryItems.filter(item => item.part_combo_name === part.part_combo_name);
        },
        async submitForm() {
            await this.updateFormRequest('approved');
            this.formSubmitted = true;
        }
    },
    computed: {
        requestedItems() {
            return get(this.request, 'data.items', []);
        },
        jobNumber() {
            return get(this.request, 'data.job.job_number', null);
        },
        jobDescription() {
            return get(this.request, 'data.job.description', null);
        },
        source() {
            return get(this.request, 'data.source', null);
        },
        sourceName() {
            if(this.source === 'warehouse') {
                return 'Warehouse';
            }

            if(this.source === 'jobsite') {
                return 'Job Site';
            }
        },
        jobId() {
            return get(this.request, 'data.job.id', null);
        },
        jcco() {
            return get(this.request, 'data.job.jcco', null);
        },
    },
    async mounted() {
        await this.fetchFormRequest();
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>
            Approve RMA Request
        </PageTitle>

        <FormSubmittedMessage
            v-show="formSubmitted"
            title="RMA Request Submitted"
            button-text="View Approval Requests"
            :on-click="resetForm"
        >
            Back to Approval Requests
        </FormSubmittedMessage>

        <div v-show="!formSubmitted">
            <VRow class="my-2 mx-0 py-1 px-2 grey darken-4">
                <VCol>
                    <VRow>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Job #</i> <br>
                            {{jobNumber}}
                        </VCol>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Description</i> <br>
                            {{ jobDescription }}
                        </VCol>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Source</i> <br>
                            {{ sourceName }}
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
            <VStepper v-model="step"
                      vertical
                      non-linear
            >
                <!-- step 1 -->
                <VStepperStep
                    step="1"
                    :complete="step > 1"
                    :rules="[v => stepValid(1)]"
                    editable
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    error-icon="mdi-alert-octagon"
                >
                    RMA Items
                </VStepperStep>
                <VStepperContent step="1">
                    <VCard v-for="(part, idx) in form.items"
                           :key="`item2-${idx}`"
                           class="mb-4"
                    >
                        <VCardText>
                            <VRow>
                                <VCol cols="2">
                                    <PhotoThumbnail :photo="get(part, 'photos.0', {})"></PhotoThumbnail>
                                </VCol>
                                <VCol cols="6">
                                    <PartName
                                        :part-number="part.part_number"
                                        :part-description="part.part_description"
                                    />
                                </VCol>
                                <VCol cols="4">
                                    <TextInput
                                        v-if="source === 'jobsite'"
                                        hide-details
                                        dense
                                        label="RMA Quantity"
                                        v-model="part.rma_quantity"
                                    />
                                </VCol>
                            </VRow>
                        </VCardText>
                        <VCardText v-if="source === 'warehouse'">
                            <VRow class="mb-0 px-0 grey--text font-italic">
                                <VCol class="mr-1 ml-0 pl-6">
                                    Location
                                </VCol>
                                <VCol class="mr-1 ml-0 pl-6">
                                    PO Number
                                </VCol>
                                <VCol class="mr-1 ml-0 pl-6">
                                    Total Available Qty
                                </VCol>
                                <VCol class="mr-1 ml-0 pl-6">
                                    RMA Quantity
                                </VCol>
                            </VRow>
                            <VRow
                                    class="mb-2 mt-0 px-4 py-0 align-content-center"
                            >
                                <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                    {{part.location_key}}
                                </VCol>
                                <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                    {{part.po_number}}
                                </VCol>
                                <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                    {{part.part_quantity}}
                                </VCol>
                                <VCol class="grey darken-3 mr-1 ml-0 px-6 pt-2 pb-2">
                                    <TextInput
                                        label="RMA Qty"
                                        hide-details
                                        dense
                                        v-model="part.rma_quantity"
                                    />
                                </VCol>
                            </VRow>
                        </VCardText>
                    </VCard>

                    <VRow>
                        <VCol>
                            <VCardText>
                                <NotesTextField
                                    v-model="form.notes"
                                    label="RMA Reason"
                                ></NotesTextField>
                            </VCardText>
                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol>
                            <VBtn
                                color="primary"
                                @click="submitForm()"
                                :disabled="!stepValid(1)"
                            >
                                Approve RMA Request
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>
            </VStepper>
        </div>
    </VContainer>
</template>

<style scoped>

</style>