<template>
    <div>
        <v-autocomplete
                outlined
                v-on="inputListeners"
                v-bind:value="value"
                v-bind:items="items"
                v-bind:rules="rules"
                v-bind:loading="loading"
                :search-input.sync="poSearchInput"
                prepend-inner-icon="mdi-folder-pound-outline"
                item-text="po_number"
                item-value="id"
                label="PO Number"
                return-object
                clearable
                hide-details
        >
            <template v-slot:selection="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.po_number }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="data.item.company">
                        <i>{{ data.item.company.slug }}</i> -
                        <b v-if="data.item.purchase_order_job">{{ data.item.purchase_order_job.description }}</b> -
                        <i>{{data.item.line_item_count}} items</i>
                    </v-list-item-subtitle>

                </v-list-item-content>
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.po_number }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="data.item.company">
                        <i>{{ data.item.company.slug }}</i> -
                        <b v-if="data.item.purchase_order_job">{{ data.item.purchase_order_job.description }}</b> -
                        <i>{{data.item.line_item_count}} items</i>
                    </v-list-item-subtitle>

                </v-list-item-content>
            </template>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            No POs found.
                        </v-list-item-title>
                        <v-list-item-action v-if="poSearchInput">
                            <v-btn
                                    color="primary"
                                    @click="importPo"
                                    v-bind:loading="importPoLoading"
                            >
                                <VIcon left>mdi-import</VIcon>
                                Import PO# '{{poSearchInput}}'
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>

        <VDialog
                v-model="dialog"
                persistent
                max-width="600px"
        >
            <VCard>
                <VCardTitle>{{ duplicatePoItems.length }} PO Numbers Found for
                    {{ value ? value.po_number : '' }}
                </VCardTitle>
                <VCardSubtitle>Please select a PO to continue</VCardSubtitle>
                <VCardText>

                    <VList two-line>
                        <VListItemGroup
                                v-model="selectedDuplicatePo"
                        >
                            <template v-for="po in duplicatePoItems">
                                <VListItem
                                        :key="po.id"
                                        :value="po"
                                >
                                    <template v-slot:default="{ active }">
                                        <VListItemContent>
                                            <VListItemTitle v-if="po.purchase_order_job"  v-text="po.purchase_order_job.description"></VListItemTitle>
                                            <VListItemSubtitle v-if="po.company"
                                                               v-text="po.company.name"
                                            />
                                            <VListItemSubtitle v-show="po.line_item_count">
                                                {{po.line_item_count}} line items
                                            </VListItemSubtitle>
                                        </VListItemContent>
                                        <VListItemAction>
                                            <VIcon color="success" v-show="active">mdi-check-circle-outline</VIcon>
                                        </VListItemAction>
                                    </template>
                                </VListItem>
                            </template>
                        </VListItemGroup>
                    </VList>
                </VCardText>
                <VCardActions>
                    <VSpacer></VSpacer>
                    <VBtn
                            color="primary"
                            @click="duplicatePoDialogSelect"
                    >
                        Select
                    </VBtn>
                </VCardActions>
            </VCard>
        </VDialog>
    </div>
</template>

<script>
export default {
    name: "PurchaseOrdersAutocomplete",
    inheritAttrs: false,
    props: ['items', 'loading', 'value', 'rules', 'pos', 'poImported'],
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
        dialog: function() {
            if(this.pos && this.value) {
                return this.pos.filter(po => po.po_number === this.value.po_number).length > 1 && this.closeDialog === false;
            }

            this.closeDialog = false;

            return false;
        }
    },
    data: function () {
        return {
            duplicatePoItems: [],
            selectedDuplicatePo: null,
            closeDialog: false,
            poSearchInput: '',
            importPoLoading: false
        }
    },
    watch: {
        value: function (val) {
            if (val && val.po_count > 1 && !val.selected) {
                this.duplicatePoItems = this.pos.filter(item => item.po_number === val.po_number);
            } else {
                this.duplicatePoItems = [];
            }
        },
    },
    methods: {
        importPo() {
            this.importPoLoading = true;
            this.$http.get('/v2/forms/receive/import-po', {params: {po_number: this.poSearchInput}}).then(response => {
                this.importPoLoading = false;
                this.poImported(false);
            }).catch(error => {
                this.importPoLoading = false;
                this.poImported(true);
            });
        },
        duplicatePoDialogSelect: function () {
            this.closeDialog = true;
            this.$emit('input', this.selectedDuplicatePo);
            this.selectedDuplicatePo = null;
            this.duplicatePoItems = [];
        }
    }
}
</script>

<style scoped>

</style>