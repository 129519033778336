<template>
    <v-container class="pa-12" v-once>
        <v-row>
            <v-col
                v-for="card in cards"
                :key="card.title"
                :cols="card.flex"
                :md="card.md ? card.md : 6"
            >
                <v-card outlined elevation="2" hover :to="card.to">
                    <v-card-title  class="d-flex justify-center text-center text-break" >
                            {{ card.title }}
                    </v-card-title>

                    <v-card-text class="text-center">
                        <p>{{ card.text }}</p>
                        <v-icon x-large :color="card.iconColor">{{ card.icon }}</v-icon>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Home",
    title: 'Home',
    data: () => ({
        cards: [
            {
                title: "Material Request",
                to: "/materials/requests/create",
                text: "Submit Material Order Request",
                icon: "mdi-toolbox",
                iconColor: "purple lighten-2",
                flex: 12,
            },
            {
                title: "Warehouse Inventory",
                to: "/warehouse-inventory",
                text: "To display all items in warehouse",
                icon: "mdi-clipboard-text",
                iconColor: "green darken-2",
                flex: 12
            },
            {
                title: "Receive Form",
                to: "/receive-form",
                text: "To receive item(s) into warehouse",
                icon: "mdi-home-import-outline",
                iconColor: "blue darken-2",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "Transmit Form",
                to: "/transmit-form",
                text: "To request a transmittal of item(s) from warehouse",
                icon: "mdi-truck-delivery",
                iconColor: "green darken-2",
                src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
                flex: 12,
            },
            {
                title: "Owner Provided Materials Receiving Form",
                to: "/receive-owner-materials-form",
                text: "To receive owner-provided item(s) into warehouse",
                icon: "mdi-home-import-outline",
                iconColor: "purple darken-2",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "Return to Warehouse from Job Restock Form",
                to: "/restock-form",
                text: "To receive returned item(s) into warehouse",
                icon: "mdi-archive-refresh",
                iconColor: "indigo",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "Inventory Form",
                to: "/inventory-form",
                text: "To do inventory (recount, move, etc.) for item(s) in warehouse",
                icon: "mdi-order-bool-ascending-variant",
                iconColor: "cyan",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "Add Purchase Order Form",
                to: "/po-form",
                text: "To add more Purchase Order(s) before receiving item(s)",
                icon: "mdi-cart-plus",
                iconColor: "red",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "Project Kit Creation Form",
                to: "/kit-form",
                text: "To add item(s) to a project kit",
                icon: "mdi-tag-multiple",
                iconColor: "green darken-2",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
            {
                title: "RMA Form",
                to: "/rma-form",
                text: "To receive RMA into warehouse",
                icon: "mdi-archive-refresh",
                iconColor: "indigo",
                src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
                flex: 12,
            },
        ],
    }),
};
</script>
