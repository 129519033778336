<script>
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import {get} from "@/client/utilities/Object";

export default {
    name: "PhotoManagerListItem",
    components: {ConfirmDialog, PhotoThumbnail},
    inheritAttrs: false,
    props: {
        photo: {
            type: Object,
            required: true
        },
        deleted: {
            type: Function,
            default: (response) => {}
        },
        failed: {
            type: Function,
            default: (error) => {}
        }
    },
    data() {
        return {
            deleting: false
        };
    },
    methods: {
        get,
        deletePhoto(photo) {
            let id = get(photo, 'id');
            if(id) {
                this.deleting = true;
                setTimeout(() => {
                    this.$http.delete('/v2/photos/' + id).then(response => {
                        this.$emit('deleted');
                    }).catch((error) => {
                        this.$emit('failed');
                    }).finally(() => {
                        this.deleting = false;
                    });
                }, 1000);
            }
        }
    }
}
</script>

<template>
    <tr>
        <td class="py-2" style="width:50px;">
            <PhotoThumbnail
                    :photo="photo"
                    width="40"
            />
        </td>
        <td>
            {{ photo.original_filename }}

            <VChip label color="warning" class="ml-2 pa-1" x-small v-show="photo.is_duplicate">Duplicate</VChip>
        </td>
        <td>
            {{get(photo, 'user.name')}}
        </td>
        <td>
            {{photo.created_at | date('MM/DD/YY')}}
        </td>
        <td class="text-right">
            <ConfirmDialog
                    :title="`Delete Photo ${photo.id}?`"
                    :message="`Are you sure you want to delete ${photo.original_filename}?`"
                    @confirm="deletePhoto(photo)"
                    :loading="deleting"
                    color="delete"
                    small
            >
                <VIcon small>
                    mdi-delete
                </VIcon>
            </ConfirmDialog>
        </td>
    </tr>
</template>

<style scoped>

</style>