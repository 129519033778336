<script>
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import CertificationDocumentManager from "@/components/CertificationDocuments/CertificationDocumentManager.vue";

export default {
    name: 'AddToolConsumableComponent',
    components: {CertificationDocumentManager, PhotoManager},
    props: [
        'name', 'description', 'price', 'photos'
    ],
    data() {
        return {
        };
    }
}
</script>

<template>
<v-row cols="3" dense>
    <v-col cols="12">
        <v-text-field
                outlined
                label="Name"
                v-model="name"
        />
    </v-col>
    <v-col cols="12">
        <v-text-field
                outlined
                label="Description"
                v-model="description"
        />
    </v-col>
    <v-col cols="6">
        <v-text-field
                outlined
                label="Price"
                v-model="price"
        />
    </v-col>
    <v-col cols="12">
        <PhotoManager :photos="photos"></PhotoManager>
    </v-col>
</v-row>
</template>

<style scoped>

</style>