<script>

import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import {get} from "@/client/utilities/Object";

export default {
    name: "FormRequestDetailHeader",
    methods: {get},
    components: {PhotoThumbnail},
    computed: {
        poNumber() {
            return get(this.request, "data.purchase_order.po_number");
        },
        companyName() {
            return get(this.request, "data.purchase_order.company.name");
        },
        jobDescription() {
            return get(this.request, "data.purchase_order.purchase_order_job.description");
        },
        expectedDeliveryDate() {
            return get(this.request, 'data.purchase_order.expiration_date')
        },
        packingSlipPhotos() {
            return get(this.request, "data.packing_slip_photos", []);
        }
    },
    props: {
        request: {
            type: Object,
            required: true
        }
    }
}
</script>

<template>
    <VRow class="my-2 mx-0 py-1 px-2 grey darken-4">
        <VCol class="px-2 py-2">
            <i class="grey--text">PO #</i> <br>
            {{get(request, 'data.purchase_order.po_number')}}
        </VCol>
        <VCol class="px-2 py-2">
            <i class="grey--text">Company</i> <br>
            {{ get(request, 'data.purchase_order.company.name') }}
        </VCol>
        <VCol class="px-2 py-2">
            <i class="grey--text">Job</i> <br>
            {{ get(request, 'data.purchase_order.purchase_order_job.description') }}
        </VCol>
        <VCol class="px-2 py-2">
            <i class="grey--text">Expected Delivery Date</i> <br>
            {{ get(request, 'data.purchase_order.expiration_date') }}
        </VCol>
        <VCol class="px-2 py-2">
            <i class="grey--text">Packing Slips</i> <br>
            <PhotoThumbnail
                    v-for="photo in packingSlipPhotos"
                    :key="`frm-req-detail-header-packing-slip-photo-thumbnail-${photo.id}`"
                    :photo="photo"
                    width="40"
            />
        </VCol>
    </VRow>
</template>

<style scoped>

</style>