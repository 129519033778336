import { render, staticRenderFns } from "./CreateConsumable.vue?vue&type=template&id=092dd0e5&scoped=true"
import script from "./CreateConsumable.vue?vue&type=script&lang=ts"
export * from "./CreateConsumable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092dd0e5",
  null
  
)

export default component.exports