const kebabCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
};
const upperFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
const toString = (value) => {
    return String(value);
};
const startsWith = (str, searchValue) => {
    return str.startsWith(searchValue);
};
const endsWith = (str, searchValue) => {
    return str.endsWith(searchValue);
};
const trimStart = function (str, searchValue = '') {
    let value = toString(str);
    let search = toString(searchValue);
    search = '' + search;
    if (search.length === 0) {
        value = value.trimStart();
    }
    if (search.length > 0) {
        value = str.replace(new RegExp(`^${search}`), '');
    }
    return value.toString();
};
const trimEnd = function (str, searchValue = '') {
    let value = toString(str);
    let search = toString(searchValue);
    if (search.length === 0) {
        value = value.trimEnd();
    }
    if (search.length > 0) {
        value = str.replace(new RegExp(`${search}$`), '');
    }
    return value.toString();
};
export { kebabCase, upperFirst, trimStart, trimEnd, toString, startsWith };
