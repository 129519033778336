<script>
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import {get} from "@/client/utilities/Object";

export default {
    name: "JobAutocomplete",
    components: {JobInfoChip},
    inheritAttrs: true,
    props: ['value'],
    data() {
        return {
            jobs: [],
            hideItemCount: false,
        }
    },
    methods: {
        get,
        async fetchJobs() {
            const jobs = await this.$http.get('/v3/jobs');
            this.jobs = get(jobs, 'data.jobs');
        },
        onChange(value) {

        }
    },
    watch: {
        value: (newValue) => {
        }
    },
    mounted() {
        this.fetchJobs();
    }
}
</script>
<template>
    <VAutocomplete
            :items="jobs"
            v-on="$listeners"
            v-bind="$attrs"
            :value.sync="value"
            @change="onChange"
            return-object
            item-text="job_number"
            item-value="id"
            label="Select a Job"
            chips
            small-chips
            deletable-chips
            outlined
            small
            clearable
            hide-details
    >
        <template v-slot:selection="data">
            <JobInfoChip
                    clearable
                    :job-number="data.item.job_number"
                    :job-description="data.item.description"
                    :company-slug="get(data, 'item.company.slug')"
            />
        </template>
        <template v-slot:item="data">
            <v-list-item-content>

                <v-list-item-title>
                    {{ data.item.job_number }}
                </v-list-item-title>

                <v-list-item-subtitle>

                        <span>
                            <i v-if="data.item.company">{{ data.item.company.slug }}</i> -
                            <b>{{ data.item.description }}</b>
                        </span>
                    <span v-if="data.item.inventory_item_count !== undefined && !hideItemCount">
                           - {{data.item.inventory_item_count}} items
                        </span>

                </v-list-item-subtitle>

            </v-list-item-content>
        </template>

    </VAutocomplete>
</template>