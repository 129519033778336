import axios, { AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";
import ApiResponse from "./ApiResponse";
import { ApiConfigurationManager } from "./ApiConfiguration";
import Model from "../../models/Model";
import ApiResponseError from "./ApiResponseError";
export default class Client {
    configuration;
    baseUrl;
    apiVersion = 'v3';
    request;
    headers;
    dataKey;
    model;
    constructor(configuration) {
        this.configuration = configuration;
        this.headers = new AxiosHeaders();
        this.headers.set('Content-Type', 'application/json');
        this.headers.set('Accept', 'application/json');
        this.configure();
    }
    setKey(dataKey) {
        this.dataKey = dataKey;
        return this;
    }
    configure() {
        this.baseUrl = this.configuration.baseUrl();
        this.apiVersion = this.configuration.apiVersion();
        this.request = axios.create({
            baseURL: this.configuration.baseUrl(),
            headers: this.headers
        });
        this.request.interceptors.response.use(this.handleResponse.bind(this), this.handleError.bind(this));
    }
    handleResponse(response) {
        return ApiResponse.create(response, this.dataKey, (item) => {
            let model = this.model.clone();
            model.fill(item);
            return model;
        });
    }
    handleError(error) {
        return new ApiResponseError(error);
    }
    version(version) {
        this.apiVersion = version;
        this.configure();
        return this;
    }
}
