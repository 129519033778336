import PageTitle from "@/components/PageTitle.vue";
import vehicleApiMixin from "@/mixins/Api/vehicle-api.mixin";
import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import VehicleModelAutocomplete from "@/components/VehicleModelAutocomplete.vue";
import { Range } from "@/client/utilities/Range";
export default {
    name: 'CreateVehicle',
    title: 'Create Vehicle',
    components: { VehicleModelAutocomplete, PageTitle },
    mixins: [vehicleApiMixin],
    inheritAttrs: false,
    props: [],
    data() {
        return {
            modelSearchQuery: null,
            loading: false,
            data: {
                vehicle: null,
                states: [
                    {
                        "id": "AL",
                        "name": "Alabama"
                    },
                    {
                        "id": "AK",
                        "name": "Alaska"
                    },
                    {
                        "id": "AZ",
                        "name": "Arizona"
                    },
                    {
                        "id": "AR",
                        "name": "Arkansas"
                    },
                    {
                        "id": "CA",
                        "name": "California"
                    },
                    {
                        "id": "CO",
                        "name": "Colorado"
                    },
                    {
                        "id": "CT",
                        "name": "Connecticut"
                    },
                    {
                        "id": "DE",
                        "name": "Delaware"
                    },
                    {
                        "id": "DC",
                        "name": "District Of Columbia"
                    },
                    {
                        "id": "FL",
                        "name": "Florida"
                    },
                    {
                        "id": "GA",
                        "name": "Georgia"
                    },
                    {
                        "id": "HI",
                        "name": "Hawaii"
                    },
                    {
                        "id": "ID",
                        "name": "Idaho"
                    },
                    {
                        "id": "IL",
                        "name": "Illinois"
                    },
                    {
                        "id": "IN",
                        "name": "Indiana"
                    },
                    {
                        "id": "IA",
                        "name": "Iowa"
                    },
                    {
                        "id": "KS",
                        "name": "Kansas"
                    },
                    {
                        "id": "KY",
                        "name": "Kentucky"
                    },
                    {
                        "id": "LA",
                        "name": "Louisiana"
                    },
                    {
                        "id": "ME",
                        "name": "Maine"
                    },
                    {
                        "id": "MD",
                        "name": "Maryland"
                    },
                    {
                        "id": "MA",
                        "name": "Massachusetts"
                    },
                    {
                        "id": "MI",
                        "name": "Michigan"
                    },
                    {
                        "id": "MN",
                        "name": "Minnesota"
                    },
                    {
                        "id": "MS",
                        "name": "Mississippi"
                    },
                    {
                        "id": "MO",
                        "name": "Missouri"
                    },
                    {
                        "id": "MT",
                        "name": "Montana"
                    },
                    {
                        "id": "NE",
                        "name": "Nebraska"
                    },
                    {
                        "id": "NV",
                        "name": "Nevada"
                    },
                    {
                        "id": "NH",
                        "name": "New Hampshire"
                    },
                    {
                        "id": "NJ",
                        "name": "New Jersey"
                    },
                    {
                        "id": "NM",
                        "name": "New Mexico"
                    },
                    {
                        "id": "NY",
                        "name": "New York"
                    },
                    {
                        "id": "NC",
                        "name": "North Carolina"
                    },
                    {
                        "id": "ND",
                        "name": "North Dakota"
                    },
                    {
                        "id": "OH",
                        "name": "Ohio"
                    },
                    {
                        "id": "OK",
                        "name": "Oklahoma"
                    },
                    {
                        "id": "OR",
                        "name": "Oregon"
                    },
                    {
                        "id": "PA",
                        "name": "Pennsylvania"
                    },
                    {
                        "id": "RI",
                        "name": "Rhode Island"
                    },
                    {
                        "id": "SC",
                        "name": "South Carolina"
                    },
                    {
                        "id": "SD",
                        "name": "South Dakota"
                    },
                    {
                        "id": "TN",
                        "name": "Tennessee"
                    },
                    {
                        "id": "TX",
                        "name": "Texas"
                    },
                    {
                        "id": "UT",
                        "name": "Utah"
                    },
                    {
                        "id": "VT",
                        "name": "Vermont"
                    },
                    {
                        "id": "VA",
                        "name": "Virginia"
                    },
                    {
                        "id": "WA",
                        "name": "Washington"
                    },
                    {
                        "id": "WV",
                        "name": "West Virginia"
                    },
                    {
                        "id": "WI",
                        "name": "Wisconsin"
                    },
                    {
                        "id": "WY",
                        "name": "Wyoming"
                    }
                ],
                colors: [
                    "Black",
                    "Blue",
                    "Brown",
                    "Burgundy",
                    "Green",
                    "Grey",
                    "Orange",
                    "Red",
                    "Silver",
                    "White",
                    "Yellow"
                ],
                years: Range(1970, new Date().getFullYear() + 4),
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                registration_years: Range(new Date().getFullYear() - 3, new Date().getFullYear() + 4),
                makes: [],
                models: [],
                fuel_types: [],
                class_types: [],
                companies: []
            },
            form: {
                id: null,
                company: null,
                exists: false,
                driver_name: null,
                make: null,
                model: null,
                year: null,
                color: null,
                fuel_type: null,
                class_type: null,
                vin_number: null,
                name: null,
                vehicle_registration: {
                    state: null,
                    plate_number: null,
                    exp_year: null,
                    exp_month: null
                }
            }
        };
    },
    computed: {
        isEditing() {
            return this.form.id !== null;
        }
    },
    watch: {
        modelSearchQuery() {
            this.debounceFetchTools();
        },
        'form.model'(model, oldModel) {
            if (typeof oldModel === 'object' && model === null) {
                this.form.make = null;
                this.form.year = null;
                this.form.fuel_type = null;
                this.form.class_type = null;
            }
            const id = model ? model.id : null;
            if (id) {
                this.form.make = model.make;
                this.form.year = model.year;
                this.form.fuel_type = model.fuel_type;
                this.form.class_type = model.class_type;
            }
        }
    },
    methods: {
        debounceFetchTools: debounce(async function () { return await this.fetchModels(); }, 300),
        async fetchAttributes() {
            const { data } = await this.$http.get('/v3/vehicles/attributes');
            this.data.fuel_types = data.fuel_types;
            this.data.class_types = data.class_types;
        },
        async fetchMakes() {
            const { data } = await this.$http.get('/v3/vehicles/makes');
            this.data.makes = data.makes;
        },
        async fetchModels() {
            const make_id = get(this.form, 'make.id');
            const params = { search: this.modelSearchQuery, vehicle_make_id: make_id };
            const { data } = await this.$http.get(`/v3/vehicles/models`, { params: params });
            this.data.models = data.models;
        },
        async fetchCompanies() {
            return this.$http.get('/v3/companies').then((response) => {
                this.data.companies = response.data.companies.filter((company) => company.name !== 'None');
                return this.data.companies;
            });
        },
        async submit() {
            if (this.$refs.vehicleForm.validate()) {
                await this.$http.post('/v3/vehicles', this.form).then((response) => {
                    this.$router.push({ name: 'vehicles' });
                });
            }
        }
    },
    async created() {
        await Promise.all([
            await this.fetchAttributes(),
            await this.fetchMakes(),
            await this.fetchCompanies(),
        ]);
        const id = this.$route.params.id;
        if (id) {
            const vehicle = await this.$http.get(`/v3/vehicles/${id}`).then((response) => {
                return response.data.vehicle;
            });
            this.data.vehicle = vehicle;
            this.form.exists = true;
            this.form.id = vehicle.id;
            this.form.company = vehicle.company;
            this.form.make = vehicle.make;
            this.form.model = vehicle.model;
            this.data.models.push(vehicle.model);
            this.form.year = vehicle.year;
            this.form.color = vehicle.color;
            this.form.fuel_type = vehicle.fuel_type;
            this.form.class_type = vehicle.class_type;
            this.form.vin_number = vehicle.vin_number;
            this.form.name = vehicle.name;
            this.form.driver_name = vehicle.driver_name;
            if (vehicle.vehicle_registration) {
                this.form.vehicle_registration.state = vehicle.vehicle_registration.state;
                this.form.vehicle_registration.plate_number = vehicle.vehicle_registration.plate_number;
                const exp = new Date(vehicle.vehicle_registration.expiration_date);
                this.form.vehicle_registration.exp_year = exp.getFullYear();
                this.form.vehicle_registration.exp_month = this.data.months[exp.getMonth()];
            }
            console.log('editing mode', vehicle);
        }
    },
};
