import PageTitle from "@/components/PageTitle.vue";
import ToolNameInput from "@/components/Tools/Inputs/ToolNameInput.vue";
import { get } from "@/client/utilities/Object";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import BasicImage from "@/components/Photos/BasicImage.vue";
import SubmitButtonWithMessages from "@/components/Form/SubmitButtonWithMessages.vue";
import BaseForm from "@/components/Form/BaseForm.vue";
import debounce from "@/client/utilities/Debounce";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
export default {
    name: 'ToolKits',
    title: 'Tool Kits',
    components: {
        ConfirmDialog,
        ToolModelAutocomplete,
        BaseForm, SubmitButtonWithMessages, BasicImage, PhotoThumbnail, ToolNameInput, PageTitle
    },
    inheritAttrs: false,
    props: [],
    data() {
        return {
            toolKits: [],
        };
    },
    methods: {
        get,
        async fetchToolKits() {
            return this.$http.get('/v3/tools/kits').then(response => {
                this.toolKits = get(response, 'data.tool_kits', []);
            });
        },
        destroy(toolKitId) {
            this.$http.delete(`/v3/tools/kits/${toolKitId}`).then(() => {
                this.fetchToolKits();
            });
        }
    },
    async mounted() {
        await this.fetchToolKits();
    }
};
