import { render, staticRenderFns } from "./ToolKits.vue?vue&type=template&id=1247478e&scoped=true"
import script from "./ToolKits.vue?vue&type=script&lang=ts"
export * from "./ToolKits.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1247478e",
  null
  
)

export default component.exports