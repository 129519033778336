import {get} from "@/client/utilities/Object";
import {UserService} from "./api/api.service";
import UrlService from "./url.service";

const AuthService = {
    data: {
        user: null,
        authenticated: null,
    },
    store(key, data) {
        window.localStorage.setItem(key, data);
    },
    remove(key) {
        window.localStorage.removeItem(key);
    },
    get(key) {
        return window.localStorage.getItem(key);
    },
    setUser(user) {
        if (user) {
            this.data.user = user;
            this.store('user', JSON.stringify(user));
            this.setAuthenticated(true);
        } else {
            console.error('[AuthService] Error setting user in cache', e);
        }
    },
    setAuthenticated(authenticated) {
        this.store('authenticated', authenticated);
    },
    isAuthenticated() {
        const state = this.get('authenticated');
        return state === 'true' || state === true;
    },
    /**
     * Gets user data from browsers local storage
     * @returns {any|null}
     */
    getUser() {
        try {
            return JSON.parse(this.get('user'));
        } catch (e) {
            console.error('[AuthService] Error getting user from cache', e);
            return null;
        }
    },

    /**
     * Remove all data from cache and set user to unauthenticated
     * @returns void
     */
    clearSession() {
        this.data.user = null;
        this.data.authenticated = false;
        this.store('user', null);
        this.store('authenticated', false);
    },

    intendedRoute(route) {
        try {
            const json = JSON.stringify(route);
            this.store('intendedRoute', json);
            return true;
        } catch (e) {
            return false;
        }
    },

    socialAuth(provider) {
        const redirect_to = window.location.href;
        window.location.href = UrlService.build(`/social/${provider}/redirect?back=${redirect_to}`);
    },

    /**
     * Logs user out of web application and removes cookies and session from database
     * @param redirect
     * @returns {Promise<Response>}
     */
    async logout(redirect = false) {
        let response = await UserService.logout();
        this.clearSession();

        if(redirect && window.location.href !== '/#/login') {
            window.location.href = '/#/login';
        }

        return response;
    },

    /**
     * Login user with credentials via API
     * @param email
     * @param password
     * @returns {Promise<Response>}
     */
    async login(email, password) {
        let login = await UserService.login(email, password);

        if(login) {
            let user = get(login, 'data.user');
            if(user) {
                this.setUser(user);
            } else {
                console.error('[AuthService] User not found.');
            }
        } else {
            console.error('[AuthService] User authentication failure.');
        }

        return login;
    },

    /**
     * Get user details from API
     * @returns {Promise<any>}
     */
    async authenticate() {
        return await UserService.getUser().then(user => {
            this.setUser(user);
            return user;
        });
    }
}

export default AuthService;