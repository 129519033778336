import NotificationTypeAutocomplete from "@/components/Notification/NotificationType/NotificationTypeAutocomplete.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import NotificationType from "@/client/models/Notification/NotificationType/NotificationType";
import { clone, get } from "@/client/utilities/Object";
export default {
    name: "CreateSubscriptionForm",
    title: "Create New Subscription",
    components: {
        NotificationTypeAutocomplete,
        JobsAutocomplete
    },
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            notificationType: {},
            job: null,
            email: '',
            valid: false,
            jobs: [],
            jobs2: [],
            emails: [],
        };
    },
    watch: {
        show(val) {
            if (val) {
                this.reset();
            }
        }
    },
    computed: {},
    methods: {
        submit() {
            if (this.valid) {
                let payload = {
                    email: this.email,
                    job_id: this.job.id,
                };
                this.$http.post('/v3/notifications/subscriptions', payload)
                    .then(response => {
                    this.reset();
                    this.close();
                })
                    .catch(response => {
                })
                    .finally(() => {
                });
            }
        },
        reset() {
            this.email = null;
            this.job = null;
            this.valid = false;
            this.formValid = false;
            this.formSubmitting = false;
        },
        fetchEmails() {
            this.$http.get('/v3/user/emails')
                .then((response) => {
                this.emails = get(response, 'data.emails', []).map((email) => {
                    return email.email;
                });
            });
        },
        fetchJobs() {
            this.$http.get('/v3/jobs/open/for-admins')
                .then(response => {
                this.jobs2 = response.data;
                this.jobs = clone(this.jobs2);
            })
                .catch(error => {
                this.error = error;
            })
                .finally(() => {
                this.loading = false;
            });
        },
        close() {
            this.$emit('close');
        }
    },
    mounted() {
        this.fetchJobs();
        this.fetchEmails();
    }
};
