<script>
export default {
    functional: true,
    name: 'ToolSerialNumberInput',
    props: [],
}
</script>

<template functional>
<v-text-field
        v-bind="data.attrs"
        v-on="listeners"
        prepend-inner-icon="mdi-badge-account"
        label="Serial Number"
        outlined
        hide-details
/>
</template>

<style scoped>

</style>