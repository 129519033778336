<script>
import PageTitle from "@/components/PageTitle.vue";
import ToolCategoryAutocomplete from "@/components/Tools/Autocompletes/ToolCategoryAutocomplete.vue";
import ToolLocation from "@/components/Tools/ToolLocation.vue";
import {get} from "@/client/utilities/Object";
import ToolManufacturerAutocomplete from "@/components/Tools/Autocompletes/ToolManufacturerAutocomplete.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import ToolStatusAutocomplete from "@/components/Tools/Autocompletes/ToolStatusAutocomplete.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import cloneObject from "@/common/clone-object";

export default {
    name: 'CreateDevice',
    components: {
        PhotoManager,
        NotesTextField,
        ToolStatusAutocomplete,
        DividingHeader, ToolManufacturerAutocomplete, ToolLocation, ToolCategoryAutocomplete, PageTitle
    },
    title: 'Create Device',
    data() {
        return {
            rules: {
                required: (value) => !!value || 'This field is required',
            },
            device: null,
            isValid: false,
            submitting: false,
            submitted: false,
            editing: false,
            validationErrors: [],
            data: {
                racks: [],
                types: [
                    {
                        value: 'laptop',
                        text: 'Laptop'
                    },
                    {
                        value: 'tablet',
                        text: 'Tablet',
                    },
                    {
                        value: 'phone',
                        text: 'Phone',
                    }
                ],
            },
            form: {
                photos: [],
                imei: null,
                serial_number: null,
                sku: null,
                name: null,
                description: null,
                tool_type: null,
                category: null,
                manufacturer: null,
                status: {
                    "id": 1,
                    "name": "Available",
                    "slug": "available",
                    "description": null,
                    "can_be_reserved": true,
                    "deleted_at": null,
                    "created_at": "2024-01-04T22:41:11.000000Z",
                    "updated_at": "2024-01-04T22:41:11.000000Z"
                }
            },
            location: {
                type: 'warehouse',
                job: null,
                vehicle: null,
                employee: null,
                warehouse: {
                    rack: null,
                    section: null,
                    sub_section: null,
                }
            }
        }
    },
    watch: {
        location: {
            handler(location) {
                this.form.rack = null;
                this.form.section = null;
                this.form.sub_section = null;
                this.form.job = null;
                this.form.vehicle = null;
                this.form.employee = null;

                this.form.locationType = location.type;

                if (location.type === 'warehouse') {
                    this.form.rack = location.rack;
                    this.form.section = location.section;
                    this.form.sub_section = location.sub_section;
                }

                if (location.type === 'job') {
                    this.form.job = location.job;
                }

                if (location.type === 'vehicle') {
                    this.form.vehicle = location.vehicle;
                }

                if (location.type === 'employee') {
                    this.form.employee = location.employee;
                }
            },
            deep: true
        }
    },
    methods: {
        async fetchData() {
            const toolId = this.$route.params.id;
            this.loadingTool = true;
            return this.$http.get('/v3/tools/forms/create/data', {params: {tool_id: toolId}}).then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                this.tool = get(response, 'data.tool', null);
            }).finally(() => {
                this.loadingTool = false;
            });
        },
        async submit() {
            console.log(this.form);
            if (this.$refs.createDeviceForm.validate() && !this.submitting) {
                if (this.data.tool) {
                    this.form.tool_id = this.data.tool.id;
                    this.form.quantity = 1;
                }

                this.form.location = this.location;
                this.form.rack = this.location.warehouse.rack;
                this.form.section = this.location.warehouse.section;
                this.form.sub_section = this.location.warehouse.sub_section;

                this.$http.post('/v3/tools', this.form).then((response) => {
                    this.$router.push({path: '/tools/devices/list'})
                });
            } else {

                this.validationErrors = this.$refs.createDeviceForm.inputs.filter(input => !input.valid)
                    .map(input => input.errorBucket)
                    .flat();

                this.submitting = false;
                this.submitted = false;
            }
        },
        getRack(rackId) {
            if (rackId && this.data.racks) {
                const rack = this.data.racks.filter((r) => r.id === rackId);
                if (rack && rack[0]) {
                    return cloneObject(rack[0]);
                }
            }

            return [];
        },
        getSection(rack, sectionId) {
            if (rack && sectionId && rack.sections) {
                const section = rack.sections.filter((s) => s.id === sectionId);

                if (section) {
                    return cloneObject(section[0]);
                }
            }

            return [];
        },
        getSubSection(section, subSectionId) {
            if (section && subSectionId && section.sub_sections) {
                const subSection = section.sub_sections.filter((s) => s.id === subSectionId);

                if (subSection) {
                    return cloneObject(subSection[0]);
                }
            }

            return [];
        }
    },
    async created() {
        await this.fetchData();

        if (this.$route.params.id && this.tool) {
            this.editing = true;
            let tool = this.tool;

            document.title = tool.name + ' - Edit Device - Virtual Warehouse';

            this.form.id = tool.id;

            const location = {
                type: 'warehouse',
                job: null,
                vehicle: null,
                employee: null,
                warehouse: {
                    rack: null,
                    section: null,
                    sub_section: null,
                }
            };

            if (tool.job) {
                this.location.type = 'jobsite';
                this.location.job = tool.job;
                this.form.locationType = 'jobsite';
                this.form.job = tool.job;
            } else if (tool.vehicle) {
                this.location.type = 'vehicle';
                this.location.vehicle = tool.vehicle;
                this.form.locationType = 'vehicle';
                this.form.vehicle = tool.vehicle;
            } else if (tool.employee) {
                this.form.locationType = 'employee';
                this.location.type = 'employee';
                this.location.employee = tool.employee;
            } else {
                this.location.type = 'warehouse';

                const rack = this.getRack(tool.tool_rack_id);
                this.location.warehouse.rack = get(rack, 'id', false) ? rack : null;
                this.form.rack = this.location.warehouse.rack;

                const section = this.getSection(this.form.rack, tool.tool_section_id);
                this.location.warehouse.section = get(section, 'id', false) ? section : null;
                this.form.section = this.location.warehouse.section;

                const subSection = this.getSubSection(this.form.section, tool.tool_sub_section_id);
                this.location.warehouse.sub_section = get(subSection, 'id', false) ? subSection : null;
                this.form.sub_section = this.location.warehouse.sub_section;
            }

            this.form.name = tool.name;
            this.form.description = tool.description;
            this.form.category = tool.tool_category;
            this.form.manufacturer = tool.tool_manufacturer;
            this.form.photos = tool.photos;
            this.form.notes = tool.notes;
            this.form.status = tool.tool_status;
            this.form.price = tool.price;
            this.form.sku = tool.sku;
            this.form.serial_number = tool.serial_number;
            this.form.imei = tool.imei;
            this.form.tool_type = tool.tool_type;
        }
    }
}
</script>

<template>
    <v-container fluid>
        <PageTitle/>
        <v-form
            v-model="isValid"
            ref="createDeviceForm"
            @submit.prevent="submit"
        >
            <v-card class="my-2">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                :rules="[(v) => !!v || 'Device type is required']"
                                outlined
                                v-model="form.tool_type"
                                label="Device Type"
                                :items="data.types"
                            />
                        </v-col>
                        <v-col>
                            <tool-manufacturer-autocomplete
                                :rules="[(v) => !!v || 'Manufacturer is required']"
                                v-model="form.manufacturer"
                            />
                        </v-col>
                        <v-col>
                            <tool-category-autocomplete
                                :rules="[(v) => !!v || 'Category is required']"
                                v-model="form.category"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="my-4">
                <v-card-text>
                    <v-row dense>
                        <v-col>
                            <v-text-field
                                hide-details
                                prepend-inner-icon="mdi-music-accidental-sharp"
                                v-model="form.sku"
                                outlined
                                label="Model Number"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                hide-details
                                :rules="[(v) => !!v || 'Device Name is required']"
                                outlined
                                v-model="form.name"
                                label="Device Name"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                outlined
                                hide-details
                                v-model="form.description"
                                label="Description"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="my-4">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.imei"
                                label="IMEI Number"
                                hide-details
                                outlined
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.serial_number"
                                label="Serial Number"
                                hide-details
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <DividingHeader>
                Device Assignment
            </DividingHeader>

            <ToolLocation
                hide-job
                hide-vehicle

                :racks="data.racks"
                v-model="location"
                :data="location"
            />

            <v-card class="my-4">
                <v-card-title>Device Photos</v-card-title>
                <v-card-text>
                    <PhotoManager
                        category="device-photos"
                        :photos="form.photos"
                    />
                </v-card-text>
            </v-card>

            <v-card class="my-4">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <notes-text-field
                                hide-details
                                v-model="form.notes"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <ToolStatusAutocomplete
                                v-model="form.status"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card color="transparent" class="my-2 pa-0">
                <v-card-text class="pa-0 ma-0 mb-2">
                    <v-divider></v-divider>
                    <v-alert
                        v-show="validationErrors && validationErrors.length > 0"
                        text
                        outlined
                        prominent
                        type="error"
                        class="mt-2"
                    >
                        <div class="font-weight-bold">
                            Form has errors that need to be corrected
                        </div>
                        <div>
                            <ul>
                                <li v-for="(message, errIdx) in validationErrors" :key="`${errIdx}-error-msg`">
                                    {{ message }}
                                </li>
                            </ul>
                        </div>
                    </v-alert>
                </v-card-text>
                <v-card-text class="pa-0">
                    <v-btn color="primary"
                           @click="submit"
                           block
                           :loading="submitting"
                           :disabled="submitting"
                    >
                        <VIcon left>mdi-content-save</VIcon>
                        {{ (editing ? 'Save' : 'Add') }} Device
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-form>
    </v-container>
</template>

<style scoped>

</style>