<script>

import PageTitle from "@/components/PageTitle.vue";
import FormRequestDetailHeader from "@/components/FormRequests/FormRequestDetailHeader.vue";
import {get} from "@/client/utilities/Object";
import {each} from "@/client/utilities/Each";
import ProjectManagerAutocomplete from "@/components/V2/ProjectManagerAutocomplete.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import TextInput from "@/components/TextInput.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import Axios from "axios";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import cloneObject from "@/common/clone-object";
import PartName from "@/components/V2/PartName.vue";

export default {
    name: 'RmaRequestForm',
    title: 'RMA Request',
    components: {
        PartName,
        PhotoManager,
        FormSubmittedMessage,
        NotesTextField,
        DeliveryWindowSelect,
        DatePicker, TextInput, JobsAutocomplete, ProjectManagerAutocomplete, FormRequestDetailHeader, PageTitle
    },
    watch: {
        async 'form.job'(job) {

            if (job) {
                await this.fetchInventoryItems();
                this.changeStep(3);
            } else {
                this.form.job = null;
            }
        },
        'form.source'(source) {
            if (source) {

            } else {
                this.form.source = null;
            }
        }
    },
    data() {
        return {
            photoExpansionPanel: false,
            rules: {
                required: v => !!v || "This is a required field",
            },
            step: 1,
            steps: {
                1: {
                    name: 'Select Location',
                    isValid: () => {
                        return this.form.source !== null;
                    }
                },
                2: {
                    name: 'Select Job',
                    isValid: () => {
                        return this.form.job !== null;
                    }
                },
                3: {
                    name: 'Enter Quantities',
                    isValid: () => {
                        let valid = this.stepValid(1) && this.stepValid(2);

                        let total_quantity = 0;

                        each(this.form.items, (item) => {
                            total_quantity += item.rma_quantity;
                        });

                        if (total_quantity <= 0) {
                            valid = false;
                        }

                        return valid;
                    }
                },
                4: {
                    name: 'Upload Photos',
                    isValid: () => {
                        return true;
                    }
                }
            },
            form: {
                job: null,
                loading: false,
                source: null,
                items: [],
                notes: '',
            },
            jobs: [],
            data: {
                jobs: [],
                open_jobs: [],
                inventory_jobs: [],
                inventory_items: [],
                inventoryItemCount: 0
            },
            request: null,
            singleExpand: false,
            overlay: true
        }
    },
    methods: {
        get,
        async updateFormRequest(status = null) {
            let uuid = this.$route.params.uuid;

            let url = `/v2/forms/requests/rma/${uuid}`;

            if (typeof status === 'string') {
                url += `/${status}`;
                console.info('[FormRequest] Updated Form Request Status ' + uuid, status);
            }

            if(this.isEditable) {
                return this.$http.post(url, this.form)
                    .then(response => {
                        this.request = response.data.request;
                        return response.data.request;
                    });
            }

            return null;
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/rma/' + uuid).then(response => {
                    this.request = response.data;
                    return response.data;
                });
            } else {
                this.$toast.error('No Form Request Found');
                this.$router.push('/');
            }

            return null;
        },
        async fetchFormData() {

            this.form.loading = true;

            return this.$http.get('/rma/jobs').then((response) => {
                this.data.open_jobs = get(response, 'data.open_jobs');
                this.data.inventory_jobs = get(response, 'data.inventory_jobs');
            });

        },
        async fetchInventoryItems() {
            const job_number = get(this.form, 'job.job_number');
            const source = get(this.form, 'source');
            const jcco = get(this.form, 'job.jcco');

            if (job_number) {
                return this.$http.post('/rma/inventory', {
                    job_number: job_number,
                    source: source,
                    jcco: jcco
                }).then((response) => {
                    this.data.inventory_items = get(response, 'data');
                    this.form.items = cloneObject(this.data.inventory_items).map(item => {
                        item.rma_quantity = 0;
                        item.deleted = false;
                        item.include = false;
                        return item;
                    });

                    this.data.inventoryItemCount = this.data.inventory_items.length;
                })
            }

            return null;
        },
        changeStep(step, update = true) {
            if (this.stepValid(step - 1)) {

                if(update) {
                    console.info('[FormRequest] Updating form request for step ' + step);
                    this.updateFormRequest(this.form);
                }

                this.step = step;
            }
        },
        onPhotoSelected(photo) {

        },
        onPhotoUploaded(photo) {
            this.updateFormRequest();
            this.photoExpansionPanel++;
        },
        stepValid(stepNumber) {

            let step = get(this.steps, stepNumber);

            if (step) {
                return step.isValid();
            }
        },
        stepEditable(stepNumber) {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            });

            return valid;
        },
        async submitForm() {
            if (this.stepValid(3) && this.isEditable) {
                this.form.submitted = true;
                this.form.status = 'submitted';
                this.form.items = this.form.items.filter(item => item.rma_quantity > 0);
                await this.updateFormRequest('submitted');
                this.formSubmitted = true;
                this.$toast.success('RMA Request Submitted!');
            }
        },
        resetForm() {
            Axios.get('/v2/forms/requests/rma/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if (uuid) {
                    this.$router.push({path: '/requests/rma/' + uuid});
                }
            });
        },
        fromWarehouse(update = true) {
            this.form.source = 'warehouse';
            this.data.jobs = this.data.inventory_jobs;
            this.jobs = this.data.inventory_jobs;
            this.changeStep(2, update);
        },
        fromJobsite(update = true) {
            this.form.source = 'jobsite';
            this.changeStep(2, update);
            this.data.jobs = this.data.open_jobs;
            this.jobs = this.data.open_jobs;
        },
        focusQty(itemId) {

        },
        toggleItemInclusion(item) {
            item.include = !item.include;
            if(!item.include) {
                item.rma_quantity = 0;
            } else {
                let input = document.getElementById(`rmaQuantity-${item.id}`);
                input.focus();
                input.select();
            }
        },
        redirectToApprovalRequests() {
            this.$router.push('/approval/requests');
        }
    },
    computed: {

        foremanName() {
            return get(this.form, 'job.foreman.name');
        },
        projectManagers() {
            return this.data.project_managers;
        },
        jobsForNewJob() {
            if (this.form.job && this.form.job.jcco && this.form.job.jcco < 9999) {
                return this.data.open_jobs.filter(job => job.jcco === this.form.job.jcco);
            }

            return this.data.open_jobs ?? [];
        },
        jobsForStock() {
            return this.data.jobs_for_stock;
        },
        isEditable() {
            return get(this.request, 'status_id', 1) === 1;
        }
    },
    async created() {
        await this.fetchFormData();
        const request = await this.fetchFormRequest();

        if(request && this.isEditable) {
            let source = get(request, 'data.source');
            let notes = get(request, 'data.notes');
            let job = get(request, 'data.job');
            let items = get(request, 'data.items');

            this.form.notes = notes;

            if (source) {

                if(source === 'warehouse') {
                    this.fromWarehouse(false);
                }

                if(source === 'jobsite' ) {
                    this.fromJobsite(false);
                }

                this.step = 2;

                if (job) {
                    this.form.job = job;
                    this.step = 2;
                }
            }

            console.log('[FormRequest] Loading RMA data for form uuid ' + this.request.uuid);
        }
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>RMA Request</PageTitle>

        <FormSubmittedMessage
                v-show="form.submitted"
                title="RMA Request Submitted"
                button-text="New RMA Request"
                :on-click="resetForm"
        >
            RMA Request Has Been Submitted
        </FormSubmittedMessage>

        <FormSubmittedMessage
                type="error"
                v-show="!isEditable && !form.submitted"
                button-text="View Approval Requests"
                :on-click="redirectToApprovalRequests"
        >
            Request Already Submitted. Only drafts may be modified.
        </FormSubmittedMessage>

        <div v-if="!form.submitted && isEditable">
            <VSheet class="mb-2 pa-2 text-subtitle-2 grey--text" v-if="request">
                <VRow>
                    <VCol>
                        Request ID
                    </VCol>
                    <VCol>
                        {{ request.uuid }}
                    </VCol>
                    <VCol>
                        {{ request.status }}
                    </VCol>
                </VRow>
            </VSheet>

            <VStepper
                    v-model="step"
                    vertical
                    non-linear
            >
                <VStepperStep
                        :complete="step > 1"
                        :key="1"
                        step="1"
                        :rules="[v => stepValid(1)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >

                    <div>
                        Select Job Source Location
                    </div>

                    <small key="source1" v-if="!stepValid(1)">
                        Please select a location
                    </small>
                    <div key="source2" v-else>
                        <v-chip
                                small
                                label
                                class="mt-2 ml-4"
                                :color="(form.source === 'warehouse') ? 'yellow darken-3': 'accent'"
                        >
                            {{form.source === 'warehouse' ? 'Warehouse' : 'Job Site'}}
                        </v-chip>
                    </div>
                </VStepperStep>
                <VStepperContent
                        step="1"
                >
                    <VCard>
                        <VCardSubtitle>Select Source Location</VCardSubtitle>
                        <VCardText>
                            <VRow>
                                <VCol>
                                    <VBtn
                                            class="mr-4 accent"
                                            x-large
                                            @click="fromJobsite()"
                                    >
                                        Job Site
                                    </VBtn>

                                    <VBtn text disabled class="mr-4">
                                        Or
                                    </VBtn>

                                    <VBtn
                                            x-large
                                            class="yellow darken-3"
                                            @click="fromWarehouse()"
                                    >
                                        Warehouse
                                    </VBtn>
                                </VCol>
                            </VRow>
                        </VCardText>
                    </VCard>
                </VStepperContent>

                <VStepperStep
                        :complete="step > 2"
                        :key="2"
                        step="2"
                        :rules="[v => stepValid(2)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    <div
                            v-if="this.form.job"
                            key="1"
                    >
                        <div
                                class="font-italic grey--text my-1"
                        >
                            {{ this.form.job.job_number }}
                        </div>
                        <div>
                            {{ this.form.job.description }}
                        </div>

                    </div>

                    <small v-if="!stepValid(2)">
                        Please select a Job
                    </small>
                </VStepperStep>
                <VStepperContent
                        step="2"
                >

                    <VCard>
                        <VCardSubtitle>Select Job</VCardSubtitle>
                        <VCardText>
                            <JobsAutocomplete
                                    :jobs="data.jobs"
                                    :items="jobs"
                                    v-model="form.job"
                            />
                        </VCardText>
                    </VCard>
                </VStepperContent>

                <VStepperStep
                        :complete="step > 3"
                        :key="3"
                        step="3"
                        :rules="[v => stepValid(3)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    Enter Quantities
                </VStepperStep>
                <VStepperContent
                        step="3"
                >

                    <VDataIterator
                            :items-per-page="-1"
                            :items="form.items"
                            item-key="id"
                            :single-expand="singleExpand"
                    >
                        <template v-slot:default="{items}">
                            <VRow class="grey--text font-weight-bold pb-3">
                                <VCol cols="12" lg="8" md="8">
                                    Part
                                </VCol>
                                <VCol>
                                    Quantity
                                </VCol>
                                <VCol cols="12" lg="2" md="2">
                                    Actions
                                </VCol>
                            </VRow>
                            <v-card
                                    v-for="item in items"
                                    :key="item.id"
                                    class="mb-2 pa-0"
                                    elevation="3"
                                    color="grey darken-3"
                            >
                                <v-card-text class="py-2 px-4">
                                    <VRow
                                            no-gutters
                                            dense
                                            :class="{excluded: item.deleted}"
                                    >
                                        <VCol cols="10" lg="8" md="8" class="py-2">
                                            <PartName
                                                    :part-number="item.part_number"
                                                    :part-description="item.part_description"
                                            ></PartName>
                                        </VCol>
                                        <VCol class="py-2 px-2">
                                            <VTextField
                                                    outlined
                                                    hide-details
                                                    type="number"
                                                    @change="updateFormRequest()"
                                                    v-model="item.rma_quantity"
                                                    :id="`rmaQuantity-${item.id}`"
                                                    :rules="[v => (v <= item.part_quantity) || `Maximum quantity is  ${item.part_quantity}`]"
                                                    :label="`${item.part_quantity} available`"
                                            >

                                            </VTextField>
                                        </VCol>
                                        <VCol
                                                cols="2" lg="2" md="2"
                                                class="pt-4 px-0 text-right"
                                        >

                                            <VTooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <VBtn
                                                            :color="(item.include ? 'error' : 'primary')"
                                                            @click="toggleItemInclusion(item)"
                                                            v-bind="attrs"

                                                            v-on="on"
                                                    >
                                                        <VIcon
                                                                v-text="(!item.include ? 'mdi-plus' : 'mdi-close')"
                                                        />
                                                    </VBtn>
                                                </template>
                                                <span v-text="(!item.include ? 'Include Item' : 'Exclude Item' )"></span>
                                            </VTooltip>

                                        </VCol>
                                    </VRow>
                                </v-card-text>
                                <v-overlay
                                        absolute
                                        v-if="!item.include"
                                >
                                    <v-btn
                                            @click="toggleItemInclusion(item)"
                                            color="primary"
                                    >
                                        <v-icon left>mdi-plus</v-icon>
                                        Include
                                    </v-btn>
                                </v-overlay>
                            </v-card>

                        </template>


                    </VDataIterator>

                    <VBtn
                            color="primary"
                            @click="changeStep(4)"
                            :disabled="!stepValid(3)"
                    >
                        Continue
                    </VBtn>

                    <VBtn
                            text
                            @click="step = 2"
                    >
                        Back
                    </VBtn>

                </VStepperContent>

                <VStepperStep
                        :complete="step > 4"
                        :key="4"
                        step="4"
                        :rules="[v => stepValid(4)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    Upload Photos
                </VStepperStep>
                <VStepperContent
                        step="4"
                >
                    <VExpansionPanels
                            v-model="photoExpansionPanel"
                    >
                        <VExpansionPanel
                                v-for="(item, idx) in form.items"
                                :key="`${idx}-photos`"
                                v-if="item && item.rma_quantity > 0"
                        >
                            <VExpansionPanelHeader
                                    disable-icon-rotate
                            >
                                <template v-slot:default="{ open }">
                                    <VRow align-content="center" align="center" dense no-gutters>
                                        <VCol cols="1">
                                            <VBadge
                                                    bordered
                                                    overlap
                                                    :color="(item.photos.length > 0 ? 'accent' : 'warning')"
                                                    :content="item.photos.length > 0 ? item.photos.length : '!'"
                                            >
                                                <VAvatar size="30" color="error">
                                                    <img :src="get(item, 'photos[0].thumbnail.url')"
                                                         v-show="item.photos.length > 0" alt="none">
                                                    <span v-show="item.photos.length === 0">?</span>
                                                </VAvatar>
                                            </VBadge>
                                        </VCol>
                                        <VCol cols="1">
                                            <VBtn
                                                    small
                                                    icon
                                                    color="green"
                                            >
                                                <VIcon left>mdi-upload</VIcon>
                                            </VBtn>
                                        </VCol>
                                        <VCol class="align-content-center">
                                            ({{ item.rma_quantity }})
                                            <b class="mr-3" v-show="item.part_number.length > 0">
                                                {{ item.part_number }}
                                            </b>
                                            <span class="font-weight-thin">{{ item.part_description }}</span>
                                        </VCol>
                                    </VRow>
                                </template>

                                <template v-slot:actions>
                                    <span
                                            v-if="item.photos.length < 1" key="0"
                                            class="error--text"
                                    >
                                        Needs Photos
                                        <VIcon color="error">mdi-alert-octagon</VIcon>
                                    </span>
                                    <small class="success--text" v-else key="1">
                                        <VIcon small right color="success">mdi-check</VIcon>
                                    </small>

                                </template>
                            </VExpansionPanelHeader>
                            <VExpansionPanelContent>

                                <PhotoManager
                                        category="rma"
                                        :photos="item.photos"
                                        :purchase-order-job-id="form.job.id"
                                        :warehouse-inventory-item-id="(form.source === 'warehouse' ? item.id : null)"
                                        :warehouse-rack-id="(form.source === 'warehouse' ? item.warehouse_rack_id : null)"
                                        :warehouse-section-id="(form.source === 'warehouse' ? item.warehouse_section_id : null)"
                                        :warehouse-sub-section-id="(form.source === 'warehouse' ? item.warehouse_sub_section_id : null)"
                                        :purchase-order-line-item-id="(form.source === 'jobsite' ? item.id: null)"
                                        @uploaded="onPhotoUploaded"
                                />

                            </VExpansionPanelContent>
                        </VExpansionPanel>
                    </VExpansionPanels>

                    <VDivider class="my-4"></VDivider>

                    <VRow>
                        <VCol>
                            <VSheet class="pa-3">
                                <NotesTextField
                                        label="RMA Reason"
                                        v-model="form.notes"
                                />
                            </VSheet>
                        </VCol>
                    </VRow>
                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                    color="primary"
                                    @click="submitForm()"
                                    :disabled="!stepValid(4)"
                            >
                                Submit
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>
            </VStepper>
        </div>

    </VContainer>
</template>

<style scoped>

</style>