<template>
    <v-btn
        block
        x-large
        color="primary"
        v-bind="$attrs"
        :disabled="!valid || submitting"
        :loading="submitting"
        @click="$emit('click')"
    >
        <slot>{{text}}</slot>
    </v-btn>
</template>
<script>
export default {
    inheritAttrs: false,
    name: 'FormSubmitButton',
    props: {
        text: {
            type: String,
            default: 'Submit'
        },
        valid: {
            type: Boolean,
            default: true
        },
        submitting: {
            type: Boolean,
            default: false
        }
    }
}
</script>