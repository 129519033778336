<script>
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import {get} from "@/client/utilities/Object";

export default {
    name: 'DeletePhotoButton',
    components: {ConfirmDialog},
    props: {
        photo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            deleting: false,
        }
    },
    methods: {
        deletePhoto(photo) {
            let id = get(photo, 'id');
            if (id) {
                this.deleting = true;

                this.$http.delete('/v2/photos/' + id).then(response => {
                    this.$emit('deleted');
                }).catch((error) => {
                    this.$emit('failed');
                }).finally(() => {
                    this.deleting = false;
                });
            }
        }
    }
}
</script>

<template>
    <ConfirmDialog
        :title="`Delete Photo ${photo.id}?`"
        :message="`Are you sure you want to delete ${photo.original_filename}?`"
        @confirm="deletePhoto(photo)"
        :loading="deleting"
        color="delete"
        small
    >
        <VIcon small>
            mdi-delete
        </VIcon>
    </ConfirmDialog>
</template>

<style scoped>

</style>