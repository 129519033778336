import {kebabCase, upperFirst} from "@/client/utilities/String";

const MetricsService = {
    data: {
        geo: {
            lat: null,
            long: null,
            altitude: null,
            accuracy: null,
            time: null
        },
        permission: {
            geolocation: null,
            notifications: null
        },
        device: {
            cores: null,
            memoryGb: null,
            platform: null,
            uuid: null,
            plugins: null
        },
        screen: {
            resolution: null,
            pixelDepth: null,
            orientation: null,
        },
        network: {
            mbps: null,
            rttMs: null,
            type: null,
        },
    },
    headers: {},
    device_uuid: null,
    init() {

        this.setDeviceUUID();

        if("permissions" in navigator) {
            navigator.permissions.query({name: 'geolocation'}).then((status) => {
                this.data.permission.geolocation = status.state;

                status.onchange = () => {
                    this.data.permission.geolocation = status.state;
                    console.log('[MetricsService] Geolocation Permission changed', this.data.permission.geolocation);
                }
            })

            navigator.permissions.query({name: 'notifications'}).then((status) => {
                this.data.permission.notifications = status.state;

                status.onchange = () => {
                    this.data.permission.notifications = status.state;
                    console.log('[MetricsService] Notifications Permission changed', this.data.permission.notifications);
                }
            })
        }
    },

    setDeviceUUID() {

        this.device_uuid = window.localStorage.getItem('device_uuid');

        if(!this.device_uuid) {
            this.device_uuid = '';
            window.localStorage.setItem('device_uuid', this.device_uuid);
        }
    },
    getHeaders() {

        this.updateMetrics();

        for (const index in this.data) {
            const values = this.data[index];
            for (const index2 in values) {
                const value = values[index2];

                const kebab = kebabCase(index + '-' + index2)
                    .split('-')
                    .map((k) => {
                        return upperFirst(k);
                    })
                    .join('-');

                const key = 'X-' + kebab;

                this.headers[key] = value;
            }
        }

        return this.headers;

    },
    updateMetrics() {
        const screen = this.getScreen();
        const device = this.getDevice();
        const connection = this.getConnection();

        this.data.device.uuid = this.device_uuid;

        this.data.screen.resolution = screen.width + 'x' + screen.height;
        this.data.screen.pixelDepth = screen.pixelDepth;
        this.data.screen.orientation = screen.orientation;

        this.data.device.cores = device.cores;
        this.data.device.memoryGb = device.memory;
        this.data.device.platform = device.platform;

        this.data.network.mbps = connection.downlink;
        this.data.network.rttMs = connection.rtt;
        this.data.network.type = connection.effectiveType;
    },
    getGeoLocation() {
        if ("geolocation" in window.navigator) {

            let stored = this.get('geolocation');

            if(stored) {
                if (stored.time) {
                    let stored_at = new Date(stored.time);
                    let now = new Date();
                    let diff = (now.getTime() - stored_at.getTime()) / 1000;

                    if (diff < 300) {
                        this.data.geo = stored;
                        console.log('[MetricsService] Got geolocation from localstorage', this.data.geo);
                    } else {
                        console.warn('[MetricsService] Geolocation cache stale, discarding', stored);
                    }
                }
            }

            window.navigator.geolocation.getCurrentPosition((position) => {

                this.data.geo = {
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                    altitude: position.coords.altitude,
                    accuracy: position.coords.accuracy,
                    time: (new Date()).toISOString(),
                };

                this.store('geolocation', this.data.geo);

                console.log('[MetricsService] Current geolocation updated', this.data.geo);

            }, (error) => {
                console.error('[MetricsService] Error getting geolocation', error);

            }, {enableHighAccuracy: true, maximumAge: 60000, timeout: 40000});
        }
    },
    getScreen() {
        let screen = {};

        if("screen" in window) {
            let screen = {
                width: window.screen.width,
                height: window.screen.height,
                pixelDepth: window.screen.pixelDepth,
                colorDepth: window.screen.colorDepth
            };

            if("orientation" in window.screen && window.screen.orientation) {
                screen.orientation = window.screen.orientation.type;
            }
        }

        return screen;
    },
    getDevice() {
        return {
            platform: window.navigator.platform,
            vendor: window.navigator.vendor,
            cores: window.navigator.hardwareConcurrency,
            memory: window.navigator.deviceMemory ?? 0,
            plugins: window.navigator.plugins,
        }
    },
    getLocation() {
        return {
            href: window.location.href,
            hash: window.location.hash,
            hostname: window.location.hostname,
            host: window.location.host,
            origin: window.location.origin,
            pathname: window.location.pathname,
            port: window.location.port,
            protocol: window.location.protocol,
            search: window.location.search,
        };
    },
    getConnection() {

        let connection = this.get('connection', {
            downlink: 0,
            maxDownlink: 0,
            effectiveType: 'unknown',
            rtt: 0
        });

        if("connection" in window.navigator) {
            connection.downlink = window.navigator.connection.downlink;
            connection.downlinkMax = window.navigator.connection.downlinkMax;
            connection.effectiveType = window.navigator.connection.effectiveType;
            connection.rtt = window.navigator.connection.rtt;
        }

        this.store('connection', connection);

        return connection;
    },
    store(key, json) {
        try {
            localStorage.setItem(key, JSON.stringify(json));
        } catch (e) {
            console.error(`[MetricsService] Error storing ${key}`, e);
        }
    },
    get(key, defaultValue) {
        return JSON.parse(localStorage.getItem(key)) || defaultValue;
    },
    getMetrics() {
        return {
            screen: this.getScreen(),
            device: this.getDevice(),
            location: this.getLocation(),
            connection: this.getConnection()
        }
    }
}

export default MetricsService;