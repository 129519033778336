<template>
    <v-textarea
        :label="label"
        :placeholder="placeholder"
        :rows="rows"
        :prepend-inner-icon="prependInnerIcon"
        outlined
        hide-details="auto"
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
    ></v-textarea>
</template>

<script>
export default {
    name: "NotesTextField",
    inheritAttrs: false,
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        }
    },
    props: {
        label: {
            type: String,
            default: 'Notes'
        },
        placeholder: {
            type: String,
            default: 'Enter your notes...'
        },
        prependInnerIcon: {
            type: String,
            default:'mdi-message-text-outline'
        },
        rows: {
            type: String,
            default: "1"
        },
        value: ''
    }
}
</script>