<script>
export default {
    name: 'PackageTypeAutocomplete',
    inheritAttrs: false,
    props: ['loading', 'value', 'rules', 'items'],
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
        getItems() {
            return JSON.parse(JSON.stringify(this.items));
        }
    },
}
</script>

<template>
    <VSelect
            outlined
            v-on="inputListeners"
            v-bind:value="value"
            :items="getItems"
            v-bind:rules="rules"
            v-bind:loading="loading"
            prepend-inner-icon="mdi-package-variant"
            item-text="name"
            item-value="id"
            label="Package Type"
            return-object
            clearable
    />
</template>

<style scoped>

</style>