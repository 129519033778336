import NotificationTypeService from "./Notification/NotificationTypeService";
import NotificationSubscriptionService from "./Notification/NotificationSubscriptionService";
import JobService from "./Job/JobService";
import ApiConfiguration from "./lib/ApiConfiguration";
import CompanyService from "@/client/api/CompanyService";
class ApiService {
    configuration = ApiConfiguration;
    notificationTypes;
    notificationSubscriptions;
    companyService;
    jobs;
    constructor() {
        this.notificationTypes = new NotificationTypeService(ApiConfiguration);
        this.notificationSubscriptions = new NotificationSubscriptionService(ApiConfiguration);
        this.companyService = new CompanyService(ApiConfiguration);
        this.jobs = new JobService(ApiConfiguration);
    }
}
const Api = new ApiService();
export default Api;
