<script>
export default {
    name: 'ToolNameInput',
    props: {
        label: {
            type: String,
            default: 'Name'
        }
    }
}
</script>

<template>
<v-text-field
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        :label="label"
/>
</template>

<style scoped>

</style>