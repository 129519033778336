import {POSITION} from "vue-toastification";

const Config = {
    api_url: '/api',
    endpoints: {
        ryvitJobs: {
            openJobs: ['GET', '/ryvit/jobs/open']
        },
        v2: {
            transmittalForm: {
                data: ['GET', '/v2/forms/transmittal/data'],
                inventory: ['GET', '/v2/forms/transmittal/inventory'],
                submit: ['POST', '/v2/forms/transmittal/submit']
            }
        },
        user: {
            get: ['GET', '/user'],
            login: ['POST', '/user/login'],
            logout: ['GET', '/user/logout']
        },
        purchaseOrders: {
            data: ['POST', '/purchase-orders/search'],
            submit: ['POST', '/purchase-orders/save']
        },
        warehouseInventory: {
            data: ['GET', '/warehouse/inventory/items'],
            byJobNumber: ['POST', '/warehouse/inventory/items/by-job-number']
        },
        inventoryForm: {
            data: ['GET', '/warehouse/inventory/form'],
            submit: ['POST', '/warehouse/inventory/form/save']
        },
        receiveForm: {
            data: ['GET', '/receiving/form'],
            submit: ['POST', '/receiving/form/save']
        },
        transmittalForm: {
            data: ['GET', '/transmittal/form'],
            submit: ['POST', '/transmittal/form/save'],
            search: ['POST', '/transmittal/form/search']
        },
        ownerMaterialsReceivingForm: {
            data: ['GET', '/owner-materials-receiving/form'],
            submit: ['POST', '/owner-materials-receiving/form/save']
        },
        restockForm: {
            data: ['GET', '/restock/form'],
            submit: ['POST', '/restock/form/save'],
            search: ['POST', '/restock/form/search']
        },
        kitCreationForm: {
            data: ['GET', '/kit-creation/form'],
            submit: ['POST', '/kit-creation/form/save']
        },
        rmaForm: {
            data: ['GET', '/rma/form'],
            search: ['POST', '/rma/form/search'],
            submit: ['POST', '/rma/form/save']
        }
    },
    photos: {
        placeholder: {
            type: 'svg',
            url: '/img/icons/placeholder.svg'
        }
    },
    toastOptions: {
        position: POSITION.TOP_CENTER,
        timeout: 5000,
        newestOnTop: true,
        filterBeforeCreate: (toast, toasts) => {
            if (toasts.filter(
                t => t.type === toast.type
            ).length !== 0) {
                // Returning false discards the toast
                return false;
            }
            // You can modify the toast if you want
            return toast;
        }
    }
};

const MATERIAL_REQUEST_TYPES = {
    NEW_ITEM: 'N',
    PURCHASED: 'P',
    WAREHOUSE: 'W'
}

export default Config;
