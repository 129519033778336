import Axios from "axios";
import { get } from "@/client/utilities/Object";
import Vehicle from "@/client/models/Vehicle";
export default {
    computed: {},
    data() {
        return {
            vehicles: [],
        };
    },
    methods: {
        async fetchVehicles() {
            return await Axios.get('/v3/vehicles')
                .then(response => {
                return get(response, 'data.vehicles', []);
            });
        },
        async fetchVehicle(vehicleId) {
            return Axios.get('/v3/vehicles/' + vehicleId)
                .then(response => {
                return get(response, 'data.vehicle');
            });
        }
    },
};
