import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
export default {
    name: 'VehicleModelAutocomplete',
    inheritAttrs: false,
    props: {
        year: {
            type: Number,
            required: false
        },
        fuelType: {
            type: String,
            required: false
        },
        vehicleMakeId: {
            type: Number,
            required: false
        },
        dataUrl: {
            type: String,
            default: '/v3/vehicles/models'
        },
        initModel: {
            type: [Object, String],
            required: false
        }
    },
    data() {
        return {
            total: 0,
            localModel: null,
            currentPage: 1,
            loadedPages: [],
            lastPage: 1,
            perPage: 15,
            remoteTotal: 0,
            localTotal: 0,
            loadingOnScroll: false,
            models: [],
            selectedModels: [],
            search: null,
            loading: false,
        };
    },
    watch: {
        async search(value) {
            this.resetScrollState();
            await this.debounceFetchVehicleModels();
        },
        localModel(value) {
            this.$emit('input', value);
        },
        async vehicleMakeId(value) {
            this.resetScrollState();
            await this.debounceFetchVehicleModels();
        },
        async fuelType(value) {
            this.resetScrollState();
            await this.debounceFetchVehicleModels();
        }
    },
    methods: {
        get,
        async fetchVehicleModels() {
            const params = {
                search: this.search,
                vehicle_make_id: this.vehicleMakeId,
                page: this.currentPage,
                fuel_type: this.fuelType,
                year: this.year,
            };
            this.loading = true;
            return await this.$http.get(this.dataUrl, { params: params }).then((response) => {
                const data = response.data;
                const models = get(data, 'data', []);
                this.models = [
                    ...this.models,
                    ...models
                ];
                this.remoteTotal = get(data, 'total', 0);
                this.lastPage = get(data, 'last_page', 1);
                this.currentPage = get(data, 'current_page', 1);
                this.perPage = get(data, 'per_page', 15);
                this.loadedPages = get(data, 'last_page', 1);
                this.localTotal = this.models.length;
            }).finally(() => {
                this.loading = false;
            });
        },
        resetScrollState() {
            this.currentPage = 1;
            this.lastPage = 1;
            this.total = 0;
            this.remoteTotal = 0;
            this.models = [];
            this.loadedPages = [];
        },
        onIntersect() {
            if (this.currentPage < this.lastPage && !this.loadingOnScroll) {
                this.loadingOnScroll = true;
                this.currentPage += 1;
                this.$log.log(`Loading page ${this.currentPage} from scroll intersection event`);
                this.fetchVehicleModels().finally(() => {
                    this.loadingOnScroll = false;
                });
            }
        },
        debounceFetchVehicleModels: debounce(async function () { await this.fetchVehicleModels(); }, 400),
    },
    async mounted() {
        await this.fetchVehicleModels();
        if (this.value) {
            this.localModel = this.value;
        }
    }
};
