<template>
    <v-container>
        <PageTitle>
            Transmittal Form
            <VBtn
                    color="accent"
                    small
                    class="float-right"
                    href="#/transmit-form"
            >
                New Transmittal Form
            </VBtn>
        </PageTitle>

        <FormSubmittedMessage
            :button-text="message.buttonText"
            v-if="!showForm"
            :type="message.type"
            :onClick="resetForm"
        >
            {{ message.text }}
        </FormSubmittedMessage>

        <v-form v-else v-model="formValid" ref="form">
            <v-card>
                <v-card-text v-if="!user">
                    <v-combobox
                        v-model="employeeName"
                        :items="presetEmployees"
                        :rules="[requiredField]"
                        clearable
                        label="Your name"
                        filled
                        placeholder="Start typing to Search or Add a new name"
                        prepend-icon="mdi-account-search"
                        return-object
                    />
                </v-card-text>
                <v-card-text class="pb-0 mb-0">
                    <NotesTextField
                        v-model="transmittalNotes"
                        label="Transmittal Notes"
                    />
                </v-card-text>
            </v-card>

            <!-- Search for Parts by Job number -->
            <v-card class="my-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-select
                                label="Project manager"
                                :items="managers"
                                v-model="selectedManager"
                                :loading="loadingImport"
                                clearable
                                outlined
                                prepend-inner-icon="mdi-account-box"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-autocomplete
                                v-model="selectedJobNumber"
                                :items="jobNumbers"
                                :loading="loadingImport"
                                :rules="[requiredField]"
                                clearable
                                outlined
                                label="Job number"
                                placeholder="Start typing to Search"
                                prepend-inner-icon="mdi-database-search"
                                return-object
                            >
                                <template v-slot:selection="data">
                                    {{data.item}}
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-alert
                        close-text="Close Alert"
                        v-show="!selectedJobNumber"
                        prominent
                        type="info"
                        border="left"
                        dense
                    >
                        Select Job Number to Continue
                    </v-alert>

                </v-card-text>
            </v-card>

            <!-- Searching by Location or Part number + description



             -->
            <v-card
                    class="my-6"
                    v-if="selectedJobNumber"
            >
                <!-- Tab names -->
                <v-tabs
                    v-model="transmittalMode"
                    centered
                    grows
                    background-color="tabHeaderBackground"
                    fixed-tabs
                    float
                    slider-size="4"
                    class="my-4"
                >
                    <v-tab href="#warehouseToJobsite">From Warehouse</v-tab>
                    <v-tab href="#switchToJob">From Job/Stock to Job</v-tab>
                    <v-tab href="#switchToStock">From Job/Stock to Stock</v-tab>
                    <!-- <v-tab>Search by Part Number + Description</v-tab> -->
                    <!-- <v-tab>Search by Kit Name</v-tab> -->
                </v-tabs>
                <!-- Tab contents -->
                <v-tabs-items v-model="transmittalMode">
                    <!-- For From Warehouse tab -->
                    <v-tab-item class="my-8 mx-4" value="warehouseToJobsite">
                        <v-row>
                            <v-col>

                                <TextInput
                                    label="Site Contact Name"
                                    v-model="siteContactName"
                                    :rules="transmittalMode === 'warehouseToJobsite' ? [requiredField] : []"
                                />

                            </v-col>

                            <!-- Date picker for Delivery Date -->
                            <v-col cols="12" sm="4">
                                <v-menu
                                    v-model="datePickerMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="deliveryDate"
                                            :rules="[requiredField]"
                                            label="Delivery Date"
                                            outlined
                                            prepend-inner-icon="mdi-calendar"
                                            hint="YYYY-MM-DD format"
                                            persistent-hint
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="deliveryDate"
                                        @input="datePickerMenu = false"
                                        no-title
                                        scrollable
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col>
                                <TextInput
                                    label="Delivery Window"
                                    v-model="deliveryWindow"
                                    :rules="
                      transmittalMode == 'warehouseToJobsite'
                        ? [requiredField]
                        : []"
                                />
                            </v-col>
                        </v-row>

                    </v-tab-item>
                    <!-- For From Job/Stock to Job tab -->
                    <v-tab-item class="my-8 mx-4" value="switchToJob">
                        <v-row>
                            <v-col cols="12">New Job Info:</v-col>
                            <v-col>
                                <v-combobox
                                    v-model="selectedNewJobNumber"
                                    :items="jobNumbersForNewJob"
                                    :rules="
                      transmittalMode == 'switchToJob' ? [requiredField] : []
                    "
                                    :loading="loadingImport"
                                    clearable
                                    outlined
                                    label="Job Number"
                                    placeholder="Start typing to Search"
                                    prepend-inner-icon="mdi-database-search"
                                    return-object
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-btn
                                    @click="getJobInfo"
                                    block
                                    color="primary"
                                    style="min-height: 50px"
                                    :disabled="!selectedNewJobNumber"
                                    :loading="jobLookupLoading"
                                    :rules="
                      transmittalMode == 'switchToJob' ? [requiredField] : []
                    "
                                >Lookup Job
                                </v-btn
                                >
                            </v-col>
                            <!-- <v-col cols="12">
                              <v-checkbox
                                v-model="unknownJob"
                                label="Unknown or Not Found Job number"
                                :disabled="!!selectedJobNumber"
                              ></v-checkbox>
                            </v-col> -->
                        </v-row>
                        <!-- Lookup Alert -->
                        <div>
                            <v-alert
                                close-text="Close Alert"
                                v-show="alert.show"
                                prominent
                                dense
                                border="left"
                                dismissible
                                :type="alert.type"
                            >
                                {{ alert.message }}
                            </v-alert>
                        </div>

                        <v-list
                            v-show="
                  selectedNewJobNumber &&
                  selectedNewJobNumber.toString() != '' &&
                  JSON.stringify(newJobInfo) !== '{}' &&
                  selectedNewJobNumber.toString() ==
                    newJobInfo['jobNumber'].toString()
                "
                            color="jobDetailsBackground"
                            elevation="2"
                        >
                            <!-- Display part Info -->
                            <v-list-item>
                                <v-list-item-content>
                                    <v-row>

                                        <v-col cols="12" sm="6">
                                            <v-list-item-subtitle>Job Name</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    (newJobInfo ? newJobInfo["jobName"] : '')
                                                }}
                                            </v-list-item-title>

                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-list-item-subtitle>Manager</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    (newJobInfo ? newJobInfo["projectManager"] : '')
                                                }}
                                            </v-list-item-title>

                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-list-item-subtitle>Foreman</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    (newJobInfo ? newJobInfo["foreman"]: '')
                                                }}
                                            </v-list-item-title>

                                        </v-col>

                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                    <!-- For From Job/Stock to Stock tab -->
                    <v-tab-item class="my-8 mx-4" value="switchToStock">
                        <v-row>
                            <v-col cols="12">New Stock Info:</v-col>
                            <v-col>
                                <v-select
                                    v-model="selectedNewStock"
                                    :items="Object.keys(stocksInfo)"
                                    :rules="
                      transmittalMode == 'switchToStock' ? [requiredField] : []
                    "
                                    cache-items
                                    outlined
                                    clearable
                                    label="Stock"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-list
                            v-show="selectedNewStock && selectedNewStock.toString() != ''"
                            color="jobDetailsBackground"
                            elevation="2"
                        >
                            <!-- Display part Info -->
                            <v-list-item v-if="selectedNewStockInfo">
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-list-item-subtitle>Job Name</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    selectedNewStockInfo["jobName"]
                                                }}
                                            </v-list-item-title>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-list-item-subtitle>Rack</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    selectedNewStockInfo["rack"]
                                                }}
                                            </v-list-item-title>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>

            <v-card class="my-6" v-show="selectedJobNumber">

                <v-tabs
                    v-model="filterTabs"
                    centered
                    grows
                    background-color="tabHeaderBackground"
                    fixed-tabs
                    float
                    slider-size="4"
                >
                    <v-tab href="#filterByPartNumber">Filter by Part Number</v-tab>
                    <v-tab href="#filterByKitName">Filter by Kit Name</v-tab>
                </v-tabs>
                <v-tabs-items v-model="filterTabs" class="mb-0 pb-0">

                    <v-tab-item class="pt-6 px-4" value="filterByPartNumber">
                        <v-autocomplete
                            v-model="selectedItemsOfSelectedJob"
                            :items="itemNamesOfSelectedJob"
                            clearable
                            deletable-chips
                            multiple
                            small-chips
                            outlined
                            label="Filter by Part Number & Description"
                            placeholder="Start typing to Search"
                            prepend-inner-icon="mdi-database-search"
                            return-object
                        ></v-autocomplete>
                    </v-tab-item>

                    <v-tab-item class="my-2 mx-2 py-3 px-4" value="filterByKitName">
                        <v-autocomplete
                            v-model="selectedKitNamesOfSelectedJob"
                            :items="kitNamesOfSelectedJob"
                            clearable
                            deletable-chips
                            multiple
                            outlined
                            small-chips
                            label="Filter by Kit Name"
                            placeholder="Start typing to Search"
                            prepend-inner-icon="mdi-database-search"
                            return-object
                        ></v-autocomplete>
                    </v-tab-item>

                </v-tabs-items>

            </v-card>


            <DividingHeader v-show="selectedJobNumber">
                <v-badge
                    :content="availableParts.length"
                    color="accent"
                    inline
                    bordered
                >
                    Parts
                </v-badge>
            </DividingHeader>

            <v-card
                class="my-2"
                v-show="selectedJobNumber"
                v-for="(part, p) in availableParts"
                :key="p"
            >
                <v-card-text>
                    <!-- Display all parts under this job number -->
                    <!-- <v-row v-for="(part, p) in availableParts" :key="p" class="pa-4"> -->
                    <v-row>
                        <v-col cols="12" md="8" lg="8" class="pb-0">
                            <!--<div class="text-caption font-weight-bold">Part Number</div>
                            <p>{{part.partComboName}}</p> -->
                            <v-text-field
                                label="Part number"
                                :value="part.partComboName"
                                :rules="[requiredField]"
                                item-text="partComboName"
                                disabled
                                outlined
                                auto-grow
                                no-resize
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" class="pb-0">
                            <v-text-field
                                :label="partQuantityLabel(part)"
                                type="number"
                                outlined
                                dense
                                v-model="selectedParts[p].partQuantity"
                                :rules="partQuantityRule2(selectedParts[p], p)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" md="4" lg="4" class="pb-0">
                            <!-- <div class="text-caption font-weight-bold">Current Location</div>
                            <p>
                                {{selectedParts[p].rack +
                            ' - ' +
                            selectedParts[p].section +
                            ' - ' +
                            selectedParts[p].subSection}}
                            </p> -->
                            <v-text-field
                                label="Current location"
                                :value="
                          selectedParts[p].rack +
                          ' - ' +
                          selectedParts[p].section +
                          ' - ' +
                          selectedParts[p].subSection
                        "
                                disabled
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" class="pb-0">
                            <!--<div class="text-caption font-weight-bold">Kit Name</div>
                            <p>
                                {{selectedParts[p].kitName}}
                            </p> -->

                            <v-text-field
                                :value="selectedParts[p].kitName"
                                disabled
                                outlined
                                dense
                                label="Kit Name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- </v-row> -->

                </v-card-text>
            </v-card>

            <!-- Submit button -->
            <v-row>
                <v-col class="text-center">

                    <FormSubmitButton
                            :valid="formValid"
                            :submitting="formSubmitting"
                            @click="submitForm"
                            text="Transmit"
                    />

                </v-col>
            </v-row>
        </v-form>
        <v-dialog
                v-model="duplicateJobNumberDialog"
                persistent
                max-width="600px"
        >
            <v-card>
                <v-card-title>{{jobCount}} Job Numbers Found for {{duplicateJobNumber}}</v-card-title>
                <v-card-subtitle>Please select a job to continue</v-card-subtitle>
                <v-card-text>

                    <v-list two-line>
                        <v-list-item-group
                                v-model="selectedJob"
                                mandatory
                        >
                            <template v-for="job in jobs">
                                <v-list-item
                                        :key="job.id"
                                        :value="job"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="job.description"></v-list-item-title>
                                            <v-list-item-subtitle v-if="job.company" v-text="job.company.name"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="success" v-show="active">mdi-check-circle-outline</v-icon>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            @click="duplicateJobNumberDialog = false"
                    >
                        Select
                    </v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </v-container>
</template>

<script>
import {get} from "@/client/utilities/Object";
import {each} from "@/client/utilities/Each";
import {isArray} from "@/client/utilities/Array";
import {TransmittalFormService} from "@/common/api/api.service";
import DividingHeader from "@/components/DividingHeader.vue";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import PageTitle from "@/components/PageTitle.vue";
import TextInput from "@/components/TextInput.vue";

export default {
    components: {FormSubmitButton, DividingHeader, PageTitle, TextInput, NotesTextField, FormSubmittedMessage},
    title: 'Transmittal Form',
    data() {
        return {
            allJobNumbers: [],
            jobCount:0,
            jobs: [],
            selectedJob: null,
            selectedCompanyId: null,
            duplicateJobNumber: null,
            duplicateJobNumberDialog: false,
            jobSelectionDialog: false,
            filterTabs: '',
            transmittalMode: null,
            showForm: true,
            serverResponseMessage: "",
            loadingImport: true,
            loading: false,
            formSubmitting: false,
            formValid: false,
            datePickerMenu: false,

            // Form data
            db: {},
            employeeName: "",
            presetEmployees: [],
            siteContactName: "",
            deliveryDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .slice(0, 10),
            deliveryWindow: "",
            transmittalNotes: "",
            managerInfo: {}, // Object containing manager and their jobs
            managers: [],
            selectedManager: "",
            jobNumbers: [],
            selectedJobNumber: "",
            filterOption: "Filtered by Part Number & Description",

            availableParts: [], // all available parts for the selected job numbers
            chooseByLocation: false,
            selectedLocation: "",

            // partsByLocation: {}, // object of parts details by location
            // locationsForSelectedJob: [], // array of all locations for all items of a selected job

            partsGroupedByJobNumber: {}, // object of parts details by job number
            itemsOfSelectedJob: [], // array of all items of a selected job
            itemNamesOfSelectedJob: [], // array of all item names for all items of a selected job
            selectedItemsOfSelectedJob: [], // array of all selected items of a selected job
            kitNamesOfSelectedJob: [], // array of all kit names for all items of a selected job
            selectedKitNamesOfSelectedJob: [], // array of all selected kit names for all items of a selected job

            selectedParts: [],
            partsAtSelectedLocation: [],

            warehousePartsGroupedByPO: [], // Array of items (Object) from the server
            itemNames: [], // Array of items' names (String)
            chosenItems: [], // Name (part Number + Description) of the chosen item to be returned back to the warehouse

            jobNumbersForOpenJobs: [], // Array of unique job numbers for open jobs for job info lookup
            jobNumbersByCompany: [],
            jobNumbersForNewJob: [],
            jccoByJob: [],
            selectedNewJobNumber: "", // Selected job number for new job
            jobLookupLoading: false,
            newJobInfo: {}, // Object containing new job info
            newJobs: [],
            requireNewJobInfo: false,
            transmittalModeInfo: {}, // Object containing transmittal mode info

            // Object containing stocks info
            stocksInfo: {
                "SCS Stock": {
                    jobNumber: "SCS Stock",
                    jobName: "SCS Warehouse Stock",
                    projectManager: "",
                    foreman: "",
                },
                "FA Stock": {
                    jobNumber: "FA Stock",
                    jobName: "Fire Alarm Warehouse Stock",
                    projectManager: "",
                    foreman: "",
                },
                "AV Stock": {
                    jobNumber: "AV Stock",
                    jobName: "AV Warehouse Stock",
                    projectManager: "",
                    foreman: "",
                },
                "SEC Stock": {
                    jobNumber: "SEC Stock",
                    jobName: "SEC Warehouse Stock",
                    projectManager: "",
                    foreman: "",
                },
            },
            selectedNewStock: "", // Selected stock for new stock
            selectedNewStockInfo: {}, // Object containing new stock info

            // kitNames: [], // Array of kit names (String)
            // chosenKit: "", // Name of the chosen Kit to be returned back to the warehouse
            // partsGroupedByKit: {}, // object of parts details grouped by kit name

            // Alert:
            alert: {
                show: false,
                type: undefined,
                message: "",
            },

            requiredField: (v) => !!v || "This is a required field",
            partQuantityRule: [
                (v) => !!v || "This is a required field",
                (v) => (parseFloat(v) > 0 ? true : "Quantity must be > 0"),
            ],
        };
    },
    created() {
        console.log("created");
        this.fetchDatabase();
    },
    computed: {
        form() {
            let formDetails = {
                employeeName: this.employeeName,
                siteContactName: this.siteContactName,
                deliveryDate: this.deliveryDate,
                deliveryWindow: this.deliveryWindow,
                transmittalNotes: this.transmittalNotes,
                transmittalMode: this.transmittalMode,
                transmittalModeInfo: this.transmittalModeInfo,
                // projectManager: this.selectedManager,
                // jobNumber: this.selectedJobNumber,
                // chooseByLocation: this.chooseByLocation,
            };

            // If submitter did not choose specific location, returns all parts
            if (!this.chooseByLocation) {
                return {
                    ...formDetails,
                    parts: this.selectedParts,
                };
            } else {
                return {
                    ...formDetails,
                    selectedLocation: this.selectedLocation,
                    partsAtSelectedLocation: this.partsAtSelectedLocation,
                };
            }
        },
    },
    watch: {
        selectedJob() {
            console.log('selected job', this.selectedJob);
        },
        formValid() {

            let quantity_is_valid = false;
            let selected_quantity = 0;

            each(this.selectedParts, (part) => {
                selected_quantity += part.partQuantity;
            });

            if(selected_quantity > 0) {
                quantity_is_valid = true;
            }

            if (
                this.transmittalMode === "switchToJob" ||
                this.transmittalMode === "stockToJob"
            ) {
                let haveNewJobInfo =
                    this.selectedNewJobNumber &&
                    JSON.stringify(this.newJobInfo) != "{}" &&
                    this.selectedNewJobNumber.toString() ==
                    this.newJobInfo["jobNumber"].toString();
                this.formValid = this.$refs.form.validate() && haveNewJobInfo && quantity_is_valid;
            } else {
                this.formValid = this.$refs.form.validate() && quantity_is_valid;
            }
        },
        selectedManager(val) {
            if (!val) {
                this.jobNumbers = [
                    ...new Set(Object.keys(this.partsGroupedByJobNumber)),
                ];
            } else {
                this.jobNumbers = this.managerInfo[val].sort();
            }
        },
        selectedJobNumber(val) {
            // If there is no selected job number, clear the locations and parts
            if (!val) {
                this.itemsOfSelectedJob = [];
                this.availableParts = [];
                this.selectedParts = [];
                this.itemNamesOfSelectedJob = [];
                this.kitNamesOfSelectedJob = [];
                this.jobNumbersForNewJob = [];
                return;
            }

            this.itemsOfSelectedJob = this.partsGroupedByJobNumber[val];
            this.availableParts = this.itemsOfSelectedJob;
            this.itemNamesOfSelectedJob = [];
            this.kitNamesOfSelectedJob = [];
            this.selectedParts = [];

            const jcco = this.jccoByJob[val];

            if (jcco > 0) {
                this.jobNumbersForNewJob = Object.values(this.jobNumbersByCompany[jcco]);
            } else {
                this.jobNumbersForNewJob = Object.values(this.jobNumbersForOpenJobs);
            }

            for (let ap in this.availableParts) {
                const newObj = {...this.availableParts[ap]};
                if (this.selectedManager !== newObj.manager) {
                    this.selectedManager = newObj.manager;
                }

                this.selectedParts.push(newObj);
                // console.log(newObj);
                this.itemNamesOfSelectedJob.push(newObj.partComboName);
                if (newObj.kitName != "") {
                    this.kitNamesOfSelectedJob.push(newObj.kitName);
                }
            }

            // console.table(this.selectedParts);

            for (let sp in this.selectedParts) {
                this.selectedParts[sp].partQuantity = 0;
            }
        },
        selectedItemsOfSelectedJob(val) {
            // const itemsOfSelectedJob = this.partsGroupedByJobNumber[this.selectedJobNumber];
            if (val.length > 0) {
                this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    this.availableParts.push(
                        ...this.itemsOfSelectedJob.filter(
                            (part) =>  {
                                let matchesName = part.partComboName === item;
                                let isNotKit = (part.kitName === "" || part.kitName === null);

                                if(matchesName) {
                                    console.log(item, matchesName, isNotKit, part);
                                }

                                return  matchesName && isNotKit;
                            }
                        )
                    );
                }
                // console.log(this.availableParts);

                this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));

                for (let sp in this.selectedParts) {
                    this.selectedParts[sp].partQuantity = 0;
                }
            } else {
                this.resetSelectedParts();
            }
        },
        selectedKitNamesOfSelectedJob(val) {
            if (val.length > 0) {
                this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    // console.log(item)
                    this.availableParts.push(
                        ...this.itemsOfSelectedJob.filter((part) => part.kitName === item)
                    );
                }
                // console.log(this.availableParts);
                // console.log(this.selectedItemsOfSelectedJob)

                this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));

                for (let sp in this.selectedParts) {
                    this.selectedParts[sp].partQuantity = 0;
                }
            } else {
                this.resetSelectedParts();
            }
        },
        chosenItems(selectedItems) {
            if (!this.chosenItems) return [];

            console.log("chosenItems", this.chosenItems);
            // Reset availableParts and selectedParts
            this.availableParts = [];
            this.selectedParts = [];

            for (const item of selectedItems) {
                // const index = this.itemNames.indexOf(item);
                // Find all of the matched name (could be several)
                let indexes = [];
                for (let i = 0; i < this.itemNames.length; i++) {
                    if (this.itemNames[i].includes(item)) {
                        indexes.push(i);
                    }
                }

                // For each matched name, add the corresponding item to the availableParts
                for (const index of indexes) {
                    // console.log(`index: ${index}`)
                    // console.table(this.warehousePartsGroupedByPO[index])
                    let partsWithThisName = Object.values(
                        this.warehousePartsGroupedByPO[index]
                    )[0];
                    // console.log(partsWithThisName);

                    for (const partInfo of partsWithThisName) {
                        let itemDetails = {
                            partComboName: item,
                            partQuantity: partInfo["partQuantity"],
                            jobNo: partInfo["jobNo"],
                            poNo: partInfo["poNo"],
                            manager: partInfo["manager"],
                            rack: partInfo["rack"],
                            section: partInfo["section"],
                            subSection: partInfo["subSection"],
                            kitName: partInfo["kitName"],
                        };
                        // Make a copy into the selectedParts array
                        // const cloneItem = JSON.parse(JSON.stringify(itemDetails));
                        this.availableParts.push(JSON.parse(JSON.stringify(itemDetails)));
                        this.selectedParts.push(JSON.parse(JSON.stringify(itemDetails)));

                        // Set the default quantity to 0 for `selectedParts`
                        for (let sp in this.selectedParts) {
                            this.selectedParts[sp].partQuantity = 0;
                        }
                    }
                }
            }

            // console.log(this.selectedParts);
        },
        chosenKit(val) {
            // If there is no selected job number, clear the locations and parts
            if (!val) {
                this.availableParts = [];
                this.selectedParts = [];
                return;
            }

            this.availableParts = this.partsGroupedByKit[val];
            this.selectedParts = [];

            for (let ap in this.availableParts) {
                const newObj = {...this.availableParts[ap]};
                this.selectedParts.push(newObj);
            }
            // console.table(this.selectedParts);

            // Set the default quantity to 0 for `selectedParts`
            for (let sp in this.selectedParts) {
                this.selectedParts[sp].partQuantity = 0;
            }
        },
        selectedNewStock(val) {
            if (val === "") return;

            this.selectedNewStockInfo = this.stocksInfo[val];
        },
    },
    methods: {
        getJobNumbersForNewJob() {
            let jobNum = this.selectedJobNumber;
            const jcco = this.jccoByJob[jobNum];

            console.log(jobNum, jcco);

            if (parseInt(jcco) > 0) {
                return Object.values(this.jobNumbersByCompany[jcco]);
            } else {
                return Object.values(this.jobNumbersForOpenJobs);
            }
        },
        fetchDatabase() {

            TransmittalFormService
                .getFormData()
                .then((response) => {
                    if (response) {
                        const json = response.data;
                        // this.db = json['partsByPO'];
                        // this.managers = Object.keys(this.db).sort();

                        this.loadingImport = false;
                        this.presetEmployees = json['employeeNames'];

                        this.jobNumbersForOpenJobs = json["jobNumbersForOpenJobs"];
                        this.jobNumbersByCompany = json["jobNumbersByCompany"];
                        this.jccoByJob = json['jccoByJob'];

                        this.warehousePartsGroupedByPO = json["partsByPO"]; // This is the array of objects
                        for (const item of this.warehousePartsGroupedByPO) {
                            // Set up for item lookup using partComboName:
                            this.itemNames.push(...Object.keys(item));

                            // Set up for item lookup using jobNumbers:
                            for (const [itemName, itemList] of Object.entries(item)) {
                                // `itemInfo` is an array of all items of this same name
                                for (const singleItemInfo of itemList) {
                                    // console.log(`singleItemInfo: ${singleItemInfo}`);

                                    // Add the item to the partsGroupedByJobNumber array
                                    if (!this.partsGroupedByJobNumber[singleItemInfo["jobNo"]]) {
                                        this.partsGroupedByJobNumber[singleItemInfo["jobNo"]] = [];
                                    }
                                    this.partsGroupedByJobNumber[singleItemInfo["jobNo"]].push({
                                        partComboName: itemName,
                                        ...singleItemInfo,
                                    });

                                    // Add manager name to the managers array
                                    if (!this.managerInfo[singleItemInfo["manager"]]) {
                                        this.managerInfo[singleItemInfo["manager"]] = [];
                                    }
                                    if (
                                        !this.managerInfo[singleItemInfo["manager"]].includes(
                                            singleItemInfo["jobNo"]
                                        )
                                    ) {
                                        this.managerInfo[singleItemInfo["manager"]].push(
                                            singleItemInfo["jobNo"]
                                        );
                                    }
                                }
                            }
                        }

                        this.jobNumbers = [
                            ...new Set(Object.keys(this.partsGroupedByJobNumber)),
                        ];
                        // this.kitNames = [
                        //   ...new Set(Object.keys(this.partsGroupedByKit)),
                        // ];
                        // console.log(this.managerInfo);
                        this.managers = Object.keys(this.managerInfo).sort();
                    }
                });
        },
        submitForm() {
            switch (this.transmittalMode) {
                case "switchToJob":
                    this.transmittalModeInfo = this.newJobInfo;
                    break;
                case "switchToStock":
                    this.transmittalModeInfo = this.selectedNewStockInfo;
                    break;
                default:
                    this.transmittalModeInfo = {};
                    break;
            }

            this.loading = true;
            this.formSubmitting = true;

            TransmittalFormService
                .submitForm(this.form)
                .then((response) => {

                    this.message = get(response, 'data.message', {
                        type: 'success',
                        text: 'Form Received.',
                        buttonText: 'New Transmittal Form'
                    });

                    const results = response.data;
                    this.serverResponseMessage = results.response;
                    this.loading = false;
                    this.showForm = false;
                })
                .catch((err) => {
                    const messages = get(err,'response.data.messages');

                    if(isArray(messages)) {
                        each(messages, (message) => {
                            this.$toast.error(message.message);
                        })
                    } else {
                        this.$toast.error('Error processing transmittal form.');
                    }
                })
                .finally(() => {
                    this.formSubmitting = false;
                    this.loading = false;
                });
        },
        resetForm() {
            window.location.reload();
            /* this.loadingImport = true;
            this.fetchDatabase();
            this.selectedManager = "";
            this.selectedJobNumber = "";
            this.selectedPoNumber = "";
            this.showForm = true; */
        },
        partQuantityRule2(part, partIndex) {

            if (part.partQuantity === "")
                return ["This is a required field"];

            if (parseFloat(part.partQuantity) < 0 || isNaN(part.partQuantity))
                return ["Quantity can't be a negative number"];

            if (part.partQuantity > this.availableParts[partIndex].partQuantity)
                return ["Quantity not allowed"];

            return [true];
        },
        partQuantityLabel(part) {
            return part.partQuantity + " available";
        },
        partByLocationQuantityRule(part) {

            if (part.partQuantity === "")
                return ["This is a required field"];

            if (parseFloat(part.partQuantity) < 0 || isNaN(part.partQuantity))
                return ["Quantity can't be a negative number"];

            if (part.partQuantity > part.locationQuantityLimit)
                return ["Quantity not allowed"];

            return [true];
        },
        partByLocationLabel(part) {
            return part.locationQuantityLimit + " available";
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        resetSelectedParts() {
            // Reset `selectedParts` to the all of the parts of a selected job number
            this.availableParts = this.itemsOfSelectedJob;
            this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));
            for (let sp in this.selectedParts) {
                this.selectedParts[sp].partQuantity = 0;
            }
        },
        getJobInfo() {
            if (!this.selectedNewJobNumber) return;

            this.jobLookupLoading = true;

            TransmittalFormService.getJobInfo({
                jobNumber: this.selectedNewJobNumber.toString(),
            })
                .then(response => {
                        console.log("resp", response);
                        console.log(
                            `Job's Info lookup successful for job: ${this.selectedNewJobNumber}!`
                        );

                        const results = response.data;

                        this.serverResponseMessage = results.message;
                        this.serverResponseStatus = results.status;
                        this.jobLookupLoading = false;

                        if (results.status !== "success") {

                            this.alert = {
                                type: "error",
                                message: results.message,
                                show: true,
                            };

                            this.newJobInfo = {};
                            this.formValid = this.$refs.form.validate();

                        } else {

                           // if(results['__jobs_count'] === 1) {
                                this.alert = {show: false};
                                this.newJobInfo = results["jobInfoForTransmittalForm"];
                                this.jobCount = get(results, '_jobs_count', 0);
                                this.formValid = this.$refs.form.validate();
                                this.jobs = get(results, '__jobs', []);
                                console.log(this.jobs);

                                if(this.jobCount > 1) {
                                    this.duplicateJobNumberDialog = true;
                                    this.duplicateJobNumber = this.selectedNewJobNumber.toString();
                                }
                            /*
                            } else {
                                console.log('more than 1 job', results);
                                this.newJobs = results['__jobs'];
                            }
                            */
                        }
                    }
                )
                .finally((err) => {
                    console.log(err);
                    this.jobLookupLoading = false;
                });
        },
    },
}
;
</script>