<template>
    <div>
        <v-autocomplete
                outlined
                v-on="$listeners"
                v-bind="$attrs"
                :items="jobs"
                :filter="searchFilter"
                prepend-inner-icon="mdi-briefcase-search"
                item-text="job_number"
                item-value="id"
                label="Job Number"
                return-object
                clearable
                hide-details="auto"
        >
            <template v-slot:selection="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.job_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.company">
                        <i>{{ data.item.company.slug }}</i> -
                        <b>{{ data.item.description }}</b>
                        <span v-if="data.item.inventory_item_count !== undefined && !hideItemCount">
                           - {{data.item.inventory_item_count}} items
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.job_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle>

                        <span>
                            <i v-if="data.item.company">{{ data.item.company.slug }}</i> -
                            <b>{{ data.item.description }}</b>
                        </span>
                        <span v-if="data.item.inventory_item_count !== undefined && !hideItemCount">
                           - {{data.item.inventory_item_count}} items
                        </span>

                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <VDialog
                v-model="dialog"
                persistent
                max-width="600px"
        >
            <VCard>
                <VCardTitle>{{ duplicateJobItems.length }} Job Numbers Found for
                    {{ value ? value.job_number : '' }}
                </VCardTitle>
                <VCardSubtitle>Please select a job to continue</VCardSubtitle>
                <VCardText>

                    <VList two-line>
                        <VListItemGroup
                                v-model="selectedDuplicateJob"
                        >
                            <template v-for="job in duplicateJobItems">
                                <VListItem
                                        :key="job.id"
                                        :value="job"
                                >
                                    <template v-slot:default="{ active }">
                                        <VListItemContent>
                                            <VListItemTitle v-text="job.description"></VListItemTitle>
                                            <VListItemSubtitle v-if="job.company"
                                                               v-text="job.company.name"></VListItemSubtitle>
                                        </VListItemContent>
                                        <VListItemAction>
                                            <VIcon color="success" v-show="active">mdi-check-circle-outline</VIcon>
                                        </VListItemAction>
                                    </template>
                                </VListItem>
                            </template>
                        </VListItemGroup>
                    </VList>
                </VCardText>
                <VCardActions>
                    <VSpacer></VSpacer>
                    <VBtn
                            color="primary"
                            @click="duplicateJobDialogSelect"
                    >
                        Select
                    </VBtn>
                </VCardActions>
            </VCard>
        </VDialog>
    </div>
</template>

<script>
import {get} from "@/client/utilities/Object";

export default {
    name: "JobsAutocomplete",
    inheritAttrs: false,
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        jobs: {
            type: Array,
            required: true
        },
        hideItemCount : {
            type: Boolean,
            default: false
        }
    },
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        },
        dialog: function() {
            return false;
            if(this.jobs && this.value) {
                return this.jobs.filter(job => job.job_number === this.value.job_number).length > 1 && this.closeDialog === false;
            }

            this.closeDialog = false;

            return false;
        }
    },
    data: function () {
        return {
            duplicateJobItems: [],
            selectedDuplicateJob: null,
            closeDialog: false,
            companies: [],
            company: null,
        }
    },
    watch: {
        value: function (val) {
            if (val && val.job_count > 1 && !val.selected) {
                this.duplicateJobItems = this.jobs.filter(item => item.job_number === val.job_number);
            } else {
                this.duplicateJobItems = [];
            }
        },
    },
    methods: {
        searchFilter: function (item, queryText) {
            const fields = [
                get(item, 'job_number', ''),
                get(item, 'company.name', ''),
                get(item, 'company.slug', ''),
                get(item, 'description', '')
            ].join(' ').toLowerCase();

            return fields.includes(queryText.toLowerCase());
        },
        duplicateJobDialogSelect: function () {
            this.closeDialog = true;
            this.$emit('input', this.selectedDuplicateJob);
            this.selectedDuplicateJob = null;
            this.duplicateJobItems = [];
        }
    },
    async created() {

    }
}
</script>

<style scoped>

</style>