<script>
import {get} from "@/client/utilities/Object";

export default {
    name: 'ProjectManagerAutocomplete',
    methods: {get},
    inheritAttrs: false,
    props: ['items', 'loading', 'value', 'rules'],
}
</script>

<template>
    <v-autocomplete
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            v-bind:value="value"
            v-bind:items="items"
            v-bind:rules="rules"
            v-bind:loading="loading"
            prepend-inner-icon="mdi-account-supervisor-outline"
            item-text="name"
            item-value="id"
            label="Project Manager"
            return-object
            clearable
            hide-details="auto"
    >
        <template v-slot:selection="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ data.item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip v-if="data.item.company" label x-small
                            :class="`px-1 pm-chip ${get(data, 'item.company.slug')}`">
                        {{ get(data, 'item.company.slug') }}
                    </v-chip>
                    {{ data.item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>

    </v-autocomplete>
</template>

<style>
.pm-chip.RTE {
    background-color: #722323FF !important;
}

.pm-chip.P1 {
    background-color: #1E3A5DFF !important;
}
</style>