export default {
    name: 'LoadingDialog',
    inheritAttrs: false,
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    methods: {
        goBack() {
            window.history.go(-1);
        }
    },
    async mounted() {
    }
};
