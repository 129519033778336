<template>
    <span>
        <span v-if="partNumber && partNumber.length > 0">
            <span class="grey--text">
                Part #
            </span>
            <span>
                <b>{{ partNumber }}</b>
            </span>
            <br>
        </span>
        <span class="">
            {{ partDescription }}
        </span>
    </span>
</template>

<script>
export default {
    name: "PartName",
    inheritAttrs: false,
    props: ['partNumber', 'partDescription']
}
</script>

<style scoped>

</style>