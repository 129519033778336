<script>
import debounce from "@/client/utilities/Debounce";

export default {
    name: 'EmployeeAutocomplete',
    data() {
        return {
            loading: false,
            selected: null,
            search: '',
            employees: [],
        };
    },
    watch: {
        search() {
            this.debounceFetchEmployees();
        }
    },
    methods: {
        onChange() {
        },
        async fetchEmployees() {
            this.loading = true;
            const response = await this.$http.get('/v3/employees', {params: {search: this.search}});
            this.employees = response.data.employees;
            this.loading = false;
        },
        debounceFetchEmployees: debounce(function () {
            this.fetchEmployees()
        }, 300),
    }
}
</script>

<template>
    <v-combobox
        ref="employeeAutocomplete"
        label="Select Employee"
        prepend-inner-icon="mdi-account"
        outlined
        v-on="$listeners"
        v-bind="$attrs"
        :items="employees"
        item-text="name"
        item-value="id"
        return-object
        hide-details
        single-line
        no-filter
        :loading="loading"
        :search-input.sync="search"
        clearable
        @focus="fetchEmployees"
        @change="onChange"
    />
</template>

<style scoped>

</style>