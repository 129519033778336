const Cache = {
    _ttl: null,
    ttl(seconds) {
        this._ttl = seconds;
        return this;
    },
    ttlDays(days) {
        return this.ttl(86400 * days);
    },
    store(key, value) {
        try {
            window.localStorage.setItem(key, this.encode(key, value));
            return true;
        } catch (e) {
            console.error(`[Cache] Error storing ${key}`, e);
        }

        this._ttl = null;

        return false;
    },
    async get(key, retrieveValueCallback = null) {
        return new Promise(async (resolve, reject) => {
            console.log(`[Cache] Retrieving ${key} from cache`);

            let value = this.decode(window.localStorage.getItem(key));

            if (!value && retrieveValueCallback) {
                value = await retrieveValueCallback();
                if (value) {
                    this.store(key, value);
                }
            }

            resolve(value);
        });
    },
    remove(key) {
        window.localStorage.removeItem(key);
    },
    exists(key) {
        return window.localStorage.getItem(key) !== null;
    },
    decode(value) {
        if (!value) {
            return null;
        }

        const decoded = JSON.parse(value);
        if (decoded.expires_at < new Date().getTime()) {
            this.remove(decoded.key);
            return null;
        }

        return decoded.value;
    },
    encode(key, value, ttl) {
        return JSON.stringify({
            value: value,
            key: key,
            ttl: ttl,
            expires_at: new Date().getTime() + (this._ttl * 1000)
        });
    }
};

export default Cache;