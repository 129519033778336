<script>
import {get} from "@/client/utilities/Object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";

export default {
    name: "CertificationDocumentManagerListItem",
    components: {ConfirmDialog},
    inheritAttrs: false,
    props: {
        document: {
            type: Object,
            required: true
        },
        deleted: {
            type: Function,
            default: (response) => {}
        },
        failed: {
            type: Function,
            default: (error) => {}
        }
    },
    data() {
        return {
            deleting: false
        };
    },
    methods: {
        remove(document) {
            let id = get(document, 'id');
            if(id) {
                this.deleting = true;
                setTimeout(() => {
                    this.$http.delete('/v3/tools/certifications/documents/' + id).then(response => {
                        this.$emit('deleted');
                    }).catch((error) => {
                        this.$emit('failed');
                    }).finally(() => {
                        this.deleting = false;
                    });
                }, 1000);
            }
        }
    }
}
</script>

<template>
    <tr>
        <td class="py-3">
            {{ document.id }}
        </td>
        <td>
            {{ document.original_filename }}
        </td>
        <td>
            {{document.created_at | date}}
        </td>
        <td class="text-right">
            <v-btn
                    class="mr-6"
                    color="blue-grey darken-2"
                    small
                    :href="document.url"
                    target="_blank"
            >
                Open
                <v-icon small right>mdi-open-in-new</v-icon>
            </v-btn>
            <ConfirmDialog
                    :title="`Delete Document ${document.id}?`"
                    :message="`Are you sure you want to delete ${document.original_filename}?`"
                    @confirm="remove(document)"
                    :loading="deleting"
                    color="delete"
                    small
            >
                <VIcon small>
                    mdi-delete
                </VIcon>
            </ConfirmDialog>
        </td>
    </tr>
</template>

<style scoped>

</style>