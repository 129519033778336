<script>
import PageTitle from "@/components/PageTitle.vue";
import PurchaseOrdersAutocomplete from "@/components/V2/PurchaseOrdersAutocomplete.vue";
import {each} from "@/client/utilities/Each";
import {get} from "@/client/utilities/Object";
import TextInput from "@/components/TextInput.vue";
import FormFieldContainer from "@/components/V2/FormFieldContainer.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import Axios from "axios";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import {LOG_STYLES} from "@/common/log";
import cloneObject from "@/common/clone-object";

export default {
    title: 'New Receiving Request',
    logContext: 'ReceivingRequest',
    logStyle: LOG_STYLES.GREEN,
    components: {
        PhotoManager,
        PhotoThumbnail,
        DividingHeader,
        FormSubmittedMessage, FormFieldContainer, TextInput, PurchaseOrdersAutocomplete, PageTitle
    },
    data() {
        return {
            photoModal: false,
            photoExpansionPanel: 0,
            formSubmitted: false,
            isEditable: true,
            viewPhotoDialog: {
                show: false,
                photo: null,
            },
            request: null,
            steps: {
                1: {
                    valid: false,
                    step: 1,
                    name: "Select PO Number",
                    isValid: () => {
                        return (this.form.purchase_order && this.form.purchase_order.id) > 0;
                    }
                },
                2: {
                    valid: false,
                    step: 2,
                    name: "Upload Packing Slip Photo",
                    isValid: () => {
                        return (this.form.packing_slip_photos.length > 0 && this.form.packing_slip_photos[0].url.length > 0) === true;
                    }
                },
                3: {
                    valid: false,
                    step: 3,
                    name: "Select Items & Quantities",
                    isValid: () => {
                        let total_quantity = 0;
                        let valid = true;

                        each(this.form.line_items, (item) => {
                            if (!item.deleted && (isNaN(parseFloat(item.received_quantity)) || item.received_quantity > item.quantity)) {
                                valid = false;
                            }

                            total_quantity += item.received_quantity;
                        });

                        return valid && total_quantity > 0;
                    }
                },
                4: {
                    valid: false,
                    step: 4,
                    name: "Add Item Photos",
                    isValid: () => {
                        let valid = true;

                        each(this.form.line_items, (item) => {
                            if (!item.deleted && item.received_quantity > 0 && item.photos.length === 0) {
                                valid = false;
                            }
                        });

                        return valid;
                    }
                }
            },
            step: 1,
            form: {
                loading: false,
                purchase_order: null,
                line_items: [],
                packing_slip_photo: {
                    loading: false,
                    photo: null,
                    _photo: null
                },
                packing_slip_photos: [],
                submitting: false,
                submitted: false
            },
            originalForm: null,
            data: {
                purchase_orders: [],
                line_items: []
            },
            rules: {
                required: v => !!v || "This is a required field",
                photos: (item) => {
                    if (!item.url) {
                        return false;
                    }
                    return true;
                }
            },
            singleExpand: false,
            uploadedPhotos: []
        }
    },
    watch: {},
    computed: {
        jobName() {
            return get(this.form, 'purchase_order.purchase_order_job.description');
        },
        poNumber() {
            return get(this.form, 'purchase_order.po_number');
        },
        purchaseOrderId() {
            return get(this.form, 'purchase_order.id');
        },
        packingSlipThumbnailUrl() {
            let photo = this.form.packing_slip_photo.photo;
            return get(photo, 'thumbnails.0.url');
        },
        currentUrl() {
            return window.location.href;
        },
        requiredPhotosCount() {
            let count = 0;

            for (const item of this.form.line_items) {
                if (item.photos.length === 0 && item.received_quantity > 0) {
                    count++;
                }
            }

            return count;
        }
    },
    methods: {
        get,
        poImported(failed) {
            if (!failed) {
                this.fetchPurchaseOrders();
            }
        },
        selectPurchaseOrder() {
            let po = this.form.purchase_order;
            this.form = Object.assign({}, this.originalForm);
            this.form.purchase_order = po;
            this.fetchLineItems(this.form.purchase_order);
            this.changeStep(2);
        },
        async updateFormRequest(data = null, status = null) {
            let uuid = this.$route.params.uuid;

            if (!data) {
                data = this.form;
            }

            let url = `/v2/forms/requests/receiving/${uuid}`;

            if (status) {
                url += `/${status}`;
            }

            return this.$http.post(url, data)
                .then(response => {
                    this.request = response.data;
                    return response.data;
                })
        },
        changeStep(step) {
            if (this.stepValid(step - 1)) {
                this.updateFormRequest(this.form);
                this.step = step;
            }

            if (step === 4) {
                let nextPhotoIdx = 0;
                each(this.data.line_items, (item, idx) => {
                    if (item.received_quantity > 0 && item.photos.length > 0) {
                        nextPhotoIdx++;
                    }
                });

                this.photoExpansionPanel = nextPhotoIdx;
            }
        },
        validate(step) {
            if (this.stepValid(step)) {
                this.step = step + 1;
            }
        },
        stepValid(stepNumber) {

            let step = get(this.steps, stepNumber);

            if (step) {
                return step.isValid();
            }
        },
        stepEditable(stepNumber) {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            });

            return valid;
        },
        formValid() {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (!step.isValid()) {
                    valid = false;
                }
            });

            return valid && !this.form.submitting;
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/receiving/' + uuid).then(response => {
                    this.request = response.data;

                    if (this.request.status && this.request.status.slug !== 'draft') {
                        this.$toast.error('Only drafts may be modified.');
                    }
                });
            } else {
                this.$toast.error('No Form Request Found');
                await this.$router.push('/');
            }
        },
        async fetchLineItems(po) {
            const purchase_order_id = get(po, 'id');

            return this.$http.get('/v2/forms/receive/line-items', {params: {purchase_order_id: purchase_order_id}})
                .then(response => {
                    let qtys = {};

                    each(get(response, 'data.line_items', []), (item) => {
                        qtys[item.id] = item.received_quantity;
                    });

                    this.data.line_items = cloneObject(get(response, 'data.line_items', []).map(item => {
                        if (item.photos) {
                            item.photos = item.photos.map(photo => {
                                let thumbnails = {};
                                each(photo.thumbnails, (thumbnail, idx) => {
                                    thumbnails[thumbnail.width] = thumbnail;
                                })
                                photo.thumbnails = thumbnails;
                                return photo;
                            });
                        }

                        let photo = get(item, 'photos.0');
                        let thumbnail = get(photo, 'thumbnails.0');

                        item.photo = null;
                        item.photo_loading = false;

                        item.image_url = get(photo, 'url', '');
                        console.log(item.image_url, photo);
                        item.thumbnail_url = get(thumbnail, 'url', '');

                        item.select_photo = item.image_url.length <= 0;

                        item.deleted = false;
                        item.exclude = false;
                        return item;
                    }));

                    this.data.part_quantities = get(response, 'data.part_quantities', {});
                    this.data.part_numbers = get(response, 'data.part_numbers', {});

                    this.form.line_items = Object.assign([], this.data.line_items).map(item => {
                        let li = get(this.request, 'data.line_items.' + item.id, null);

                        if (li) {
                            item.received_quantity = li.received_quantity;
                        }

                        return item;
                    });
                });
        },
        showPhotos() {
            this.photoModal = true;
        },
        async fetchPurchaseOrders() {
            this.form.loading = true;

            return this.$http.get('/v2/forms/receive/data').then(response => {
                this.data.purchase_orders = get(response, 'data.purchase_orders');
            }).finally(() => {
                this.form.loading = false;
            });
        },
        async createReceivingRequest() {
            return this.$http.post('/v2/receiving/request', this.form).then(response => {

            });
        },
        async submitForm() {
            if (this.stepValid(3)) {
                if (!this.form.submitting) {
                    this.form.submitting = true;

                    this.form.line_items = this.form.line_items.filter(item => {
                        return item.deleted !== true && item.received_quantity > 0;
                    });

                    this.updateFormRequest(this.form, 'submitted').then(response => {
                        this.form.submitted = true;
                        this.formSubmitted = true;
                    }).finally(() => {
                        this.form.submitting = false;
                    });
                }
            }
        },
        onPhotoSelected(photo) {

        },
        onPhotoUploaded(photo) {
            this.uploadedPhotos.push({
                photo: photo.photo,
                src: get(photo, 'thumbnail.url')
            });

            this.updateFormRequest();
            this.photoExpansionPanel++;
        },
        resetForm() {
            Axios.get('/v2/forms/requests/receiving/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if (uuid) {
                    this.$router.push({path: '/requests/receiving/' + uuid});
                }
            });
        },
        usePhoto(p) {
        },
        redirectToApprovalRequests() {
            this.$router.push('/approval/requests');
        }
    },
    async mounted() {

        this.originalForm = Object.assign({}, this.form);
        await this.fetchFormRequest();
        let packing_slip_photos = get(this.request, 'data.packing_slip_photos', []);

        if (this.request.status_id > 1) {
            this.isEditable = false;
        } else {

            await this.fetchPurchaseOrders();

            let step = 1;
            let expansionPanelIndex = 0;

            let has_photos = false;
            let po = get(this.request, 'data.purchase_order');
            let line_items = get(this.request, 'data.line_items', null);

            if (line_items) {

                let items = [];

                each(line_items, (item, idx) => {
                    items[item.id] = item;

                    if (item.photos && item.photos.length > 0 && item.received_quantity > 0) {
                        has_photos = true;
                        expansionPanelIndex++;

                        each(item.photos, (photo) => {
                            const url = get(photo, 'thumbnail.url');

                            if (url) {
                                this.uploadedPhotos.push({
                                    photo: photo,
                                    src: url
                                });
                            }
                        });
                    }
                });

                this.request.data.line_items = items;
            }

            if (po) {
                this.form.purchase_order = po;
                await this.fetchLineItems(po);

                if (this.stepEditable(2)) {
                    step = 2;
                }
            }

            if (packing_slip_photos.length > 0) {
                this.form.packing_slip_photos = packing_slip_photos;

                if (this.stepEditable(3)) {
                    step = 3;
                }
            }

            if (has_photos) {
                step = 4;
                this.photoExpansionPanel = expansionPanelIndex;
            }

            this.step = step;

            let item_id = get(this.$route, 'query.item_id');

            if (item_id) {
                this.photoExpansionPanel = parseInt(item_id);
            }
        }
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>
            Receive Form Request
        </PageTitle>

        <FormSubmittedMessage
                v-show="formSubmitted"
                title="Form Request Submitted"
                button-text="New Receiving Request"
                :on-click="resetForm"
        >
            Form Request Has Been Submitted
        </FormSubmittedMessage>

        <FormSubmittedMessage
                type="error"
                v-show="!isEditable"
                button-text="View Approval Requests"
                :on-click="redirectToApprovalRequests"
        >
            Request Already Submitted. Only drafts may be modified.
        </FormSubmittedMessage>

        <div
                v-show="!formSubmitted && isEditable"
        >
            <VSheet class="mb-2 pa-2 text-subtitle-2 grey--text" v-if="false">
                <VRow>
                    <VCol>
                        Request ID
                    </VCol>
                    <VCol>
                        {{ request.uuid }}
                    </VCol>
                    <VCol>
                        <VChip
                                :color="get(request,'status.background_color')"
                                label
                        >
                            <VIcon left>
                                {{ get(request, 'status.icon') }}
                            </VIcon>

                            {{ get(request, 'status.name') }}
                        </VChip>
                    </VCol>
                </VRow>
            </VSheet>

            <VStepper v-model="step"
                      vertical
                      non-linear
            >
                <!-- step 1 -->
                <VStepperStep
                        step="1"
                        :complete="step > 1"
                        :rules="[v => stepValid(1)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >

                    <div
                            v-if="this.form.purchase_order"
                            key="1"
                    >
                        <div
                                class="font-italic grey--text my-1"
                        >
                            {{ this.poNumber }}
                        </div>
                        <div>
                            {{ this.jobName }}
                        </div>


                    </div>
                    <div v-else key="2">
                        Select PO Number
                    </div>

                    <small v-if="!stepValid(1)">
                        Please select a purchase order
                    </small>
                </VStepperStep>
                <VStepperContent step="1">

                    <VCard
                            class="pa-4 mb-10"
                            color="grey darken-3"
                    >
                        <PurchaseOrdersAutocomplete
                                v-model="form.purchase_order"
                                :items="data.purchase_orders"
                                :loading="form.loading"
                                :po-imported="poImported"
                                :pos="data.purchase_orders"
                                :rules="[rules.required]"
                                @change="selectPurchaseOrder"
                        ></PurchaseOrdersAutocomplete>
                    </VCard>

                    <VBtn
                            color="primary"
                            @click="changeStep(2)"
                            :disabled="!stepValid(1)"
                    >
                        Continue
                    </VBtn>
                </VStepperContent>
                <!-- end step 1 -->

                <!-- step 2 -->
                <VStepperStep
                        step="2"
                        :complete="step > 2"
                        :rules="[v => stepValid(2)]"
                        :editable="stepEditable(2)"
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    <VRow
                            v-if="form.packing_slip_photo.photo"
                            key="ps_1"
                            align="center"
                            no-gutters
                    >
                        <VCol>
                            <VAvatar
                                    tile
                                    size="36px"
                                    class="mr-4"
                            >
                                <img
                                        alt="Packing Slip Photo"
                                        :src="packingSlipThumbnailUrl"
                                >
                            </VAvatar>
                            Packing Slip Photo
                        </VCol>
                        <VCol>

                        </VCol>
                    </VRow>
                    <VRow
                            v-else
                            key="ps_2"
                    >
                        <VCol>
                            Upload Packing Slip Photo
                        </VCol>
                    </VRow>
                    <small class="pt-1" v-if="!stepValid(2)">Add Packing slip photos</small>
                </VStepperStep>
                <VStepperContent step="2">
                    <VCard
                            class="pa-4 mb-10"
                            color="grey darken-3"
                    >
                        <PhotoManager
                                :photos="form.packing_slip_photos"
                                category="packing-slip"
                                :purchase-order-id="purchaseOrderId"
                                app-notification-title="Upload Packing Slip Photo"
                                :app-notification-content="`Upload Packing Slip Photo for ${poNumber}`"
                                :app-notification-url="currentUrl"
                                @uploaded="onPhotoUploaded"
                        />
                    </VCard>

                    <VBtn
                            color="primary"
                            @click="changeStep(3)"
                            :disabled="!stepValid(2)"
                    >
                        Continue
                    </VBtn>
                </VStepperContent>
                <!-- end step 2 -->

                <!-- step 3 -->
                <VStepperStep
                        step="3"
                        :complete="step > 3"
                        :rules="[v => stepValid(3)]"
                        :editable="stepEditable(3)"
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    Select Item & Quantity

                    <small class=pt-1 v-if="!stepValid(3)">
                        Please enter valid quantities
                    </small>

                </VStepperStep>
                <VStepperContent step="3">
                    <VDataIterator
                            :items-per-page="-1"
                            :items="form.line_items"
                            item-key="id"
                            :single-expand="singleExpand"
                    >
                        <template v-slot:default="{items}">
                            <VRow class="grey--text font-weight-bold pb-3">
                                <VCol cols="12" lg="6" md="6" sm="6">
                                    Part
                                </VCol>
                                <VCol cols="12" lg="4" md="4" sm="4">
                                    Quantity
                                </VCol>
                                <VCol cols="12" lg="2" md="2" sm="2">
                                    Actions
                                </VCol>
                            </VRow>

                            <VRow
                                    v-for="item in items"
                                    :key="item.id"
                                    no-gutters
                                    class="py-2 my-4"
                                    :class="{excluded: item.deleted}"
                                    style="border-bottom:1px solid #666;"
                            >
                                <VCol cols="12" lg="6" md="6" sm="6" class="py-2">
                                 <span
                                         v-show="item.part_number.length > 0"
                                         class="grey--text"
                                 >
                                    Part #
                                </span>
                                    <span v-show="item.part_number.length > 0">
                                    <b>{{ item.part_number }}</b>
                                </span>

                                    <br>

                                    <span class="grey--text">
                                    {{ item.part_description }}
                                    </span>
                                </VCol>
                                <VCol cols="12" lg="4" md="4" sm="4" class="py-2 px-2">
                                    <VTextField
                                            outlined
                                            :disabled="item.deleted"
                                            hide-details
                                            type="number"
                                            @change="updateFormRequest()"
                                            v-model="item.received_quantity"
                                            :rules="[v => (v <= item.quantity && v >= 0) || `Maximum quantity is  ${item.received_quantity}`]"
                                            :label="`${item.quantity} available`"
                                    >

                                    </VTextField>
                                </VCol>
                                <VCol
                                        cols="12" lg="2" md="2" sm="2"
                                        class="py-2 px-2"
                                >
                                    <VTooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <VBtn
                                                    :color="(item.deleted ? 'primary' : 'error')"
                                                    @click="item.deleted = !item.deleted; item.received_quantity = (item.deleted ? 0 : item.quantity)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                <VIcon
                                                        v-text="(item.deleted ? 'mdi-plus' : 'mdi-cancel')"
                                                />
                                            </VBtn>
                                        </template>
                                        <span v-text="(item.deleted ? 'Include Item' : 'Exclude Item' )"></span>
                                    </VTooltip>

                                </VCol>
                            </VRow>

                        </template>
                    </VDataIterator>

                    <VBtn
                            color="primary"
                            @click="changeStep(4)"
                            :disabled="!stepValid(3)"
                    >
                        Continue
                    </VBtn>

                    <VBtn
                            text
                            @click="step = 2"
                    >
                        Back
                    </VBtn>
                </VStepperContent>
                <!-- end step 3 -->

                <!-- step 4 -->
                <VStepperStep
                        step="4"
                        :complete="step > 4"
                        :rules="[v => stepValid(4)]"
                        :editable="stepEditable(4)"
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                >
                    Add Photos

                    <div class="pt-2 font-italic font-weight-thin" v-if="!stepValid(4)">
                        {{ requiredPhotosCount }} items still require photos.
                    </div>

                </VStepperStep>

                <VStepperContent step="4">

                    <VRow>
                        <VCol>
                            <VExpansionPanels
                                    v-model="photoExpansionPanel"
                            >
                                <VExpansionPanel
                                        v-for="(item, idx) in data.line_items"
                                        :key="`${item.id}-photos`"
                                        v-if="item.received_quantity > 0"
                                >
                                    <VExpansionPanelHeader
                                            disable-icon-rotate
                                    >
                                        <template v-slot:default="{ open }">
                                            <VRow align-content="center" align="center" dense no-gutters>
                                                <VCol cols="1">
                                                    <VBadge
                                                            bordered
                                                            overlap
                                                            :color="(item.photos.length > 0 ? 'accent' : 'warning')"
                                                            :content="item.photos.length > 0 ? item.photos.length : '!'"
                                                    >
                                                        <VAvatar size="30" color="error">
                                                            <img :src="get(item, 'photos[0].thumbnail.url')"
                                                                 v-show="item.photos.length > 0" alt="none">
                                                            <span v-show="item.photos.length === 0">?</span>
                                                        </VAvatar>
                                                    </VBadge>
                                                </VCol>
                                                <VCol cols="1">
                                                    <VBtn
                                                            small
                                                            icon
                                                            color="green"
                                                            @click="showPhotos"
                                                    >
                                                        <VIcon left>mdi-upload</VIcon>
                                                    </VBtn>
                                                </VCol>
                                                <VCol class="align-content-center">
                                                    ({{ item.received_quantity }})
                                                    <b class="mr-3" v-show="item.part_number.length > 0">
                                                        {{ item.part_number }}
                                                    </b>
                                                    <span class="font-weight-thin">{{ item.part_description }}</span>
                                                </VCol>
                                            </VRow>
                                        </template>

                                        <template v-slot:actions>
                                    <span
                                            v-if="item.photos.length < 1" key="0"
                                            class="error--text"
                                    >
                                        Needs Photos
                                        <VIcon color="error">mdi-alert-octagon</VIcon>
                                    </span>
                                            <small class="success--text" v-else key="1">
                                                <VIcon small right color="success">mdi-check</VIcon>
                                            </small>

                                        </template>
                                    </VExpansionPanelHeader>
                                    <VExpansionPanelContent>

                                        <PhotoManager
                                                category="receiving"
                                                :photos="item.photos"
                                                app-notification-title="Upload Item Photo"
                                                :app-notification-content="`Upload photo for ${item.part_number} ${item.part_description}`"
                                                :app-notification-url="currentUrl + '?item_id=' + idx"
                                                :purchase-order-id="purchaseOrderId"
                                                :purchase-order-line-item-id="item.id"
                                                @uploaded="onPhotoUploaded"
                                                @selected="onPhotoSelected"
                                        />

                                    </VExpansionPanelContent>
                                </VExpansionPanel>
                            </VExpansionPanels>
                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol>
                            <VBtn
                                    class="mt-8"
                                    color="primary"
                                    :disabled="formValid() === false"
                                    @click="submitForm"
                            >
                                Submit
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>
            </VStepper>
        </div>
        <VDialog scrollable v-model="photoModal">
            <VCard>
                <VCardTitle>Uploaded Photos</VCardTitle>
                <VCardText>
                    <VRow v-for="(p, idx) in uploadedPhotos" :key="idx" class="mb-1">
                        <VCol cols="1" class="grey darken-3 ma-3 text-center">
                            <VImg class="ma-auto" contain width="150" eager :src="p.src" alt="none"></VImg>
                            <VBtn class="mt-3 mx-auto" @click="usePhoto(p)" color="primary">Use Photo</VBtn>
                        </VCol>
                    </VRow>
                </VCardText>
                <VCardActions>
                    <VBtn text @click="photoModal = false;">Close</VBtn>
                </VCardActions>
            </VCard>
        </VDialog>
    </VContainer>
</template>

<style scoped>
.row.excluded {
    text-decoration: line-through;
    color: red;
    font-style: italic;
}
</style>