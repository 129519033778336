export default {
    name: "ConfirmDialog",
    inheritAttrs: true,
    props: {
        message: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: 'Confirm Action'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            confirmDialog: false
        };
    },
    methods: {
        doConfirm() {
            this.$emit("confirm");
            this.confirmDialog = false;
        },
        doCancel() {
            this.$emit("cancel");
            this.confirmDialog = false;
        }
    }
};
