import {UserService} from "../common/api/api.service";

const _state = {
    authenticated: false,
    user:  null,
    errors: {}
};

const mutations = {
    setAuth(state, user) {
        state.authenticated = true;
        state.user = user;
        state.errors = {};
    },
    clearAuth(state) {
        state.authenticated = false;
        state.user = {};
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};
const actions = {
    authenticate(context) {
        return UserService.getUser().then(response => {
            console.log('authed', response);
            context.commit('setAuth', response.data.user);
        }).catch((response) => {
            console.log('not auth', response);
            context.commit('clearAuth');
            context.commit('setErrors', response.data);
        })
    }
};

const getters = {
    isAuthenticated(state) {
        return state.authenticated;
    }
};
export default {
    state: _state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
