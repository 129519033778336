import vehicleApiMixin from "@/mixins/Api/vehicle-api.mixin";
import { get } from "@/client/utilities/Object";
export default {
    name: 'VehicleAutocomplete',
    inheritAttrs: false,
    props: [],
    mixins: [vehicleApiMixin],
    data() {
        return {
            vehicles: [],
            loading: false,
        };
    },
    methods: {
        get,
        async onFocus() {
            this.vehicles = await this.fetchVehicles();
        }
    }
};
