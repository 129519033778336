<template>
    <VContainer>

        <PageTitle>
            Receive Form

            <VBtn
                    class="float-right d-none d-sm-flex"
                    color="accent"
                    href="#/v1/receive-form"
                    small
            >
                Old Receive Form
            </VBtn>

        </PageTitle>

        <VForm
                v-if="!form.submitted"
                ref="form"
                v-model="form.valid"
        >
            <FormFieldContainer>
                <VRow>
                    <VCol cols="12" lg="9" md="8" sm="7" xl="9" xs="12">
                        <PurchaseOrdersAutocomplete
                                v-model="form.po"
                                :items="data.purchase_orders"
                                :loading="form.loading"
                                :po-imported="poImported"
                                :pos="data.purchase_orders"
                                :rules="[rules.required]"
                        />
                    </VCol>
                    <VCol
                            cols="12"
                            lg="3"
                            md="4"
                            sm="5"
                            xl="3"
                            xs="12"
                    >
                        <VBtn
                                :disabled="!form.po"
                                block
                                color="primary"
                                small
                                @click="fetchInventoryItems"
                        >
                            View Job Inventory
                        </VBtn>
                    </VCol>
                </VRow>
                <VRow v-show="deliveryAlert.show">
                    <VCol>
                        <VAlert
                                :color="deliveryAlert.color"
                                :type="deliveryAlert.type"
                                close-text="Close Delivery Info"
                                dismissible
                                prominent
                        >
                            {{ deliveryAlert.message }}
                        </VAlert>
                    </VCol>
                </VRow>
            </FormFieldContainer>

            <VExpansionPanels v-show="form.po && data.inventory_items" v-model="showInventory">
                <VExpansionPanel>
                    <VExpansionPanelHeader>
                        This Jobs Items in Warehouse
                    </VExpansionPanelHeader>
                    <VExpansionPanelContent>
                        <VAlert
                                v-if="data.inventory_items && data.inventory_items.length === 0"
                                dense
                                icon="mdi-alert"
                                type="warning"
                        >
                            No inventory items for this job
                        </VAlert>
                        <VSimpleTable
                                v-if="data.inventory_items && data.inventory_items.length > 0"
                        >
                            <thead>
                            <tr>
                                <th>PO #</th>
                                <th>Job #</th>
                                <th>Part #</th>
                                <th>Part Description</th>
                                <th>Qty</th>
                                <th>Location</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="item in data.inventory_items"
                                    :key="item.id"
                            >
                                <td>{{ item.po_number }}</td>
                                <td>{{ item.job_number }}</td>
                                <td>{{ item.part_number }}</td>
                                <td>{{ item.part_description }}</td>
                                <td>{{ item.part_quantity }}</td>
                                <td>{{ item.location_key }}</td>
                            </tr>
                            </tbody>
                        </VSimpleTable>
                    </VExpansionPanelContent>
                </VExpansionPanel>
            </VExpansionPanels>

            <div v-show="form.po !== null">
                <FormFieldContainer
                        v-for="(location, locationIndex) in form.locations"
                        :key="locationIndex"
                >
                    <VRow>
                        <VCol class="text-lg-subtitle-1" cols="6">
                            Receiving Location {{ locationIndex + 1 }}
                            <VBtn
                                    v-show="form.locations.length > 1"
                                    class="ml-3"
                                    color="removeLocation"
                                    outlined
                                    small
                                    text
                                    @click="removeLocation(locationIndex)"
                            >
                                <VIcon left>mdi-map-marker-minus</VIcon>

                                Remove Location
                            </VBtn>
                        </VCol>
                        <VCol
                                class="text-right"
                                cols="6"
                        >
                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol cols="12" lg="3" md="3" sm="12">
                            <PackageTypeAutocomplete
                                    v-model="location.package_type"
                                    :items="data.package_types"
                                    :rules="[rules.required]"
                                    @change="location.rack = null;"
                            />
                        </VCol>
                        <VCol cols="12" lg="3" md="3" sm="4">
                            <RackAutocomplete
                                    v-model="location.rack"
                                    :disabled="!location.package_type"
                                    :package-type="location.package_type"
                                    :rules="[rules.required]"
                                    @change="location.section = null;"
                            />

                        </VCol>
                        <VCol cols="12" lg="3" md="3" sm="4">
                            <SectionAutocomplete
                                    v-model="location.section"
                                    :disabled="!location.rack"
                                    :rack="location.rack"
                                    :rules="[rules.required]"
                                    @change="location.sub_section = null"
                            />

                        </VCol>
                        <VCol cols="12" lg="3" md="3" sm="4">

                            <SubSectionAutocomplete
                                    v-model="location.sub_section"
                                    :disabled="!location.section"
                                    :rules="[rules.required]"
                                    :section="location.section"
                            />

                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol>
                            <VDivider/>
                        </VCol>
                    </VRow>
                    <VRow
                            v-for="(item, itemIndex) in location.line_items"
                            :key="itemIndex"
                            align="center"
                            justify="center"
                    >
                        <VCol cols="4" md="8">
                            <VSelect
                                    v-model="item.selected"
                                    :items="location.parts"
                                    :rules="[rules.required]"
                                    item-text="name"
                                    item-value="id"
                                    label="Part number"
                                    outlined
                                    required
                                    return-object
                            >
                                <template v-slot:item="data">
                                    <VListItemContent>
                                        <VListItemTitle>{{ data.item.part_number }}</VListItemTitle>
                                        <VListItemSubtitle>{{ data.item.part_description }}</VListItemSubtitle>
                                    </VListItemContent>
                                </template>
                            </VSelect>
                        </VCol>

                        <VCol cols="4" md="2">
                            <VTextField
                                    v-model="item.received_quantity"
                                    :label="quantityLabel(item)"
                                    :rules="[quantityLogic(item)]"
                                    outlined
                                    required
                                    type="number"
                            />
                        </VCol>

                        <VCol cols="4" md="2">

                            <VBtn
                                    class="mb-7"
                                    color="removeLocation"
                                    outlined
                                    small
                                    @click="location.line_items.splice(itemIndex, 1)"
                            >
                                <VIcon left>mdi-close</VIcon>
                                Remove
                            </VBtn>

                        </VCol>
                    </VRow>
                    <VRow>
                        <VCol class="text-center">
                            <v-btn
                                    class="mb-7"
                                    color="primary"
                                    medium
                                    outlined
                                    @click="addItem(locationIndex)"
                            >
                                <v-icon class="pr-1" left>mdi-plus</v-icon>
                                Add Part
                            </v-btn>
                        </VCol>
                    </VRow>
                </FormFieldContainer>


                <!-- Button to add more location -->
                <VRow
                        class="my-6 d-flex justify-space-around"
                >
                    <VCol class="d-flex justify-space-around">
                        <VBtn
                                color="addLocation"
                                @click="addLocation"
                        >
                            <v-icon left>mdi-map-marker-plus</v-icon>
                            Add Location
                        </VBtn>
                    </VCol>
                    <VCol class="d-flex justify-space-around">
                        <VBtn
                                :disabled="form.locations.length <= 1"
                                color="removeLocation"
                                @click="removeLocation(form.locations.length-1)"
                        >
                            <VIcon left>mdi-map-marker-minus</VIcon>
                            Remove Location
                        </VBtn>
                    </VCol>
                </VRow>

                <VDivider/>

                <FormSubmitButton
                        :submitting="form.submitting"
                        :valid="true"
                        class="mt-8"
                        @click="submitForm"
                />
            </div>
        </VForm>

        <FormSubmittedMessage
                v-if="form.submitted"
                :on-click="resetForm"
                button-text="New Receiving Form"
                type="success"
        >
            Order Submitted.
        </FormSubmittedMessage>

    </VContainer>
</template>

<script>
import {get} from "@/client/utilities/Object";
import DividingHeader from "../../components/DividingHeader.vue";
import FormSubmitButton from "../../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../../components/FormSubmittedMessage.vue";
import PageTitle from "../../components/PageTitle.vue";
import FormFieldContainer from "../../components/V2/FormFieldContainer.vue";
import PackageTypeAutocomplete from "../../components/V2/PackageTypeAutocomplete.vue";
import PurchaseOrdersAutocomplete from "../../components/V2/PurchaseOrdersAutocomplete.vue";
import RackAutocomplete from "../../components/V2/RackAutocomplete.vue";
import SectionAutocomplete from "../../components/V2/SectionAutocomplete.vue";
import SubSectionAutocomplete from "../../components/V2/SubSectionAutocomplete.vue";

export default {
    components: {
        FormSubmittedMessage,
        PurchaseOrdersAutocomplete,
        DividingHeader,
        FormSubmitButton,
        SubSectionAutocomplete,
        SectionAutocomplete, RackAutocomplete, PackageTypeAutocomplete, FormFieldContainer, PageTitle
    },
    title: "Receive Form",
    data() {
        return {
            data: {
                purchase_orders: [],
                line_items: [],
                available_line_items: [],
                part_numbers: [],
                line_item_headers: [
                    {text: 'Line #', value: 'line_item_number'},
                    {text: 'Part #', value: 'material'},
                    {text: 'Part Description', value: 'description'},
                    {text: 'Order Qty', value: 'original_units', sortable: false},
                    {text: 'Receive Qty', value: 'quantity', sortable: false},
                    {text: 'Actions', value: 'actions', sortable: false}
                ],
                package_types: [],
                racks: [],
                inventory_items: null,
                part_quantities: {}
            },
            form: {
                po: null,
                locations: [],
                submitted: false,
                submitting: false,
                loading: false,
                valid: false
            },
            rules: {
                required: v => !!v || "This is a required field",
            },
            currentLocation: 0,
            poSearchInput: null,
            location_skeleton: {
                package_type: null,
                rack: null,
                section: null,
                sub_section: null,
                line_items: [
                    {
                        name: "",
                        received_quantity: 0,
                        selected: {}
                    }
                ]
            },
            showInventory: false,
            importPoLoading: false,
            deliveryAlert: {
                show: false,
                type: undefined,
                color: undefined,
                message: "",
            }
        }
    },
    computed: {},
    watch: {
        'form.po'(po) {
            this.fetchLineItems().then(() => {
                this.form.locations = [];
                this.available_line_items = [];
                this.addLocation();
                this.form.locations[0].parts = JSON.parse(JSON.stringify(this.data.line_items));
                this.form.locations[0].line_items = JSON.parse(JSON.stringify(this.data.line_items));
                this.form.locations[0].line_items.map(item => {
                    item.selected = JSON.parse(JSON.stringify(item));
                    return item;
                });
                this.available_line_items = [];
                this.data.inventory_items = null;
            });

            if (po && po.expiration_date) {
                this.parseExpectedDelivery(po.expiration_date);
            } else {
                this.deliveryAlert.show = false;
            }
        }
    },
    methods: {
        fetchFormData() {
            this.form.loading = true;

            this.$http.get('/v2/forms/receive/data').then(response => {
                this.data.purchase_orders = get(response, 'data.purchase_orders');
            }).finally(() => {
                this.form.loading = false;
            });
        },
        fetchInventoryItems() {

            const params = {
                job_number: get(this.form.po, 'job_number'),
                company_id: get(this.form.po, 'company.id')
            }

            this.$http.get('/v2/forms/receive/inventory', {params: params}).then(response => {
                this.data.inventory_items = get(response, 'data.inventory_items', []);
                this.showInventory = 0;
            })
        },
        fetchLineItems() {
            const purchase_order_id = get(this.form.po, 'id');

            return this.$http.get('/v2/forms/receive/line-items', {params: {purchase_order_id: purchase_order_id}}).then(response => {
                this.data.line_items = get(response, 'data.line_items', []);
                this.data.part_quantities = get(response, 'data.part_quantities', {});
                this.data.part_numbers = get(response, 'data.part_numbers', {});
                this.$refs.form.validate();
            });
        },
        fetchLocations() {
            this.$http.get('/v2/forms/receive/locations').then(response => {
                this.data.package_types = get(response, 'data.package_types', []);
            });
        },
        submitForm() {
            if (this.$refs.form.validate(true)) {

                this.form.submitting = true;

                let data = JSON.parse(JSON.stringify(this.form));

                console.log(data);

                for (const idx of data.locations) {
                    let location = data.locations[idx];
                    location.package_type.racks = null;
                    location.rack.sections = null;
                    location.section.sub_sections = null;
                }

                /*
                each(data.locations, (location, index) => {
                    location.package_type.racks = null;
                    location.rack.sections = null;
                    location.section.sub_sections = null;
                });
                 */

                this.$http.post('/v2/forms/receive/submit', data).then(response => {
                    console.log(response);
                    this.form.submitted = true;
                }).catch((e) => {
                    const status = get(e, 'response.status');
                    const alerts = get(e, 'response.data.__alerts', []);

                    if (status >= 500 || alerts.length === 0) {
                        this.$toast.error('Error submitting form');
                    }
                })
                    .finally(() => {
                        this.form.submitting = false;
                    });
            }
        },
        resetForm() {
            window.location.reload();
        },
        addLocation() {
            const skeleton = JSON.parse(JSON.stringify(this.location_skeleton));
            skeleton.parts = JSON.parse(JSON.stringify(this.data.line_items));

            this.form.locations.push(skeleton);
            this.currentLocation++;
        },
        removeLocation(idx) {
            const _locations = this.form.locations;
            let locations = [];

            if (_locations.length <= 1) {
                this.$toast.warning('Cannot remove last location, there must be at least 1 location to receive items.');
                return false;
            }

            for (const idx of _locations) {
                locations.push(_locations[idx2]);
            }

            /*
            each(_locations, (location, idx2) => {
                if (idx !== idx2) {
                    locations.push(location);
                }
            })

             */

            this.form.locations = locations;
        },
        addItem(locationIndex) {
            this.form.locations[locationIndex].line_items.push({
                name: "",
                received_quantity: 0,
                selected: {}
            });
        },
        removeItem(item, location) {
            const idx = location.line_items.indexOf(item);
            location.line_items.splice(idx, 1);
        },
        poImported(failed) {
            if (!failed) {
                this.fetchFormData();
            }
        },
        quantityLabel(part) {
            let partName = get(part, 'selected.name');
            // Sometimes, there is a total of 0 for the quantity (some positive and some negative).
            if (this.data.part_quantities[partName] >= 0)
                return this.data.part_quantities[partName] + ' available.';

            return "Quantity";
        },
        quantityLogic(item) {
            console.log(item);
            let partName = get(item, 'selected.name');

            // Sum of all the amount for this part in all the locations
            let sum = 0;
            for (let location of this.form.locations) {
                if (location.line_items) {
                    for (let partInfo of location.line_items) {
                        if (partInfo.selected.name === partName) {
                            sum += parseFloat(partInfo.received_quantity);
                        }
                    }
                }
            }

            let quantityAllowed = parseFloat(this.data.part_quantities[partName] ?? 0);

            return sum <= quantityAllowed || `Total cannot exceed ${quantityAllowed}`;
        },
        parseExpectedDelivery(expectedDelivery) {
            // console.log(expectedDelivery);

            // Reset the delivery alert:
            this.deliveryAlert.color = undefined;
            this.deliveryAlert.type = undefined;
            // this.deliveryAlert.message = '';

            // an application may want to use UTC and make that visible
            // request a weekday along with a long date
            var options = {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "America/Los_Angeles",
            };
            // options.timeZoneName = 'short';

            let expectedDeliveryDate = new Date(expectedDelivery);
            let daysInFuture = this.getDaysHoursAndMinutes(expectedDeliveryDate);
            // console.log(daysInFuture);
            let prefix = "Expected Delivery: ";
            if (daysInFuture >= 0) {
                // console.log('HERE')
                let firstDayNextWeek = this.getNextWeekBegin();
                let isDeliveryDateNextWeek = expectedDeliveryDate >= firstDayNextWeek;
                // console.log(`Delivery date is next week? ${isDeliveryDateNextWeek}`)
                if (daysInFuture < 1) {
                    prefix += `TODAY`;
                    this.deliveryAlert.type = "error";
                } else if (daysInFuture == 1) {
                    prefix += `TOMORROW`;
                    this.deliveryAlert.type = "error";
                } else if (daysInFuture <= 3) {
                    prefix += `In ${daysInFuture} day(s)`;
                    this.deliveryAlert.type = "warning";
                } else {
                    this.deliveryAlert.type = "warning";
                }

                // If delivery date is next week, leave out the day of the week:
                if (isDeliveryDateNextWeek) {
                    delete options["weekday"];
                    this.deliveryAlert.type = "info";
                }
            } else {
                delete options["weekday"];
                // this.deliveryAlert.type = 'disabled'
                this.deliveryAlert.color = "grey";
            }

            let formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                expectedDeliveryDate
            );
            let dateMessage = `${prefix} ${formattedDate}`;
            console.log(dateMessage);

            // Set deliveryAlert message
            this.deliveryAlert.message = dateMessage;
            this.deliveryAlert.show = true;
            // this.deliveryAlert.type = 'info';
        },
        getNextWeekBegin() {
            // Assuming that the week begins on Sunday.
            var now = new Date();
            var next = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + (7 - now.getDay())
            );
            return next;
        },
        getDaysHoursAndMinutes(futureDateText) {
            var now = new Date();
            var today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            ).valueOf();
            var future = new Date(futureDateText).valueOf();
            var sec = 1000;
            var min = 60 * sec;
            var hour = 60 * min;
            var day = 24 * hour;
            var diff = future - today;
            var days = Math.floor(diff / day);
            // var hours=Math.floor(diff%day/hour);
            // var minutes=Math.floor(diff%day%hour/min);
            // Logger.log('%s days %s hours %s minutes',days,hours,minutes);
            return days;
        }
    },
    created() {
        this.fetchFormData();
        this.fetchLocations();
    },
};

</script>

<style scoped>

</style>