export default {
    inheritAttrs: false,
    name: 'ToolCategoryAutocomplete',
    data() {
        return {
            categories: [],
            loading: false
        };
    },
    methods: {
        async fetchCategories() {
            this.loading = true;
            return await this.$http.get('/v3/tools/categories').then((response) => {
                const categories = response.data;
                this.categories = categories;
                return categories;
            }).finally(() => {
                this.loading = false;
            });
        },
        async onFocus() {
            await this.fetchCategories();
        }
    }
};
