<template>
    <v-container>

        <PageTitle>
            Return to Warehouse from Job Restock Form
        </PageTitle>

        <FormSubmittedMessage
            v-if="!showForm"
            :on-click="resetForm"
            button-text="New Return to Warehouse from Job Restock Form"
        >
            {{ serverResponseMessage }}
        </FormSubmittedMessage>


        <v-form v-if="showForm" v-model="formValid" ref="form">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <NotesTextField
                                label="Restock Notes"
                                v-model="restockNotes"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <JobsAutocomplete
                                    :jobs="jobs"
                                    :items="jobs"
                                    v-model="selectedJob"
                                    :loading="loading"
                                    item-value="job_number"
                                    hide-item-count
                                    @change="selectedParts = [];itemsOfSelectedJob = [];"
                            >

                            </JobsAutocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn
                                @click="getItemsOfSelectedJobNumber"
                                block
                                tile
                                color="primary"
                                style="min-height: 50px"
                                :disabled="!selectedJob"
                                :loading="jobLookupLoading"
                            >
                                Lookup Job
                            </v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-show="alert.show">
                    <v-alert
                        close-text="Close Alert"
                        prominent
                        dismissible
                        border="left"
                        :type="alert.type"
                    >
                        {{ alert.message }}
                    </v-alert>
                </v-card-text>
            </v-card>

            <div v-show="selectedParts.length > 0 && selectedJob">

                <v-card class="my-2 py-0 mb-4 mt-6" v-show="itemsOfSelectedJob.length > 0">
                    <v-card-subtitle>
                        Filter
                    </v-card-subtitle>
                    <v-card-text class="pb-0">
                        <v-autocomplete
                            v-model="selectedItemsOfSelectedJob"
                            :items="itemNamesOfSelectedJob"
                            :loading="jobLookupLoading"
                            clearable
                            deletable-chips
                            multiple
                            outlined
                            small-chips
                            label="Filter by Part Number & Description"
                            placeholder="Start typing to Search"
                            prepend-inner-icon="mdi-archive-search"
                            return-object
                        ></v-autocomplete>
                    </v-card-text>
                </v-card>

                <div class="text-subtitle-1">Job Items</div>
                <v-divider class="mb-2"></v-divider>

                <v-card
                    class="mb-3"
                    v-for="(part, partIndex) in selectedParts"
                    :key="partIndex"
                >
                    <v-card-text class="py-3 px-4">
                        <v-row>
                            <v-col cols="12" md="2">
                                <div class="font-weight-bold">PO #</div>
                                {{ selectedParts[partIndex]["poNo"] }}
                            </v-col>

                            <v-col cols="12" md="3">
                                <div class="font-weight-bold">Part Number</div>
                                {{ selectedParts[partIndex]["partNumber"] }}
                            </v-col>

                            <v-col cols="12" md="4">
                                <div class="font-weight-bold">Part Description</div>
                                {{ selectedParts[partIndex]["partDescription"] }}
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Quantity"
                                    v-model="selectedParts[partIndex].partQuantity"
                                    type="number"
                                    @focus="$event.target.select()"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <!-- Display part Info -->
            </div>

            <!-- Submit button -->
            <v-row v-show="selectedParts.length > 0" class="py-4">
                <v-col>
                    <FormSubmitButton
                            :valid="formValid"
                            :submitting="formSubmitting"
                            @click="submitForm"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import {get} from "@/client/utilities/Object";
import {RestockFormService} from "@/common/api/api.service";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import PageTitle from "@/components/PageTitle.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";

export default {
    components: {JobsAutocomplete, FormSubmitButton, PageTitle, NotesTextField, FormSubmittedMessage},
    title: 'Restock Form',
    data() {
        return {
            showForm: true,
            formSubmitting: false,
            serverResponseStatus: "",
            serverResponseMessage: "",
            loading: true,
            jobLookupLoading: false,
            // itemsLookupLoading: true,
            formValid: false,
            requiredField: (v) => !!v || "This is a required field",

            uniqueJobNumbers: [],
            selectedJobNumber: null,
            selectedJob: null,
            jobs: [],
            // Alert:
            alert: {
                show: false,
                type: undefined,
                message: "",
            },

            newJobNumbers: ["FA Stock", "SCS Stock"],

            employeeName: "",
            presetEmployees: [],
            restockNotes: "",

            transmittedItems: [], // Array of items (Object) from the server
            itemNames: [], // Array of items' names (String)
            chosenItem: null, // Name (part Number + Description) of the chosen item to be returned back to the warehouse
            returnItem: null, // Object of the returned item, to be sent back to the server
            selectedParts: [], // Array of the selected parts to be returned back to the server

            // partsGroupedByJobNumber: {}, // object of parts details by job number
            itemsOfSelectedJob: [], // array of all items of a selected job
            itemNamesOfSelectedJob: [], // array of all item names for all items of a selected job
            selectedItemsOfSelectedJob: [], // array of all selected items of a selected job
        };
    },
    created() {
        console.log("created");
        // this.getTransmittedItems();
        this.getUniqueJobNumbers();
    },
    computed: {
        form() {
            return {
                employeeName: this.employeeName,
                restockNotes: this.restockNotes,
                job: this.selectedJob,
                // returnItem: this.returnItem,
                parts: this.selectedParts,
            };
        }
    },
    watch: {
        selectedItemsOfSelectedJob(val) {
            // const itemsOfSelectedJob = this.partsGroupedByJobNumber[this.selectedJobNumber];
            if (val.length > 0) {
                // console.log(this.itemsOfSelectedJob);
                // this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    // console.log(item)
                    this.selectedParts.push(
                        ...this.itemsOfSelectedJob.filter(
                            (part) =>
                                `${part.partNumber} -|- ${part.partDescription}` === item
                        )
                    );
                }
                // console.log(this.availableParts);

                // this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));

                for (let sp in this.selectedParts) {
                    this.selectedParts[sp].partQuantity = 0;
                }
            } else {
                this.resetSelectedParts();
            }
        }
    },
    methods: {

        getUniqueJobNumbers() {

            RestockFormService.getFormData()
                .then((response) => {
                    const results = get(response, "data");
                    // this.uniqueJobNumbers = results["uniqueJobNumbers"];
                    this.uniqueJobNumbers = results["uniqueJobNumbersForAllOpenJobs"];
                    this.presetEmployees = results['employeeNames'];
                    this.jobs = results['jobs'];
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    this.$toast.error('Error loading form data.');
                });
        },
        getItemsOfSelectedJobNumber() {
            if (!this.selectedJob) return;

            this.jobLookupLoading = true;

            RestockFormService.itemsByJobNumber({
                jobNumber: this.selectedJob.job_number,
                jcco: this.selectedJob.jcco,
            })
                .then((response) => {
                    console.log("Job's Items lookup successful!");
                    const results = response.data;
                    this.serverResponseMessage = results.message;
                    this.serverResponseStatus = results.status;
                    this.jobLookupLoading = false;

                    if (results.status !== "success") {
                        this.alert = {
                            type: "error",
                            message: results.message,
                            show: true,
                        };
                        // this.availableParts = [];
                        this.selectedParts = [];
                    } else {
                        this.alert = {show: false};

                        // Reset
                        this.itemNamesOfSelectedJob = [];

                        this.itemsOfSelectedJob = results["itemsByJob"];

                        if(this.itemsOfSelectedJob.length === 0) {
                            this.$toast.warning(`No items found for job number ${this.selectedJob.job_number}`);
                        }

                        // this.availableParts = this.itemsOfSelectedJob;
                        // console.log(this.availableParts);

                        // Set up item names for filtering:
                        for (const part of this.itemsOfSelectedJob) {
                            let partComboName =
                                part["partNumber"] + " -|- " + part["partDescription"];
                            this.itemNamesOfSelectedJob.push(partComboName);
                        }

                        this.selectedParts = JSON.parse(
                            JSON.stringify(this.itemsOfSelectedJob)
                        );
                        for (let sp in this.selectedParts) {
                            this.selectedParts[sp].partQuantity = 0;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast.error('Error loading job details.');
                })
                .finally(() => {
                    this.loading = false;
                    this.jobLookupLoading = false;
                });
        },
        submitForm() {
            this.loading = true;
            this.formSubmitting = true;

            RestockFormService
                .submitForm(this.form)
                .then((response) => {
                    console.log(response);

                    this.serverResponseMessage = get(response, 'data.response');

                    this.showForm = false;
                    this.loading = false;

                })
                .catch((error) => {
                    this.showForm = true;
                    this.$toast.error('There was an error submitting the form.');
                })
                .finally(() => {
                    this.loading = false;
                    this.formSubmitting = false;
                });
        },
        resetForm() {
            window.location.reload();
        },
        resetSelectedParts() {
            // Reset `selectedParts` to the all of the parts of a selected job number
            // this.availableParts = this.itemsOfSelectedJob;
            this.selectedParts = JSON.parse(JSON.stringify(this.itemsOfSelectedJob));
            for (let sp in this.selectedParts) {
                this.selectedParts[sp].partQuantity = 0;
            }
        }
    },
};
</script>