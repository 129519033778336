import { get } from '@/client/utilities/Object';
export default {
    name: "PageTitle",
    inheritAttrs: false,
    computed: {
        title() {
            return get(this, '$parent.$options.title');
        }
    }
};
