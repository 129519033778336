<template>
    <v-container>

        <PageTitle>
            RMA Form
        </PageTitle>

        <FormSubmittedMessage
                v-show="!showForm"
                button-text="New RMA Form"
                :on-click="resetForm"
        >
            {{ serverResponseMessage }}
        </FormSubmittedMessage>

        <v-form v-show="showForm" v-model="formValid" ref="form">

            <FormFieldContainer>
                <NotesTextField
                        label="RMA Notes"
                        v-model="rmaNotes"
                ></NotesTextField>
            </FormFieldContainer>

            <DividingHeader>
                Pickup Location
            </DividingHeader>

            <FormFieldContainer>
                <v-radio-group
                        v-model="pickupLocation"
                        row
                        class="pt-0 mt-4"
                        :rules="[requiredField]"
                        @change="selectedJob = ''"
                >
                    <v-radio
                            v-for="n in ['Job site', 'Warehouse']"
                            :key="n"
                            :label="`${n}`"
                            :value="n"
                    ></v-radio>
                </v-radio-group>

                <v-row v-show="pickupLocation !== ''">
                    <v-col>
                        <JobsAutocomplete
                                v-model="selectedJob"
                                :jobs="jobs"
                                :items="jobs"
                                :loading="loading"
                                hide-item-count
                        />
                        <!-- <v-combobox
                                v-model="selectedJobNumber"
                                :items="uniqueJobNumbers"
                                :loading="loading"
                                clearable
                                outlined
                                label="Job Number"
                                placeholder="Start typing to Search"
                                prepend-inner-icon="mdi-briefcase-search"
                                return-object
                        ></v-combobox>
                        -->
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-btn
                                @click="getItemsOfSelectedJobNumber"
                                block
                                color="primary"
                                style="min-height: 50px"
                                :disabled="!selectedJob"
                                :loading="jobLookupLoading"
                        >
                            Lookup Job
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                                v-model="unknownJob"
                                label="Unknown or Not Found Job number"
                                :disabled="!!selectedJob"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-show="alert.show">
                    <v-col>
                        <v-alert
                                close-text="Close Alert"
                                prominent
                                dismissible
                                :type="alert.type"
                        >
                            {{ alert.message }}
                        </v-alert>
                    </v-col>
                </v-row>
            </FormFieldContainer>

            <DividingHeader
                    v-show="selectedJob && !unknownJob && itemsOfSelectedJob.length > 0"
            >
                Parts (From {{ pickupLocation }})
            </DividingHeader>

            <DividingHeader v-show="unknownJob">
                Parts (Unknown Job Number)
            </DividingHeader>

            <v-card class="my-3" v-show="selectedJob && !unknownJob && itemsOfSelectedJob.length > 0">
                <v-card-text class="">
                    <v-autocomplete
                            v-model="selectedItemsOfSelectedJob"
                            :items="itemNamesOfSelectedJob"
                            :loading="jobLookupLoading"
                            clearable
                            deletable-chips
                            outlined
                            multiple
                            small-chips
                            hide-details
                            label="Filter by Part Number & Description"
                            placeholder="Start typing to Search"
                            prepend-inner-icon="mdi-database-search"
                            return-object
                    ></v-autocomplete>
                </v-card-text>
            </v-card>

            <v-card v-if="selectedJob" class="mb-2">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <span class="grey--text">
                                Pickup Location
                            </span> <br>
                            <span style="font-size:16px;">{{pickupLocation}}</span>
                        </v-col>
                        <v-col>
                            <span class="grey--text">
                                Job #
                            </span> <br>
                            <span style="font-size:16px;">{{selectedJob.job_number}}</span>
                        </v-col>
                        <v-col>
                            <span class="grey--text">
                                Job Description
                            </span> <br>
                            <span style="font-size:16px;">{{selectedJob.description}}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card
                    v-for="(part, partIndex) in selectedParts"
                    :key="partIndex"
                    class="mb-3"
            >
                <v-card-text>
                    <VRow>
                        <VCol>
                            <span class="grey--text">PO #</span> <br>
                            <span style="font-size:16px;">{{ part.poNo }}</span>
                        </VCol>
                        <VCol>
                            <PartName
                                    :part-number="part.partNumber"
                                    :part-description="part.partDescription"
                            />
                        </VCol>
                        <VCol>
                            <span class="grey--text">Current Location</span> <br>
                            <span>
                                <b>
                                    {{part.rack ?? 'None'}} - {{part.section ?? 'None'}} - {{part.subSection ?? 'None'}}
                                </b>
                            </span>
                        </VCol>
                        <VCol>
                            <span class="grey--text">
                                Available Qty
                            </span> <br>
                            <span>
                                <b>{{ part.availableQuantity }}</b>
                            </span>
                        </VCol>
                        <VCol>
                            <VTextField
                                    label="RMA Quantity"
                                    v-model="selectedParts[partIndex].partQuantity"
                                    :rules="unknownJob ? [requiredField] : []"
                                    rows="1"
                                    auto-grow
                                    type="number"
                                    outlined
                            ></VTextField>
                        </VCol>
                    </VRow>
                    <v-row>
                        <v-col cols="12" sm="2" v-show="unknownJob">
                            <v-text-field
                                    label="Job #"
                                    v-model="selectedParts[partIndex]['jobNo']"
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-show="unknownJob">
                            <v-text-field
                                    label="Job Name"
                                    v-model="selectedParts[partIndex]['jobName']"
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" v-show="unknownJob">
                            <v-text-field
                                    label="Manager"
                                    v-model="selectedParts[partIndex]['manager']"
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" v-show="unknownJob">
                            <v-text-field
                                    label="Foreman"
                                    v-model="selectedParts[partIndex]['foreman']"
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-textarea
                                    label="PO #"
                                    v-model="selectedParts[partIndex]['poNo']"
                                    rows="1"
                                    auto-grow
                                    :disabled="!unknownJob"
                                    outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-textarea
                                    v-model="selectedParts[partIndex]['partNumber']"
                                    :rules="unknownJob ? [requiredField] : []"
                                    label="Part Number"
                                    rows="1"
                                    auto-grow
                                    outlined
                                    :disabled="!unknownJob"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-textarea
                                    v-model="selectedParts[partIndex]['partDescription']"
                                    :rules="unknownJob ? [requiredField] : []"
                                    label="Part Description"
                                    rows="1"
                                    auto-grow
                                    outlined
                                    :disabled="!unknownJob"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                    label="Current location"
                                    :value="
                      (selectedParts[partIndex].rack !== undefined ? ((selectedParts[partIndex].rack ?? 'None') +
                      ' - ' +
                      (selectedParts[partIndex].section ?? 'None') +
                      ' - ' +
                      (selectedParts[partIndex].subSection ?? 'None')) : '')
                    "
                                    disabled
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-textarea
                                    label="Quantity"
                                    v-model="selectedParts[partIndex].partQuantity"
                                    :rules="unknownJob ? [requiredField] : []"
                                    rows="1"
                                    auto-grow
                                    type="number"
                                    outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Button to add/remove more parts -->
            <v-row class="my-4 text-center justify-center" v-show="unknownJob">
                <v-col
                        cols="6"
                >
                    <v-btn class="addLocation" @click="addPackage" small>
                        <v-icon small>mdi-plus</v-icon>
                        Add part
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                            class="removeLocation"
                            @click="selectedParts.pop()"
                            small
                            v-show="selectedParts.length > 1"
                    >
                        <v-icon>mdi-minus</v-icon>
                        Remove part
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Submit button -->
            <v-row v-show="selectedParts.length > 0" class="py-4">
                <v-col>
                    <FormSubmitButton
                            :valid="formValid"
                            :submitting="formSubmitting"
                            @click="submitForm"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import {RmaFormService} from "../common/api/api.service";
import DividingHeader from "../components/DividingHeader.vue";
import FormSubmitButton from "../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../components/FormSubmittedMessage.vue";
import PageTitle from "../components/PageTitle.vue";
import FormFieldContainer from "../components/V2/FormFieldContainer.vue";
import NotesTextField from "../components/NotesTextField.vue";
import JobsAutocomplete from "../components/V2/JobsAutocomplete.vue";
import PartName from "@/components/V2/PartName.vue";

export default {
    components: {
        PartName,
        JobsAutocomplete,
        FormFieldContainer, FormSubmitButton, DividingHeader, NotesTextField, PageTitle, FormSubmittedMessage
    },
    title: 'RMA Form',
    data() {
        return {
            showForm: true,
            serverResponseStatus: "",
            serverResponseMessage: "",
            loading: true,
            formSubmitting: false,
            jobLookupLoading: false,
            // itemsLookupLoading: false,
            formValid: false,
            requiredField: (v) => !!v || "This is a required field",

            pickupLocation: "",
            // searchJobNumber: null,
            uniqueJobNumbersForAllOpenJobs: [], // array of unique job numbers for all open jobs
            uniqueJobNumbersInWarehouse: [], //
            uniqueJobNumbers: [],
            selectedJobNumber: null,
            selectedJob: null,
            unknownJob: false,

            // Alert:
            alert: {
                show: false,
                type: undefined,
                message: "",
            },

            employeeName: "",
            presetEmployees: [],
            rmaNotes: "",
            openJobs: [],
            inventoryJobs: [],

            // transmittedItems: [], // Array of items (Object) from the server
            itemNames: [], // Array of items' names (String)
            chosenItem: null, // Name (part Number + Description) of the chosen item to be returned back to the warehouse
            // availableParts: [], // Object of the returned item, to be sent back to the server
            selectedParts: [], // Array of the selected parts to be returned back to the server

            // partsGroupedByJobNumber: {}, // object of parts details by job number
            itemsOfSelectedJob: [], // array of all items of a selected job
            itemNamesOfSelectedJob: [], // array of all item names for all items of a selected job
            selectedItemsOfSelectedJob: [], // array of all selected items of a selected job
        };
    },
    created() {
        console.log("created");
        this.getUniqueJobNumbers();
    },
    computed: {
        jobs() {
            if(this.pickupLocation.toLowerCase() === 'Job site'.toLowerCase()) {
                return this.openJobs;
            }

            return this.inventoryJobs;
        },
        form() {
            return {
                employeeName: this.employeeName,
                pickupLocation: this.pickupLocation,
                rmaNotes: this.rmaNotes,
                unknownJob: this.unknownJob,
                parts: this.selectedParts,
            };
        }
    },
    watch: {
        selectedJobNumber(val) {
            console.log("selectedJobNumber", val);
            if (!val) {
                this.selectedParts = [];
                this.itemsOfSelectedJob = [];
                console.log("no job number", val);
            }

        },
        selectedJob(val) {
            console.log("selected job", val);
            if(!val) {
                this.selectedParts = [];
                this.itemsOfSelectedJob = [];
                console.log("no job number", val);
            }
        },
        // Loading take a while so when done, assign the correct jobs depending on the pickup location
        loading(val) {
            if (!val) {
                this.setJobNumbers();
            }
        },
        pickupLocation(val) {
            this.setJobNumbers();
        },
        selectedItemsOfSelectedJob(val) {
            // const itemsOfSelectedJob = this.partsGroupedByJobNumber[this.selectedJobNumber];
            // Copy over the current selected items:
            const currentSelectedParts = JSON.parse(JSON.stringify(this.selectedParts));
            if (val.length > 0) {
                // console.log(this.itemsOfSelectedJob);
                // this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    // console.log(item)
                    this.selectedParts.push(
                        ...this.itemsOfSelectedJob.filter(
                            (part) =>
                                `${part.partNumber} -|- ${part.partDescription}` === item
                        )
                    );
                }
                // console.log(this.availableParts);

                // this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));
                // console.log(currentSelectedParts);
                // console.log(this.selectedParts);
                let foundOnce = false;
                for (let sp in this.selectedParts) {
                    let newSelectedPart = this.selectedParts[sp];
                    // console.log(newSelectedPart);

                    // Check if the newly selected part is already in the current selected parts:
                    let foundPart = currentSelectedParts.find((element) => element.partNumber.toString() === newSelectedPart.partNumber.toString())
                    // console.log(foundPart);

                    // If the part is already in the current selected parts, use that part current quantity
                    if (foundPart && !foundOnce) {
                        // console.log('found');
                        this.selectedParts[sp].partQuantity = foundPart.partQuantity;
                        foundOnce = true;
                    }
                    // otherwise, set the quantity of the new part to 0
                    else {
                        // console.log('NOT found');
                        if (this.pickupLocation === "Job site") {
                            this.selectedParts[sp].partQuantity = 0;
                        }
                    }
                    // this.selectedParts[sp].partQuantity = 0;
                }
            } else {
                this.resetSelectedParts();
            }
        },
        unknownJob(val) {
            if (val) {
                const newPart = this.createNewPart();
                // this.availableParts = [];
                this.selectedParts = [];
                // this.availableParts.push(newPart);
                this.selectedParts.push(newPart);
            } else {
                this.selectedParts = [];
            }
        },
    },
    methods: {
        getUniqueJobNumbers() {

            RmaFormService.getFormData()
                .then((response) => {
                    console.log(response, 'rma form data');
                    const results = response.data;
                    // console.log(results);
                    // this.uniqueJobNumbers = results["uniqueJobNumbersForAllOpenJobs"];
                    this.uniqueJobNumbersForAllOpenJobs =
                        results["uniqueJobNumbersForAllOpenJobs"];
                    this.uniqueJobNumbersInWarehouse =
                        results["uniqueJobNumbersInSubmissionsSheet"];

                    this.openJobs = results["openJobs"];
                    this.inventoryJobs = results["inventoryJobs"];

                    this.presetEmployees = results['employeeNames'];

                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setJobNumbers() {
            if (this.pickupLocation === "Warehouse") {
                this.uniqueJobNumbers = this.uniqueJobNumbersInWarehouse;
            } else if (this.pickupLocation === "Job site") {
                this.uniqueJobNumbers = this.uniqueJobNumbersForAllOpenJobs;
            }
            console.log(`Number of Jobs: ${this.uniqueJobNumbers.length}`);
        },
        getItemsOfSelectedJobNumber() {
            if (!this.selectedJob) return;

            this.jobLookupLoading = true;

            RmaFormService
                .itemsByJobNumber({
                    jobNumber: this.selectedJob.job_number,
                    jcco: this.selectedJob.jcco,
                    pickupLocation: this.pickupLocation
                })
                .then((response) => {
                    console.log("Job's Items lookup successful!", response);
                    const results = response.data;

                    // console.log(results);
                    this.serverResponseMessage = results.message;
                    this.serverResponseStatus = results.status;
                    this.jobLookupLoading = false;

                    if (results.status !== "success") {
                        this.alert = {
                            type: "error",
                            message: results.message,
                            show: true,
                        };
                        // this.availableParts = [];
                        this.selectedParts = [];

                    } else {

                        this.alert = {show: false};

                        // for (const part in results['itemsByJob']) {
                        //   const itemDetails = {
                        //     partJobNumber: part['jobNo'],
                        //     partJobName: part['jobName'],
                        //     partPONumber: part['poNo'],
                        //     partComboName: part['partComboName'],
                        //     partQuantity: 0,
                        //     manager: part["manager"],
                        //     foreman: part["foreman"],
                        //   };
                        //   // console.table(itemDetails);
                        //   this.availableParts.push(itemDetails);
                        // }

                        // Reset
                        this.itemNamesOfSelectedJob = [];

                        this.itemsOfSelectedJob = results["itemsByJob"];
                        // this.availableParts = this.itemsOfSelectedJob;
                        // console.log(this.availableParts);

                        // Set up item names for filtering:
                        for (const part of this.itemsOfSelectedJob) {
                            let partComboName =
                                part["partNumber"] + " -|- " + part["partDescription"];
                            this.itemNamesOfSelectedJob.push(partComboName);
                        }

                        this.selectedParts = JSON.parse(
                            JSON.stringify(this.itemsOfSelectedJob)
                        );
                        // Only do this for pickup location "Job site"
                        if (this.pickupLocation === "Job site") {
                            for (let sp in this.selectedParts) {
                                this.selectedParts[sp].partQuantity = 0;
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        quantityValid(part) {
            console.log(part);
        },
        submitForm() {
            this.loading = true;
            this.formSubmitting = true;

            // Check to make sure at least there is 1 items with quantity > 0
            let hasSelectedItems = false;
            for (let sp of this.selectedParts) {
                if (sp.partQuantity > 0) {
                    hasSelectedItems = true;
                    break;
                }
            }
            if (!hasSelectedItems) {
                this.alert = {
                    type: "error",
                    message: "Please select at least 1 item (quantity > 0) to return.",
                    show: true,
                };
                this.loading = false;
                return;
            }

            RmaFormService
                .submitForm(this.form)
                .then((response) => {
                    const results = response.data;
                    this.serverResponseMessage = results.response;
                    this.showForm = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.showForm = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.formSubmitting = false;
                });
        },
        resetForm() {
            window.location.reload();
        },
        resetSelectedParts() {
            // Reset `selectedParts` to the all of the parts of a selected job number
            // this.availableParts = this.itemsOfSelectedJob;
            this.selectedParts = JSON.parse(JSON.stringify(this.itemsOfSelectedJob));
            if (this.pickupLocation === "Job site") {
                for (let sp in this.selectedParts) {
                    this.selectedParts[sp].partQuantity = 0;
                }
            }
        },
        createNewPart() {
            return {
                companyId: "",
                jobNo: "",
                jobName: "",
                poNo: "",
                partNumber: "",
                partDescription: "",
                partQuantity: null,
                availableQty: null,
                manager: "",
                foreman: "",
            };
        },
        addPackage() {
            const newPart = this.createNewPart();
            this.selectedParts.push(newPart);
        },
    },
};
</script>