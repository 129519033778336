<template>
<v-container>
    <PageTitle>
        About LegaC Forms page
    </PageTitle>
    <v-card elevation="3" class="mb-3">
        <v-card-text>
            As of Jan 18, 2022, this is the new home for LegaC Receive and Transmit
            Forms
        </v-card-text>
        <v-card-text>
            This is a work in progress. If you encounter any trouble, please email to
            <a
                href="mailto:legac.forms@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
            >legac.forms@gmail.com</a
            >. Thank you for your patience!
        </v-card-text>
    </v-card>

</v-container>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";

export default {
    name: "About",
    title: 'About',
    components: {
        PageTitle
    }
}
</script>