<template>
    <v-row class="py-1 my-2">
        <v-col class="py-0">
            <h3 class="pb-1">
                <slot></slot>
            </h3>
            <v-divider></v-divider>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "DividingHeader",
    inheritAttrs: false,
}
</script>