export default class Avatar {
    constructor(name) {
        this._name = name;
        this._initials = this.getInitials(this._name);

        this._margin = 5;
        this._radius = 30;
        this._width = 30;
        this._height = 30;
        this._fontSize = '24px';
        this._fontColor = 'white';
        this._fontFamily = 'Arial';
        this._textAlign = 'center';
        this._backgroundColor = 'grey';

        this.margin(5);
        this.radius(30);

        this.canvas = document.createElement('canvas');
        this.context = null;
        this.generated = false;
        this.dataURL = null;
    }

    generate() {
        this.canvas.width = this.getWidth();
        this.canvas.height = this.getHeight();
        this.context = this.canvas.getContext('2d');
        this.context.beginPath();

        if (this._radius > 1) {
            this.context.arc(this._radius + this._margin, this._radius + this._margin, this._radius, 0, 2 * Math.PI, false);
        }

        this.context.closePath();
        this.context.fillStyle = this._backgroundColor;
        this.context.fill();
        this.context.fillStyle = this._fontColor;
        this.context.font = `${this._fontSize} ${this._fontFamily}`;
        this.context.textAlign = this._textAlign;

        if (this._radius > 1) {
            this.context.fillText(this._initials, this._radius + 5, this._radius * 4 / 3 + this._margin);
        } else {
            this.context.fillText(this._initials, 5, this._height);
        }
    }

    toDataURL() {
        this.generate();
        this.dataURL = this.canvas.toDataURL();
        return this.dataURL;
    }

    font(fontSize = '24px', fontColor = 'white', fontFamily = 'Arial', textAlign = 'center') {
        this._fontSize = fontSize;
        this._fontColor = fontColor;
        this._fontFamily = fontFamily;
        this._textAlign = textAlign;
        return this;
    }

    radius(radius) {
        this._radius = radius;
        return this;
    }

    margin(margin) {
        this._margin = margin;
        return this;
    }

    getWidth() {
        if (this._radius > 1) {
            this._width = this._radius * 2 + this._margin * 2;
        }

        return this._width;
    }

    getHeight() {
        if (this._radius > 1) {
            this._height = this._radius * 2 + this._margin * 2;
        }
        return this._height;
    }

    backgroundColor(color) {
        if (color) {
            this._backgroundColor = color;
        }

        return this._backgroundColor;
    }

    getInitials(name) {
        let initials = '';
        if (name) {
            const names = name.split(' ');
            if (names.length > 1) {
                initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
            } else {
                initials = name.charAt(0);
            }
        }
        return initials;
    }
}