<script>
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import VehicleAutocomplete from "@/components/VehicleAutocomplete.vue";
import EmployeeAutocomplete from "@/components/V2/EmployeeAutocomplete.vue";
import {get} from "@/client/utilities/Object";

export default {
    name: 'ToolLocation',
    components: {EmployeeAutocomplete, VehicleAutocomplete, JobAutocomplete},
    props: {
        racks: {
            type: Array,
            required: true,
        },
        data: {
            type: Object,
            default: () => {
            }
        },
        value: {
            type: Object,
            required: true,
        },
        hideJob: {
            type: Boolean,
            default: false
        },
        hideVehicle: {
            type: Boolean,
            default: false
        },
        hideEmployee: {
            type: Boolean,
            default: false
        },
        hideWarehouse: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: {
                required: (value) => !!value || 'This field is required',
            },
            loading: false,
            location: {
                type: 'warehouse',
                warehouse: {
                    rack: null,
                    section: null,
                    sub_section: null,
                },
                job: null,
                vehicle: null,
                employee: null,
            }
        }
    },
    watch: {
        location: {
            handler: function (location) {
                this.$emit('input', location);
            },
            deep: true
        }
    },
    methods: {
        fetchData() {
            const toolId = this.$route.params.id;
            this.loading = true;
            return this.$http.get('/v3/tools/forms/create/data', {params: {tool_id: toolId}}).then((response) => {
                this.data.racks = get(response, 'data.racks', []);
            }).finally(() => {
                this.loading = false;
            });
        },
        getSections(rack) {
            return get(rack, 'sections', []);
        },
        getSubSections(section) {
            return get(section, 'sub_sections', []);
        },
        onRackChanged(rack) {
            this.location.warehouse.section = null;
            this.location.warehouse.sub_section = null;
        },
        onSubSectionChanged(subSection) {
            this.location.warehouse.sub_section = null;
        }
    },
    created() {
        this.location = this.data;
    }
}
</script>

<template>
    <v-card class="my-2">
        <v-card-text>
            <v-tabs
                v-model="location.type"
                fixed-tabs
                background-color="tabHeaderBackground"
            >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#warehouse" v-if="!hideWarehouse">
                    Warehouse
                </v-tab>
                <v-tab href="#jobsite" v-if="!hideJob">
                    Job Site
                </v-tab>
                <v-tab href="#vehicle" v-if="!hideVehicle">
                    Vehicle
                </v-tab>
                <v-tab href="#employee" v-if="!hideEmployee">
                    Employee
                </v-tab>
            </v-tabs>

            <v-card>
                <v-card-text>
                    <v-tabs-items v-model="location.type">
                        <v-tab-item value="warehouse" class="pt-2">
                            <v-row dense>
                                <v-col>
                                    <v-combobox
                                        v-model="location.warehouse.rack"
                                        :rules="(location.type === 'warehouse') ? [v => !!v || 'Rack is required.'] : []"
                                        :items="racks"
                                        item-text="rack"
                                        label="Rack"
                                        item-value="id"
                                        outlined
                                        return-object
                                        clearable
                                        hide-details
                                        @change="onRackChanged"
                                    >
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <v-combobox
                                        v-if="location.warehouse.rack"
                                        :items="getSections(location.warehouse.rack)"
                                        :rules="(location.type === 'warehouse') ? [v => !!v || 'Section is required.'] : []"
                                        label="Section"
                                        v-model="location.warehouse.section"
                                        item-text="section"
                                        item-value="id"
                                        outlined
                                        hide-details
                                        clearable
                                        @change="onSubSectionChanged"
                                    >
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <v-combobox
                                        v-if="location.warehouse.section"
                                        :items="getSubSections(location.warehouse.section)"
                                        v-model="location.warehouse.sub_section"
                                        label="Sub Section"
                                        :rules="(location.type === 'warehouse') ? [v => !!v || 'Sub Section is required.'] : []"
                                        item-text="sub_section"
                                        item-value="id"
                                        outlined
                                        hide-details
                                        clearable
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="jobsite" class="pt-2">
                            <JobAutocomplete hide-details v-model="location.job"
                                             :rules="(location.type === 'jobsite') ? [rules.required] : []"></JobAutocomplete>
                        </v-tab-item>
                        <v-tab-item value="vehicle" class="pt-2">

                            <vehicle-autocomplete
                                :rules="(location.type === 'vehicle') ? [rules.required] : []"
                                v-model="location.vehicle"
                                label="Select a vehicle"
                            />
                        </v-tab-item>
                        <v-tab-item value="employee" class="pt-2">
                            <employee-autocomplete
                                :rules="(location.type === 'employee') ? [rules.required] : []"
                                v-model="location.employee"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>