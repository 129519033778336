<template>
    <v-sheet
            v-bind="$attrs"
            :class="cssClass"
            elevation="2"
    >
        <slot />
    </v-sheet>
</template>

<script>
export default {
    name: "FormFieldContainer",
    inheritAttrs: false,
    props: {
        cssClass: {
            type: String,
            default: "px-3 py-3 my-3"
        }
    }
}
</script>

<style scoped>

</style>