<template>
    <VContainer>
        <PageTitle>
            Transmittal Form
            <VBtn
                    color="accent"
                    small
                    class="float-right d-none d-sm-flex"
                    href="#/v1/transmit-form"
            >
                Old Transmittal Form
            </VBtn>
        </PageTitle>

        <VForm
                v-model="form.valid"
                v-if="!form.submitted"
                ref="form"
        >

            <FormFieldContainer>
                <VRow>
                    <VCol cols="12" lg="6" md="6" sm="6">
                        <ProjectManagerAutocomplete
                                :items="projectManagers"
                                v-model="form.project_manager"
                                :loading="form.loading"
                                :rules="(form.job && form.job.jcco) ? [rules.required] : []"
                        />
                    </VCol>
                    <VCol cols="12" lg="6" md="6" sm="6">
                        <JobsAutocomplete
                                :items="jobs"
                                :jobs="data.jobs"
                                v-model="form.job"
                                :loading="form.loading"
                                :rules="[rules.required]"
                        />
                    </VCol>
                </VRow>
            </FormFieldContainer>

            <FormFieldContainer
                    cssClass="px-0 py-0 my-6"
                    v-show="showModeOptions"
            >

                <VTabs
                        fixed-tabs
                        centered
                        v-model="form.mode"
                        background-color="tabHeaderBackground"
                >
                    <VTab href="#warehouseToJobsite">From Warehouse</VTab>
                    <VTab href="#switchToJob">From Job/Stock to Job</VTab>
                    <VTab href="#switchToStock">From Job/Stock to Stock</VTab>
                </VTabs>

                <VTabsItems
                        v-model="form.mode"
                >
                    <VTabItem
                            value="warehouseToJobsite"
                            class="my-8 mx-4"
                    >
                        <VRow>
                            <VCol>
                                <TextInput
                                        label="Site Contact Name"
                                        v-model="form.delivery.site_contact_name"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                />
                            </VCol>
                            <VCol>
                                <DatePicker
                                        v-model="form.delivery.delivery_date"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                        label="Delivery Date"
                                        hint="YYYY-MM-DD Format"
                                />
                            </VCol>
                            <VCol>
                                <TextInput
                                        label="Delivery Window"
                                        v-model="form.delivery.delivery_window"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                />
                            </VCol>
                        </VRow>

                    </VTabItem>

                    <VTabItem
                            value="switchToJob"
                            class="my-8 mx-4"
                    >
                        <JobsAutocomplete
                                v-model="form.newJob"
                                :items="jobsForNewJob"
                                :jobs="jobsForNewJob"
                                :rules="form.mode ==='switchToJob' ? [rules.required] : []"
                        />

                        <v-list
                                class="mt-3"
                                v-if="form.newJob"
                                color="jobDetailsBackground"
                                elevation="2"
                        >
                            <!-- Display part Info -->
                            <v-list-item>
                                <v-list-item-content>
                                    <v-row>

                                        <v-col cols="12" sm="6">
                                            <v-list-item-subtitle>Job Name</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    form.newJob.description
                                                }}
                                            </v-list-item-title>

                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-list-item-subtitle>Manager</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    (form.newJob.project_manager ? form.newJob.project_manager.name : '')
                                                }}
                                            </v-list-item-title>

                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-list-item-subtitle>Foreman</v-list-item-subtitle>
                                            <v-list-item-title>{{
                                                    (form.newJob.foreman ? form.newJob.foreman.name : '')
                                                }}
                                            </v-list-item-title>

                                        </v-col>

                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </VTabItem>

                    <VTabItem
                            value="switchToStock"
                            class="my-8 mx-4"
                    >
                        <JobsAutocomplete
                                v-model="form.newJob"
                                :items="jobsForStock"
                                :jobs="jobsForStock"
                                :rules="form.mode ==='switchToStock'? [rules.required] : []"
                        >

                        </JobsAutocomplete>
                    </VTabItem>

                </VTabsItems>
            </FormFieldContainer>

            <FormFieldContainer
                    class="mx-auto"
                    v-show="showInventoryTable"
            >
                <DividingHeader class="mt-0">
                    Items
                    <VBadge color="accent" :content="form.items.length" />
                </DividingHeader>

                <VProgressLinear
                        :active="table.loading"
                        indeterminate
                ></VProgressLinear>

                <div
                        ref="table"
                />

            </FormFieldContainer>

            <FormFieldContainer>
                <NotesTextField
                        v-model="form.notes"
                        label="Transmittal Notes"
                />
            </FormFieldContainer>

            <VDivider
                    class="my-2"
            />

            <FormSubmitButton
                    :submitting="form.submitting"
                    @click="submitForm"
            />

        </VForm>

        <VDialog
                v-model="reviewDialog"
                scrollable
                max-width="80%"
        >
            <VCard
                    class="pb-2"
            >
                <VToolbar
                        color="primary-darken-4"
                >
                    Transmitted Items
                </VToolbar>

                <VCardText class="mt-4">
                    <VRow v-if="form.mode === 'switchToJob' && form.newJob">
                        <VCol>
                            <b>Transmittal Mode</b> <br>
                            Switch to Job
                        </VCol>
                        <VCol v-if="form.newJob">
                            <b>New Job</b> <br>
                            {{form.newJob.job_number}} <br>
                            {{(form.newJob.company ? form.newJob.company.slug : '')}} - {{form.newJob.description}}
                        </VCol>
                        <VCol v-if="form.newJob.project_manager">
                            <b>New Project Manager</b> <br>
                            {{form.newJob.project_manager.name}}
                        </VCol>
                        <VCol v-if="form.newJob.foreman">
                            <b>New Foreman</b> <br>
                            {{form.newJob.foreman.name}}
                        </VCol>
                        <VCol>
                            <b>Notes</b>
                            {{form.notes}}
                        </VCol>
                    </VRow>
                    <VRow v-if="form.mode === 'switchToStock'">
                        <VCol>
                            <b>Transmittal Mode</b> <br>
                            Switch to Stock
                        </VCol>
                        <VCol v-if="form.newJob">
                            <b>Stock</b> <br>
                            {{form.newJob.job_number}}
                        </VCol>
                        <VCol>
                            <b>Notes</b>
                            {{this.form.notes}}
                        </VCol>
                    </VRow>
                    <VRow v-if="form.mode === 'warehouseToJobsite' && this.form.delivery">
                        <VCol lg="2" md="3" sm="12">
                            <b>Transmittal Mode</b> <br>
                            Warehouse to Jobsite
                        </VCol>
                        <VCol lg="3" md="3" sm="12">
                            <b>Site Contact Name</b> <br>
                            {{form.delivery.site_contact_name}} <br>
                        </VCol>
                        <VCol lg="2" md="3" sm="12">
                            <b>Delivery Date</b> <br>
                            {{form.delivery.delivery_date}} <br>
                        </VCol>
                        <VCol lg="3" md="3" sm="12">
                            <b>Delivery Window</b> <br>
                            {{form.delivery.delivery_window}} <br>
                        </VCol>
                        <VCol lg="2" md="3" sm="12">
                            <b>Notes</b> <br>
                            {{form.notes}}
                        </VCol>
                    </VRow>
                </VCardText>
                <VCardSubtitle>
                    Items
                </VCardSubtitle>
                <VCardText>
                    <VSimpleTable>
                        <thead>
                            <tr>
                                <th>Location</th>
                                <th>Part Description</th>
                                <th>Part #</th>
                                <th>Avail Qty</th>
                                <th>Transmittal Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in form.items" v-show="item.transmittal_quantity > 0">
                            <td>{{ item.location_key }}</td>
                            <td>{{ item.part_description }}</td>
                            <td>{{ item.part_number }}</td>
                            <td>{{ item.part_quantity }}</td>
                            <td>{{ item.transmittal_quantity }}</td>
                        </tr>
                        </tbody>
                    </VSimpleTable>
                </VCardText>
                <VCardActions>
                    <VBtn
                            color="secondary"
                            class="ml-auto"
                            @click="reviewDialog = false"
                    >
                        <VIcon left>mdi-close-circle</VIcon>
                        Close
                    </VBtn>
                </VCardActions>
            </VCard>
        </VDialog>

        <FormSubmittedMessage
                button-text="New Transmittal Form"
                v-if="form.submitted"
                :type="formSubmittedMessage.type"
                :onClick="resetForm"
        >
            {{ formSubmittedMessage.text }}
        </FormSubmittedMessage>

    </VContainer>
</template>
<script>
import {get} from "@/client/utilities/Object";
import TransmittalForm from "../../common/api/forms/TransmittalForm";
import DividingHeader from "../../components/DividingHeader.vue";
import FormSubmitButton from "../../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../../components/FormSubmittedMessage.vue";
import NotesTextField from "../../components/NotesTextField.vue";
import PageTitle from "../../components/PageTitle.vue";
import FormFieldContainer from "../../components/V2/FormFieldContainer.vue";
import TextInput from "../../components/TextInput.vue";
import DatePicker from "../../components/V2/DatePicker.vue";
import JobsAutocomplete from "../../components/V2/JobsAutocomplete.vue";
import ProjectManagerAutocomplete from "../../components/V2/ProjectManagerAutocomplete.vue";

import {TabulatorFull} from "tabulator-tables";

export default {
    components: {
        DatePicker,
        ProjectManagerAutocomplete,
        JobsAutocomplete,
        DividingHeader,
        TextInput, FormSubmitButton, FormFieldContainer, NotesTextField, PageTitle, FormSubmittedMessage
    },
    title: 'Transmittal Form',
    data() {
        return {
            tabulator: null,
            columns: [
                { title: 'Location', field: 'location_key', minWidth: 100 },
                { title: 'PO #', field: 'po_number', minWidth:100 },
                { title: 'Part Desc', field: 'part_description', minWidth:400 },
                {
                    title: 'Part #',
                    field: 'part_number',
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth:150,
                    frozen: true
                },
                {
                    title: 'Avail',
                    field: 'part_quantity',
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth:80,
                    width: 80,
                    maxWidth:100,
                    frozen: true
                },
                {
                    title: 'Qty',
                    field: 'transmittal_quantity',
                    editor: 'number',
                    editorParams: {
                        selectContents: true
                    },
                    validator: "partQuantityMax",
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth:80,
                    width: 80,
                    maxWidth:100,
                    frozen: true
                }
            ],
            table: {
                loading: false
            },
            form: {
                project_manager: null,
                job: null,
                newJob: null,
                mode: 'warehouseToJobsite',
                delivery: {
                    site_contact_name: null,
                    delivery_date: null,
                    delivery_window: null,
                },
                items: [],
                notes: '',
                loading: false,
                submitting: false,
                submitted: false,
                valid: false,
            },
            rules: {
                required: v => !!v || "This is a required field",
            },
            data: {
                jobs: [],
                open_jobs: [],
                jobs_for_stock: [],
                project_managers: [],
                _project_managers: [],
                inventoryItems: []
            },
            duplicateJobs: [],
            duplicateJobNumberDialog: false,
            selectedDuplicateJob: null,
            reviewDialog: false,
            formSubmitted: false,
            formValid: false,
            formLoading: true,
            formSubmittedMessage: {
                text: '',
                type: '',
                buttonText: ''
            },
            max_lengths: {}
        }
    },
    computed: {
        jobs() {
            let jobs = this.data.jobs;

            // Only return jobs that match the selected project manager
            if (this.form.project_manager) {
                jobs = jobs.filter(job => job.project_manager_user_id === this.form.project_manager.id);
            }

            return jobs;
        },
        projectManagers() {
            return this.data.project_managers;
        },
        jobsForNewJob() {
            if (this.form.job && this.form.job.jcco && this.form.job.jcco < 9999) {
                return this.data.open_jobs.filter(job => job.jcco === this.form.job.jcco);
            }

            return this.data.open_jobs ?? [];
        },
        jobsForStock() {
            return this.data.jobs_for_stock;
        },
        showModeOptions() {
            return this.form.job;
        },
        showInventoryTable() {
            return this.form.job;
        }
    },
    created() {
        this.fetchFormData();
    },
    mounted() {
        this.tabulator = new TabulatorFull(this.$refs.table, {
            data: this.form.items,
            layout: 'fitData',
            layoutColumnsOnNewData: true,
            reactiveData: true,
            validationMode: 'highlight',
            rowFormatter: (row) => {
                const data = row.getData();
                let el = row.getElement();

                if (row && data && data.transmittal_quantity > 0) {

                    if (el) {
                        el.classList.add('row-modified');
                    }
                } else {
                    el.classList.remove('row-modified');
                }
            },
            popupContainer: true,
            resizableColumnFit:true,
            rowHeight: 45,
            columnDefaults: {
                vertAlign: 'middle',
                headerSortTristate: true
            },
            columns: this.columns
        });

        this.tabulator.on('cellEdited', function (cell) {

            try {
                let row = cell.getRow();
                if (row) {
                    setTimeout(() => {
                        row.reformat();
                        row.validate();
                    }, 200);
                }
            } catch (e) {
                console.error('Error in cellEdited event', e);
            }
        });
    },
    watch: {
        'form.job'(job) {

            if (job) {

                const pm_id = get(this.form, 'job.project_manager_user_id');
                const project_manager = get(this.data._project_managers, pm_id);

                if (project_manager) {
                    this.form.project_manager = project_manager;
                }

                this.fetchInventoryItems();

            } else {
                this.form.project_manager = null;
                this.form.items = [];
                this.form.newJob = null;
            }
        },
        'form.project_manager'(project_manager) {
            if (!project_manager) {
                this.form.job = null;
            }
        },
        'form.mode'(mode) {
            this.form.newJob = null;
            this.form.delivery = {
                site_contact_name: null,
                delivery_date: null,
                delivery_window: null,
            }
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();

            if(this.transmittalQuantity() <= 0) {
                this.$toast.error('Total transmittal quantity must be greater than 0');
            }

            return this.$refs.form.validate() && (this.tabulator.validate() === true) && this.transmittalQuantity() > 0;
        },
        transmittalQuantity() {
            let quantity = 0;
            this.form.items.forEach(item => {
                quantity += item.transmittal_quantity;
            });

            return quantity;
        },
        fetchFormData() {

            this.form.loading = true;

            TransmittalForm.data()
                .then(response => {
                    if (response) {
                        this.data.jobs = get(response, 'data.jobs', []);
                        this.data.open_jobs = get(response, 'data.open_jobs', []);
                        this.data.jobs_for_stock = get(response, 'data.jobs_for_stock', []);

                        this.data._project_managers = get(response, 'data.project_managers', []);
                        this.data.project_managers = Object.values(this.data._project_managers);
                    }
                })
                .finally(() => {
                    this.form.loading = false;
                });
        },
        fetchInventoryItems() {
            const job_number = get(this.form, 'job.job_number');
            const jcco = get(this.form, 'job.company.jcco');

            this.tabulator.clearData();

            this.data.inventoryItems = [];
            this.form.items = [];
            this.table.loading = true;

            TransmittalForm.inventory(job_number, jcco)
                .then(response => {

                    let items = get(response, 'data.items', []);

                    this.data.inventoryItems = items;
                    this.form.items = items;
                    this.data.inventoryItemCount = get(response, 'data.count', 0);

                    this.tabulator.replaceData(this.form.items).then(() => {
                        this.table.loading = false;
                    }).catch((e) => {
                        console.log('error', e);
                    });
                })
                .catch((e) => {
                    this.table.loading = false;
                    this.$toast.error('Error loading inventory items.');
                    console.error(e);
                });
        },
        submitForm(preview) {

            console.log(this.form);

            if (this.validate()) {

                if(preview === true) {
                    this.reviewDialog = true;
                    return false;
                }

                this.form.items = this.form.items.filter(item => item.transmittal_quantity > 0);

                this.form.submitting = true;

                TransmittalForm.submit(this.form)
                    .then(response => {
                        console.log('submit response', response);
                        this.form.submitted = true;
                        this.formSubmittedMessage.text = 'Transmittal received.';
                        this.formSubmittedMessage.type ='success';
                        this.formSubmittedMessage.buttonText = 'New Transmittal';
                        this.reviewDialog = true;
                    })
                    .catch(response => {
                        this.$toast.error('Error submitting transmittal form');
                    })
                    .finally(() => {
                        this.form.submitting = false;
                    })

                console.log('Submitting transmittal form', this.form);

            } else {
                this.$toast.error('Form validation failed. Verify entered data.');
            }
        },
        resetForm() {
            window.location.reload();
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/tabulator";
</style>