import AuthService from "../common/auth.service";
import {get} from "@/client/utilities/Object";

export default {
    beforeRouteEnter(to, from, next) {

        const requiresAuth = get(to, "meta.requiresAuth", true);

        /**
         * If a route does not require auth, continue to route
         */
        if (requiresAuth === false) {
            next();
        } else {
            /**
             * If a route requires auth, check if the user is authenticated from the local storage before proceeding
             */
            const authState = AuthService.isAuthenticated();

            if (authState) {
                next();
            } else {
                AuthService.intendedRoute(to);
                /**
                 * Redirect to login page if user is not authenticated
                 */
                next({name: 'Login'});
            }
        }
    },
    computed: {
        isAuthenticated() {
            return this.getAuthenticationState();
        },
        user() {
            return this.getUser();
        },
        userIsAdmin() {
            return this.user && this.user.is_admin;
        }
    },
    methods: {
        getAuthenticationState() {
            return AuthService.isAuthenticated();
        },
        authenticated() {
            return this.getAuthenticationState();
        },
        logout() {
            return AuthService.logout(true);
        },
        getUser() {
            return AuthService.getUser();
        },
    },
    watch: {
        darkMode(newValue, oldValue) {
            window.localStorage.setItem('darkMode', newValue);
            this.$vuetify.theme.dark = newValue;
        }
    }
}