import Client from "../lib/Client";
import NotificationTypeInterface from "../../models/Notification/NotificationType/NotificationTypeInterface";
import NotificationType from "../../models/Notification/NotificationType/NotificationType";
import ApiResponse from "../lib/ApiResponse";
export default class NotificationTypeService extends Client {
    model = new NotificationType;
    dataKey = 'notification_types';
    async get() {
        return await this.request.get('/notifications/types');
    }
}
