<template>
    <v-container class="pa-2 mt-14">
        <v-row align="center" align-content="center">
            <v-col cols="12" class="text-center mb-2">
                <h1 class="font-weight-light">
                    <v-icon large>mdi-warehouse</v-icon>
                    Virtual Warehouse
                </h1>
            </v-col>
        </v-row>

        <v-row
            align="center"
            align-content="center"
            justify="center"
            dense
        >
            <v-col lg="5" md="7" sm="10" align-self="center">
                <v-form @submit.prevent="login" class="text-center" ref="form" model="formValid">
                    <v-card class="mx-auto my-auto mt-2 mb-4 pt-0">
                        <v-card-title class="pb-1 font-weight-light">
                            Login
                        </v-card-title>
                        <v-card-text class="my-0 py-0 text-left">
                            <v-alert
                                type="error"
                                border="left"
                                v-show="defaultAuthAlert"
                                text
                                dense
                                dismissible
                            >
                                You must be logged in to access this page
                            </v-alert>
                        </v-card-text>
                        <v-card-text>
                            <v-alert
                                v-show="hasError"
                                border="left"
                                type="error"
                                prominent
                                class="text-left"
                                transition="scale-transition"
                                dense
                            >
                                {{errorMessage}}
                            </v-alert>
                            <v-text-field
                                outlined
                                v-model="form.email"
                                label="Email"
                                type="email"
                                required
                            />
                            <v-text-field
                                outlined
                                v-model="form.password"
                                required
                                label="Password"
                                type="password"
                            />

                            <v-btn
                                tile
                                block
                                large
                                class="ma-auto pa-5 px-16"
                                color="primary"
                                type="submit"
                                :loading="loading"
                            >
                                Login
                            </v-btn>
                        </v-card-text>
                    </v-card>

                </v-form>

                <v-row>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>


                <v-row class="text-center align-content-center justify-center">
                    <v-col>
                        <v-btn
                            tile
                            large
                            color="microsoftLoginButton"
                            class="pa-5 white--text"
                            @click="loginAzure"
                        >
                            <v-icon class="mr-3">mdi-microsoft</v-icon>
                            Login with Microsoft
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {get} from "@/client/utilities/Object";
import AuthService from "../common/auth.service";

export default {
    name: 'Login',
    title: 'Login',
    created() {
        if(this.isAuthenticated) {
            console.log('Already logged in');
            this.$router.push('/');
        }
    },
    methods: {
        loginAzure() {
            AuthService.socialAuth('azure');

        },
        async login() {

            this.loading = true;
            this.hasError = false;
            this.errorMessage = '';

            let login = await AuthService.login(this.form.email, this.form.password);

            this.loading = false;

            const status = get(login, 'data.status');
            const user = get(login, 'data.user');

            if(user) {
                AuthService.setUser(user);
                this.user = user;
            }

            if(status === 'error') {
                this.hasError = true;
                this.errorMessage = get(login, 'data.message');
            } else {
                this.$nextTick(() => {
                    window.location.href = '/';
                });
            }
        }
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            loading: false,
            formValid: false,
            hasError: false,
            errorMessage: '',
            defaultAuthAlert: true,
        }
    }
}
</script>