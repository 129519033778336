import { AxiosError, AxiosResponse } from "axios";
import ApiResponseInterface from "./ApiResponseInterface";
export default class ApiResponseError {
    data;
    response;
    error;
    status;
    dataKey;
    constructor(error) {
        this.error = error;
        this.response = error.response;
        this.status = error.response.status;
    }
    ok() {
        return false;
    }
}
