<script>

import {LOG_STYLES} from "@/common/log";
import CertificationDocumentManagerListItem
    from "@/components/CertificationDocuments/CertificationDocumentManagerListItem.vue";

export default {
    name: "CertificationDocumentManager",
    logContext: 'CertificationDocumentManager',
    logStyle: LOG_STYLES.PURPLE,
    components: {CertificationDocumentManagerListItem},
    inheritAttrs: false,
    props: [
        'documents',
        'toolId',
        'toolCertificationId',
        'toolReservationId',
        'rules'
    ],
    computed: {
        allowMobileDevice() {
            return false;
            // return (this.appNotificationTitle && this.appNotificationContent && this.appNotificationUrl);
        }
    },
    data: function () {
        return {
            loading: false,
            document: null,
            progress: {
                uploading: false,
                uploaded: false,
                processing: false,
                value: 0,
                color: 'primary'
            },
            uploading: false,
            uploadProgress: 0,
            uploadProcessing: false,
            uploadMessage: '',
            uploadColor: 'accent',
        }
    },
    methods: {
        onUploadProgress(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.progress.value = percentCompleted;
            this.progress.message = 'Uploading... ' + percentCompleted + '%';

            if (percentCompleted >= 100) {
                this.progress.processing = true;
                this.progress.message = 'Processing...';
            }
        },
        onDelete(index) {
            this.documents.splice(index, 1);
        },
        isValid() {
            return true;
        },
        save() {

            if (this.document && this.isValid()) {

                try {
                    const selectedData = {
                        photo: this.photo,
                        url: URL.createObjectURL(this.photo)
                    };

                    this.$emit('selected', selectedData);
                } catch (e) {

                }

                this.loading = 'orange';

                let data = new FormData();

                data.append('document', this.document);

                if (this.toolId) {
                    data.append('tool_id', this.toolId);
                }

                if (this.toolReservationId) {
                    data.append('tool_reservation_id', this.toolReservationId);
                }

                if (this.toolCertificationId) {
                    data.append('tool_certification_id', this.toolCertificationId);
                }

                if (typeof this.context === 'object') {
                    for (const ctx of this.context) {
                        data.append(ctx, this.context[ctx]);
                        this.$log.info(`Appending Context ${item}: ${this.context[ctx]}`);
                    }
                }

                this.uploading = true;

                this.$http.post('/v3/tools/certifications/documents/upload', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: this.onUploadProgress
                }).then(response => {

                    this.uploading = false;

                    this.document = null;

                    if (response.data && response.data.document) {
                        this.documents.push(response.data.document);
                        this.$emit('uploaded', response.data.document);
                        this.uploadProcessing = false;
                    } else {
                        this.$emit('failed');
                    }

                }).catch(error => {
                    this.$toast.error('Error uploading document.');
                    this.$log.error('Error Uploading Document', error);
                    this.$emit('failed');
                    this.uploadProgress = 0;
                    this.uploadMessage = 'Failed to upload document.';
                    this.uploadColor = 'error';
                    this.uploading = false;
                })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    },
    mounted() {
        if (!this.documents) {
            this.documents = [];
        }
    }
}
</script>

<template>
    <div class="my-2 mb-4">
        <VRow>
            <VCol>
                <VFileInput
                    v-model="document"
                    :loading="uploading"
                    :show-size="1000"
                    clearable
                    hide-details
                    label="Add Document"
                    outlined
                    placeholder="Add Document"
                    prepend-icon="mdi-file-upload"
                    v-bind:rules="rules"
                    @change="save"
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                            color="accent"
                            dark
                            label
                        >
                            {{ text }}
                        </v-chip>
                    </template>
                </VFileInput>
            </VCol>
        </VRow>
        <VRow>
            <VCol>
                <VSimpleTable
                    class="grey darken-4 elevation-4"
                    dense
                >
                    <thead v-once class="blue-grey darken-4">
                    <tr>
                        <th>ID</th>
                        <th>Filename</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <CertificationDocumentManagerListItem
                        v-for="(document, idx) in documents"
                        :key="idx"
                        :document="document"
                        @deleted="onDelete(idx)"
                    >
                    </CertificationDocumentManagerListItem>
                    </tbody>
                </VSimpleTable>
            </VCol>
        </VRow>
    </div>
</template>

<style scoped>

</style>