import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import BasicImage from "@/components/Photos/BasicImage.vue";
export default {
    name: 'ToolModelAutocomplete',
    components: { BasicImage },
    inheritAttrs: false,
    data() {
        return {
            loading: false,
            tool_models: [],
            used_with_tool_models: [],
            selected: null,
            search: null,
            dialog: false,
        };
    },
    watch: {
        search(value) {
            this.debounceFetchTools();
        }
    },
    methods: {
        get,
        searchFilter(item, queryText, itemText) {
            const words = queryText.toUpperCase().split(' ')
                .map(word => word.trim())
                .filter(word => word.length > 0);
            let matches = [];
            itemText = itemText.toUpperCase();
            for (const word of words) {
                if (itemText.indexOf(word) > -1) {
                    matches.push(word);
                }
            }
            return matches.length === words.length;
        },
        debounceFetchTools: debounce(function () { this.fetchToolModels(); }, 300),
        async fetchToolModels() {
            this.loading = true;
            const params = {
                search: this.search,
                toolType: this.toolType,
                onlyWithInventory: this.onlyWithInventory,
            };
            return this.$http.get('/v3/tools/models?toolType=' + this.toolType, { params: params })
                .then((response) => {
                this.tool_models = get(response, 'data.tool_models.data', []);
                return this.tool_models;
            }).finally(() => {
                this.loading = false;
            });
        },
        async fetchToolKitModels(toolKitId) {
            return this.$http.get('/v3/tools/kits/' + toolKitId + '/tools/models')
                .then((response) => {
                return get(response, 'data.tool_models', []);
            });
        },
        async onChange(value) {
            this.selected = value;
            if (this.resetOnSelection) {
                this.reset();
            }
            if (!value) {
                return;
            }
            if (value.is_tool_kit) {
                const models = await this.fetchToolKitModels(value.tool_kit_id);
                for (let model of models) {
                    model.from_tool_kit = true;
                    model.from_tool_kit_id = value.tool_kit_id;
                    setTimeout(() => {
                        //this.$emit('input', model);
                    }, 30);
                }
            }
            if (this.disableRelatedDialog) {
                return;
            }
            if (get(this.selected, 'used_with_tool_models', []).length > 0) {
                this.dialog = true;
                this.used_with_tool_models = get(this.selected, 'used_with_tool_models', []);
            }
        },
        reset() {
            this.$nextTick(() => {
                this.$refs.toolModelAutocomplete.reset();
            });
        }
    },
    props: {
        hideSelection: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Object, String, Array],
            default: []
        },
        toolType: {
            type: String,
            default: 'tool'
        },
        disableRelatedDialog: {
            type: Boolean,
            default: false
        },
        resetOnSelection: {
            type: Boolean,
            default: false
        },
        onlyWithInventory: {
            type: Boolean,
            default: false
        }
    },
    async mounted() {
        //  await this.fetchToolModels();
    }
};
