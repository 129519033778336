export default {
    name: 'TabBadgeHeader',
    props: {
        count: {
            type: String,
            default: 0
        },
        icon: {
            type: String,
            default: null
        }
    },
    computed: {
        countValue() {
            return '' + this.count;
        }
    }
};
