<template>
    <v-card
        color="jobDetailsBackground"
        v-bind="$attrs"
    >
        <v-card-subtitle
            v-if="!noTitle"
            class="jobDetailsTitleBackground py-2"
        >
            {{ title }}
        </v-card-subtitle>
        <v-card-text>
            <v-row class="py-2">
                <v-col v-if="jobNumber.length > 0">
                    <b>Job Number</b> <br>
                    {{ jobNumber }}
                </v-col>
                <v-col v-if="jobDescription.length > 0">
                    <b>Job Description</b> <br>
                    {{ jobDescription }}
                </v-col>
                <v-col v-if="projectManager.length > 0">
                    <b>Project Manager</b> <br>
                    {{ projectManager }}
                </v-col>
                <v-col v-if="foreman.length > 0">
                    <b>Foreman</b> <br>
                    {{ foreman }}
                </v-col>
                <v-col v-if="poNumber.length > 0">
                    <b>PO Number</b> <br>
                    {{ poNumber }}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'jobDetails',
    inheritAttrs: false,
    props: {
        noTitle: {
            type: Boolean,
            default: false
        },
        title: {
            default: 'Job Details'
        },
        jobNumber: {
            default: ''
        },
        jobDescription: {
            default: ''
        },
        projectManager: {
            default: ''
        },
        foreman: {
            default: ''
        },
        poNumber: {
            default: ''
        }
    }
}
</script>