import PageTitle from "@/components/PageTitle.vue";
import { get } from "@/client/utilities/Object";
import cloneObject from "@/common/clone-object";
import DividingHeader from "@/components/DividingHeader.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import NotesTextFieldV2 from "@/components/V2/NotesTextField-V2.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import ToolAutocomplete from "@/components/ToolAutocomplete.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import ToolStatusAutocomplete from "@/components/Tools/Autocompletes/ToolStatusAutocomplete.vue";
import ToolManufacturerAutocomplete from "@/components/Tools/Autocompletes/ToolManufacturerAutocomplete.vue";
import ToolCategoryAutocomplete from "@/components/Tools/Autocompletes/ToolCategoryAutocomplete.vue";
import ToolNameInput from "@/components/Tools/Inputs/ToolNameInput.vue";
import ToolDescriptionInput from "@/components/Tools/Inputs/ToolDescriptionInput.vue";
import QuantityInput from "@/components/Tools/Inputs/QuantityInput.vue";
import CurrencyInput from "@/components/Input/CurrencyInput.vue";
import ToolModelNumberInput from "@/components/Tools/Inputs/ToolModelNumberInput.vue";
import ToolCompatibilityAutocomplete from "@/components/Tools/Autocompletes/ToolCompatibilityAutocomplete.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import CertificationDocumentManager from "@/components/CertificationDocuments/CertificationDocumentManager.vue";
import ToolCertificationForm from "@/components/Tools/Forms/ToolCertificationForm.vue";
import ToolSerialNumberInput from "@/components/Tools/Inputs/ToolSerialNumberInput.vue";
import VehicleAutocomplete from "@/components/VehicleAutocomplete.vue";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";
export default {
    name: 'CreateAccessory',
    components: {
        LoadingDialog,
        VehicleAutocomplete,
        ToolSerialNumberInput,
        ToolCertificationForm,
        CertificationDocumentManager, DatePicker,
        FormSubmittedMessage,
        ToolCompatibilityAutocomplete,
        ToolModelNumberInput,
        CurrencyInput,
        QuantityInput,
        ToolDescriptionInput,
        ToolNameInput,
        ToolCategoryAutocomplete,
        ToolManufacturerAutocomplete,
        ToolStatusAutocomplete,
        NotesTextField,
        ToolAutocomplete,
        PhotoThumbnail,
        ToolModelAutocomplete, JobAutocomplete, NotesTextFieldV2, PhotoManager, DividingHeader, PageTitle
    },
    title: 'Create Accessory',
    data() {
        return {
            rules: {
                req: (message) => {
                    return [v => !!v || message];
                },
                required: [v => !!v || 'Required']
            },
            data: {
                tool_models: [],
                categories: [],
                manufacturers: [],
                racks: [],
                statuses: [],
                accessory: null,
            },
            loadingTool: false,
            loadingAccessoryDialog: false,
            toolModelId: null,
            validationErrors: [],
            valid: false,
            selectedToolModels: [],
            submitting: false,
            submitted: false,
            form: {
                id: null,
                locationType: 'warehouse',
                photos: [],
                vehicle: null,
                quantity: 1,
                name: null,
                sku: null,
                product: null,
                description: null,
                price: null,
                tool_model: null,
                notes: null,
                linked_tool_models: [],
                status: null,
                serial_number: null,
                category: null,
                manufacturer: null,
                rack: null,
                section: null,
                sub_section: null,
                job: null,
                certification: {
                    documents: [],
                    certified_at: null,
                    expires_at: null,
                    notes: null,
                    required: false,
                }
            }
        };
    },
    methods: {
        get,
        async fetchCompatibilities() {
            return this.$http.get('/v3/tools/compatibilities/' + this.toolModelId).then(response => {
                return get(response, 'data.tool_compatibilities', []);
            });
        },
        async fillForm(tool) {
            this.data.accessory = tool;
            this.toolModelId = get(tool, 'tool_model_id');
            let compatibilities = await this.fetchCompatibilities();
            for (let c of compatibilities) {
                this.form.linked_tool_models.push(c.tool_model);
            }
            this.form.id = tool.id;
            if (tool.job) {
                this.form.job = tool.job;
                this.form.locationType = 'jobsite';
            }
            else if (tool.vehicle) {
                this.form.vehicle = tool.vehicle;
                this.form.locationType = 'vehicle';
            }
            else {
                this.form.locationType = 'warehouse';
                this.form.rack = this.getRack(tool.tool_rack_id);
                this.form.section = this.getSection(this.form.rack, tool.tool_section_id);
                this.form.sub_section = this.getSubSection(this.form.section, tool.tool_sub_section_id);
            }
            this.form.name = tool.name;
            this.form.description = tool.description;
            this.form.category = tool.tool_category;
            this.form.manufacturer = tool.tool_manufacturer;
            this.form.photos = tool.photos;
            this.form.notes = tool.notes;
            this.form.status = tool.tool_status;
            this.form.price = tool.price;
            this.form.sku = tool.sku;
        },
        async fetchAccessory() {
            let id = this.$route.params.id;
            if (id) {
                const tool = await this.$http.get('/v3/tools/accessories/' + id).then((response) => {
                    return response.data;
                });
                if (tool) {
                    await this.fillForm(tool);
                }
            }
        },
        async submit() {
            this.$log.log('Creating accessory', this.form);
            if (this.$refs.accForm.validate() && !this.submitting) {
                this.submitting = true;
                return await this.$http.post('/v3/tools/accessories', this.form).then(response => {
                    this.$log.log('Accessory created', response);
                    this.submitted = true;
                    if (this.editing) {
                        this.$router.push({ name: 'tools' });
                    }
                }).finally(() => {
                    this.submitting = false;
                });
            }
            else {
                this.validationErrors = this.$refs.accForm.inputs.filter(input => !input.valid)
                    .map(input => input.errorBucket)
                    .flat();
                this.submitting = false;
                this.submitted = false;
            }
        },
        async fetchRacks() {
            return this.$http.get('/v3/tools/locations/racks').then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                return this.data.racks;
            });
        },
        async fetchData() {
            const toolId = this.$route.params.id;
            if (toolId) {
                this.loadingTool = true;
            }
            return this.$http.get('/v3/tools/forms/create/data', { params: { tool_id: toolId } }).then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                //this.categories = get(response, 'data.categories', []);
                //this.manufacturers = get(response, 'data.manufacturers', []);
                const tool = get(response, 'data.tool', null);
                if (tool) {
                    this.fillForm(tool);
                }
            }).finally(() => {
                this.loadingTool = false;
            });
        },
        getRack(rackId) {
            if (rackId) {
                const rack = this.data.racks.filter((r) => r.id === rackId);
                if (rack) {
                    return cloneObject(rack[0]);
                }
            }
            return [];
        },
        getSection(rack, sectionId) {
            if (rack && sectionId) {
                const section = rack.sections.filter((s) => s.id === sectionId);
                if (section) {
                    return cloneObject(section[0]);
                }
            }
            return [];
        },
        getSubSection(section, subSectionId) {
            if (section && subSectionId) {
                const subSection = section.sub_sections.filter((s) => s.id === subSectionId);
                if (subSection) {
                    return cloneObject(subSection[0]);
                }
            }
            return [];
        },
        racks() {
            return this.data.racks;
        },
        sections(rack) {
            return get(rack, 'sections', []);
        },
        subSections(section) {
            return get(section, 'sub_sections', []);
        },
        goBack() {
            this.$router.push({ name: 'tools' });
        }
    },
    watch: {
        'form.tool_model'(model) {
            this.form.product = 'no-clear';
            if (model) {
                this.form.manufacturer = model.tool_manufacturer;
                this.form.category = model.tool_category;
                this.form.name = model.name;
                this.form.sku = model.sku;
                this.form.description = model.description;
                this.form.price = model.price;
                this.form.photos = model.photos;
            }
            else {
                this.form.manufacturer = null;
                this.form.category = null;
                this.form.name = null;
                this.form.sku = null;
                this.form.description = null;
                this.form.price = null;
                this.form.photos = [];
            }
        },
        'form.product'(product) {
            if (product && product !== 'no-clear') {
                this.form.name = this.form.product.title;
                this.form.description = this.form.product.description;
                this.form.sku = this.form.product.sku;
                this.form.sku = this.form.product.sku;
                this.form.manufacturer = get(product, 'manufacturer.name');
                const images = this.form.product.images;
                for (let i = 0; i < images.length; i++) {
                    let url = images[i].url;
                    if (!url) {
                        url = images[i].original_url;
                    }
                    let photo = {
                        id: null,
                        url: url,
                        original_filename: '',
                        thumbnail: {
                            url: url
                        }
                    };
                    this.form.photos.push(photo);
                }
            }
            else {
                if (product !== 'no-clear') {
                    this.form.name = null;
                    this.form.description = null;
                    this.form.sku = null;
                    this.form.photos = [];
                }
            }
        },
    },
    computed: {
        editing() {
            return this.$route.params.id > 0;
        },
    },
    async created() {
        await this.fetchData();
    }
};
