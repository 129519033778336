import { get } from "../../utilities/Object";
class ApiConfigurationManager {
    _apiRoot = '/api/';
    _baseUrl;
    _apiVersion = 'v3';
    _environment;
    constructor(version = 'v3') {
        this.setEnvironment(this.getCurrentEnvironment());
        this.setApiRoot(this.getEnv('VUE_APP_API_ROOT', '/'));
        this.setApiVersion(this.getEnv('VUE_APP_API_VERSION', version));
        this._baseUrl = this.baseUrl();
    }
    setEnvironment(environment) {
        console.log("API ENVIRONMENT: " + environment);
        this._environment = environment;
    }
    setBaseUrl(baseUrl) {
        if (baseUrl) {
            this._baseUrl = this.padEnd(baseUrl, '/');
        }
        console.log(`API_BASE_URL: ${this._baseUrl}`);
        return this;
    }
    setApiVersion(apiVersion) {
        this._apiVersion = this.padEnd(apiVersion, '/');
        console.log(`API_VERSION: ${this._apiVersion}`);
        return this;
    }
    setApiRoot(apiRoot) {
        if (apiRoot) {
            this._apiRoot = this.padEnd(apiRoot, '/');
        }
        console.log(`API_ROOT: ${this._apiRoot}`);
        return this;
    }
    padEnd(subject, replacement = '/') {
        if (subject && !subject.endsWith(replacement)) {
            return subject + replacement;
        }
        return subject;
    }
    baseUrl() {
        return `${this._apiRoot}${this._apiVersion}`;
    }
    apiVersion() {
        return this._apiVersion;
    }
    getEnv(key, _default) {
        return get(process.env, key, _default);
    }
    getCurrentEnvironment() {
        return get(process, 'env.NODE_ENV', 'local');
    }
}
const instance = new ApiConfigurationManager();
export default instance;
export { ApiConfigurationManager };
