const SidebarItems = (that) => {
    return [
        {
            text: 'Home',
            to: '/',
            icon: {
                name: 'mdi-home',
            }
        },
        {
            text: 'Tools',
            to: null,
            visible: () => {
                return that.user && that.user.is_admin;
            },
            icon: {
                name: 'mdi-tools',
                color: 'blue-grey'
            },
            items: [
                {
                    text: 'Manage Tools',
                    to: '/tools/list',
                },
                {
                    text: 'Create Tool',
                    to: '/tools/create',
                },
                {
                    text: 'Categories',
                    to: '/tools/categories',
                },
                {
                    text: 'Certifications',
                    to: '/tools/certifications',
                },
                {
                    subHeader: 'Devices'
                },
                {
                    text: 'Add Device',
                    to: '/tools/devices/create'
                },
                {
                    text: 'Manage Devices',
                    to: '/tools/devices/list'
                },
                {
                    subHeader: 'Kits'
                },
                {
                    text: 'Tool Kits',
                    to: '/tools/kits/list',
                },
                {
                    text: 'Create Tool Kit',
                    to: '/tools/kits/create'
                },
                {
                    subHeader: 'Accessories'
                },
                {
                    text: 'Create Accessory',
                    to: '/tools/accessories/create'
                },
                {
                    text: 'Create Consumable',
                    to: '/tools/consumables/create'
                },
                {
                    subHeader: 'Reservations'
                },
                {
                    text: 'Checkout Request',
                    to: '/tools/checkout/request',
                },
                {
                    text: 'Checkout Approval',
                    to: '/tools/checkout/approval',
                },
                {
                    text: 'Check-in',
                    to: '/tools/check-in',
                },
                {
                    subHeader: 'Vehicles',
                },
                {
                    text: 'Manage Vehicles',
                    to: '/vehicles/list'
                },
                {
                    text: 'Create Vehicle',
                    to: '/vehicles/create'
                },
                {
                    subHeader: 'Data Management',
                },
                {
                    text: 'Export Tool Data',
                    to: '/tools/data/export',
                    icon: {
                        name: 'mdi-cloud-download-outline',
                        color: 'green'
                    }
                },
              /*  {
                    text: 'Import Tool Data',
                    to: '/tools/data/import',
                    icon: {
                        name: 'mdi-cloud-upload-outline',
                        color: 'blue-grey lighten-1'
                    }
                },*/
            ]
        },
        {
            text: 'Requests',
            to: null,
            icon: {
                name: 'mdi-home-import-outline',
                color: 'light-blue'
            },
            visible: () => {
                return true;
            },
            items: [
                {
                    text: 'View Approval Requests',
                    to: '/approval/requests'
                },
                {
                    text: 'Receiving Request',
                    to: '/requests/receiving'
                },
                {
                    text: 'RMA Request',
                    to: '/requests/rma'
                },
                {
                    text: 'Transmittal Request',
                    to: '/requests/transmittal'
                },
                {
                    subHeader: 'Materials'
                },
                {
                    text: 'Material Requests',
                    to: '/materials/requests',
                },
                {
                    text: 'New Material Request',
                    to: '/materials/requests/create',
                }
            ]
        },
        {
            text: 'Warehouse Inventory',
            to: '/warehouse-inventory',
            icon: {
                name: 'mdi-clipboard-text',
                color: 'green'
            }
        },
        {
            text: 'Receive Form',
            to: '/receive-form',
            icon: {
                name: 'mdi-home-import-outline',
                color: 'light-blue'
            }
        },
        {
            text: 'Transmittal Form',
            to: '/transmit-form',
            icon: {
                name: 'mdi-truck-delivery',
                color: 'green'
            }
        },
        {
            text: 'Owner Provided Materials Receiving Form',
            to: '/receive-owner-materials-form',
            icon: {
                name: 'mdi-import',
                color: 'purple lighten-2'
            }
        },
        {
            text: 'Return to Warehouse from Job Restock Form',
            to: '/restock-form',
            icon: {
                name: 'mdi-home-import-outline',
                color: 'indigo lighten-2'
            }
        },
        {
            text: 'Inventory Form',
            to: '/inventory-form',
            icon: {
                name: 'mdi-order-bool-ascending-variant',
                color: 'cyan'
            }
        },
        {
            text: 'Add Purchase Order Form',
            to: '/po-form',
            icon: {
                name: 'mdi-cart-plus',
                color: 'red lighten-1'
            }
        },
        {
            text: 'Project Kit Creation Form',
            to: '/kit-form',
            icon: {
                name: 'mdi-tag-multiple',
                color: 'green lighten-1'
            }
        },
        {
            text: 'RMA Form',
            to: '/rma-form',
            icon: {
                name: 'mdi-cash-refund',
                color: 'yellow darken-3'
            }
        },
        {
            text: 'Reports',
            to: null,
            visible: () => {
                return true;
            },
            icon: {
                name: 'mdi-chart-multiple',
                color: 'light-blue'
            },
            items: [
                {
                    text: 'Reports',
                    to: '/reports/photos'
                },
            ]
        },
        {
            text: 'About',
            to: '/about',
            icon: {
                name: 'mdi-help-box',
            }
        },
    ];
}

export default SidebarItems;