import Api from "@/client/api/Api";
export default {
    name: "NotificationTypeAutocomplete",
    data() {
        return {
            notificationTypes: [],
            loading: false
        };
    },
    methods: {
        async fetchNotificationTypes() {
            const response = await Api.notificationTypes.get();
            this.notificationTypes = response.data;
        }
    },
    mounted() {
        this.fetchNotificationTypes();
    }
};
