import PageTitle from "@/components/PageTitle.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import { clone, get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import BasicImage from "@/components/Photos/BasicImage.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
export default {
    name: 'ToolCheckIn',
    components: { PhotoThumbnail, BasicImage, PhotoManager, JobInfoChip, DividingHeader, JobAutocomplete, PageTitle },
    title: 'Tool Check In',
    data() {
        return {
            searchText: '',
            job: null,
            bench: 0,
            reservations: [],
            reservationItems: [],
            filteredReservations: [],
            selectedReservation: {
                tool: {
                    name: null,
                },
            },
            data: {
                jobs: [],
                statuses: [],
                racks: [],
                form: {
                    valid: false,
                    loading: false,
                    reservation: {},
                    notes: null,
                    photos: [],
                    submitting: false,
                    rack: 'Tool Receiving Table',
                    section: 'NONE',
                    sub_section: 'NONE',
                    status: 'Available',
                }
            },
            dialog: false,
            selected: {
                reservations: [],
                job: null,
            },
            form: {
                valid: false,
                loading: false,
                submitting: false,
                reservation: {},
                notes: null,
                photos: [],
                rack: 'Tool Receiving Table',
                section: 'NONE',
                sub_section: 'NONE',
                status: 'Available',
            }
        };
    },
    watch: {
        dialog: function (value) {
            if (value) {
                this.selected.reservations = this.selected.reservations.map(reservation => {
                    reservation.tool_status = this.data.statuses[0];
                    return reservation;
                });
            }
        }
    },
    methods: {
        get,
        onSearch: debounce(function () {
            this.filteredReservations = clone(this.reservations.filter((reservation) => this.filterReservations(reservation)));
        }, 500),
        filterReservations(reservation) {
            const queryText = this.searchText;
            let items = [
                reservation.search_text,
                get(reservation, 'job.job_number'),
                reservation.employee_names,
                reservation.vehicle_names
            ];
            let itemText = items.join(' ');
            if (queryText.length > 2) {
                const words = queryText.toUpperCase().split(' ')
                    .map(word => word.trim())
                    .filter(word => word.length > 0);
                let matches = [];
                itemText = itemText.toUpperCase();
                for (const word of words) {
                    if (itemText.indexOf(word) > -1) {
                        matches.push(word);
                    }
                }
                return matches.length === words.length;
            }
            return true;
        },
        async fetchReservationJobs() {
            return this.$http.get('/v3/tools/reservations/jobs').then(response => {
                this.data.jobs = get(response, 'data.jobs', []);
                return response;
            });
        },
        async fetchReservations() {
            return this.$http.get('/v3/tools/reservations').then(response => {
                this.reservationItems = get(response, 'data.reservations', []);
            });
        },
        async fetchReservationBatches() {
            return this.$http.get('/v3/tools/reservations/batches/reserved').then(response => {
                this.reservations = get(response, 'data.reservations', []);
                this.filteredReservations = clone(this.reservations);
                return this.reservations;
            });
        },
        getBatchItems(uuid) {
            //this.selectedReservation = reservation;
            this.$http.get('/v3/tools/reservations/by-batch-uuid/' + uuid).then(response => {
                this.selected.job = get(response, 'data.reservations.0.job', null);
                this.dialog = true;
            });
        },
        checkIn(uuid, reservation) {
            reservation.loading = true;
            this.$http.get(`/v3/tools/reservations/by-batch-uuid/${uuid}/reserved`).then(response => {
                this.selected.reservations = get(response, 'data.reservations', []);
                this.selected.job = get(response, 'data.reservations.0.job', null);
                this.dialog = true;
            }).finally(() => {
                reservation.loading = false;
            });
        },
        async sleep(delay) {
            return new Promise((resolve) => setTimeout(resolve, delay));
        },
        async submit(item = null) {
            if (item && item.type !== 'submit') {
                this.selected.reservations = [item];
            }
            await this.sleep(100);
            if (this.$refs.form.validate()) {
                if (item && item.type !== 'submit') {
                    item.submitting = true;
                }
                this.form.reservations = clone(this.selected.reservations);
                this.form.job = clone(this.selected.job);
                this.form.submitting = true;
                this.$http.post(`/v3/tools/reservations/${this.selectedReservation.id}/check-in`, this.form)
                    .then(response => {
                    this.dialog = false;
                    this.fetchReservationBatches();
                    this.reset();
                }).finally(response => {
                    this.form.submitting = false;
                });
            }
            else {
                if (item && item.type !== 'submit') {
                    item.submitting = false;
                }
                return false;
            }
        },
        async fetchRacks() {
            return this.$http.get('/v3/tools/locations/racks').then((response) => {
                return get(response, 'data.racks', []);
            });
        },
        async fetchStatuses() {
            return this.$http.get('/v3/tools/statuses').then((response) => {
                return get(response, 'data.statuses', []);
            });
        },
        sections(item) {
            return get(item, 'sections', []);
        },
        subSections(item) {
            return get(item, 'sub_sections', []);
        },
        cancel() {
            this.dialog = false;
            this.reset();
        },
        reset() {
            this.form = clone(this.data.form);
        }
    },
    computed: {},
    async mounted() {
        this.data.racks = await this.fetchRacks();
        this.data.statuses = await this.fetchStatuses();
        this.form.status = this.data.statuses[0];
        await this.fetchReservationBatches();
        await this.fetchReservationJobs();
    }
};
