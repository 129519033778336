import Vue from 'vue';
import {get} from "@/client/utilities/Object";

const AlertService = {
    vue: null,
    messages: [],
    loadMessages() {
        this.messages = Vue.$cookies.get('messages') ?? [];
    },
    clearMessages() {
        Vue.$cookies.remove('messages');
        this.messages = [];
    },
    displayToasts() {
        if(Array.isArray(this.messages)) {

            for (let message of this.messages) {
                if (message.type === 'error') {
                    Vue.$toast.error(message.message);
                }
            }

            this.clearMessages();
        }
    },
    processResponseAlerts(response) {
        if(response) {
            let alerts = get(response, 'data.__alerts');
            if (Array.isArray(alerts)) {
                for (const alert of alerts) {
                    switch (alert.type) {
                        default:
                            Vue.$toast.info(alert.message, alert.options);
                            break;
                        case 'error':
                            Vue.$toast.error(alert.message, alert.options);
                            break;
                        case'success':
                            Vue.$toast.success(alert.message, alert.options);
                            break;
                        case 'warning':
                            Vue.$toast.warning(alert.message, alert.options);
                            break;
                    }
                }
            }
        }
    }
}

export default AlertService;