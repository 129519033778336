<template>
<v-container>

    <PageTitle>Not Found</PageTitle>

    <v-row>
        <v-col>
            <v-alert
                    color="error"
                    prominent
                    type="error"
                    border="left"
            >
                Page Not Found
            </v-alert>
        </v-col>
    </v-row>
    <v-row>
        <v-col class="text-center">

            <v-btn
                    class="align-center ma-auto"
                    large
                    block
                    color="primary"
                    @click="$router.push('/')"
            >
                Go Back to Home
            </v-btn>
        </v-col>
    </v-row>

</v-container>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";

export default {
    name: "About",
    title: 'About',
    components: {
        PageTitle
    }
}
</script>