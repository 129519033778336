<template>
    <v-container>

        <VImg width="100" contain src="/img/logos/redtop-logo-2.svg"></VImg>
        <VImg width="100" contain src="/img/logos/legac-logo.svg"></VImg>
        <VImg width="100" contain src="/img/logos/point1-logo.svg"></VImg>


        <v-card class="mb-3">
            <v-card-subtitle>Buttons</v-card-subtitle>
            <v-card-text>
                <v-row class="my-6">
                    <v-col v-for="color in buttonColors" :key="color">
                        <v-btn :color="color">
                            {{ color }}
                        </v-btn>

                    </v-col>
                </v-row>

                <v-row class="my-6">
                    <v-col v-for="alertColor in alertColors" :key="alertColor">
                        <v-alert
                            :type="alertColor"
                            rounded
                            elevation="2"
                            border="left"
                        >
                            {{ alertColor }}
                        </v-alert>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <v-row>
                    <v-col v-for="toast in toastTypes" :key="toast">
                        <v-btn @click="showToast(toast)">
                            test {{toast}} toast
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-3">
            <v-card-title class="green lighten-4">
                Transmittal Form
            </v-card-title>
            <v-card-text>

            </v-card-text>
            <v-card-text>

                <OpenJobsAutocomplete v-model="selectedJob" />

                <PurchaseOrdersAutocomplete v-bind:purchaseOrders.sync="pos"/>

            </v-card-text>
        </v-card>

        <JobDetails
            po-number="22-035.1015"
            job-number="22-035."
            job-description="JMH - OR Expansion & NPC4 Phs 3&4"
            project-manager="Mike Podolak"
            foreman="Brian Supinger"
        />

    </v-container>
</template>
<script>
import JobDetails from "../components/JobDetails.vue";
import OpenJobsAutocomplete from "../components/OpenJobsAutocomplete.vue";
import PurchaseOrdersAutocomplete from "../components/PurchaseOrdersAutocomplete.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";

export default {
    components: {
        PhotoManager,
        JobDetails,
        PurchaseOrdersAutocomplete,
        OpenJobsAutocomplete
    },
    title: 'About',
    watch: {
        selectedJob: (newVal, oldVal) => {
            console.log(newVal, oldVal);
        },
        pos(newVal, oldVal) {
            console.log(newVal, oldVal);
        }
    },
    methods: {
        showToast(toast) {
            this.$toast[toast]('Test ' + toast + ' toast');
        }
    },
    async mounted() {
        let resp = await this.$http.get('/v2/photos');
        this.photos = resp.data.photos.slice(0, 10);
    },
    data() {
        return {
            photos: [],
            context: {
                purchase_order_id: 1,
                purchase_order_line_item_id: 1,
                warehouse_rack_id: 1
            },
            pos: [],
            selectedJob: {},
            toastTypes: ['success', 'info', 'warning', 'error'],
            buttonColors: [
                'primary', 'secondary', 'accent', 'addLocation', 'removeLocation',
            ],
            alertColors: [
                'info', 'success', 'warning', 'error',
            ]
        }
    }
}
</script>