<template>
    <v-container>
        <PageTitle>
            Project Kit Creation Form
        </PageTitle>

        <FormSubmittedMessage
            button-text="New Project Kit Creation Form"
            v-if="!showForm"
            :on-click="resetForm"
        >
            {{ serverResponseMessage }}
        </FormSubmittedMessage>

        <v-form v-else v-model="formValid" ref="form">
            <v-card class="my-3 pt-4">
                <v-card-text class="py-2">
                    <NotesTextField
                        label="Kit Creation Notes"
                        v-model="kitCreationNote"
                    />
                </v-card-text>
                <v-card-text class="py-0">
                    <v-combobox
                        v-model="kitName"
                        :items="existingKitNames"
                        :rules="[requiredField]"
                        :loading="loadingImport"
                        clearable
                        label="Kit Name"
                        outlined
                        placeholder="Start typing to Search or Add a new name"
                        prepend-inner-icon="mdi-database-search"
                        return-object
                    ></v-combobox>
                </v-card-text>
            </v-card>

            <v-card class="mb-6">
                <v-card-subtitle>
                    Kit Location
                </v-card-subtitle>
                <v-card-text class="py-0">
                    <v-row
                        class="d-flex flex-col flex-sm-row justify-sm-space-around py-0 my-0"
                    >
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select
                                v-model="kitLocation.packageType"
                                outlined
                                :items="packageTypes"
                                label="Package Type"
                                prepend-inner-icon="mdi-package-variant"
                                @change="
                  kitLocation.rack = '';
                  kitLocation.section = '';
                  kitLocation.subSection = '';
                "
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="4" md="3" class="py-0">
                            <v-select
                                v-model="kitLocation.rack"
                                :items="availableRacks(kitLocation)"
                                label="Rack"
                                @change="
                  selectedSection = '';
                  selectedSubSection = '';
                "
                                :rules="[requiredField]"
                                :loading="loadingImport"
                                required
                                outlined
                                hide-details="auto"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="4" md="3" class="py-0">
                            <v-select
                                v-model="kitLocation.section"
                                :items="availableSections(kitLocation)"
                                label="Section"
                                :rules="[requiredField]"
                                @change="selectedSubSection = ''"
                                required
                                outlined
                                hide-details="auto"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="4" md="3" class="py-0">
                            <v-select
                                v-model="kitLocation.subSection"
                                :items="availableSubSections(kitLocation)"
                                label="Sub-section"
                                :rules="packageType !== 'Loose' ? [requiredField] : []"
                                :disabled="packageType === 'Loose'"
                                required
                                outlined
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="my-3">
                <v-card-text class="px-4">

                    <JobsAutocomplete
                            v-model="chosenJob"
                            :jobs="inventoryJobs"
                            :items="inventoryJobs"
                            :loading="loadingImport"
                    />
                </v-card-text>
                <v-card-text class="px-4 py-0">
                    <v-autocomplete
                        v-model="chosenItems"
                        :items="itemNames"
                        clearable
                        :disabled="!chosenJob"
                        label="Part Number & Description"
                        placeholder="Start typing to Search"
                        prepend-inner-icon="mdi-tag-search"
                        deletable-chips
                        small-chips
                        outlined
                        multiple
                        return-object
                    ></v-autocomplete>
                </v-card-text>
            </v-card>

            <v-card class="mb-3" v-show="chosenItems.length > 0">
                <v-card-text>
                    <v-list
                        v-for="(item, i) in selectedParts"
                        :key="i"
                    >
                        <!-- Display part Info -->
                        <v-list-item>
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="12" sm="2">
                                        <v-list-item-subtitle>PO #</v-list-item-subtitle>
                                        {{ item.partPONumber }}
                                    </v-col>
                                    <v-col cols="12" sm="10">
                                        <v-list-item-subtitle>Name</v-list-item-subtitle>
                                        {{ item.partComboName }}
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-col cols="12" sm="2">Location(s):</v-col>
                        <v-list-item
                            v-for="(locationInfo, locationIndex) in item.partLocations"
                            :key="locationIndex"
                            class="ml-4"
                        >
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="12" sm="2">
                                        <v-list-item-subtitle>Rack</v-list-item-subtitle>
                                        {{ locationInfo.rack }}
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-list-item-subtitle>Section</v-list-item-subtitle>
                                        {{ locationInfo.section }}
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-list-item-subtitle>Sub Section</v-list-item-subtitle>
                                        {{ locationInfo.subSection }}
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-list-item-subtitle
                                        >Amount ({{
                                                partQuantityLabel(i, item.partComboName, locationIndex)
                                            }})
                                        </v-list-item-subtitle
                                        >
                                        <v-text-field
                                            type="number"
                                            filled
                                            outlined
                                            v-model="locationInfo.movingQuantity"
                                            :rules="[
                      requiredField,
                      partQuantityLimit(
                        locationInfo.movingQuantity,
                        item.partComboName,
                        locationIndex
                      ),
                    ]"
                                            class="mt-4"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-show="packageType === 'Loose'">
                                        <v-list-item-subtitle>New sub section:</v-list-item-subtitle>
                                        <v-select
                                            v-model="locationInfo.newSubSection"
                                            :items="subSections"
                                            label="New Sub-Section"
                                            :rules="
                      packageType === 'Loose' &&
                      parseFloat(locationInfo.movingQuantity) != parseFloat(0)
                        ? [requiredField]
                        : []
                    "
                                            :disabled="
                      parseFloat(locationInfo.movingQuantity) === parseFloat(0)
                    "
                                            filled
                                            outlined
                                            dense
                                            hide-details="auto"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider color="grey"></v-divider>
                    </v-list>
                </v-card-text>
            </v-card>

            <!-- Submit button -->
            <v-row>
                <v-col>
                    <FormSubmitButton
                        @click="submitForm"
                        :loading="loading"
                        :disabled="!formValid"
                    >
                        Submit
                    </FormSubmitButton>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import {get} from "@/client/utilities/Object";
import {KitCreationFormService} from "@/common/api/api.service";
import FormSubmitButton from "../components/FormSubmitButton.vue";
import FormSubmittedMessage from "../components/FormSubmittedMessage.vue";
import NotesTextField from "../components/NotesTextField.vue";
import PageTitle from "../components/PageTitle.vue";
import JobsAutocomplete from "../components/V2/JobsAutocomplete.vue";

export default {
    components: {JobsAutocomplete, FormSubmitButton, NotesTextField, PageTitle, FormSubmittedMessage},
    title: 'Kit Form',
    data() {
        return {
            showForm: true,
            serverResponseMessage: "",
            loadingImport: true,
            loading: false,
            formValid: false,
            db: {},
            employeeName: "",
            presetEmployees: [],

            kitCreationNote: "",
            kitName: "",
            existingKitNames: [],

            packageType: "Pallet",
            packageTypes: ["Pallet", "Long Pallet", "Loose"],
            kitLocation: {
                packageType: "Pallet",
                rack: null,
                section: null,
                subSection: null
            },
            racks: [],
            sections: [],
            subSections: [],
            rackInfo: [],
            selectedRack: "",
            selectedSection: "",
            selectedSubSection: "",

            availableLocations: {},
            availableLocationsForLooseItems: {},

            availableJobs: [],
            chosenJob: {},
            chosenItems: [],
            itemNames: [],
            inventoryJobs: [],

            itemsOfSelectedJob: {},
            selectedParts: [],
            requiredField: (v) => !!v || "This is a required field",
            partQuantityRule: [
                (v) => !!v || "This is a required field",
                (v) => (parseInt(v) >= 0 ? true : "Quantity cannot be negative"),
            ],
        };
    },
    created() {
        console.log("created");
        this.fetchDatabase();
    },
    computed: {
        form() {
            return {
                employeeName: this.employeeName,
                kitCreationNote: this.kitCreationNote,
                kitName: this.kitName,
                kitLocation: this.kitLocation,
                parts: this.selectedParts,
            };
        }
    },
    watch: {
        // If packageType is Loose, reset the selectedSubSection
        packageType(val) {
            if (val === "Loose") {
                this.selectedSubSection = "";
            }
        },
        // Fill out the list of available parts when a job number is chosen
        chosenJob(job) {
            if (!job) return;
            this.chosenItems = [];
            this.itemNames = [];

            const val = get(job, 'job_number');

            console.log(val);

            for (const [poNumber, itemDetails] of Object.entries(this.db[val])) {
                // Create list of items' names for selection
                this.itemNames.push(...Object.keys(itemDetails));
            }
            // Create a list of items and their details
            this.getParts();
        },
        // Fill out the list of available parts when a part name is chosen
        chosenItems(selectedItems) {
            if (!selectedItems) return;

            this.selectedParts = [];

            for (const item of selectedItems) {
                // Create a copy of the item details
                const details = JSON.parse(
                    JSON.stringify(this.itemsOfSelectedJob[item])
                );
                this.selectedParts.push(details);
            }
        },
    },
    methods: {
        fetchDatabase() {

            KitCreationFormService.getFormData()
                .then((response) => {
                    let json = get(response, "data");

                    if (json) {
                        this.db = json["jobs"];
                        this.presetEmployees = json['employeeNames'];
                        this.availableLocations = json["availableLocations"];
                        this.availableLocationsForLooseItems = json["availableLocationsForLooseItems"];
                        this.rackInfo = this.availableLocations;
                        this.racks = Object.keys(this.availableLocations);
                        this.availableJobs = Object.keys(this.db);
                        this.existingKitNames = json["existingKitNames"];
                        this.loadingImport = false;
                        this.inventoryJobs = json["inventoryJobs"];
                    }

                });
        },
        availableRacks(location) {
            let rackOptions = [];
            if (location.packageType.toLowerCase() === "Pallet".toLowerCase()) {
                rackOptions = Object.keys(this.rackInfo).sort((a, b) => a - b);
            } else if (
                location.packageType.toLowerCase() === "Long Pallet".toLowerCase()
            ) {

                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const rack of Object.keys(this.rackInfo)) {
                    for (const section of Object.keys(this.rackInfo[rack])) {

                        if (
                            this.rackInfo[rack][section].includes(1) &&
                            this.rackInfo[rack][section].includes(2)
                        ) {
                            rackOptions.push(rack);
                            break;
                        }
                    }
                }
                console.log(
                    `Amount of rack options for Long Pallet: ${rackOptions.length}`
                );
            } else {
                rackOptions = Object.keys(this.availableLocationsForLooseItems).sort(
                    (a, b) => a - b
                );
            }

            // return Object.keys(this.rackInfo).sort();
            return rackOptions;
        },
        availableSections(location) {
            // When the form is load, no rack is selected.
            if (!location.rack) return [];

            let sectionOptions = [];

            if (location.packageType.toLowerCase() === "Pallet".toLowerCase()) {

                sectionOptions = Object.keys(this.rackInfo[location.rack]).sort(
                    (a, b) => a - b
                );
            } else if (
                location.packageType.toLowerCase() === "Long Pallet".toLowerCase()
            ) {

                // rackOptions = Object.keys(this.rackInfo).sort((a, b) => (a - b));
                // Only include location that has 2 consecutive available subSections 1 and 2
                for (const section of Object.keys(this.rackInfo[location.rack])) {

                    if (
                        this.rackInfo[location.rack][section].includes(1) &&
                        this.rackInfo[location.rack][section].includes(2)
                    ) {
                        sectionOptions.push(section);
                    }

                }
            } else {
                sectionOptions = Object.keys(
                    this.availableLocationsForLooseItems[location.rack]
                ).sort((a, b) => a - b);
            }
            return sectionOptions;
        },
        availableSubSections(location) {
            if (!location.rack || !location.section) return [];

            let subSectionOptions = [];

            if (location.packageType.toLowerCase() !== "Loose".toLowerCase()) {
                subSectionOptions = [...this.rackInfo[location.rack][location.section]];
            } else {
                subSectionOptions = [
                    ...this.availableLocationsForLooseItems[location.rack][
                        location.section
                        ],
                ];
            }

            // if the package type is 'Long Pallet', then the subSection is "1,2"
            if (location.packageType.toLowerCase() === "Long Pallet".toLowerCase()) {
                subSectionOptions = ["1,2"];
            }
            return subSectionOptions;
        },
        submitForm() {

            this.loading = true;

            if(this.$refs.form.validate()) {

                KitCreationFormService
                    .submitForm(this.form)
                    .then((response) => {

                        const results = get(response, "data");

                        this.serverResponseMessage = results.response;
                        this.loading = false;
                        this.showForm = false;
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },
        resetForm() {
            window.location.reload();
            /* this.loadingImport = true;
            this.fetchDatabase();
            this.selectedManager = "";
            this.selectedJobNumber = "";
            this.selectedPoNumber = "";
            this.showForm = true; */
        },
        partQuantityLabel(itemIndex, itemName, locationIndex) {
            const amount =
                this.itemsOfSelectedJob[itemName]["partLocations"][locationIndex][
                    "locationQuantity"
                    ];
            return amount + " in record";
        },
        partQuantityLimit(quantity, itemName, locationIndex) {
            if (parseFloat(quantity) < 0 || isNaN(quantity))
                return "Quantity can't be less than 0";

            const amountLimit = parseFloat(
                this.itemsOfSelectedJob[itemName]["partLocations"][locationIndex][
                    "locationQuantity"
                    ]
            );

            if (parseFloat(quantity) > parseFloat(amountLimit))
                return "Quantity not allowed";

            return true;
        },
        getParts() {
            // Get all available parts if there is selected job number
            if (!this.chosenJob) return;

            // Reset itemsOfSelectedJob and selectedParts
            this.itemsOfSelectedJob = {};
            this.selectedParts = [];

            let job_number = get(this.chosenJob, "job_number");

            let poNumbers = this.db[job_number];

            for (const [poNumber, poItems] of Object.entries(poNumbers)) {

                for (const [partComboName, partDetails] of Object.entries(poItems)) {
                    let itemDetails = {
                        partPONumber: poNumber,
                        partComboName: partComboName,
                        partLocations: partDetails["locations"],
                    };

                    this.itemsOfSelectedJob[partComboName] = JSON.parse(
                        JSON.stringify(itemDetails)
                    );
                }
            }
        },
    },
};
</script>