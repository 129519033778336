const ACTIVE_EVENTS = [
    'keypress',
    'mousemove',
    'touchmove',
    'orientationchange',
    'focus'
];
const IDLE_TIMEOUT = 10000;

class IdleTrackerService {
    constructor() {
        this.idle = false;
        this.lastActiveTime = null;
        this.listeners = [];
    }

    start() {
        this.idle = false;
        this.lastActiveTime = this.getTime();
        this.registerIdleCheck();
        this.registerListeners();
        this.registerIdleCheck();
    }

    isActive() {
        return this.idle === false;
    }

    isIdle() {
        return this.idle === true;
    }

    checkIfIdle() {
        return this.getIdleTime() > IDLE_TIMEOUT;
    }

    registerIdleCheck() {
        setInterval(() => {
            const idle = this.checkIfIdle();

            if(idle !== this.idle) {

                this.idle = idle;

                if(this.idle) {

                    dispatchEvent(new CustomEvent('userIdle', {
                        detail: {
                            lastActiveTime: this.lastActiveTime,
                            idle: this.idle
                        }
                    }));

                   // console.info('[IdleTracker] User idle for '  + this.getIdleTime());
                }
                else {

                    dispatchEvent(new CustomEvent('userActive', {
                        detail: {
                            lastActiveTime: this.lastActiveTime,
                            idle: this.idle
                        }
                    }));

                   // console.info('[IdleTracker] User active');
                }
            }

        }, 1000);
    }

    registerListeners() {
        ACTIVE_EVENTS.forEach(event => {
                window.addEventListener(event, this.handleEvent.bind(this));
                this.listeners.push(event);
        });
    }

    getTime() {
        return new Date().getTime();
    }

    getIdleTime() {
        return this.getTime() - this.lastActiveTime;
    }

    handleEvent(event) {
        this.lastActiveTime = this.getTime();
    }
}

const IdleTracker = new IdleTrackerService();

export default IdleTracker;
