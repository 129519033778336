<script>
import {get} from "@/client/utilities/Object";
import cloneObject from "@/common/clone-object";

export default {
    name: 'WarehouseLocationsAutocomplete',
    inheritAttrs: false,
    props: ['value', 'loadingData'],
    data() {
        return {
            search: null,
            data: {
                locations: []
            },
            loading: false,
            valid: false,
            selected: [],
            currenType: 'rack'
        }
    },
    computed: {
        isLoading() {
            return !(this.loading === false && this.loadingData === false);
        },
        label: function () {
            if (!this.rack) {
                return 'Rack';
            }

            if (!this.section) {
                return 'Section';
            }

            if (!this.subSection) {
                return 'Sub Section';
            }

            return 'Warehouse Location';
        },
        locations: function () {
            return cloneObject(this.data.locations).filter(item => {

                if (this.label === 'Rack') {
                    return item.type === 'rack';
                }

                if (this.label === 'Section') {
                    return (
                        item.type === 'section' &&
                        item.warehouse_rack_id === this.rack.id
                    ) || item.type === 'rack' && item.id === this.rack.id;
                }

                if (this.label === 'Sub Section') {
                    return (
                            item.type === 'subSection' &&
                            item.warehouse_section_id === this.section.id
                        )
                        || (item.type === 'section' && item.id === this.section.id)
                        || (item.type === 'rack' && item.id === this.section.warehouse_rack_id);
                }

                return (item.type === 'rack' && item.id === this.rack.id)
                    || (item.type === 'section' && item.id === this.section.id)
                    || (item.type === 'subSection' && item.id === this.subSection.id);
            })
                .map(item => {

                    if (this.rack && item.type === 'rack' && item.id === this.rack.id) {
                        item.header = 'Rack ' + item.name;
                    }

                    if (this.section && item.type === 'section' && item.id === this.section.id) {
                        item.header = 'Section ' + item.name;
                    }

                    if (this.subSection && item.type === 'subSection' && item.id === this.subSection.id) {
                        item.header = 'Sub Section ' + item.name;
                    }

                    return item;
                });
        },
        rack() {
            let rack = this.selected.filter(item => item.type === 'rack');

            if (rack.length > 0) {
                return rack[0];
            }

            return null;
        },
        section() {
            let section = this.selected.filter(item => item.type === 'section');

            if (section.length > 0) {
                return section[0];
            }

            return null;
        },
        subSection() {
            let subSection = this.selected.filter(item => item.type === 'subSection');

            if (subSection.length > 0) {
                return subSection[0];
            }

            return null;
        }
    },
    methods: {
        get,
        updateSelection(item) {
            let location = [];
            this.valid = false;
            this.search = null;

            if(this.rack) {
                location.push(this.rack);
                if(this.section) {
                    location.push(this.section);
                    if(this.subSection) {

                        location.push(this.subSection);

                        this.valid = true;

                        this.$refs.locationAutocomplete.blur();
                    }
                }
            }

            this.selected = location;
            this.dispatchEvents();
        },
        dispatchEvents() {
            let data = false;
            if(this.valid) {
                data = {
                    rack: this.rack,
                    section: this.section,
                    subSection: this.subSection
                };
            }

            this.$emit('selected', data);
        },
        remove(index) {
            if (index >= 0) this.selected.splice(index, 1)
            this.updateSelection(this.selected);
        },
        fetchLocations() {
            this.selfLoading = true;
            return this.$http.get('/v3/warehouse/locations/in-use').then((response) => {
                this.data.locations = get(response, 'data.locations');
            }).finally(() => {
                this.selfLoading = false;
            });
        }
    },
    mounted() {
       this.fetchLocations();
    }
}
</script>

<template>
    <VAutocomplete
            :search-input.sync="search"
            ref="locationAutocomplete"
            outlined
            hide-details="auto"
            v-bind="$attrs"
            v-model="selected"
            :items="locations"
            item-text="name"
            item-value="key"
            :loading="isLoading"
            :placeholder="`Select a ${this.label}`"
            @change="updateSelection"
            :label="label"
            return-object
            multiple
            small-chips
            clearable
    >

        <template v-slot:selection="data">
            <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    label
                    color="primary"
                    @click:close="remove(data.index)"
            >
                <span style="font-size:12px;font-style: italic;margin-right:10px;">{{ get(data, 'item.label', '') }}</span>
                <span style="font-weight:bold;font-size:16px;margin-right:5px;">{{ get(data, 'item.name') }}</span>
            </v-chip>
        </template>

        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ get(data, 'item.name') }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-show="get(data, 'item.line_item_count', 0) > 0">
                <v-list-item-action-text>
                    {{get(data, 'item.line_item_count', '')}} items.
                </v-list-item-action-text>
            </v-list-item-action>
        </template>

    </VAutocomplete>
</template>