<template>
    <v-autocomplete
        outlined
        clearable
        :items="items"
        :loading="isLoading"
        label="PO Number"
        placeholder="Search Purchase Orders"
        item-text="po_number"
        item-value="po_number"
        v-bind:value="value"
        v-bind="$attrs"
        v-on="inputListeners"
        prepend-inner-icon="mdi-tag-search"
        return-object
    >
        <template v-slot:selection="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ data.item.po_number }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ data.item.po_number }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size:12px;">
                    {{ data.item.ship_location }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>
<script>
export default {
    name: "PurchaseOrdersAutocomplete",
    inheritAttrs: false,
    computed: {
        inputListeners: function () {
            let vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (val) {
                        vm.$emit('input', val);
                    }
                }
            )
        }
    },
    data() {
        return {
            items: [],
            isLoading: false,
        }
    },
    created() {
        this.isLoading = true;
        this.getItems();
    },
    methods: {
        async getItems() {
            try {
                const response = await this.axios.get('/ryvit/purchase-orders');

                if (response && response.data) {
                    this.items = response.data;
                    this.$emit('update:purchaseOrders', response.data);
                }
            } catch (error) {

            } finally {
                this.isLoading = false;
            }
        }
    },
    props: ['value']
}
</script>