import Config from "../../config";
import ApiService from "../api.service";

const TransmittalForm = {
    submit(body) {
        return ApiService.call(Config.endpoints.v2.transmittalForm.submit, body);
    },
    data(body) {
        return ApiService.call(Config.endpoints.v2.transmittalForm.data, body);
    },
    inventory(jobNumber, jcco) {
        return ApiService.call(Config.endpoints.v2.transmittalForm.inventory, {job_number: jobNumber, jcco: jcco});
    }
}

export default TransmittalForm;