import Client from "../lib/Client";
import NotificationSubscription from "../../models/Notification/NotificationSubscription";
import NotificationSubscriptionInterface from "../../models/Notification/NotificationSubscriptionInterface";
import ApiResponse from "../lib/ApiResponse";
export default class NotificationSubscriptionService extends Client {
    model = new NotificationSubscription;
    dataKey = 'notification_subscriptions';
    async get() {
        return await this.request.get('/notifications/subscriptions');
    }
    async create(notificationSubscription) {
        return await this.request.post('/notifications/subscriptions', notificationSubscription);
    }
}
