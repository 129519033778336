<template>
    <v-container fluid>

        <PageTitle>
            Warehouse Inventory
        </PageTitle>

        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                height="70vh"
                :headers="headers"
                :items="rows"
                :search="search"
                :loading="loading"
                loading-text="Loading... Please wait"
                :items-per-page="50"
                multi-sort
                :sort-by="[]"
                :sort-desc="[]"
                class="elevation-1"
                dense
                fixed-header
                calculate-widths
                :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          showFirstLastPage: true,
        }"
            >
                <template v-slot:item.labelActions="{ item }">
                    <!-- <v-icon small class="mr-2" @click="previewLabel(item)">
                      mdi-file-find
                    </v-icon> -->
                    <!-- <v-icon small @click="printLabel(item)"> mdi-printer </v-icon> -->
                    <v-icon small @click="generatePDF(item)"> mdi-printer</v-icon>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogLabelPreview" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Label</v-card-title>
                <v-card-text>
                    <iframe :src="labelUrl" frameborder="0" width="100%" height="100%"></iframe>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialogLabelPreview = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {get} from "@/client/utilities/Object";
import {WarehouseInventoryService} from "@/common/api/api.service";
import PageTitle from "@/components/PageTitle.vue";
import printJS from "print-js";
import {API_ROOT} from "@/common/environment";

export default {
    components: {PageTitle},
    title: 'Warehouse Inventory',
    data() {
        return {
            search: "",
            options: {},
            totalItems: 0,
            headers: [],
            rows: [],
            loading: true,

            // For DYMO label:
            dialogLabelPreview: false, // Dialog for label preview
            labelUrl: null,
            labelImage: "", // Label image src
            label: "", // Label object
            printer: "", // DYMO Printer object
        };
    },
    created() {
        this.fetchDatabase();

        // Attach the DYMO JS scripts, ref: https://stackoverflow.com/a/60257961/6596203
        var scripts = [
            // "https://s3.amazonaws.com/download.dymo.com/dymo/Software/JavaScript/dymo.connect.framework.js",
            "https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.fetchDatabase();
            },
            deep: true
        },

        selectedItemsOfSelectedJob(val) {
            // const itemsOfSelectedJob = this.partsGroupedByJobNumber[this.selectedJobNumber];
            if (val.length > 0) {
                // this.availableParts = [];
                this.selectedParts = [];
                for (let item of val) {
                    this.selectedParts.push(
                        ...this.itemsOfSelectedJob.filter(
                            (part) =>
                                `${part.partNumber} -|- ${part.partDescription}` === item
                        )
                    );
                }

                // console.log(this.availableParts);
                // this.selectedParts = JSON.parse(JSON.stringify(this.availableParts));

                for (let sp in this.selectedParts) {
                    this.selectedParts[sp].partQuantity = 0;
                }
            } else {
                this.resetSelectedParts();
            }
        },
    },
    methods: {
        fetchDatabase() {

            const that = this;

            WarehouseInventoryService.getFormData(this.options).catch((error) => {
                this.$toast.error('Error retrieving inventory items.');
                that.loading = false;
            })
            .then(response => {
                const headers = get(response, 'data.data.headers', []);
                that.rows = get(response, 'data.data.rows',);
                that.totalItems = get(response, 'data.meta.total', 0);

                that.headers = [
                    {text: "Label Actions", value: "labelActions", sortable: false},
                    ...headers,
                ];

                that.loading = false;
            });
        },
        generatePDF(item) {

            let url = `${API_ROOT}labels/${item.id}`;
            printJS({printable: url, type: 'pdf', showModal: true});
           // this.labelUrl = url;
           // this.dialogLabelPreview = true;
           // window.open(url);

            /*
            // jsPDF: refs
            // https://rawgit.com/MrRio/jsPDF/master/docs/index.html
            // https://parall.ax/products/jspdf

            // Portrait export, 6×4 inches
            const {jsPDF} = window.jspdf;

            const [width, height] = [3.81, 5.9];
            const fontSize = 14; // in "points"
            const margin = 0.4; // in the same unit as the document setup
            const doc = new jsPDF({
                // orientation: "portrait",
                unit: "in",
                format: [width, height],
            });
            doc.setFont("helvetica");
            // doc.text(JSON.stringify(item), 0.5, 0.5, textOptions);

            let text = [];
            // Replace the label objects with the item data
            const params = [
                ["partNumber", "Part #"],
                ["partDescription", "Part Description"],
                ["jobNumber", "Job #"],
                ["jobName", "Job Name"],
                ["poNumber", "PO #"],
                ["projectManager", "Project Manager"],
                ["foreman", "Foreman"],
                ["receivedDatetime", "Received Date"],
                ["receivedBy", "Received By"],
            ];
            params.forEach(([param, paramName]) => {
                if (param === "receivedDatetime") {
                    text.push(
                        `${paramName.toUpperCase()}: ${item["Timestamp"].toString()}`
                    );
                } else if (param === "receivedBy") {
                    text.push(
                        `${paramName.toUpperCase()}: ${item["employeeName"].toString()}`
                    );
                } else {
                    if(item[param]) {
                        text.push(`${paramName.toUpperCase()}: ${item[param].toString()}`);
                    }
                }
            });

            // console.log(text);
            doc.setFontSize(fontSize);
            const textOptions = {
                maxWidth: width - margin * 2,
            };
            // Add the text
            doc.text(text.join("\n\n"), margin / 1.5, margin, textOptions);

            // Add a surrounded rectangle
            /*
          doc.setLineWidth(0.05);
          doc.rect(
            margin / 3,
            margin / 3,
            width - (margin * 2) / 3,
            height - (margin * 2) / 3
          );



            let filename = `Label-${item["partNumber"].toString().trim()}-${item[
                "partDescription"
                ]
                .toString()
                .trim()}.pdf`;
            // Sanitize filename due to error with PDF file in some cases
            filename = this.sanitize(filename);
            // console.log("HERE");
            console.log(`Filename: ${filename}`);
            // Open PDF to another window, ready for printing or saving
            // https://stackoverflow.com/questions/17739816/how-to-open-generated-pdf-using-jspdf-in-new-window
            doc.setDocumentProperties({
                title: filename,
            });

            doc.autoPrint();

            //doc.output("dataurlnewwindow", { filename: filename });

            let iframe = document.getElementById("pdf");
            iframe.src = `${doc.output("datauristring", { filename: filename })}`;

            // doc.save(filename);

             */
        },
        sanitize(input, replacement = "") {
            // var truncate = require("truncate-utf8-bytes");
            var illegalRe = /[\/\?<>\\:\*\|":]/g;
            var controlRe = /[\x00-\x1f\x80-\x9f]/g;
            var reservedRe = /^\.+$/;
            var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
            var spaces = /[\s\.,]/g;
            var sanitized = input
                .replace(illegalRe, replacement)
                .replace(controlRe, replacement)
                .replace(reservedRe, replacement)
                .replace(windowsReservedRe, replacement)
                .replace(spaces, "_");
            // return truncate(sanitized, 255);
            return sanitized.slice(0, 255);
        },
        // dymoInitTests() {
        //   /** Refs for DYMO:
        //    * Print from website: https://www.defproc.co.uk/tutorial/labelin-made-easy/
        //    * StackOverFlow: https://stackoverflow.com/questions/34369573/print-on-dymo-label-printer-from-website
        //    * DYMO Developer Blog: https://developers.dymo.com/2016/08/08/dymo-label-web-service-faq/
        //    * https://developers.dymo.com/
        //    * DYMO githubs: https://github.com/dymosoftware/dymo-connect-framework
        //    * DYMO githubs with examples and webpages: https://github.com/dymosoftware/DCD-SDK-Sample
        //    * Sample HTML pages: https://github.com/dymosoftware/DCD-SDK-Sample#sample-html-pages-
        //    */
        //   if (dymo.label.framework.init) {
        //     // dymo.label.framework.trace = 1;
        //     dymo.label.framework.init(this.dymoOnload);
        //   } else {
        //     this.dymoOnload();
        //   }

        //   // Check for connected printers:
        //   setTimeout(this.checkPrinters, 2000);

        //   setTimeout(this.labelInit, 2000);
        // },
        // dymoOnload() {
        //   try {
        //     //clearOutput();
        //     var result = dymo.label.framework.checkEnvironment();
        //     console.log("DYMO: Validate if the environment meets the requirements");
        //     console.log("isBrowserSupported: " + result.isBrowserSupported);
        //     console.log("isFrameworkInstalled: " + result.isFrameworkInstalled);
        //     if (dymo.label.framework.init) {
        //       console.log("isWebServicePresent: " + result.isWebServicePresent);
        //     }
        //     console.log("errorDetails: " + result.errorDetails);
        //   } catch (e) {
        //     alert(e.message || e);
        //   }
        // },
        // checkPrinters() {
        //   var printers = dymo.label.framework.getPrinters();
        //   if (printers.length == 0) {
        //     alert("No DYMO printers are installed. Install DYMO printers.");
        //     return;
        //   }

        //   if (!printers[0].isConnected) {
        //     alert("DYMO printer is not connected.");
        //     return;
        //   }

        //   // If all tests passed, print to console
        //   console.log(`DYMO printer "${printers[0]["modelName"]}" is connected.`);
        //   this.printer = dymo.label.framework.getPrinters()[0].modelName;
        // },
        // previewLabel(item) {
        //   console.log(item);

        //   // Process the label with the current item info:
        //   this.processLabel(item);

        //   // Generate the label image:
        //   this.generatePreviewLabel();

        //   // Show the label preview dialog:
        //   this.dialogLabelPreview = true;
        // },
        // async labelInit() {
        //   // Initialize label template and test it
        //   const dymoTemplateFile = await // await fetch("legac-template.label")
        //   (await fetch("legac-template2.label")).text();

        //   // console.log(dymoTemplateFile);
        //   const label = dymo.label.framework.openLabelXml(dymoTemplateFile);
        //   // console.log(label);
        //   if (label.isDCDLabel()) console.log("DYMO Connect label");
        //   if (label.isDLSLabel()) console.log("DLS label");
        //   if (label.isValidLabel()) console.log("The file is a valid label");
        //   else {
        //     alert(" The file is not a valid label");
        //     return;
        //   }
        //   // Return DYMO label object
        //   // return label;
        //   this.label = label;
        // },
        // processLabel(item) {
        //   // console.log(item);

        //   // Replace the label objects with the item data
        //   const params = [
        //     "partNumber",
        //     "partDescription",
        //     "jobNumber",
        //     "jobName",
        //     "poNumber",
        //     "projectManager",
        //     "foreman",
        //     "receivedDatetime",
        //     "receivedBy",
        //   ];
        //   params.forEach((param) => {
        //     if (param === "receivedDatetime") {
        //       this.label.setObjectText(param, item["Timestamp"].toString());
        //     } else if (param === "receivedBy") {
        //       this.label.setObjectText(param, item["employeeName"].toString());
        //     } else {
        //       this.label.setObjectText(param, item[param].toString());
        //     }
        //   });
        // },
        // generatePreviewLabel() {
        //   // console.log(label.getLabelXml());
        //   var pngData = this.label.render();
        //   // console.log(pngData);
        //   this.labelImage = "data:image/png;base64," + pngData;
        // },
        // printLabel(item = null) {
        //   if (item) {
        //     // Process the label with the current item info:
        //     this.processLabel(item);
        //   }
        //   // Set the print options
        //   let labelXml = this.label.getLabelXml();
        //   // console.log(labelXml);
        //   let printQuality = dymo.label.framework.LabelWriterPrintQuality.Auto;
        //   dymo.label.framework.createLabelRenderParamsXml();
        //   let paramsXml = dymo.label.framework.createLabelWriterPrintParamsXml({
        //     copies: 1,
        //     printQuality: printQuality,
        //   });

        //   // Print
        //   dymo.label.framework.printLabel(this.printer, paramsXml, labelXml);
        // },
    },
};
</script>