import PageTitle from "@/components/PageTitle.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import { clone, get } from "@/client/utilities/Object";
import NotesTextField from "@/components/NotesTextField.vue";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import ToolAutocomplete from "@/components/ToolAutocomplete.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import NotesTextFieldV2 from "@/components/V2/NotesTextField-V2.vue";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import BasicImage from "@/components/Photos/BasicImage.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import vehicleApiMixin from "@/mixins/Api/vehicle-api.mixin";
import VehicleAutocomplete from "@/components/VehicleAutocomplete.vue";
import EmployeeAutocomplete from "@/components/V2/EmployeeAutocomplete.vue";
export default {
    name: 'ToolCheckout',
    components: {
        EmployeeAutocomplete,
        VehicleAutocomplete,
        PhotoThumbnail,
        BasicImage,
        ToolModelAutocomplete,
        NotesTextFieldV2,
        FormSubmittedMessage,
        ToolAutocomplete,
        JobsAutocomplete, FormSubmitButton, NotesTextField, DividingHeader, JobAutocomplete, PageTitle
    },
    title: 'Tool Check Out',
    mixins: [vehicleApiMixin],
    data() {
        return {
            submitting: false,
            submitted: false,
            valid: false,
            rules: {
                required: v => !!v || "This is a required field",
            },
            locationType: null,
            notes: null,
            search: null,
            job: null,
            employee: null,
            tools: [],
            vehicle: null,
            selectedTool: null,
            selectedQuantity: 1,
            data: {
                tools: [],
                jobs: [],
                employees: [],
                jobs_jobs: [],
                unavailable_tool_ids: [],
            },
            dialog: false,
        };
    },
    watch: {
        selectedTool(tool, oldTool) {
            console.log('selectedTool', tool, oldTool);
            /*
            this.selectTool(tool);

            this.$nextTick(() => {
                this.selectedTool = null;
            });
             */
        }
    },
    methods: {
        get,
        async fetchTools() {
            // const response = await this.$http.get('/v3/tools/models/available');
            // this.data.tools = get(response, 'data.tool_models', []);
            // this.data.employees = await this.$http.get('/v3/employees').then(response => response.data.employees);
        },
        resetForm() {
            window.location.reload();
        },
        addTool() {
            for (let i = 1; i <= this.selectedQuantity; i++) {
                this.selectTool(this.selectedTool);
            }
            this.$nextTick(() => {
                this.selectedTool = null;
                this.selectedQuantity = 1;
                let ref = this.$refs.toolModelAutocomplete;
                if (ref) {
                    ref.reset();
                }
            });
        },
        selectTool(tool) {
            if (tool) {
                tool.selected = true;
                this.tools.push(tool);
            }
        },
        unselectTool(tool) {
            this.tools.splice(this.tools.indexOf(tool), 1);
        },
        submit() {
            if (this.$refs.form.validate()) {
                if (this.tools.length === 0) {
                    this.$toast.error('Please select at least one tool');
                }
                else {
                    const payload = {
                        job: this.job,
                        tools: this.selectedTools,
                        vehicle: this.vehicle,
                        employee: this.employee,
                        notes: this.notes
                    };
                    this.submitting = true;
                    this.$http.post('/v3/tools/checkout', payload).then(response => {
                        this.data.unavailable_tool_ids = get(response, 'data.unavailable_tool_ids', []);
                        this.submitted = true;
                    }).finally(() => {
                        this.submitting = false;
                    });
                }
            }
        }
    },
    computed: {
        displayToolSelection() {
            return this.job || this.vehicle || this.employee;
        },
        selectedTools() {
            let tools = [];
            for (const tool of this.tools) {
                tools.push(clone(tool));
                const kit_models = get(tool, 'tool_kit.tool_models');
                if (kit_models) {
                    for (const kit_model of kit_models) {
                        tools.push(clone(kit_model));
                    }
                }
            }
            return tools;
        }
    },
    async created() {
        this.vehicles = await this.fetchVehicles();
    },
};
