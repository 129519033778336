export default {
    name: "KeyValueChip",
    props: {
        title: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        }
    }
};
