import PageTitle from "@/components/PageTitle.vue";
import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import KeyValueChip from "@/components/Chips/KeyValueChip.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
export default {
    name: 'Tools',
    components: { JobInfoChip, ConfirmDialog, PhotoThumbnail, KeyValueChip, PageTitle },
    title: 'Tools',
    data() {
        return {
            tools: [],
            expanded: [],
            headers: [
                { text: 'ID', value: 'id', sortable: false, width: 30, divider: true },
                { text: 'Photo', value: 'photo', sortable: false, width: 50, divider: true },
                { text: 'Name', value: 'name', sortable: false, width: 200, divider: true },
                { text: 'Category', value: 'tool_category.name', sortable: false, width: 100, divider: true },
                { text: 'Model', value: 'sku', sortable: false, divider: true, width: 200 },
                { text: 'Serial', value: 'serial_number', sortable: false, divider: true },
                { text: 'Certified', value: 'certification', sortable: false, divider: true },
                { text: 'Status', value: 'tool_status.name', sortable: false, divider: true },
                { text: 'Location', value: 'location', sortable: false, divider: true },
                { text: 'Manufacturer', value: 'tool_manufacturer.name', sortable: false, divider: true },
                { text: 'Type', value: 'tool_type', sortable: false, divider: true },
                { text: '', value: 'actions', sortable: false, divider: true, width: 150 },
                { text: 'Updated', value: 'updated_at', sortable: true, divider: true },
            ],
            loading: true,
            search: '',
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false,
            },
            debounceSearch: debounce(this.fetchTools, 500),
            total: 0,
        };
    },
    watch: {
        options: {
            handler(val) {
                this.fetchTools();
            },
            deep: true,
        },
        search(query) {
            this.debounceSearch();
        }
    },
    methods: {
        get,
        editTool(toolId, type = 'tool') {
            if (type === 'tool') {
                this.$router.push({ name: 'EditTool', params: { id: toolId } });
            }
            if (type === 'accessory') {
                this.$router.push({ name: 'EditAccessory', params: { id: toolId } });
            }
            if (type === 'consumable') {
                this.$router.push({ name: 'EditConsumable', params: { id: toolId } });
            }
        },
        fetchTools() {
            const page = this.options.page || 1;
            const search = this.search || null;
            this.loading = true;
            const params = {
                page: page,
                search: search,
                sortBy: this.options.sortBy,
                sortByDesc: this.options.sortDesc,
            };
            this.$http.get(`/v3/tools`, { params: params }).then(response => {
                this.tools = get(response, 'data.tools.data', []);
                this.total = get(response, 'data.tools.total', 0);
            }).finally(() => {
                this.loading = false;
            });
        },
        remove(toolId) {
            this.$http.delete(`/v3/tools/${toolId}`).then(response => {
                this.fetchTools();
            });
        }
    },
};
