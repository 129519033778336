import Model from "../../Model";
import NotificationTypeInterface from "./NotificationTypeInterface";
export default class NotificationType extends Model {
    created_at;
    description;
    id;
    name;
    slug;
    updated_at;
}
