import { render, staticRenderFns } from "./JobInfoChip.vue?vue&type=template&id=48ac9baa&scoped=true"
import script from "./JobInfoChip.vue?vue&type=script&lang=ts"
export * from "./JobInfoChip.vue?vue&type=script&lang=ts"
import style0 from "./JobInfoChip.vue?vue&type=style&index=0&id=48ac9baa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ac9baa",
  null
  
)

export default component.exports