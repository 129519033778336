export default {
    name: 'ToolStatusAutocomplete',
    props: {},
    data() {
        return {
            statuses: [],
            loading: false
        };
    },
    methods: {
        async fetchStatuses() {
            this.loading = true;
            this.$log.log('Fetching tool statuses.');
            return await this.$http.get('/v3/tools/statuses').then((response) => {
                this.statuses = (response.data && response.data.statuses) ? response.data.statuses : [];
                return this.statuses;
            }).finally(() => {
                this.loading = false;
            });
        },
        onFocus() {
            this.fetchStatuses();
        }
    },
    async created() {
        // await this.fetchStatuses();
    }
};
