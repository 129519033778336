<script>
import FormSubmittedMessage from "../../../components/FormSubmittedMessage.vue";
import PageTitle from "../../../components/PageTitle.vue";
import PhotoThumbnail from "../../../components/Photos/PhotoThumbnail.vue";
import {each} from "@/client/utilities/Each";
import PartName from "@/components/V2/PartName.vue";
import TransmittalForm from "@/common/api/forms/TransmittalForm";
import TextInput from "@/components/TextInput.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import cloneObject from "@/common/clone-object";
import {get} from "@/client/utilities/Object";
import {LOG_STYLES} from "@/common/log";

export default {
    name: "ApproveTransmittalRequest",
    logContext: 'ApproveTransmittalRequest',
    logStyle: LOG_STYLES.TEAL,
    components: {
        NotesTextField,
        DatePicker,
        DeliveryWindowSelect,
        PhotoManager, TextInput, PartName, PhotoThumbnail, PageTitle, FormSubmittedMessage},
    title: 'Approve Transmittal Request',
    data() {
        return {
            rules: {
                required: v => !!v || "This is a required field",
            },
            step: 1,
            formSubmitted: false,
            formSubmitting: false,
            form: {
                job: null,
                newJob: null,
                project_manager: null,
                items: [],
                loading: false,
                mode: 'warehouseToJobsite',
                delivery_items_photos: [],
                delivery_photos: [],
                notes: null,
                delivery: {
                    site_contact_name: '',
                    delivery_date: '',
                    delivery_window: '',
                    delivery_notes: '',
                },
                submitting: false,
                submitted: false,
            },
            steps: {
                1: {
                    name: 'Select Items',
                    isValid: () => {
                        if(this.mode === 'warehouseToJobsite') {
                            return this.validateQuantities();
                        }

                        return true;
                    }
                },
                2: {
                    name: 'Select Photos',
                    isValid: () => {
                        return true;
                    }
                },
                3: {
                    name: 'Select Delivery Window',
                    isValid: () => {
                        return true;
                    }
                }
            },
            request: null,
            inventoryItems: [],
            inventoryItemCount: 0,
            modeNames: {
                warehouseToJobsite: 'Warehouse to Jobsite',
                switchToJob: 'Switch to Job',
                switchToStock: 'Switch to Stock',
            },
        }
    },
    watch: {
        step() {
            if(this.form.mode === 'warehouseToJobsite') {
                this.updateFormRequest();
            }
        }
    },
    methods: {
        get,
        validateQuantities() {
            let valid = true;

            let total_quantity = 0;

            each(this.form.items, (item) => {
                let max_quantity = item.original_transmittal_quantity;
                let transmit_qty = 0;

                each(item.inventory_items, (inventory_item) => {

                    if(inventory_item.part_quantity < parseFloat(inventory_item.transmittal_quantity)) {
                        valid = false;
                    }

                    transmit_qty += parseFloat(inventory_item.transmittal_quantity);
                })

                total_quantity += transmit_qty;

                if(transmit_qty > max_quantity) {
                    valid = false;
                }
            })

            if(total_quantity <= 0) {
                valid = false;
            }

            return valid;
        },
        getRemainingQuantity(part) {
            let qty = 0;
            each(part.inventory_items, (inventory_item) => {
                if(inventory_item.transmittal_quantity) {
                    qty += parseFloat(inventory_item.transmittal_quantity);
                }
            })

            return parseFloat(part.original_transmittal_quantity) - qty;
        },
        quantitiesValid(part) {
            let available_quantity = parseFloat(part.original_transmittal_quantity);
            let transmittal_quantity = 0;
            let valid = true;
            each(part.inventory_items, (item) => {

                if(item.part_quantity < parseFloat(item.transmittal_quantity)) {
                    valid = false;
                }

                if(item.transmittal_quantity) {
                    transmittal_quantity += parseFloat(item.transmittal_quantity);
                }
            });

            return valid && transmittal_quantity <= available_quantity;
        },
        async fetchInventoryItems() {
            if (this.jobNumber) {
                TransmittalForm.inventory(this.jobNumber, this.jcco)
                    .then(response => {
                        let items = get(response, 'data.items', []);

                        items = items.map(item => {
                            item.original_transmittial_quantity = item.transmittal_quantity;
                            return item;
                        });

                        this.inventoryItems = JSON.parse(JSON.stringify(items));

                        this.form.items.map((item) => {
                            item.inventory_items = cloneObject(items.filter(i => {
                                return (i.part_combo_name === item.part_combo_name);
                            }));

                            return item;
                        });

                        this.inventoryItemCount = get(response, 'data.count', 0);
                    })
                    .catch((e) => {
                        this.$toast.error('Error loading inventory items.');
                        console.error(e);
                    });
            }
        },
        resetForm() {
            this.$router.push({path: '/approval/requests'})
        },
        async updateFormRequest(status = null) {
            let uuid = this.$route.params.uuid;

            let data = this.form;
            let url = `/v2/forms/requests/transmittal/${uuid}`;

            if(status) {
                url += `/${status}`
            }

            return this.$http.post(url, data)
                .then(response => {
                    return response.data;
                })
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/transmittal/' + uuid).then(response => {

                    let data = get(response ,'data');

                    let items = get(data, 'data.items', []);
                    items = items.map(item => {

                        if(!item.original_transmittal_quantity) {
                            item.original_transmittal_quantity = item.transmittal_quantity;
                        }

                        if(!item.transmittal_quantity) {
                            item.transmittal_quantity = 0;
                        }

                        item.inventory_items = [];
                        return item;
                    });

                    data.data.items = JSON.parse(JSON.stringify(items));

                    this.request = data;

                    this.form = get(this.request, 'data');
                    this.form.delivery_items_photos = get(this.request, 'data.delivery_items_photos', []);

                    console.log('[FormRequest] Loading transmittal request data for form uuid ' + this.request.uuid);
                });
            } else {
                this.$toast.error('No Form Request Found');
            }
        },
        changeStep(step) {
            if (this.stepValid(step - 1)) {
                this.step = step;
            }
        },
        validate(step) {
            if (this.stepValid(step)) {
                this.step = step + 1;
            }
        },
        stepValid(stepNumber) {

            if(this.mode === 'warehouseToJobsite') {
                let step = get(this.steps, stepNumber);

                if (step) {
                    return step.isValid();
                }
            }

            return true;
        },
        stepEditable(stepNumber) {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            });

            return valid;
        },
        getInventory(part) {
            return this.inventoryItems
                .filter(item => item.part_combo_name === part.part_combo_name);
        },
        async submitForm() {
            this.formSubmitting = true;

            if(!this.formSubmitted) {
                this.updateFormRequest('approved').then((response) => {
                    this.formSubmitted = true;
                }).catch(() => {
                    this.$toast.error('Error submitting form.');
                }).finally((response) => {
                    this.formSubmitting = false;
                });
            }
        }
    },
    computed: {
        requestedItems() {
            return get(this.request, 'data.items', []);
        },
        jobNumber() {
            return get(this.request, 'data.job.job_number', null);
        },
        jobId() {
            return get(this.request, 'data.job.id', null);
        },
        jcco() {
            return get(this.request, 'data.job.jcco', null);
        },
        mode() {
            return get(this.request, 'data.mode');
        },
        newJob() {
            return get(this.request, 'data.newJob', false);
        }
    },
    async mounted() {
        this.form.submitting = false;
        this.form.submitted = false;
        await this.fetchFormRequest();
        await this.fetchInventoryItems();
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>
            Approve Transmittal Request
        </PageTitle>

        <FormSubmittedMessage
            v-show="formSubmitted"
            title="Form Request Submitted"
            button-text="View Approval Requests"
            :on-click="resetForm"
        >
            Back to Approval Requests
        </FormSubmittedMessage>

        <div v-show="!formSubmitted">
            <VRow class="my-2 mx-0 py-1 px-2 grey darken-4">
                <VCol>
                    <VRow>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Job #</i> <br>
                            {{get(request, 'data.job.job_number')}}
                        </VCol>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Description</i> <br>
                            {{ get(request, 'data.job.description') }}
                        </VCol>
                        <VCol class="px-2 py-2">
                            <i class="grey--text">Mode</i> <br>
                            {{ get(modeNames, get(request, 'data.mode')) }}
                        </VCol>
                    </VRow>
                    <VRow v-if="mode !== 'warehouseToJobsite' && newJob">
                        <VCol>
                            <i class="green--text font-weight-bold">New Job #</i> <br>
                            {{get(newJob, 'job_number')}}
                        </VCol>
                        <VCol>
                            <i class="green--text font-weight-bold">New Job Description</i> <br>
                            {{get(newJob, 'description')}}
                        </VCol>
                        <VCol></VCol>
                    </VRow>
                    <VRow v-if="mode === 'warehouseToJobsite'">
                        <VCol>
                            <i class="grey--text">Contact</i> <br>
                            {{ get(request, 'data.delivery.site_contact_name') }} <br>
                        </VCol>
                        <VCol>
                            <i class="grey--text">Date</i> <br>
                            {{ get(request, 'data.delivery.delivery_date') }} <br>
                        </VCol>
                        <VCol>
                            <i class="grey--text">Window</i> <br>
                            {{ get(request, 'data.delivery.delivery_window') }} <br>
                        </VCol>
                        <VCol>
                            <i class="grey--text">Delivery Notes</i> <br>
                            {{ get(request, 'data.delivery.delivery_notes') }} <br>
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
            <VStepper v-model="step"
                      vertical
                      non-linear
            >

                <div>
                    <!-- step 1 -->
                    <VStepperStep
                        step="1"
                        :complete="step > 1"
                        :rules="[v => stepValid(1)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                    >
                        Select Item Sources
                        <small class=pt-1 v-if="!stepValid(1)">
                            Please select item sources
                        </small>
                    </VStepperStep>
                    <VStepperContent step="1">
                        <VCard v-for="(part, idx) in form.items"
                               :key="`item2-${idx}`"
                               class="mb-4"
                        >
                            <VCardText>
                                <VRow>
                                    <VCol cols="8">
                                        <PartName
                                                :part-number="part.part_number"
                                                :part-description="part.part_description"
                                        />
                                    </VCol>
                                    <VCol cols="4">
                                        <TextInput
                                                hide-details
                                                dense
                                                v-model="part.original_transmittal_quantity"
                                                disabled
                                                label="Requested Quantity"
                                        >
                                        </TextInput>
                                    </VCol>
                                </VRow>
                            </VCardText>
                            <VCardText>
                                <VRow class="mb-0 px-0 grey--text font-italic">
                                    <VCol class="mr-1 ml-0 pl-6">
                                        Location
                                    </VCol>
                                    <VCol class="mr-1 ml-0 pl-6">
                                        PO Number
                                    </VCol>
                                    <VCol class="mr-1 ml-0 pl-6">
                                        Available Qty
                                    </VCol>
                                    <VCol class="mr-1 ml-0 pl-6">
                                        Transmittal Qty
                                    </VCol>
                                </VRow>
                                <VRow
                                        v-for="(item, idx2) in part.inventory_items"
                                        :key="`item-${idx}-${idx2}`"
                                        class="mb-2 mt-0 px-4 py-0 align-content-center"
                                >
                                    <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                        {{item.location_key}}
                                    </VCol>
                                    <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                        {{item.po_number}}
                                    </VCol>
                                    <VCol class="grey darken-3 mr-1 ml-0 px-6">
                                        {{item.part_quantity}}
                                    </VCol>
                                    <VCol class="grey darken-3 mr-1 ml-0 px-6 pt-2 pb-2">
                                        <TextInput
                                                hide-details
                                                dense
                                                v-model="item.transmittal_quantity"
                                                :rules="[quantitiesValid(part)]"
                                        />
                                    </VCol>
                                </VRow>
                                <VRow>
                                    <VCol></VCol>
                                    <VCol></VCol>
                                    <VCol></VCol>
                                    <VCol class="text-right">
                                        <VTextField label="Remaining Quantity" outlined hide-details disabled v-bind:value="getRemainingQuantity(part)"></VTextField>
                                    </VCol>
                                </VRow>
                            </VCardText>
                        </VCard>

                        <VRow>
                            <VCol>
                                <VBtn
                                        color="primary"
                                        @click="changeStep((form.mode !== 'warehouseToJobsite') ? 3 : 2)"
                                        :disabled="!stepValid(1)"
                                >
                                    Next
                                </VBtn>
                            </VCol>
                        </VRow>
                    </VStepperContent>

                    <!-- step 1 -->
                    <VStepperStep
                            step="2"
                            :complete="step > 2"
                            editable
                            complete-icon="mdi-check"
                            edit-icon="mdi-check"
                            error-icon="mdi-alert-octagon"
                            v-if="mode === 'warehouseToJobsite'"
                    >
                        Confirm Delivery Window
                        <small class=pt-1>
                            Please confirm delivery window
                        </small>
                    </VStepperStep>
                    <VStepperContent step="2" v-if="mode === 'warehouseToJobsite'">
                        <VCard>
                            <VCardText>
                                <DatePicker
                                        v-model="form.delivery.delivery_date"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                        label="Delivery Date"
                                        hint="YYYY-MM-DD Format"
                                />
                            </VCardText>
                            <VCardText>
                                <DeliveryWindowSelect
                                        label="Delivery Window"
                                        v-model="form.delivery.delivery_window"
                                        :rules="form.mode === 'warehouseToJobsite'? [rules.required] : []"
                                />
                            </VCardText>
                        </VCard>

                        <VBtn
                                color="primary"
                                class="mt-4"
                                @click="changeStep(3)"
                        >
                            Next
                        </VBtn>

                    </VStepperContent>

                    <!-- step 1 -->
                    <VStepperStep
                        step="3"
                        :complete="step > 3"
                        :rules="[v => stepValid(3)]"
                        editable
                        complete-icon="mdi-check"
                        edit-icon="mdi-check"
                        error-icon="mdi-alert-octagon"
                    >
                        Upload Loaded Items Photos
                        <small class=pt-1 v-if="!stepValid(3)">
                            Please upload load photos
                        </small>
                    </VStepperStep>
                    <VStepperContent step="3">
                        <VCard>
                            <VCardText>
                                <PhotoManager
                                    category="delivery"
                                    :photos="form.delivery_items_photos"
                                    :purchase-order-job-id="jobId"
                                    @uploaded="updateFormRequest"
                                />
                            </VCardText>
                        </VCard>

                        <VCard class="mt-5">
                            <VCardText>
                                <NotesTextField v-model="form.notes"></NotesTextField>
                            </VCardText>
                        </VCard>

                        <VBtn
                                color="primary"
                                class="mt-8"
                                @click="submitForm"
                                :disabled="formSubmitting"
                        >
                            Submit
                        </VBtn>
                    </VStepperContent>
                </div>

            </VStepper>
        </div>
    </VContainer>
</template>

<style scoped>

</style>