<script>
import PageTitle from "@/components/PageTitle.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import {clone, get} from "@/client/utilities/Object";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import BasicImage from "@/components/Photos/BasicImage.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";

export default {
    name: 'ToolCheckOutApproval',
    components: {
        ToolModelAutocomplete,
        ConfirmDialog,
        BasicImage, PhotoThumbnail, PhotoManager, JobInfoChip, DividingHeader, JobAutocomplete, PageTitle
    },
    title: 'Tool Checkout Approval',
    data() {
        return {
            job: null,
            reservations: [],
            pendingModifications: [],
            inventory: {},
            selected: {
                reservations: [],
                inventoryIds: [],
                job: null,
                vehicle: null
            },
            selectedReservation: {
                id: null,
                tool: {
                    name: null,
                },
                inventory: []
            },
            selectedTool: {
                id: null
            },
            data: {
                statuses: [],
                racks: [],
                selected: {
                    reservations: [],
                    job: null,
                    vehicle: null,
                },
                form: {
                    valid: false,
                    loading: false,
                    submitting: false,
                    reservation: {},
                    reservations: [],
                    tool: {},
                    notes: null,
                    photos: [],
                }
            },
            dialog: false,
            form: {
                valid: false,
                loading: false,
                submitting: false,
                reservation: {},
                reservations: [],
                tool: {},
                notes: null,
                photos: [],
                rack: 'Tool Receiving Table',
                section: 'NONE',
                sub_section: 'NONE',
                status: 'Available',
            }
        }
    },
    methods: {
        get,
        changeToolModel(item) {
            console.log('Selected tool model:', item);
            this.$set(item, 'tool_model_id', item.new_tool_model.id);
            this.$set(item, 'tool_model', item.new_tool_model);
            this.$set(item, 'pending_modification', false);
            this.updateReservation(item);
        },
        updateReservation(item) {
            this.$http.post(`/v3/tools/reservations/${item.id}`, {reservation: item}).then(response => {
                const reservation = get(response, 'data.reservation');
                if (reservation) {
                    this.$set(item, 'inventory', reservation.inventory);
                    this.$set(this.selected.inventory, reservation.tool_model_id, reservation.inventory);
                }
            });
        },
        updateToolModelInventory(toolModelId, inventory) {

        },
        cancelModifyReservation(item, index) {
            this.$set(item, 'pending_modification', false);
            this.$set(item, 'original_tool_model', false);
            this.$set(item, 'new_tool_model', false);
        },
        modifyReservation(item, index) {
            this.$set(item, 'pending_modification', true);
            this.$set(item, 'original_tool_model', clone(item.tool_model));
            console.log('Modifying line item:', item);
        },
        selectInventory(item) {
            this.selected.inventoryIds = [];

            for (const res of this.selected.reservations) {
                if (res.pull_location) {
                    this.selected.inventoryIds.push(res.pull_location.id);
                }
            }

            if (item && item.id !== 0) {
                item.disabled = true;
            }

            for (const modelId in this.selected.inventory) {
                for (const inventory of this.selected.inventory[modelId]) {
                    if (this.selected.inventoryIds.indexOf(inventory.id) === -1) {
                        inventory.disabled = false;
                    }
                }
            }
        },
        selectLocation(tool, idx) {
            this.selectedTool = tool;
        },
        async fetchReservations() {
            return this.$http.get('/v3/tools/reservations/batches/pending').then(response => {
                this.reservations = get(response, 'data.reservations', []);
                return this.reservations;
            });
        },
        parseInventory(reservations) {
            const data = clone(reservations);
            const inventory = {};

            for (const res of data) {
                for (const i of res.inventory) {
                    if (!inventory[i.tool_model_id]) {
                        inventory[i.tool_model_id] = [];
                    }

                    inventory[i.tool_model_id][i.id] = i;
                }
            }

            const result = {};

            const keys = Object.keys(inventory);

            for (const key of keys) {
                result[key] = [];

                for (const id of Object.keys(inventory[key])) {
                    result[key].push(inventory[key][id]);
                }

                result[key].push({tool_location_key: 'BACKORDERED', id: 0});
            }

            return result;
        },
        getInventory(toolModelId) {
            if (this.selected.inventory[toolModelId]) {
                return this.selected.inventory[toolModelId].map((item) => {
                    return item;
                });
            }

            return [];
        },
        approve(uuid) {
            this.$http.get('/v3/tools/reservations/by-batch-uuid/' + uuid).then(response => {
                this.selected.reservations = get(response, 'data.reservations', []);
                this.selected.inventory = this.parseInventory(this.selected.reservations);
                this.selected.job = get(response, 'data.reservations.0.job', null);
                this.selected.vehicle = get(response, 'data.reservations.0.vehicle', null);
                this.dialog = true;
            });
        },
        async reject(uuid) {
            return await this.$http.delete('/v3/tools/reservations/by-batch-uuid/' + uuid).then(response => {
                this.fetchReservations();
            });
        },
        submit() {

            if (this.$refs.form.validate()) {
                this.form.reservations = clone(this.selected.reservations);
                const uuid = get(this.form, 'reservations.0.reservation_batch_uuid');

                if (uuid) {

                    this.form.submitting = true;

                    this.$http.post(`/v3/tools/reservations/batch/approve`, this.form)
                        .then(response => {
                            this.dialog = false;
                            this.form = clone(this.data.form);
                            this.fetchReservations();
                        }).finally(() => {
                        this.form.submitting = false;
                    });
                }
            } else {
                this.$toast.error('Please enter missing required fields.');
            }
        },
        async fetchStatuses() {
            return this.$http.get('/v3/tools/statuses').then((response) => {
                return get(response, 'data.statuses', []);
            });
        }
    },
    watch: {
        dialog: (dialog) => {
            if (dialog === false) {
                this.selected = {
                    reservations: [],
                    job: null,
                    vehicle: null,
                }
            }
        }
    },
    async mounted() {
        await this.fetchReservations();
    }
}
</script>

<template>
    <v-container>
        <PageTitle></PageTitle>

        <v-card>
            <v-card-text>
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th># Tools</th>
                        <th>Job</th>
                        <th>Requested At</th>
                        <th class="text-right">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, idx) in reservations">
                        <td>{{ get(item, 'user.name') }}</td>
                        <td>{{ get(item, 'count') }}</td>
                        <td>
                            <JobInfoChip
                                v-if="item.job"
                                :company-slug="get(item, 'job.company.slug', 'N')"
                                :job-number="get(item, 'job.job_number', '')"
                                small
                            />
                            <JobInfoChip
                                v-if="item.vehicle"
                                :job-number="get(item, 'vehicle.name', '')"
                                company-slug="Vehicle"
                                small
                            />
                            <JobInfoChip
                                v-if="item.employee"
                                :job-number="get(item, 'employee.name', '')"
                                company-slug="Employee"
                                small
                            />
                        </td>
                        <td>
                            {{ item.created_at | date }}
                        </td>
                        <td
                            class="text-right"
                        >
                            <v-btn
                                class="mr-4"
                                color="primary"
                                small
                                @click="approve(item.reservation_batch_uuid)"
                            >
                                <v-icon small>mdi-check</v-icon>
                                Approve
                            </v-btn>
                            <ConfirmDialog
                                :message="`Are you sure you want to delete this reservation?`"
                                color="delete"
                                small
                                @confirm="reject(item.reservation_batch_uuid)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </ConfirmDialog>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialog"
            fullscreen
            scrollable
        >
            <v-form ref="form" v-model="form.valid" @submit.prevent="submit">
                <v-card color="navDrawerHeaderBackground">
                    <v-card-title>
                        Approve Checkout {{ get(selected, 'reservations.0.reservation_batch_uuid', '') }}
                    </v-card-title>
                    <v-card-subtitle class="pt-1">
                        <JobInfoChip
                            v-if="selected.job"
                            :company-slug="get(selected, 'job.company.slug', '')"
                            :job-description="get(selected, 'job.description', '')"
                            :job-number="get(selected, 'job.job_number', '')"
                        />
                        <JobInfoChip
                            v-if="selected.vehicle"
                            :job-number="get(selected, 'vehicle.name', '')"
                            company-slug="Vehicle"
                            small
                        />
                    </v-card-subtitle>
                    <v-card-subtitle class="pt-0">
                        <v-divider></v-divider>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-card
                            v-for="(item, idx) in selected.reservations"
                            :key="`selected-reservation-${item.id}`"
                            class="my-4"
                            color="grey darken-4"
                            elevation="4"
                        >
                            <v-card-text>
                                <v-row dense>
                                    <v-col class="flex-grow-0 pr-4">
                                        <div>
                                            <PhotoThumbnail
                                                :photo="get(item, 'tool_model.photo')"
                                                width="70"
                                            />
                                        </div>
                                        <div class="pt-3">
                                            <v-btn
                                                v-show="!item.pending_modification"
                                                block
                                                small
                                                @click="modifyReservation(item)"
                                                color="primary"
                                            >
                                                <v-icon>mdi-swap-horizontal</v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-show="item.pending_modification"
                                                block
                                                small
                                                @click="cancelModifyReservation(item)"
                                                color="delete"
                                            >
                                                <v-icon>mdi-cancel</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col class="flex-grow-1">
                                        <v-row class="mb-2" dense>
                                            <v-col>
                                                <h3 class="d-inline">{{ get(item, 'tool_model.name') }}</h3>
                                                <v-chip class="pl-0 py-0 my-0 ml-2" color="blue-grey darken-2" label
                                                        small>
                                                     <span
                                                         class="grey darken-3 py-2 px-2 mr-2"
                                                     >
                                                    Model</span>
                                                    {{ get(item, 'tool_model.sku') }}
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.pending_modification">
                                            <v-col>
                                                <ToolModelAutocomplete
                                                    v-model="item.new_tool_model">

                                                </ToolModelAutocomplete>
                                            </v-col>
                                            <v-col cols="4">
                                                <div class="d-flex">
                                                    <div class="mr-3">
                                                        <v-btn
                                                            color="info"
                                                            @click="changeToolModel(item)"
                                                        >
                                                            <v-icon left>mdi-content-save-check-outline</v-icon>
                                                            Change
                                                        </v-btn>
                                                    </div>
                                                    <div>
                                                        <v-btn
                                                            color="delete"
                                                            @click="cancelModifyReservation(item)"
                                                        >
                                                            <v-icon left>mdi-cancel</v-icon>
                                                            Cancel
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>
                                                <v-autocomplete
                                                    v-model="item.pull_location"
                                                    :items="(item.inventory.length > 0 ? getInventory(item.tool_model_id) : [{tool_location_key: 'BACKORDERED', id: 0}])"
                                                    :rules="[v =>!!v || 'Please select a location']"
                                                    clearable
                                                    item-text="tool_location_key"
                                                    item-value="id"
                                                    label="Select Pull Location"
                                                    outlined
                                                    return-object
                                                    hide-details="auto"
                                                    @change="selectInventory"
                                                >
                                                    <template v-slot:selection="data">
                                                        <v-list-item-avatar tile width="10">
                                                            <BasicImage
                                                                style="width:10px;"
                                                                :src="get(data, 'item.photo.thumbnail.url')"
                                                            />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ get(data, 'item.tool_location_key') }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle
                                                                v-show="get(data, 'item.serial_number')"
                                                                style="font-size:12px;">
                                                                {{ get(data, 'item.serial_number') }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>

                                                    <template v-slot:item="data">
                                                        <v-list-item-avatar tile>
                                                            <BasicImage
                                                                :src="get(data, 'item.photo.thumbnail.url')"
                                                            />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ get(data, 'item.tool_location_key') }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                {{ get(data, 'item.sku') }}
                                                                <span v-show="get(data, 'item.serial_number')">
                                                                    - <b>Serial #</b> {{
                                                                        get(data, 'item.serial_number', 'N/A')
                                                                    }}
                                                                </span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!--
                                    <v-col cols="10">
                                        <v-row dense>
                                            <v-col>
                                                <h3 class="d-inline">{{ get(item, 'tool_model.name') }}</h3>
                                                <v-chip class="pl-0 py-0 my-0 ml-2" color="blue-grey darken-2" label
                                                        small>
                                                     <span
                                                         class="grey darken-3 py-2 px-2 mr-2"
                                                     >
                                                    Model</span>
                                                    {{ get(item, 'tool_model.sku') }}
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>
                                                <v-autocomplete
                                                    v-model="item.pull_location"
                                                    :items="(item.inventory.length > 0 ? getInventory(item.tool_model_id) : [{tool_location_key: 'BACKORDERED', id: 0}])"
                                                    :rules="[v =>!!v || 'Please select a location']"
                                                    class="my-2"
                                                    clearable
                                                    item-text="tool_location_key"
                                                    item-value="id"
                                                    label="Select Pull Location"
                                                    outlined
                                                    return-object
                                                    @change="selectInventory"
                                                >
                                                    <template v-slot:selection="data">
                                                        <v-list-item-avatar tile width="20">
                                                            <BasicImage
                                                                :src="get(data, 'item.photo.thumbnail.url')"
                                                                width="20"
                                                            />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ get(data, 'item.tool_location_key') }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>

                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle
                                                                v-show="get(data, 'item.serial_number')"
                                                                style="font-size:12px;">
                                                                {{ get(data, 'item.serial_number') }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>

                                                    <template v-slot:item="data">
                                                        <v-list-item-avatar tile>
                                                            <BasicImage
                                                                :src="get(data, 'item.photo.thumbnail.url')"
                                                            />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ get(data, 'item.tool_location_key') }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                {{ get(data, 'item.sku') }}
                                                                <span v-show="get(data, 'item.serial_number')">
                                                                    - <b>Serial #</b> {{
                                                                        get(data, 'item.serial_number', 'N/A')
                                                                    }}
                                                                </span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>-->
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="5">
                            <v-card-title>Checkout Photos</v-card-title>
                            <v-card-text>
                                <PhotoManager
                                    :photos="form.photos"
                                    :tool-reservation-id="(selectedReservation.id + '')"
                                    category="tool-checkout-approval"
                                />
                            </v-card-text>
                        </v-card>
                        <v-card class="my-2">
                            <v-card-text>
                                <v-text-field
                                    v-model="form.notes"
                                    hide-details
                                    label="Notes"
                                    outlined
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-actions
                        class="pa-4"
                    >

                        <v-btn :disabled="form.submitting"
                               :loading="form.submitting"
                               color="primary"
                               type="submit"
                        >
                            Approve Check Out
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn color="red darken-1" text @click="dialog = false">Cancel</v-btn>

                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<style scoped>
tr.selected {
    background-color: rgba(113, 213, 255, 0.2);
}
</style>