<script>
import PageTitle from "@/components/PageTitle.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import {get} from "../../../client/utilities/Object";

export default {
    name: 'ToolCategories',
    components: {ConfirmDialog, DividingHeader, PageTitle},
    title: 'Tool Categories',
    data: () => {
        return {
            categories: [],
            categoryTypes: [
                'Consumable',
                'Accessory',
                'Attachment',
                'Equipment',
                'Rental'
            ],
            category: {
                name: null,
                description:null,
                category_type: null,
                parent_tool_category_id: null,
                requires_certification: false,
            },
            form: false
        }
    },
    methods: {
        get,
        fetchCategories() {
            this.$http.get('/v3/tools/categories').then(response => {
                this.categories = response.data;
            })
        },
        createCategory() {
            this.$http.post('/v3/tools/categories', this.category).then(response => {
                this.category = {
                    name: null,
                    parent_tool_category_id: null,
                };
                this.fetchCategories();
            })
        },
        removeCategory(id) {
            this.$http.delete('/v3/tools/categories/' + id).then(response => {
                this.fetchCategories();
            });
        }
    },
    mounted() {
        this.fetchCategories();
    }
}

</script>

<template>
    <v-container>

        <PageTitle />

        <v-form v-model="form" @submit.prevent="createCategory">
            <v-card>
                <v-card-title>Create Category</v-card-title>
                <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                        outlined
                                        v-model="category.name"
                                        label="Name"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                        outlined
                                        v-model="category.description"
                                        label="Description"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-autocomplete
                                    :items="categories"
                                    v-model="category.parent_tool_category_id"
                                    outlined
                                    label="Parent Category"
                                    item-text="name"
                                    item-value="id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-autocomplete
                                        outlined
                                        :items="categoryTypes"
                                        label="Category Type"
                                        v-model="category.category_type"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col>
                                <b>Requires Certification</b><br>
                                <v-switch
                                        v-model="category.requires_certification"
                                ></v-switch>
                            </v-col>
                        </v-row>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn type="submit" color="primary">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-divider class="my-3"></v-divider>

        <div
            v-for="(cat, idx) in categories" :key="`cat${idx}`"
            v-if="cat.parent_tool_category_id === null"
            class="my-3"
        >
            <DividingHeader>{{cat.name}}</DividingHeader>

            <div
                v-for="(item, childIdx) in cat.child_categories" :key="childIdx"
            >
                <v-card
                        class="ml-6 mt-2"
                        outlined
                >
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="1">
                                <ConfirmDialog color="delete" small message="Delete category?" @confirm="removeCategory(item.id)">
                                    Remove
                                </ConfirmDialog>
                            </v-col>
                            <v-col>
                                {{item.name}}
                            </v-col>
                            <v-col>
                                <span class="parent-category">{{item.category_type}}</span>
                            </v-col>
                            <v-col>
                                <b>Requires Certification?</b> <br>
                                {{item.requires_certification? 'Yes' : 'No'}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>

                <div
                        v-for="(item1, childIdx1) in item.child_categories" :key="childIdx1"
                        v-if="item.child_categories"
                >
                    <v-card
                        class="ml-10 mt-2"
                        outlined
                    >
                        <v-card-subtitle>
                            <v-row>
                                <v-col cols="1">
                                    <ConfirmDialog color="delete" small message="Delete category?" @confirm="removeCategory(item1.id)">
                                        Remove
                                    </ConfirmDialog>
                                </v-col>
                                <v-col>
                                    {{item1.name}}
                                </v-col>
                                <v-col>
                                    <span class="parent-category">{{item1.category_type}}</span>
                                </v-col>
                                <v-col>
                                    <b>Requires Certification?</b> <br>
                                    {{item1.requires_certification? 'Yes' : 'No'}}
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                    </v-card>

                    <div
                            v-for="(item2, childIdx2) in item1.child_categories" :key="childIdx2"
                            v-if="item1.child_categories"
                    >
                        <v-card
                                class="ml-12 mt-2"
                                outlined
                        >
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="1">
                                        <ConfirmDialog color="delete" small message="Delete category?" @confirm="removeCategory(item2.id)">
                                            Remove
                                        </ConfirmDialog>
                                    </v-col>
                                    <v-col>
                                        {{item2.name}}
                                    </v-col>
                                    <v-col>
                                        <span class="parent-category">{{item2.category_type}}</span>
                                    </v-col>
                                    <v-col>
                                        <b>Requires Certification?</b> <br>
                                    {{item2.requires_certification? 'Yes' : 'No'}}
                                    </v-col>
                                </v-row>
                            </v-card-subtitle>
                        </v-card>
                        <div
                                v-for="(item3, childIdx2) in item2.child_categories" :key="childIdx3"
                                v-if="item2.child_categories"
                        >
                            <v-card
                                    class="ml-14 mt-2"
                                    outlined
                            >
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col cols="1">
                                            <ConfirmDialog color="delete" small message="Delete category?" @confirm="removeCategory(item3.id)">
                                                Remove
                                            </ConfirmDialog>
                                        </v-col>
                                        <v-col>
                                            {{item3.name}}
                                        </v-col>
                                        <v-col>
                                            <span class="parent-category">{{item3.category_type}}</span>
                                        </v-col>
                                        <v-col>
                                            Requires Certification? <br>
                                            {{item3.requires_certification ? 'Yes' : 'No'}}
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                        </div>
                    </div>

                </div>

            </div>


        </div>

    </v-container>
</template>

<style scoped>
.parent-category {
    font-weight:normal;
    color: #8c8989;
}
</style>