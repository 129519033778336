import PageTitle from "@/components/PageTitle.vue";
import { get } from "@/client/utilities/Object";
import cloneObject from "@/common/clone-object";
import DividingHeader from "@/components/DividingHeader.vue";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import NotesTextFieldV2 from "@/components/V2/NotesTextField-V2.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import ToolAutocomplete from "@/components/ToolAutocomplete.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import ToolStatusAutocomplete from "@/components/Tools/Autocompletes/ToolStatusAutocomplete.vue";
import CurrencyInput from "@/components/Input/CurrencyInput.vue";
import ToolCategories from "@/views/V3/Tools/ToolCategories.vue";
import ToolCategoryAutocomplete from "@/components/Tools/Autocompletes/ToolCategoryAutocomplete.vue";
import ToolManufacturerAutocomplete from "@/components/Tools/Autocompletes/ToolManufacturerAutocomplete.vue";
import ToolNameInput from "@/components/Tools/Inputs/ToolNameInput.vue";
import ToolDescriptionInput from "@/components/Tools/Inputs/ToolDescriptionInput.vue";
import ToolModelNumberInput from "@/components/Tools/Inputs/ToolModelNumberInput.vue";
import ToolCompatibilityAutocomplete from "@/components/Tools/Autocompletes/ToolCompatibilityAutocomplete.vue";
import QuantityInput from "@/components/Tools/Inputs/QuantityInput.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import VehicleAutocomplete from "@/components/VehicleAutocomplete.vue";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";
export default {
    name: 'CreateConsumable',
    components: {
        LoadingDialog,
        VehicleAutocomplete,
        FormSubmittedMessage,
        QuantityInput,
        ToolCompatibilityAutocomplete,
        ToolModelNumberInput,
        ToolDescriptionInput,
        ToolNameInput,
        ToolManufacturerAutocomplete,
        ToolCategoryAutocomplete,
        ToolCategories,
        CurrencyInput,
        ToolStatusAutocomplete,
        NotesTextField,
        ToolAutocomplete,
        PhotoThumbnail,
        ToolModelAutocomplete, JobAutocomplete, NotesTextFieldV2, PhotoManager, DividingHeader, PageTitle
    },
    title: 'Create Consumable',
    data() {
        return {
            rules: {
                required: [v => !!v || 'Required']
            },
            data: {
                tool_models: [],
                categories: [],
                manufacturers: [],
                racks: [],
                statuses: []
            },
            loadingTool: false,
            toolModelId: null,
            photoUploading: false,
            selectedToolModels: [],
            validationErrors: [],
            submitting: false,
            submitted: false,
            valid: false,
            form: {
                locationType: 'warehouse',
                photos: [],
                quantity: 1,
                vehicle: null,
                name: null,
                sku: null,
                product: null,
                description: null,
                price: null,
                tool_model: null,
                notes: null,
                linked_tool_models: [],
                status: null,
                serial_number: null,
                category: null,
                manufacturer: null,
                rack: null,
                section: null,
                sub_section: null,
                job: null
            }
        };
    },
    methods: {
        get,
        removeLinkedToolModel(tool_model) {
            this.form.linked_tool_models = this.form.linked_tool_models.filter((item) => {
                return item.id !== tool_model.id;
            });
        },
        async submit() {
            if (this.$refs.consForm.validate() && !this.submitting) {
                this.submitting = true;
                return await this.$http.post('/v3/tools/consumables', this.form).then((response) => {
                    this.submitted = true;
                }).finally((response) => {
                    this.submitting = false;
                });
            }
            else {
                this.validationErrors = this.$refs.consForm.inputs.filter(input => !input.valid)
                    .map(input => input.errorBucket)
                    .flat();
                this.submitting = false;
                this.submitted = false;
            }
        },
        async fetchRacks() {
            return this.$http.get('/v3/tools/locations/racks').then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                return this.data.racks;
            });
        },
        racks() {
            return this.data.racks;
        },
        sections(rack) {
            return get(rack, 'sections', []);
        },
        subSections(section) {
            return get(section, 'sub_sections', []);
        },
        onUploading(uploading) {
            this.photoUploading = uploading;
        },
        async fetchConsumable() {
            let id = this.$route.params.id;
            return this.$http.get('/v3/tools/consumables/' + id).then((response) => {
                return response.data;
            });
        },
        getRack(rackId) {
            if (rackId) {
                const rack = this.data.racks.filter((r) => r.id === rackId);
                if (rack) {
                    return cloneObject(rack[0]);
                }
            }
            return [];
        },
        getSection(rack, sectionId) {
            if (rack && sectionId) {
                const section = rack.sections.filter((s) => s.id === sectionId);
                if (section) {
                    return cloneObject(section[0]);
                }
            }
            return [];
        },
        getSubSection(section, subSectionId) {
            if (section && subSectionId) {
                const subSection = section.sub_sections.filter((s) => s.id === subSectionId);
                if (subSection) {
                    return cloneObject(subSection[0]);
                }
            }
            return [];
        },
    },
    computed: {
        editing() {
            return this.$route.params.id > 0;
        }
    },
    watch: {
        'form.tool_model'(model) {
            this.form.product = 'no-clear';
            if (model) {
                this.form.manufacturer = model.tool_manufacturer;
                this.form.category = model.tool_category;
                this.form.name = model.name;
                this.form.sku = model.sku;
                this.form.description = model.description;
                this.form.price = model.price;
                this.form.photos = model.photos;
            }
            else {
                this.form.manufacturer = null;
                this.form.category = null;
                this.form.name = null;
                this.form.sku = null;
                this.form.description = null;
                this.form.price = null;
                this.form.photos = [];
            }
        },
        'form.product'(product) {
            if (product && product !== 'no-clear') {
                this.form.name = this.form.product.title;
                this.form.description = this.form.product.description;
                this.form.sku = this.form.product.sku;
                this.form.sku = this.form.product.sku;
                this.form.category = get(product, 'product_category');
                this.form.manufacturer = this.data.manufacturers.find((manufacturer) => manufacturer.slug === get(product, 'manufacturer.slug'));
                const images = this.form.product.images;
                for (let i = 0; i < images.length; i++) {
                    let url = images[i].url;
                    if (!url) {
                        url = images[i].original_url;
                    }
                    let photo = {
                        id: null,
                        url: url,
                        original_filename: '',
                        thumbnail: {
                            url: url
                        }
                    };
                    this.form.photos.push(photo);
                }
            }
            else {
                if (product !== 'no-clear') {
                    this.form.name = null;
                    this.form.description = null;
                    this.form.sku = null;
                    this.form.photos = [];
                }
            }
        },
    },
    async mounted() {
        await this.fetchRacks();
        if (this.$route.params.id) {
            this.loadingTool = true;
            let tool = await this.fetchConsumable();
            this.loadingTool = false;
            this.data.accessory = tool;
            this.toolModelId = get(tool, 'tool_model_id');
            this.form.id = tool.id;
            if (tool.job) {
                this.form.job = tool.job;
                this.form.locationType = 'jobsite';
            }
            else if (tool.vehicle) {
                this.form.vehicle = tool.vehicle;
                this.form.locationType = 'vehicle';
            }
            else {
                this.form.locationType = 'warehouse';
                this.form.rack = this.getRack(tool.tool_rack_id);
                this.form.section = this.getSection(this.form.rack, tool.tool_section_id);
                this.form.sub_section = this.getSubSection(this.form.section, tool.tool_sub_section_id);
            }
            this.form.name = tool.name;
            this.form.description = tool.description;
            this.form.category = tool.tool_category;
            this.form.manufacturer = tool.tool_manufacturer;
            this.form.photos = tool.photos;
            this.form.notes = tool.notes;
            this.form.status = tool.tool_status;
            this.form.price = tool.price;
            this.form.sku = tool.sku;
        }
    }
};
