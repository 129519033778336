<script>
import PageTitle from "@/components/PageTitle.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import TextInput from "@/components/TextInput.vue";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import {get, clone} from "@/client/utilities/Object";
import {isArray} from "@/client/utilities/Array";
import Api from "@/client/api/Api";
import ApiResponseError from "@/client/api/lib/ApiResponseError";
import CreateSubscriptionForm from "@/components/Notification/Subscription/CreateSubscriptionForm.vue";

export default {
    name: 'NotificationSubscriptions',
    components: {
        CreateSubscriptionForm,
        FormSubmitButton, TextInput, JobsAutocomplete, DividingHeader, ConfirmDialog, PageTitle},
    title: 'Notification Subscriptions',
    data() {
        return {
            showForm: false,
            formValid: false,
            formSubmitting: false,
            subscriptions: [],
            notificationTypes: [],
            form: {
                email: null,
                job: null,
                notificationType: null
            },
            jobs: [],
            data: {
                jobs: []
            },
            loading: true,
            error: null,
            closeFormCallback: null,
        };
    },
    methods: {
        get,
        isArray,
        toggleForm() {
            this.showForm =!this.showForm;
        },
        closeForm() {
            this.showForm = false;
            this.fetchSubscriptions();
        },
        createSubscription() {
            let payload = {
                email: this.form.email,
                job_id: this.form.job.id,
            };

            this.$http.post('/v3/notifications/subscriptions', payload)
                .then((response) => {

                }).finally(() => {
                    this.resetForm();
                    this.fetchSubscriptions();
            });
        },
        fetchNotificationTypes() {
            this.$http.get('/v3/notifications/types')
              .then(response => {
                    this.notificationTypes = response.data;
                })
              .catch(error => {
                    this.error = error;
                })
              .finally(() => {
                    this.loading = false;
                });
        },
        fetchJobs() {
            this.$http.get('/v3/jobs/open/for-admins')
              .then(response => {
                    this.data.jobs = response.data;
                    this.jobs = clone(this.data.jobs);
                })
              .catch(error => {
                    this.error = error;
                })
              .finally(() => {
                    this.loading = false;
                });
        },
        async fetchSubscriptions() {

            this.loading = true;
            this.error = null;

            this.$http.get('/v3/notifications/subscriptions').then(response => {
                this.subscriptions = get(response, 'data.notification_subscriptions', []);
            }).catch(error => {})
                .finally(() => {
                    this.loading = false;
                });

            /*
            const subscriptions = await Api.notificationSubscriptions.get().catch(error  => {

            });

            if(subscriptions) {
                this.subscriptions = subscriptions.data;
            }

             */
        },
        deleteSubscription(subscriptionId) {
            this.loading = true;
            this.error = null;
            this.$http.delete('/v3/notifications/subscriptions/' + subscriptionId)
             .then(response => {
                    this.loading = false;
                })
             .catch(error => {
                    this.error = error;
                    this.loading = false;
                })
                .finally(() => {
                    this.fetchSubscriptions();
                });
        }
    },
    mounted() {
        //this.fetchNotificationTypes();
        this.fetchJobs();
        this.fetchSubscriptions();
    }
}
</script>

<style lang="scss" scoped>
    .col-title {
        font-weight: bold;
        font-size:12px;
        margin-bottom: 2px;
    }
    .row {
        font-size:15px;
    }

    .theme--dark {

        $rteBackgroundColor: rgba(114, 35, 35);
        $rteTextColor: #fff;
        $p1BackgroundColor: rgba(30, 58, 93);
        $p1TextColor: #fff;

        $chipBackgroundColor: rgba(55, 71, 79);

        .v-chip:not(.v-chip--active) {

            &.v-chip--disabled {
                opacity: 1;
            }

            background: $chipBackgroundColor;

            &.RTE {
                background: $rteBackgroundColor;
                color: $rteTextColor;
                .v-avatar {
                    background-color: darken($rteBackgroundColor, 10%);
                }
            }

            &.P1 {
                background: $p1BackgroundColor;
                color: $p1TextColor;
                .v-avatar {
                    background-color: darken($p1BackgroundColor, 10%);
                }
            }
        }
    }
</style>

<template>
    <VContainer>
        <PageTitle>
            Notification Subscriptions
            <VBtn class="float-right" small color="primary" @click="toggleForm">
                <VIcon>mdi-plus</VIcon>
                Create New Subscription
            </VBtn>
        </PageTitle>

        <CreateSubscriptionForm
                :show="showForm"
                v-show="showForm"
                @close="closeForm"
        />

        <VDivider v-show="showForm" class="my-6"></VDivider>

        <VCard
                v-for="subscription in subscriptions"
                :key="subscription.id"
                class="my-2"
                outlined
        >
            <VCardSubtitle class="py-1 px-2">
                <VRow dense>
                    <VCol>
                        <VChipGroup>
                            <VChip outlined small disabled label>
                                {{get(subscription, 'id')}}
                            </VChip>
                            <VChip small disabled label :class="`pl-1 ${get(subscription, 'company.slug')}`">
                                <VAvatar tile left class="px-5" style="height:100% !important;">
                                    {{get(subscription, 'company.slug')}}
                                </VAvatar>
                                {{get(subscription, 'purchase_order_job.description')}}
                            </VChip>
                            <VChip small disabled label>
                                <VIcon small left color="blue-grey lighten-3">mdi-calendar-clock-outline</VIcon>
                                {{get(subscription, 'created_at') | date}}
                            </VChip>
                        </VChipGroup>
                    </VCol>

                    <VSpacer></VSpacer>

                    <VCol class="text-right pt-1 pr-1">
                        <VChip disabled outlined label x-small>
                            {{get(subscription, 'notification_type.name')}}
                        </VChip>
                    </VCol>
                </VRow>
            </VCardSubtitle>
            <VCardText>
                <VRow>

                    <VCol>
                        <div class="col-title font-italic grey--text">
                            To Email
                        </div>
                        {{subscription.email}}
                    </VCol>

                    <VCol>
                        <div class="col-title font-italic grey--text">
                            Job Number
                        </div>
                        <b>{{get(subscription, 'purchase_order_job.job_number')}}</b>
                    </VCol>

                    <VSpacer></VSpacer>

                    <VCol class="text-right pt-6">

                        <ConfirmDialog
                                :message="`Delete subscription ${subscription.id}?`"
                                @confirm="deleteSubscription(subscription.id)"
                                color="error"
                                outlined
                                small
                                text
                        >
                            <VIcon left small>mdi-delete</VIcon>
                            Remove
                        </ConfirmDialog>

                    </VCol>
                </VRow>
            </VCardText>
        </VCard>
    </VContainer>
</template>