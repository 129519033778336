import Client from "@/client/api/lib/Client";
import ApiResponse from "@/client/api/lib/ApiResponse";
import Company from "@/client/models/Company/Company";
export default class CompanyService extends Client {
    model = new Company;
    dataKey = 'companies';
    async get() {
        return await this.request.get('/companies');
    }
}
