import { get } from "@/client/utilities/Object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import ToolCertificationChip from "@/components/Chips/ToolCertificationChip.vue";
export default {
    components: { ToolCertificationChip, ConfirmDialog },
    data() {
        return {
            certifications: [],
        };
    },
    methods: {
        get,
        remove(certificationId) {
            this.$http.delete(`/v3/tools/certifications/${certificationId}`).then(response => {
                this.fetchCertifications();
            });
        },
        async fetchCertifications() {
            return this.$http.get('/v3/tools/certifications').then(response => {
                this.certifications = get(response, 'data.certifications', []);
            });
        }
    },
    async created() {
        await this.fetchCertifications();
    }
};
