import { get } from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import BasicImage from "@/components/Photos/BasicImage.vue";
export default {
    name: 'ToolCompatibilityAutocomplete',
    components: { BasicImage },
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'tool'
        },
        toolModelId: {
            type: [String, Number, Boolean],
            default: false
        }
    },
    data() {
        return {
            currentPage: 1,
            loadedPages: [],
            lastPage: 1,
            perPage: 15,
            remoteTotal: 0,
            localTotal: 0,
            loadingOnScroll: false,
            models: [],
            selectedModels: [],
            search: null,
            loading: false,
        };
    },
    computed: {
        lazySrc() {
            return '/img/icons/placeholder.svg';
        }
    },
    watch: {
        selectedModels(models) {
            this.$emit('input', models);
        },
        async toolModelId(modelId) {
            const items = await this.fetchCompatibilities();
            for (const item of items) {
                const model = get(item, 'related_tool_model');
                if (model) {
                    this.selectedModels.push(model);
                }
            }
        },
        search(value) {
            console.log(value);
            this.resetScrollState();
            this.loading = true;
            this.debounceFetchToolModels();
        }
    },
    methods: {
        get,
        resetScrollState() {
            this.currentPage = 1;
            this.lastPage = 1;
            this.total = 0;
            this.remoteTotal = 0;
            this.models = [];
            this.loadedPages = [];
        },
        remove(model) {
            this.selectedModels = this.selectedModels.filter(item => {
                return item.id !== model.id;
            });
        },
        async fetchToolModels() {
            this.loading = true;
            const params = {
                search: this.search,
                type: this.type,
                page: this.currentPage
            };
            this.$log.log(`Fetching tool models. [Search Query: ${this.search}] [Tool Type: ${this.type}] [Page: ${this.currentPage}]`);
            return this.$http.get('/v3/tools/models', { params: params })
                .then(response => {
                const data = get(response, 'data.tool_models', {});
                this.currentPage = get(data, 'current_page', 1);
                this.lastPage = get(data, 'last_page', 1);
                this.remoteTotal = get(data, 'total', 0);
                const models = get(data, 'data', []);
                this.models = [
                    ...this.models,
                    ...models
                ];
                this.localTotal = this.models.length;
            }).finally(() => {
                this.loading = false;
            });
        },
        async fetchCompatibilities() {
            if (this.toolModelId) {
                return this.$http.get('/v3/tools/compatibilities/' + this.toolModelId).then(response => {
                    return get(response, 'data.tool_compatibilities', []);
                });
            }
        },
        onIntersect() {
            if (this.currentPage < this.lastPage && !this.loadingOnScroll) {
                this.loadingOnScroll = true;
                this.currentPage += 1;
                this.$log.log(`Loading page ${this.currentPage} from scroll intersection event`);
                this.fetchToolModels().finally(() => {
                    this.loadingOnScroll = false;
                });
            }
        },
        debounceFetchToolModels: debounce(async function () { await this.fetchToolModels(); }, 400),
    },
    async mounted() {
        await this.fetchToolModels();
    }
};
